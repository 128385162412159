/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Col, ButtonToolbar, Card, CardBody, Spinner
} from 'reactstrap';
import axios from '../../../customAxios';
import S3FileUpload from 'react-s3';
import { useParams, useNavigate } from 'react-router-dom';
import { environment } from '../../env';
import swal from 'sweetalert';

// /** Working one */ justpadel aws
const config = {
  // bucketName: 'test-padel',
  bucketName: 'justpadel-courtimages',
  region: 'us-east-1',
  accessKeyId: 'AKIAZZLUGKQZZJT3G4FX',
  secretAccessKey: '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',
};

const Slides = () => {
  const [titles, setTitles] = useState();
  const [subTitle, setSubTitle] = useState();
  const [redirectUrl, setRedirectUrl] = useState();
  const [courtImage, setCourtImage] = useState();
  const [courtImageUrl, setCourtImageUrl] = useState();
  const [buttonText, setButtonText] = useState();
  const [sliderPosition, setSliderPosition] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('Token')) {
      console.log('True');
    } else {
      alert('You are not logged In. Please LogIn');
      navigate('/');
    }
  }, []);

  function handelChangeRedirectUrl(e) {
    setRedirectUrl(e.target.value);
  }

  function handleChangeTitle(e) {
    setTitles(e.target.value);
  }

  function handelChangeSubTitle(e) {
    setSubTitle(e.target.value);
  }

  function handelChangeButtonText(e) {
    setButtonText(e.target.value);
  }

  function handleChangesliderPosistion(e) {
    setSliderPosition(e.target.value);
  }

  function handleChangeSlideImage(e) {
    setCourtImage(e.target.files[0]);
    console.log(e.target.files[0]);
    S3FileUpload.uploadFile(e.target.files[0], config)
      .then((data) => {
        setCourtImageUrl(data.location);
      })
      .catch((err) => {
        console.log('Error Info: ', err);
      });
  }
  const getSlides = `${environment.baseUrl}getslidesbyid/${id}/`;

  const GetSlides = async () => {
    const result = await axios(getSlides);
    console.log(result.data);
    setRedirectUrl(result.data.redirect_url);
    setTitles(result.data.title);
    setSubTitle(result.data.sub_title);
    setButtonText(result.data.button_text);
    setCourtImageUrl(result.data.image_url);
    setSliderPosition(result.data.order_id);
  };

  useEffect(() => {
    GetSlides();
  }, []);

  const updateSlides = `${environment.baseUrl}updateslidesbyid/${id}/`;

  const handleSubmit = () => {
    setIsLoading(true)

    const SlideData = {
      image_url: courtImageUrl,
      title: titles,
      sub_title: subTitle,
      redirect_url: redirectUrl,
      button_text: buttonText,
      order_id: parseInt(sliderPosition),
    };

    axios
      .put(updateSlides, SlideData)
      .then((response) => {
        if (response.data.msg === "Slides Updated Successfully") {
          swal({
            title: "Success",
            text: response.data.msg,
            icon: "success",
            button: "Ok",
          }).then(() => {
            navigate("/list_slides");
          });
          setIsLoading(false);
        } else {
          swal({
            title: "Error",
            text: response.data.msg,
            icon: "error",
            button: "Ok",
          });
          setIsLoading(false);
        }
      }).catch((err) => {
        swal({
          title: "Error",
          text: err.messgae,
          icon: "error",
          button: "Ok",
        }).then(() => {
          navigate("/list_slides");
        });
        setIsLoading(false);
      });
  };

  const reset = () => {
    navigate('/list_slides')
  };

  return (
    <Col md={12} lg={12}>
      {isLoading && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}
      <Card>
        <CardBody>
          <form className="form product-edit" encType="multipart/form-data">
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label" style={{ fontSize: 15 }}>Redirect Url</span>
                <div className="form__form-group-field">
                  <input type="text" value={redirectUrl} onChange={handelChangeRedirectUrl} style={{ borderRadius: 5 }} />
                </div>
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label" style={{ fontSize: 15 }}>Title</span>
                <div className="form__form-group-field">
                  <input type="text" value={titles} onChange={handleChangeTitle} style={{ borderRadius: 5 }} />
                </div>
              </div>
            </div>
            <div className="form__half" style={{ marginTop: 15 }}>
              <div className="form__form-group">
                <span className="form__form-group-label" style={{ fontSize: 15 }}>Sub Title</span>
                <div className="form__form-group-field">
                  <input type="text" value={subTitle} onChange={handelChangeSubTitle} style={{ borderRadius: 5 }} />
                </div>
              </div>
            </div>
            <div className="form__half" style={{ marginLeft: 30, paddingTop: 20 }}>
              <div className="form__form-group">
                <span className="form__form-group-label" style={{ fontSize: 15 }}>Button Text</span>
                <div className="form__form-group-field">
                  <input type="text" value={buttonText} onChange={handelChangeButtonText} style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} />
                </div>
              </div>
            </div>
            <div className="form__half" style={{ marginTop: 15 }}>
              <div className="form__form-group">
                <span className="form__form-group-label" style={{ fontSize: 15 }}>Select Slide Image</span>
                <div className="form__form-group-field">
                  <input type="file" onChange={handleChangeSlideImage} style={{ borderRadius: 5 }} />
                </div>
              </div>
            </div>
            <div className="form__half" style={{ marginLeft: 30, paddingTop: 20 }}>
              <div className="form__form-group">
                <span className="form__form-group-label" style={{ fontSize: 15 }}>Position</span>
                <div className="form__form-group-field">
                  <input type="number" value={sliderPosition} onChange={handleChangesliderPosistion} style={{ borderRadius: 5 }} />
                </div>
              </div>
            </div>
            <Col md={12} lg={12}>
              <Card style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", marginTop: 30, }}>
                <div className="form__half" style={{ marginRight: -13 }}>
                  <ButtonToolbar className="form__button-toolbar" >
                    <div className='justpadel-buttons-save' onClick={handleSubmit}>
                      Update
                    </div>
                    <div className='justpadel-buttons-cancel' onClick={() => reset()}>
                      Cancel
                    </div>
                  </ButtonToolbar>
                </div>
              </Card>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default Slides;
