import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';
const MatchMakingsidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Matchmaking" icon="file-add">
            {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <SidebarLink title={`Coaches`} route="/coache-calender" />}
            {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <SidebarLink title="Customer Support" route="/customer-support-calender" />}
        </SidebarCategory>
    )
}

export default MatchMakingsidebar;