import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';

const ManageUser = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Manage User" icon="select">
            {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <SidebarLink title={`Create User`} route="/create_user" />}
            {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <SidebarLink title="Update User" route="/update-user" />}
        </SidebarCategory>
    )
}

export default ManageUser