import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';

const AdsSpaceSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Ads Space" icon="indent-increase">
            {getValueByKey(authslice.loggedAdminPermission, "slides") != 4 && <SidebarLink title="Ads Space List" route="/ad-space-list" />}
            {getValueByKey(authslice.loggedAdminPermission, "slides") != 4 && <SidebarLink title="Ads Analysis" route="/ads-impressions" />}
        </SidebarCategory>
    );
};

export default AdsSpaceSidebar