import { environment } from "../containers/env";

export const adminEndPoints = {
    padelcourt: `${environment.baseUrl}padelcourt`,
    calenderV3: `${environment.baseUrl}calenderv3`,
    bookingList: `${environment.baseUrl}court-booking-list`,
    quickPayPagination: `${environment.baseUrl}quicks-pay-paginations`,
    userTransactionLogsPagination: `${environment.baseUrl}user-transaction-log`,
    excelReportSearch: `${environment.baseUrl}transaction_log_excel_report_search`,
    couponBasedBookings: `${environment.baseUrl}coupon-based-bookings`,
    downloadCouponReportExcel: `${environment.baseUrl}download-coupon-based-bookings`,
    adminUsersList: `${environment.baseUrl}admin-user-list`,
};