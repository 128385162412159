import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { GetMainCourtForPromotion, UpdatePromotionById, GetPromotionById } from '../../Reuse/ApiCalls';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import { useNavigate } from 'react-router-dom';


const EditPromotions = () => {
  const { id } = useParams();

  const [mainCourtList, setMainCourtList] = useState([]);
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const [times, setTimes] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
    "12:45 AM",
    "01:30 AM",
    "02:15 AM",
  ]);

  const onSubmit = (data) => {
    UpdatePromotionById(id, data).then((response) => {
      if (response.msg === "Promotion is not updated") {
        swal({
          icon: "error",
          content: (<div>
            <h1 style={{ color: "darkred" }}>Error</h1>
            <p>
              Error While Updating Promotion
            </p>
          </div>)
        })
      } else if (response.msg === "Date is already used") {
        swal({
          icon: "error",
          content: (<div>
            <h1 style={{ color: "darkred" }}>Error</h1>
            <p>
              This date is already exist in promotion, Please Chose other date
            </p>
          </div>)
        })
      }
      else {
        swal(
          {
            icon: "success",
            content: (<div>
              <h1 style={{ color: "#28a745" }}>Promotion is updated sucessfully</h1>
            </div>)
          }
        ).then(() => {
          navigate("/promotion");
        })
      }
    })
  };

  const GetPromotionByID = (id) => {
    GetPromotionById(id).then((response) => {
      setTimeout(() => {
        reset({
          date: response?.date,
          price: response?.price,
          maincourt: response?.maincourt,
          additional_slot_price: response?.additional_slot_price,
          padelcourt: response?.padelcourt,
          starttime: response?.starttime,
          endtime: response?.endtime
        })
      }, 1000);
    })
  };

  const mainCourtListData = () => {
    GetPromotionByID(id);
    GetMainCourtForPromotion().then((resposne) => {
      setMainCourtList(resposne);
    })
  };

  useEffect(() => {
    mainCourtListData();
  }, [])

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
          </div>
          <div className="card__title">
            {/* <h5 className="bold-text"></h5> */}
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className='form__half'>
              <div className="form__form-group">
                <span className="form__form-group-label">Date</span>
                <div className="form__form-group-field">
                  <input type="date" name="date" id="" {...register("date", { required: true })} />
                </div>
                {errors.date && <span style={{ color: "#bc4040" }}>Date Is require</span>}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Price</span>
                <div className="form__form-group-field">
                  <input type="number" name="price" id=""  {...register("price", { required: true })} />
                </div>
                {errors.price && <span style={{ color: "#bc4040" }}>Price Is require</span>}
              </div>

              <div className="form__form-group">
                <div className="form__form-group">
                  <span className="form__form-group-label">Start Time</span>

                  <div className="form__form-group-field">
                    <select name="padelcourt" className="textareaFeild" {...register("starttime", { required: true })}>
                      <option value="">Select Start Time</option>
                      {times &&
                        times.map((time) => (
                          <option value={time}>{time}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.starttime && (<span className="invalid-feedback d-flex" >Start time is required</span>)}
              </div>
            </div>

            <div className='form__half'>
              <div className="form__form-group">
                <span className="form__form-group-label">MainCourt Id</span>
                <div className="form__form-group-field">
                  <select name="maincourt" className="select" {...register("maincourt", { required: true })}>
                    <option>Please Select Main Court</option>
                    {
                      mainCourtList.map((data, index) => {
                        return (
                          <option value={data?.id}>{data?.courtName}</option>
                        )
                      })
                    }
                  </select>
                </div>
                {errors.maincourt && <span style={{ color: "#bc4040" }} >Main Court Is require</span>}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Additional Slot Price</span>
                <div className="form__form-group-field">
                  <input type="number" name="additional_slot_price" id=""  {...register("additional_slot_price", { required: true })} />
                </div>
                {errors.additional_slot_price && <span style={{ color: "#bc4040" }}>Additional Slot Price Is require</span>}
              </div>

              <div className="form__form-group">
                <div className="form__form-group">
                  <span className="form__form-group-label">End Time</span>

                  <div className="form__form-group-field">
                    <select name="padelcourt" className="textareaFeild" {...register("endtime", { required: true })}>
                      <option value="">Select End Time</option>
                      {times &&
                        times.map((time) => (
                          <option value={time}>{time}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.endtime && (
                  <span className="invalid-feedback d-flex">End time is required</span>
                )}
              </div>
            </div>
            <div className='w-100 my-2 mt-3 d-flex justify-content-end'>
              <ButtonToolbar className="form__button-toolbar">
                <div className='justpadel-buttons-cancel' onClick={() => { reset({ date: "", price: "", maincourt_id: "" }) }}>
                  Reset
                </div>
                <button className='justpadel-buttons-save' type="submit">Submit</button>
              </ButtonToolbar>
            </div>


            {/* <div className="form__form-group">
              <span className="form__form-group-label">PadelCourt Id</span>
              <div className="form__form-group-field">
                <select name="padelcourt"  className="select" {...register("padelcourt",{ required: true })}>
                  <option >Please Select Padel Court</option>
                  {
                    padelCourtList.map((data, index) => {
                      return(
                      <option value={data.id} key={index}>{data.courtname}</option>
                      )
                    })
                  }
                </select>
              </div>
              {errors.maincourt && <span>Padel Court Is require</span>}
            </div> */}

          </form>
        </CardBody>
      </Card>
    </Col>
  )
};

export default EditPromotions;
