import { createSlice } from "@reduxjs/toolkit";
import { getPaginatedFilteredBookingList } from "../thunk/BookingListThunk";

const bookingListInitialState = {
    bookingList: {},
    bookingListFilter: {
        booking_id: "",
        created_at_after: "",
        created_at_before: "",
        booking_date_after: "",
        booking_date_before: "",
        main_court: "",
        padel_court: "",
        email: "",
        payment_status: "",
        page: 1,
        page_size: 10
    },
    bookingListInitialFilter: {
        booking_id: "",
        created_at_after: "",
        created_at_before: "",
        booking_date_after: "",
        booking_date_before: "",
        main_court: "",
        padel_court: "",
        email: "",
        payment_status: "",
        page: 1,
        page_size: 10
    },
    status: 'idle',
    error: null,
};

export const bookingListSlice = createSlice({
    name: 'bookinglistslice',
    initialState: bookingListInitialState,
    reducers: {
        setBookingListFilter: (state, action) => {
            state.bookingListFilter = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPaginatedFilteredBookingList.pending, (state, action) => {
            state.status = "loading";
            state.error = null;
        })
        builder.addCase(getPaginatedFilteredBookingList.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.bookingList = action.payload
        })
        builder.addCase(getPaginatedFilteredBookingList.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message
        })
    }
});

export const { setBookingListFilter } = bookingListSlice.actions;

export default bookingListSlice.reducer;