import { configureStore } from '@reduxjs/toolkit'
import Authslice from './slice/AuthSlice'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import GlobalConfigSlice from './slice/GlobalConfigSlice';
import BookingListSlice from './slice/BookingListSlice';
import UserSlice from './slice/UserSlice';

const rootReducer = combineReducers({
  auth: Authslice,
  config: GlobalConfigSlice,
  bookingList: BookingListSlice,
  user: UserSlice
});

const persistConfig = {
  key: 'JustPadelAdmin',
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const AppStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(AppStore);
export default AppStore;
