import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NoSelectedUserComponent from './NoSelectedUserComponent';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardText, CardTitle, Col, FormFeedback, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './UserDetails.css';
import axios from '../../../customAxios';
import { environment } from '../../env';

const UpdateUserDetails = () => {
    const navigate = useNavigate();
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({ mode: 'onChange' });
    const [countryCode, setCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { selectedUserUpdateDetails, selectedUserUpdateId } = useSelector((state) => state.user);

    const navigateToUserList = () => {
        navigate(`/user_details/${selectedUserUpdateId}`);
    };

    const handlePhoneChange = (value, country) => {
        // Extract country code and the rest of the phone number.
        const extractedCountryCode = `+${country.dialCode}`;
        const extractedPhoneNumber = value.replace(`${country.dialCode}`, '').trim();

        setCountryCode(extractedCountryCode);
        setPhoneNumber(extractedPhoneNumber);
    };

    const onSubmit = async (data) => {
        const updateDetailsPayload = {
            first_name: data.fname,
            last_name: data.lname,
            email: data.email,
            phone_no: `${countryCode}-${phoneNumber}`,
            gender: data.gender,
            address: data.address,
            city: data.city,
            state: data.state,
            country: data.country,
        };
        setIsLoading(true);
        try {
            const userUrl = `${environment.baseUrl}update_user_details/${selectedUserUpdateDetails?.email}/`
            axios
                .put(userUrl, updateDetailsPayload)
                .then((UserResponse) => {
                    setIsLoading(false);
                    navigateToUserList();
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        } catch (error) {
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (selectedUserUpdateDetails) {
            const {
                first_name,
                last_name,
                email,
                phone_no,
                gender,
                address,
                city,
                state,
                country,
                skilllevel,
            } = selectedUserUpdateDetails;

            // Only set value if it's not "null" or empty
            if (first_name && first_name !== "null") setValue('fname', first_name);
            if (last_name && last_name !== "null") setValue('lname', last_name);
            if (email && email !== "null") setValue('email', email);
            if (phone_no && phone_no !== "null") setValue('phone', phone_no);
            if (gender && gender !== "null") setValue('gender', gender);
            if (address && address !== "null") setValue('address', address);
            if (city && city !== "null") setValue('city', city);
            if (state && state !== "null") setValue('state', state);
            if (country && country !== "null") setValue('country', country);
            if (skilllevel && skilllevel !== "null") setValue('skillLevel', skilllevel);

        }
    }, [selectedUserUpdateDetails, setValue]);

    if (selectedUserUpdateId === 0 || selectedUserUpdateDetails === null) {
        return <NoSelectedUserComponent title='No User Selected' subtitle='Please Select a User' btn='Go Back' onClick={navigateToUserList} />;
    };

    return (
        <Row>

            <Col md={12}>
                <Card className="d-flex">
                    <CardBody>
                        <CardTitle tag="h3">Update User Details</CardTitle>
                    </CardBody>
                </Card>
            </Col>
            <Col md={12} className='mt-4'>
                <Card className="d-flex">
                    <CardBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col md={6}>
                                    <Label for="fname">First Name</Label>
                                    <Controller
                                        name="fname"
                                        control={control}
                                        rules={{
                                            required: "First name is required",
                                            maxLength: { value: 20, message: "Max length is 20" }
                                        }}
                                        render={({ field }) => (
                                            <Input {...field} invalid={!!errors.fname} />
                                        )}
                                    />
                                    <FormFeedback>{errors.fname?.message}</FormFeedback>
                                </Col>
                                <Col md={6}>
                                    <Label for="lname">Last Name</Label>
                                    <Controller
                                        name="lname"
                                        control={control}
                                        rules={{ required: "Last name is required" }}
                                        render={({ field }) => (
                                            <Input {...field} invalid={!!errors.lname} />
                                        )}
                                    />
                                    <FormFeedback>{errors.lname?.message}</FormFeedback>
                                </Col>
                                <Col md={6}>
                                    <Label for="email">Email</Label>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                message: "Email is not valid"
                                            }
                                        }}
                                        render={({ field }) => (
                                            <Input {...field} invalid={!!errors.lname} disabled={true} readOnly />
                                        )}
                                    />
                                    <FormFeedback>{errors.email?.message}</FormFeedback>
                                </Col>
                                <Col md={6}>
                                    <Label for="phone">Phone No</Label>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                            required: "Phone number is required",
                                            validate: value => value.length >= 6 || "Phone number is invalid" // Adjust validation as needed
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <div className={`phone-input-wrapper ${errors.phone ? 'is-invalid' : ''}`}>
                                                <PhoneInput
                                                    defaultCountry="AE"
                                                    country="ae"
                                                    value={value}
                                                    onChange={(phone, country) => {
                                                        onChange(phone);
                                                        handlePhoneChange(phone, country);
                                                    }}
                                                    onBlur={onBlur}
                                                    placeholder="Enter phone number"
                                                    containerStyle={{
                                                        width: '100%',
                                                    }}
                                                    inputStyle={{
                                                        width: '100%',
                                                        borderColor: errors.phone ? '#dc3545' : '#ced4da',
                                                        borderRadius: '.25rem',
                                                    }}
                                                    buttonStyle={{
                                                        borderColor: errors.phone ? '#dc3545' : '#ced4da',
                                                    }}
                                                />
                                                {errors.phone && (
                                                    <FormFeedback className="d-block">
                                                        {errors.phone.message}
                                                    </FormFeedback>
                                                )}
                                            </div>
                                        )}
                                    />
                                </Col>
                                <Col md={12}>
                                    <Label for="address">Address</Label>
                                    <Controller
                                        name="address"
                                        control={control}
                                        rules={{ required: "Address is required" }}
                                        render={({ field }) => (
                                            <Input {...field} invalid={!!errors.address} />
                                        )}
                                    />
                                    <FormFeedback>{errors.address?.message}</FormFeedback>
                                </Col>
                                <Col md={6}>
                                    <Label for="city">City</Label>
                                    <Controller
                                        name="city"
                                        control={control}
                                        rules={{ required: "City is required" }}
                                        render={({ field }) => (
                                            <Input {...field} invalid={!!errors.city} />
                                        )}
                                    />
                                    <FormFeedback>{errors.city?.message}</FormFeedback>
                                </Col>
                                <Col md={6}>
                                    <Label for="state">State</Label>
                                    <Controller
                                        name="state"
                                        control={control}
                                        rules={{ required: "State is required" }}
                                        render={({ field }) => (
                                            <Input {...field} invalid={!!errors.state} />
                                        )}
                                    />
                                    <FormFeedback>{errors.state?.message}</FormFeedback>
                                </Col>
                                <Col md={6}>
                                    <Label for="country">Country</Label>
                                    <Controller
                                        name="country"
                                        control={control}
                                        rules={{ required: "Country is required" }}
                                        render={({ field }) => (
                                            <Input {...field} invalid={!!errors.country} />
                                        )}
                                    />
                                    <FormFeedback>{errors.country?.message}</FormFeedback>
                                </Col>
                                <Col md={6}>
                                    <Label for="gender">Gender</Label>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup check>
                                                <Controller
                                                    name="gender"
                                                    control={control}
                                                    rules={{ required: "Gender is required" }}
                                                    render={({ field }) => (
                                                        <>
                                                            <Input
                                                                {...field}
                                                                type="radio"
                                                                value="Male"
                                                                checked={field.value === 'Male'}
                                                                onChange={() => field.onChange('Male')}
                                                                invalid={!!errors.gender}
                                                            />
                                                            <Label check>Male</Label>
                                                        </>
                                                    )}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup check>
                                                <Controller
                                                    name="gender"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <Input
                                                                {...field}
                                                                type="radio"
                                                                value="Female"
                                                                checked={field.value === 'Female'}
                                                                onChange={() => field.onChange('Female')}
                                                                invalid={!!errors.gender}
                                                            />
                                                            <Label check>Female</Label>
                                                        </>
                                                    )}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormFeedback>{errors.gender?.message}</FormFeedback>
                                </Col>
                                <Col md={12}>
                                    <Button type='submit' color={`${isLoading ? 'secondary' : 'warning'}`} className='w-100 mt-4'>
                                        {isLoading && <Spinner className="table-fetch-spinner me-2 text-white" size="sm" />}
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default UpdateUserDetails;
