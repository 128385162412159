import React from 'react'
import SidebarCategory from '../SidebarCategory';
import SidebarLink from '../SidebarLink';
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';

const PadelgramSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Padelgram" icon="select">
            {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <SidebarLink title="Live Post" route="/live-post" />}
            {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <SidebarLink title="Reported Post" route="/report-problem" />}
            {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <SidebarLink title="Deleted Post" route="/deleted-post" />}
        </SidebarCategory>
    );
};

export default PadelgramSidebar;