import React, { useState, useEffect } from "react";
import { Col, Button, Card, CardBody, Spinner, Progress } from "reactstrap";
import { environment } from "../../env";
import axios from "../../../customAxios";
import MakingFileDownload from "./MakingFileDownload";
import { useNavigate } from "react-router-dom";

const DownloadExcel = () => {
  const [excelProgress, setExcelProgress] = useState(0);
  const [downloadFile, setDownloadFile] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const navigate = useNavigate();

  const DownloadAllBookingsReport = () => {
    setExcelProgress(0);
    setDownloadFile(true);
    const DownloadAllBookingsReportUrl = `${environment.baseUrl}booking-report-excel/`;
    axios({
      url: DownloadAllBookingsReportUrl,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setExcelProgress(percentCompleted);
      },
    }).then((response) => {
      setDownloadFile(false);
      setExcelData(response.data);
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   const link = document.createElement("a");
      //   link.href = url;
      //   link.setAttribute("download", `JustPadelCourtBookingDetails.csv`);
      //   document.body.appendChild(link);
      //   link.click();
    });
  };

  const Download = () => {
    const url = window.URL.createObjectURL(new Blob([excelData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `JustPadelCourtBookingDetails.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const GoBack = () => {
    navigate("/bookings")
  }
  useEffect(() => {
    DownloadAllBookingsReport();
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <h2>Downloading Excel</h2>
          <Card>
            <CardBody>{downloadFile ? <MakingFileDownload /> : null}</CardBody>
            <div className="Excel-card">
            {excelProgress === 100 ? (
              <div className="Excel-card-div">
              <div onClick={() => Download()}  className="download-excel-btn">Download</div>
              <div onClick={() => GoBack()}  className="download-excel-cancel-btn">Cancel</div>
              </div>
            ) : (
              ""
            )}
            </div>
          </Card>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DownloadExcel;
