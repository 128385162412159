/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Col, Button, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import axios from '../../../customAxios';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../env';

const CourtTypeCard = () => {
  const [courtType, setCourtType] = useState();
  const [courtTypeErr, setcourtTypeErr] = useState(false);
  const [courtTypeErrMsg, setcourtTypeErrMsg] = useState();
  const [postError, setpostError] = useState(false);
  const [postErrMsg, setpostErrMsg] = useState();
  const [postSuccess, setpostSuccess] = useState(false);
  const [successMsg, setsuccessMsg] = useState();
  const navigate = useNavigate();

  function handleChangeCourtType(e) {
    setCourtType(e.target.value);
    setcourtTypeErr(false);
    setpostSuccess(false);
    setpostError(false);
  }

  const courtTypeUrl = `${environment.baseUrl}courttypes`;
  const handleSubmit = () => {
    if (!courtType) {
      setcourtTypeErr(true);
      setcourtTypeErrMsg('Please Enter The Court Type, It Should Not be Empty');
      return;
    }
    setcourtTypeErr(false);
    const formData = new FormData();
    formData.append('courttype', courtType);
    axios
      .post(courtTypeUrl, formData)
      .then((response) => {
        if (response.status === 200) {
          setpostSuccess(true);
          setsuccessMsg('Court Type are added successfully');
          setCourtType('');
        }
      })
      .catch((err) => {
        setpostError(true);
        setpostErrMsg(`Error..While Inserting Data..,   ${err}`);
      });
  };

  const reset = () => {
    setCourtType('');
    navigate("/list_court_type")
  };

  useEffect(() => {
    if (localStorage.getItem('Token')) {
      console.log('True');
    } else {
      alert('You are not logged In. Please LogIn');
      navigate('/');
    }
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text" style={{ color: '#acacac' }}>Insert Court Type Data Here</h5>
          </div>
          {postError
            ? (
              <div color="danger" className="card__title">
                <h5 style={{ color: '#dc3545' }}><span className="bold-text">Warning! </span>
                  {postErrMsg}
                </h5>
              </div>
            )
            : ''}
          {postSuccess
            ? (
              <div className="card__title">
                <h5 style={{ color: '#4ce1b6' }}><span className="bold-text">Congratulations! </span>
                  {successMsg}
                </h5>
              </div>
            )
            : ''}
          <form className="form product-edit">
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label" style={{fontSize:14}}>Enter Court Type</span>
                <div className="form__form-group-field">
                  <input type="text" value={courtType} style={{borderRadius:5}} onChange={handleChangeCourtType} />
                </div>
                {courtTypeErr
                  ? (
                    <div color="danger" className="form__form-group-error">
                      <p style={{ color: '#dc3545' }}><span className="bold-text">Warning! </span>
                        {courtTypeErrMsg}
                      </p>
                    </div>
                  )
                  : ''}
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar" style={{marginTop:35}}>
              <div className='justpadel-buttons-save' onClick={handleSubmit}>
                Save
              </div>
              <div className='justpadel-buttons-cancel' onClick={reset}>
                Cancel
              </div>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default CourtTypeCard;
