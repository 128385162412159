import React, { useState, useEffect, useRef } from "react";
import axios from "../../customAxios";
import { environment } from "../env";
import 'suneditor/dist/css/suneditor.min.css';
import JoditEditor from "jodit-react";
import { Col, Button, ButtonToolbar, Card, CardBody } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import swal from '@sweetalert/with-react';

const AddPage = () => {
  const [pageName, setPageName] = useState();
  const [pageContent, setPageContent] = useState();
  const editor = useRef(null)
  const [content, setContent] = useState('')
  const navigate = useNavigate()


  const reset = () => {
    navigate('/getpage')
  }

  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }
  const patternValidation = val => {
    return /\s/g.test(val);
  };


  const Submit = () => {
    console.log("page Name", pageName);
    console.log("page pageContent", pageContent);
    console.log(patternValidation(pageName))
    if (patternValidation(pageName) === true) {
      swal({
        icon: "error",
        content: (<div>
          <h1 style={{ color: "darkred" }}>Error</h1>
          <p>
            Invalid Text
          </p>
        </div>)
      })
      return;
    } else {
      const formData = new FormData();
      formData.append("name", pageName);
      formData.append("content", pageContent);
      const getpageURL = `${environment.baseUrl}getpage`;
      axios
        .post(getpageURL, formData)
        .then((response) => {
          console.log("Response", response.data.msg);
          if(response.data.msg === "Page Data is added sucessfully"){
            swal({
              icon: "success",
              content: (<div>
                <h1 style={{ color: "#28a745" }}>Sucessfully</h1>
                <p>
                  Data added successfully
                </p>
              </div>)
            }).then(() =>{
              navigate('/getpage');
            })
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err)
          }
        });
    }
  }


  return (
    <div>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text" style={{ color: "#acacac" }}>
                Insert page&apos;s Data Here
              </h5>
            </div>
            <form className="form product-edit">
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label" style={{ fontSize: 14 }}>Enter Page Name</span>
                  <div className="form__form-group-field" style={{ marginBottom: 15 }}>
                    <input type="text" name="pageName" value={pageName} onChange={(e) => setPageName(e.target.value)} />
                  </div>
                </div>
                <JoditEditor
                  ref={editor}
                  value={content}
                  // config={config}
                  tabIndex={1} // tabIndex of textarea
                  // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={newContent => setPageContent(newContent)}
                />
                <ButtonToolbar className="form__button-toolbar" style={{ marginTop: 30 }}>
                  <div  className='justpadel-buttons-save' onClick={() => Submit()} >
                    Save
                  </div>
                  <div  className='justpadel-buttons-cancel' onClick={() => reset()}>
                    Cancel
                  </div>
                </ButtonToolbar>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
      {/* <input type="text" name="pageName" value={pageName} onChange={(e) => setPageName(e.target.value)} />
      <JoditEditor
        ref={editor}
        value={content}
        // config={config}
        tabIndex={1} // tabIndex of textarea
        // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={newContent => setPageContent(newContent) }
      />
      <button onClick={() => Submit()}>Submit</button> */}
    </div>
  )
};

export default AddPage;
