import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import classNames from "classnames";
import { RTLProps } from "../prop-types/ReducerProps";
import { AiFillEye } from "react-icons/ai";
import axios from "../../customAxios";
import { environment } from "../../../src/containers/env";

export const CouponModalView = ({
  color,
  btn,
  colored,
  header,
  title,
  couponId,
}) => {
  const [couponData, setCouponData] = useState();
  const [mainCourtList, setMainCourtList] = useState([]);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (!modal) {
      GetCouponDetails(couponId);
      GetMainCourt();
    }

    setModal((prevState) => !prevState);
  };

  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const GetCouponDetails = (couponId) => {
    const viewCouponUrl = `${environment.baseUrl}coupon-list/${couponId}`;
    axios
      .get(viewCouponUrl)
      .then((response) => {
        console.log("response===>",response)
        var data = response?.data?.results?.[0];
        data.apply_for_addons = data.apply_for_addons.toString();
        setCouponData(data);
      })
      .catch((error) => {
        console.log("Error" + error);
      });
  };

  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts-list`;
    await axios.get(mainCourtUrl).then((response) => {
      setMainCourtList(
        response?.data.map((court) => ({
          label: court.courtName,
          value: court.id,
        }))
      );
    });
  };

  return (
    <div className="model-container" >
      <div className="modal-view-btn" onClick={toggle}>
        <AiFillEye style={{ fontSize: 20 }} />
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`modal ltr-support`}
        className={`modal-dialog--${color} ${modalClass}`}
        size="lg"
      >
        <div className="modal__header" style={{ backgroundColor: "#70bbfd" }}>
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            onClick={toggle}
            style={{ fontSize: 20 }}
          />
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div>
          <ModalBody style={{ textAlign: "initial", padding: 50 }}>
            {couponData ? (
              <>
                <div className="coupon-view-container">
                  <div className="w-100 m-0">
                    <div>
                      <label className="coupon-view-lhs-text">
                        Main Courts:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData?.maincourt_availability.map(
                          (courtIs, index) => {
                            const courtLabel = mainCourtList.filter(
                              (court) => court.value === courtIs
                            )[0]?.label;
                            return index === 0 ? courtLabel : `, ${courtLabel}`;
                          }
                        )}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Coupon Name:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.name}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Coupon Group Name:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.coupon_group}
                      </label>
                    </div>

                    {couponData.title ? <div>
                      <label className="coupon-view-lhs-text">Title: </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.title}
                      </label>
                    </div>: ""}

                    {couponData.description ? <div>
                      <label className="coupon-view-lhs-text">
                        Description:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.description}
                      </label>
                    </div>:""}

                    <div>
                      <label className="coupon-view-lhs-text">
                        Percentage of Discount:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">{`${couponData.percentage_of_discount}%`}</label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        User Limit:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.coupons_user_limit}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Coupons Allocated:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.coupons_alloted}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Coupons remaining:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.coupon_remaining}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Apply For Addons:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.apply_for_addons == "true" ? "Yes" : "NO"}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Buy One Get One Coupon:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.scratch_card === true ? "Yes" : "NO"}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Coupon Type:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.coupon_type}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Coupon Category:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.coupon_category}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Applicable For:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.applicablefor}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Required Card:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.require_card ? "Yes" : "No"}
                      </label>
                    </div>

                    <div>
                      <label className="coupon-view-lhs-text">
                        Expiry Date:{" "}
                      </label>{" "}
                      <label className="coupon-view-rhs-text">
                        {couponData.expiry_date}
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={toggle}>Close</Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

CouponModalView.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
};

CouponModalView.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
};
