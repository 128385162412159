/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import {
  Col, Button, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import axios from '../../../customAxios';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../env';

const CourtCategoryEditCard = () => {
  const [Category, setCategory] = useState();
  const [courtCategoryErr, setcourtCategoryErr] = useState(false);
  const [courtCategoryerrMsg, setcourtCategoryerrMsg] = useState();
  const navigate = useNavigate();

  function handleCategoryChange(e) {
    setCategory(e.target.value);
  }
  const categoryUrl = `${environment.baseUrl}category/`;
  const handleSubmit = () => {
    if (!Category) {
      setcourtCategoryErr(true);
      setcourtCategoryerrMsg('Please Enter The Court Category, It Should Not be Empty');
      return;
    }
    setcourtCategoryErr(false);
    const formData = new FormData();
    formData.append('courtcategory', Category);
    axios
      .post(categoryUrl, formData)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const reset = () => {
   // to do
  };

  useEffect(() => {
    if (localStorage.getItem('Token')) {
      console.log('True');
    } else {
      alert('You are not logged In. Please LogIn');
      navigate('/');
    }
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text" style={{ color: '#006EDC' }}>Insert Court Category Data Here</h5>
          </div>
          <form className="form product-edit">
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label" style={{fontSize:14}}>Enter Court Category</span>
                <div className="form__form-group-field">
                  <input type="text" style={{borderRadius:5}} onChange={handleCategoryChange} />
                </div>
                {courtCategoryErr
                  ? (
                    <div color="danger" className="form__form-group-error">
                      <p style={{ color: '#dc3545' }}><span className="bold-text">Warning! </span>
                        {courtCategoryerrMsg}
                      </p>
                    </div>
                  )
                  : ''}
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar" style={{marginTop:25}}>
              <Button color="warning" size="sm" onClick={handleSubmit}>
                Save
              </Button>
              <Button type="button" size="sm" onClick={reset}>
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CourtCategoryEditCard;
