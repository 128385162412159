import React, { useEffect, useState } from "react";
import {
  Nav, NavItem, NavLink, TabContent, TabPane, Table, Col, Card, CardBody, Button
} from 'reactstrap';
import { environment } from "../../env";
import axios from "../../../customAxios";
import moment from 'moment';
import { FiUser } from 'react-icons/fi';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FcCheckmark } from 'react-icons/fc';
import { FcCancel } from 'react-icons/fc';
import { GetPadelholicLevel, BookingDetailsAvailableTrueByBookingID, BookingDetailsAvailableFalseByBookingID, getCoupon, GetAddon  } from "../../Reuse/ApiCalls";

const BookingDetails = ({ bookingId }) => {
  const [bookingDetailsSingle, setBookingDetailsSingle] = useState();
  const [transactionDetails, setTransactionDetails] = useState();
  const [transactionLogs, setTransactionLogs] = useState();
  const [bookedSlots, setBookedSlots] = useState([]);
  const [finalAddonList, setFinalAddonList] = useState([]);
  const [padelholicLevels, setPadelholicLevels] = useState();
  const [totalCourtAmount, setTotalCourtAmount] = useState(0);
  const [totalAddonAmount, setTotalAddonAmount] = useState(0);
  const [logs, setLogs] = useState([]);
  const [timeToPlay, setTimeToPlay] = useState();
  const [hoursToPlay, setHoursToPlay] = useState();
  const [courtBookinDate, setCourtBookinDate] = useState();
  const [createdOrEditedLogs, setCreatedOrEditedLogs] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [couponPercentage, setCouponPercentage] = useState();

  // -------------------Return state in html-----------------
  const [totalAmount, setTotalAmount] = useState();
  const [vatAmount, setVatAmount] = useState();
  const [discountedAmount, setDiscountedAmount] = useState();
  const [payFacilityAmount, setPayFacilityAmount] = useState();


  const transactionLog = `${environment.baseUrl}get-unsucessfull-transactionlog/${bookingId}/`;

  const getTransactionLog = async () => {
    const result = await axios
      .get(transactionLog)
      .then((response) => {
        console.log("sdkjvns");
        console.log("response?.data?.booking?.booking_id", response?.data?.booking?.booking_id);
        let responseBookingId = response?.data?.booking?.booking_id;
        getBookingDetails(responseBookingId);
        const NoOfGamesPlayes = response?.data?.user?.no_of_games;
        GetPadelholicLevel().then((response) => {
          const padelHolicLevel = parseInt(NoOfGamesPlayes);
          const padelLevels = response ?? [];
          let matchingPadelLevel = padelLevels.find(
            (element) => element.games >= padelHolicLevel
          );
          setPadelholicLevels(matchingPadelLevel);
          if (matchingPadelLevel == null) {
            matchingPadelLevel = padelLevels[padelLevels.length - 1];
            setPadelholicLevels(matchingPadelLevel);
          }
        });
        setTransactionDetails(response?.data);
        DiscountedPrice(response?.data?.booking?.coupan_applied);
        localStorage.setItem(
          "amountPaid",
          response?.data?.booking?.amount_paid
        );
        setTransactionLogs(JSON.parse(response?.data?.transaction_log)[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBookingDetails = (id) => {
    BookingDetailsAvailableFalseByBookingID(id).then((response) => {

      const com =
        response.data.comments === "" || response.data.comments === undefined
          ? JSON.stringify([{ comment: "No Comments" }])
          : JSON.parse(response.data.comments);
      if (typeof (com) === 'string') {
        setCommentsData(JSON.parse(com));
      } else {
        setCommentsData(com);
      }

      if (response?.data?.Message === "No data with this id!!!") {
        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data.Message === "No data with this id!!!") {
            // setLogs();
          } else {
            StartandEndTime(response?.data);
            setBookingDetailsSingle(response?.data);
            if (typeof (response?.data?.logs) === "string") {
              setCreatedOrEditedLogs();
            } else {
              setCreatedOrEditedLogs(response?.data?.logs);
            }
          }
        })
      } else {
        StartandEndTime(response?.data);
        setBookedSlots(response?.data.booked_slots);
        setBookingDetailsSingle(response?.data);

        if(response?.data?.logs){
          if (typeof (response?.data?.logs) === "string") {
            let newData = JSON.parse(response?.data?.logs);
            setCreatedOrEditedLogs(newData);
            if (typeof (newData) === "string") {
              let againParse = JSON.parse(newData);
              setCreatedOrEditedLogs(againParse);
            } else {
              setCreatedOrEditedLogs(newData)
            }
          } else {
            setCreatedOrEditedLogs(response?.data?.logs)
          }
        }
        
      }
    })

    BookingDetailsAvailableFalseByBookingID(id).then((response) => {
      
      if (response.data.Message !== 'No data with this id!!!') {
        
        let TotlaCourtPrice
        if (response?.data?.booked_slots?.length === 1) {
          TotlaCourtPrice = response?.data?.booked_slots[0].Price
          if (response?.data?.packages > 0) {
            setTotalCourtAmount(0);
            localStorage.setItem("TotlaCourtPrice", 0);
          } else {
            setTotalCourtAmount(TotlaCourtPrice?.Price);
            localStorage.setItem("TotlaCourtPrice", TotlaCourtPrice);
          }
        } else {
          TotlaCourtPrice = response?.data?.booked_slots.reduce(function (a, b) { return { Price: a.Price + b.Price }; })

          if (response?.data?.packages > 0) {
            setTotalCourtAmount(0);
            localStorage.setItem("TotlaCourtPrice", 0);
          } else {
            setTotalCourtAmount(TotlaCourtPrice?.Price);
            localStorage.setItem("TotlaCourtPrice", TotlaCourtPrice?.Price);
          }
        }

        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data?.Message === "No data with this id!!!") {
            // setLogs();
          } else {
            setLogs(response?.data?.booked_slots);
          }
        });
        addonFunction(response.data);

      } else {
        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data?.Message === "No data with this id!!!") {
            
          } else {
            
            let TotlaCourtPrice
            if (response?.data?.booked_slots?.length === 1) {
              TotlaCourtPrice = response?.data?.booked_slots[0].Price
              if (response?.data?.packages > 0) {
                setTotalCourtAmount(0);
                localStorage.setItem("TotlaCourtPrice", 0);
              } else {
                setTotalCourtAmount(TotlaCourtPrice?.Price);
                localStorage.setItem("TotlaCourtPrice", TotlaCourtPrice?.Price);
              }
            } else {
              TotlaCourtPrice = response?.data?.booked_slots.reduce(function (a, b) { return { Price: a.Price + b.Price }; })

              if (response?.data?.packages > 0) {
                setTotalCourtAmount(0);
                localStorage.setItem("TotlaCourtPrice", 0);
              } else {
                setTotalCourtAmount(TotlaCourtPrice?.Price);
                localStorage.setItem("TotlaCourtPrice", TotlaCourtPrice?.Price);
              }
            }
            addonFunction(response.data);
            setLogs(response?.data?.booked_slots);
            if (typeof (response?.data?.logs) === "string") {
              let newData = JSON.parse(response?.data?.logs);
              setCreatedOrEditedLogs(newData);
              if (typeof (newData) === "string") {
                let againParse = JSON.parse(newData);
                setCreatedOrEditedLogs(againParse);
              } else {
                setCreatedOrEditedLogs(newData)
              }

            } else {
              setCreatedOrEditedLogs(response?.data?.logs)
            }

          }
        })
      }
    });
  };

  const DiscountedPrice = (couponCode) => {
    getCoupon().then((response) => {
      let couponApplied = response.find((o) => o.name.toLowerCase() === couponCode.toLowerCase());
      if (couponApplied) {
        setCouponPercentage(couponApplied?.percentage_of_discount);
        FinalCalculation(couponApplied);
        localStorage.setItem("couponApplied", JSON.stringify(couponApplied));
      } else {
        setCouponPercentage(0);
      }
    });
  };

  const StartandEndTime = (data) => {
    const startTimes = data?.booked_slots[0]?.time?.split("-")[0];
    const endsTimes = data?.booked_slots.slice(-1)[0]?.time?.split("-")[1];

    setTimeToPlay(startTimes + " - " + endsTimes);

    // -----------------------functionality to get hours to play code starts here-------
    let startTime = moment(startTimes, "hh:mm A");
    let endTime = moment(endsTimes, "hh:mm A");

    // Check if endTime is earlier than startTime (indicating it's past midnight)
    if (endTime.isBefore(startTime)) {
        endTime.add(1, 'days'); // Add one day to endTime
    }

    const duration = moment.duration(endTime.diff(startTime));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes(); // directly get minutes from the duration object

    setHoursToPlay(`${hours} hr . ${minutes} mins.`);
    // -----------------------functionality to get hours to play code ends here-------
};


  const addonFunction = async (data) => {
    try {
      if (data.addons === "null" || data.addons === null) {
        localStorage.setItem("TotlaAddonPrice", 0);
        setTotalAddonAmount(0);
        FinalCalculation(JSON.parse(localStorage.getItem("couponApplied")));
        return;
      }
      var match = data.addons;
      let seperateComma = match.split(",");
      var arrays = []
      var addonArray = []
      for (var i = 0; i < seperateComma.length; i++) {
        arrays = seperateComma[i].replace(/'/ig, "").split("-")
        let objs = {};
        objs['id'] = parseInt(arrays[0]);
        objs['quantity'] = parseInt(arrays[1]);
        addonArray.push(objs);
      }
      localStorage.setItem("addonList", JSON.stringify(addonArray));
      addonListData();
    } catch (error) {
      console.log(error);
    }

  };

  const FinalCalculation = (coupon) => {
    let NewTotlaCourtPrice = parseInt(localStorage.getItem("TotlaCourtPrice"));
    let NewTotlaAddonPrice = parseInt(localStorage.getItem("TotlaAddonPrice"));

    setDiscountedAmount(calculateDiscountAmount(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon :"null", coupon ? coupon : "null"));
    setVatAmount(VatCalculation(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon :"null", coupon ? coupon : "null"));
    setTotalAmount(CalculateTotalAmount(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon :"null", coupon ? coupon : "null"));
    setPayFacilityAmount(PaidToFacultyCalculation(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon :"null", coupon ? coupon : "null", parseInt(localStorage.getItem("amountPaid"))));
    if(bookingDetailsSingle?.packages>0){
      setPayFacilityAmount(0);
    }
  };

  const addonListData = () => {
    GetAddon().then((response) => {
      let addonNewList = response;
      let purchaseAddonList = JSON.parse(localStorage.getItem("addonList"))
      const result = addonNewList?.map(function (el) {
        var o = Object.assign({}, el);
        const quantity = purchaseAddonList?.find(e => e.id === el.id);
        o.qty = quantity?.quantity === undefined ? 0 : quantity?.quantity;
        return o;
      });
      setFinalAddonList(result);

      let TotlaAddonPrice = 0;
      if (result.length === 0) {
        setTotalAddonAmount(TotlaAddonPrice);
        localStorage.setItem("TotlaAddonPrice", TotlaAddonPrice);
      } else if (result.length === 1) {
        TotlaAddonPrice = result[0].price * result[0].qty;
        setTotalAddonAmount(TotlaAddonPrice);

        localStorage.setItem("TotlaAddonPrice", TotlaAddonPrice);
      } else {
        TotlaAddonPrice = result.reduce(function (a, b) { return { price: a.price * a.qty + b.price * b.qty }; })
        setTotalAddonAmount(TotlaAddonPrice?.price);

        localStorage.setItem("TotlaAddonPrice", TotlaAddonPrice?.price);
      }
      FinalCalculation(JSON.parse(localStorage.getItem("couponApplied")));
    });
    
  };

  // ---------------- Calculation for discount admount starts here ---------
  const calculateDiscountAmount = (courtamount, addonAmount, couponDetails, coupon) => {
    console.log(courtamount, addonAmount, couponDetails, coupon);
    if(coupon === ("null" || "" || undefined)){
      return 0.00;
    }else{
      if(couponDetails?.apply_for_addons){
        return Math.ceil((((courtamount+addonAmount)/100)*couponDetails?.percentage_of_discount).toFixed(2));
      }else{
        return Math.ceil((courtamount/100)*couponDetails?.percentage_of_discount);
      }      
    }
  };  
  // ---------------- Calculation for discount admount ends here -----------

  // ---------------- Calculation for vat code starts here --------------
  const VatCalculation = (courtamount, addonAmount, couponDetails, coupon) => {
    if(coupon === ("null" || "" || undefined)){
      return Math.ceil((courtamount + addonAmount) * 0.05);
    }else{
      if(couponDetails?.apply_for_addons){
        return Math.ceil(((courtamount + addonAmount) - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon))*0.05)
      }else{
        return Math.ceil(((courtamount - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon))+addonAmount)* 0.05) 
      }
    }
  };

  // ---------------- Calculation for vat code ends here --------------

  // ---------------- Calculate total amount code starts here ---------
    const CalculateTotalAmount = (courtamount, addonamount, coupondetails, coupon) => {
      if(coupon === ("null" || "" || undefined)){
        return courtamount + addonamount + VatCalculation(courtamount, addonamount, coupondetails, coupon);
      }else{
        if(couponPercentage?.apply_for_addons){
          return courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon)-calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon));
        }else{
          return courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon)-calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon));
        }
      }
    };
  // ---------------- Calculate total amount code starts here ---------

  // ---------------- Paid to faculty calculation code starts here -------
    const PaidToFacultyCalculation = (courtamount, addonamount, coupondetails, Coupon, amountPaid) => {
      return CalculateTotalAmount(courtamount, addonamount, coupondetails, Coupon) - amountPaid;
    }
  // ---------------- Paid to faculty calculation code ends here ---------

  useEffect(() => {
    getTransactionLog();
  }, []);

  return (
    <div className="tabs__wrap" >
      
          <div className="booking-id">
            <label style={{ fontSize: 17, fontWeight: 'bold' }}>Booking ID:</label>
            <label style={{ fontSize: 17, fontWeight: 'bold' }}>&nbsp;&nbsp;#{transactionDetails?.booking?.booking_id === "null" || "" ? " " : transactionDetails?.booking?.booking_id}</label>
          </div>
          <div className="payment-datails">
            <div className="payment-datails-lhs">
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "37%" }}>Status: </label> {'  '}
                {transactionDetails?.booking?.cancelled ? <label style={{ fontSize: 15 }}>Cancelled</label> : 
                <label style={{ fontSize: 15 }}>{(transactionDetails?.booking?.amount_percentage === 1 || transactionDetails?.booking?.amount_percentage === 100) ? "100% Paid" : transactionDetails?.booking?.amount_percentage === 0.5 ? "50% Paid" : ''}</label>
                }
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "37%" }}>Payment processor: </label> {'  '}
                <label style={{ fontSize: 15, fontWeight: 'bold' }}>Payfort</label>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "37%" }}>Payment received:  </label> {'  '}
                <label style={{ fontSize: 15, fontWeight: 'bold' }}>{transactionDetails?.booking?.amount_paid.toFixed(2)} AED</label>
              </div>
            </div>
            <div className="payment-datails-rhs">
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "25%" }}>Invoice date: </label> {'  '}
                <label style={{ fontSize: 15, fontWeight: 'bold' }}>{moment(transactionDetails?.booking?.created_at.split('T')[0]).format("DD-MM-YYYY")}{' '}{moment(transactionDetails?.booking?.created_at.split('T')[1].split('.')[0], "HH:mm:ss").format("hh:mm A")}</label>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "25%" }}>Due date: </label> {'  '}
                <label style={{ fontSize: 15, fontWeight: 'bold' }}> {moment(transactionDetails?.booking?.booking_date).format("DD-MM-YYYY")} {transactionDetails?.booking?.timeslot.split("-")[0]}</label>
              </div>
            </div>
          </div>
          <div className="user-datails">
            <div className="user-datails-lhs">
              <Table responsive hover>
                <tbody className="table-trans-user-details">
                  <tr>
                    <td className="first-td-width">
                      <div className="trans-client-name"><FiUser className="client-icon" /><h5>Client Name</h5></div>
                    </td>
                    <td className="client-name">
                      {transactionDetails?.user?.first_name === "null" ? " " : transactionDetails?.user?.first_name}{' '}
                      {transactionDetails?.user?.last_name === "null" ? " " : transactionDetails?.user?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="first-td-width">
                      <div className="trans-client-name"><AiOutlineMail className="client-icon" /> <h5>Email</h5></div>
                    </td>
                    <td className="client-email">{transactionDetails?.user?.email === "null" ? " " : transactionDetails?.user?.email}</td>
                  </tr>
                  <tr>
                    <td className="first-td-width">
                      <div className="trans-client-name"><AiOutlinePhone className="client-icon" /><h5 >Phone</h5></div>
                    </td>
                    <td className="client-Ph-no">{transactionDetails?.user?.phone_no === "null" ? " " : transactionDetails?.user?.phone_no}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="user-datails-lhs">
              <h5>Payment received by payment system</h5>
            </div>
          </div>
          <div className="user-datails-lhs">
            {bookedSlots?.length > 0 ? <h5>Current Bookings</h5> : ""}
          </div>
          <div className="trans-total-details">
            <Table responsive hover>
              {bookedSlots?.length > 0 ?
                <thead className="trans-total-table-header">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Status</th>
                    {/* <th>Amount</th> */}
                  </tr>
                </thead>
                : <span></span>}
              <tbody>
                {
                  bookedSlots?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {/* {transactionDetails?.booking?.main_court?.courtName === "null" ? " " : transactionDetails?.booking?.main_court?.courtName} - {" "} */}
                          {data?.main_court_name} - {" "}
                          {data?.padel_court_name}
                          {" "}({data?.date + "  " + data?.time})
                        </td>
                        <td>{data.Price.toFixed(2)} AED</td>
                        <td>1</td>
                        <td> <FcCheckmark size={25} className='fc-check' style={{ marginLeft: "10px" }} /></td>
                        {/* <td>230</td> */}
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </div>
          <div className="user-datails-lhs">
            {logs?.length > 0 ? <h5>Logs</h5> : ""}
          </div>
          <div className="trans-total-details">
            <Table responsive hover>
              {logs?.length > 0 ?
                <thead className="trans-total-table-header">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Status</th>
                  </tr>
                </thead>
                : <span></span>}
              <tbody>
                {
                  logs?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {data?.main_court_name} - {" "}
                          {data.padel_court_name}
                          {" "}({data?.date + "  " + data?.time})
                        </td>
                        <td>{data.Price.toFixed(2)} AED</td>
                        <td>1</td>
                        <td><FcCancel size={25} className='faCancel' style={{ marginLeft: '10px' }} /></td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </Table>
          </div>
          <div className="trans-total-amount">
            <div>
              <label style={{ fontSize: 15 }}>VAT 5%: </label> {' '}
              {/* <label style={{ fontSize: 15 }}>{((totalCourtAmount + totalAddonAmount) / 100 * 5).toFixed(2)} AED</label> */}
              <label style={{ fontSize: 15 }}>{Math.round(vatAmount)?.toFixed(2)} AED</label>
            </div>
            <div>
              <label style={{ fontSize: 15, fontWeight: 'bold' }}>Total amount: </label> {' '}
              {/* <label style={{ fontSize: 15, fontWeight: 'bold' }}>{(totalCourtAmount + totalAddonAmount + (totalCourtAmount + totalAddonAmount) / 100 * 5).toFixed(2)} AED</label> */}
              <label style={{ fontSize: 15, fontWeight: 'bold' }}>{Math.round(totalAmount)?.toFixed(2)} AED</label>
            </div>
          </div>

          <div className="trans-data-container">
            <div className="trans-data-lhs">
              {
                transactionDetails?.booking?.addons === "null" || transactionDetails?.booking?.addons === null ?
                  <div className="no-addons">
                    <h4>No Addons</h4>
                  </div>
                  :
                  <Table hover>
                    <thead className="trans-total-table-header">
                      <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th>Price </th>
                        <th>Quantity </th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        finalAddonList.map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data?.addonname}</td>
                              <td>{data?.price} AED</td>
                              <td>{data?.qty}</td>
                              <td>{data?.price * data?.qty} AED</td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </Table>
              }
            </div>
            <div className="trans-data-rhs">
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Coupon Applied: </label> {'  '}
                <label className="pay-result">{transactionDetails?.booking?.coupan_applied === "" ? "NO" : `(${transactionDetails?.booking?.coupan_applied})`}</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Discounted Price: </label> {'  '}
                <label className="pay-result">{discountedAmount?.toFixed(2)} AED</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Packages Used: </label> {'  '}
                <label className="pay-result">{transactionDetails?.booking?.packages == 0 ? "N0" : transactionDetails?.booking?.packages}</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Amount Paid: </label> {'  '}
                {transactionDetails?.booking?.cancelled ? 
                  <label className="pay-result">Cancelled</label>
                :
                  <label className="pay-result">{transactionDetails?.booking?.packages == 0 ? (transactionDetails?.booking?.amount_percentage === 1 || transactionDetails?.booking?.amount_percentage === 100) ? "100% Paid" : transactionDetails?.booking?.amount_percentage === 0.5 ? "50% Paid" : '' : "Paid by Packages"}</label>
                }
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Advance Paid: </label> {'  '}
                <label className="pay-result">{Math.round(transactionDetails?.booking?.amount_paid).toFixed(2)} AED</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>To be Paid at Facility:</label> {'  '}
                <label className="pay-result">{Math.round(payFacilityAmount).toFixed(2)} AED</label>
              </div>
            </div>
          </div>
          </div>
  );
};

export default BookingDetails;
