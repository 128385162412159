import React, { useState, useEffect, Fragment } from "react";
import { Card, CardBody, Col,Row, ButtonToolbar, Button } from "reactstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
// import axios from "axios";
import axios from '../../customAxios';

import swal from 'sweetalert';
import { useNavigate, Link } from "react-router-dom";
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon'
import { environment } from "../env";
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import  { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import parse from "html-react-parser";

const GetPage = () => {
  const [coupon, setCoupon] = useState([]);
  const [userRole, setUserRole] = useState();
  const [role, setRole] = useState();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  let navigate = useNavigate();
  const notifyMeURL = `${environment.baseUrl}getpage`;
  const getCoupon = () => {
    const result = axios
      .get(notifyMeURL)
      .then((response) => {
        console.log(response.data);
        setCoupon(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setPermissions = (roledata) => {
    // const localrole = "admin";
    const localrole = localStorage.getItem("Role");
    console.log("Local Added Role", localrole);
    let newRoleId;
    console.log(roledata.role_name);

    if (localrole === roledata.role_name) {
      console.log(roledata.permissions);
      let permission = JSON.parse(roledata.permissions);
      console.log(permission);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "manual_bookings"
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
      console.log("false");
    }
    console.log("Role name", newRoleId);
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
    console.log(editing);
  };


  useEffect(() => {
    let localrole = localStorage.getItem('Role');
    setUserRole(localStorage.getItem('Role'));
    console.log("NEw localrole ",localrole);
    getCoupon();
  }, []);

  var jsonObj = [];
  
  console.log("NEw JSON ",jsonObj);
  for(let i=0; i<coupon.length; i++){
    var item = {}
    item["id"] = i+1;
    item["name"] = <div className="right-space">{coupon[i].name}</div>;
    item["content"] = <div className="right-space get-page-right overflow-hidden">{parse(coupon[i].content)}</div>;
    if(deleteing === true){
      item["link"] = <div className="right-space"><div className="modal-edit-btn" size="sm" onClick={()=>handleEdit(coupon[i].name)}><AiOutlineEdit  style={{fontSize:20}}/></div></div>;
      item["delete"] = <div className="right-space"><div className="modal-delete-btn" size="sm" onClick={()=>handleDelete(coupon[i].name)}><AiOutlineDelete AiOutlineDelete  style={{fontSize:20}} /></div></div>;
    }else if(editing === true){
      item["link"] = <div className="right-space"><div className="modal-edit-btn" size="sm" onClick={()=>handleEdit(coupon[i].name)}><AiOutlineEdit  style={{fontSize:20}}/></div></div>;
    }
    jsonObj.push(item);
}

const handleEdit = (name) =>{
  navigate(`/edit_page/${name}`);
}

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Page Name",
        accessor: "name",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Content",
        accessor: "content",
        disableGlobalFilter: true,
        width: 300,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 50,
      },
    ],
    rows: jsonObj,
  };
const handleDelete = (name) => {
  const deleteCouponURL = `${environment.baseUrl}getpage/${name}/`;
  console.log(deleteCouponURL);

  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this imaginary file!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      const result = axios.delete(deleteCouponURL)
        .then((response)=>{
        
            console.log(response);
            setCoupon(() => coupon.filter((data) => data.name !== name));
          })
      swal("Your coupon details has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Your Coupon details is safe!");
    }
  });

  // if (window.confirm("are you sure ?")){
    //   const result = axios.delete(deleteCouponURL)
    //   .then((response)=>{
      //     console.log(response);
      //     setCoupon(() => coupon.filter((data) => data.name !== name));
      //   })
      
// }
// if(userRole === "admin") {
  //   alert(name);
  // } else {
    //   alert("You don't have the permissions to delete data");
    // }
    
//     confirmAlert({
//       customUI: ({ onClose }) => {
//         return (
//       <div className='custom-ui'>
//         <h1>Are you sure?</h1>
//         <p>You want to delete this file?</p>
//         <button onClick={onClose}>No</button>
//         <button onClick={() => {
//           const result = axios.delete(deleteCouponURL)
//   .then((response)=>{
//     console.log(response);
//     setCoupon(() => coupon.filter((data) => data.name !== name));
//   })
     
//         }}>Yes, Delete it!</button>
//       </div>
//     )
//   }
// })
};


  const handleCreateCouponRedirect = () => {
    navigate("/Add_page");
  }

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };
  
  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };
  return (
    <Fragment>
       <Row>
        <Col md={12}>
          <h3 className="page-title">Pages</h3>
        </Col>
      </Row>
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
                <div style={{position:'absolute', top:35, right:20}}>
                    <div className="justpadel-buttons" onClick={()=>handleCreateCouponRedirect()}>Add Page</div>
                </div>
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>
          
          <div className="hover-effect table-id-width">
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
          />
          </div>
        </CardBody>
      </Card>
    </Col>
    </Fragment>
  );
};

export default GetPage;
