/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Col, Button, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import axios from '../../../customAxios';
import { useNavigate, useParams } from 'react-router-dom';
import { environment } from '../../env';
import { useForm } from "react-hook-form";
import swal from 'sweetalert'

const PadelholicCard = () => {
  
  const { id } = useParams();
  const navigate = useNavigate();

  const {register,handleSubmit,reset,formState} = useForm({ mode: 'onChange' });
  const { touchedFields, errors, isDirty, isValid } = formState;
  const touched = Object.keys(touchedFields);
  const padelholicUrl = `${environment.baseUrl}editpadelholicslevel/${id}`;
  const GetPadelholic = async () => {
    const result = await axios(padelholicUrl);
    console.log(result);
    reset(result.data); 
  };

  useEffect(() => {
    GetPadelholic();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('Token')) {
      console.log('True');
    } else {
      alert('You are not logged In. Please LogIn');
      navigate('/');
    }
  }, []);
 
  const onSubmit = (value) =>{
    
    console.log("Value", value);
    
    const getPadelholicUrl = `${environment.baseUrl}editpadelholicslevel/${id}`;
    axios
      .put(getPadelholicUrl, value)
      .then((response) => {
        console.log(response);
       if (response.data.msg === 'Padelholic level is update') {
        swal({
          title: "Success!",
          text: "Padelholic level is updated!",
          icon: "success",
          button: "Close",
        }).then(() => {
          navigate('/listpadelholic');           
         });
       }
      });
    }

  const resetData = () =>{
    reset({});
   };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
         
          <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label" style={{fontSize:15}}>Padelholic Level</span>
                <div className="form__form-group-field">
                  <input type="text" style={{borderRadius:5}}
                   name="level" {...register("level", {required:true})}/>
                </div>
                {errors.level && <span className="error-msg">Please Enter Padelholic Level</span>}

              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label" style={{fontSize:15}}>Padelholic Games</span>
                <div className="form__form-group-field">
                  <input type="number"  style={{borderRadius:5}}
                   name="games" {...register("games", {required:true})}/>
                </div>
                {errors.games && <span className="error-msg">Please Enter Padelholic Games</span>}

              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label" style={{fontSize:15}}>Color Code</span>
                <div className="form__form-group-field">
                  <input type="text"  style={{borderRadius:5}}
                   name="color_code" {...register("color_code", {required:true})}/>
                </div>
                {errors.color_code && <span className="error-msg">Please Enter Color Code</span>}
              </div>
            </div>
            <Col md={12} lg={12}>
            <Card style={{display:'flex', flexDirection:"row", justifyContent:"flex-end", marginTop:30,}}>
              <div className="form__half" style={{marginRight:-13}}>
                <ButtonToolbar className="form__button-toolbar" >
                  <button className='justpadel-buttons-save' type='submit'>
                   Update
                  </button>
                   <button className='justpadel-buttons-cancel' onClick={() => resetData()}>
                    Cancel
                  </button>
                </ButtonToolbar>
              </div>
            </Card>
           </Col>
            
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default PadelholicCard;
