import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Spinner,
} from "reactstrap";
import axios from "../../../customAxios";
import S3FileUpload from "react-s3";
import { useParams, useNavigate } from "react-router-dom";
import { environment } from "../../env";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import JoditEditor from "jodit-react";
import PhoneInput, {
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from "react-phone-number-input";
import ImageUploader from "../../MainCourt/components/ImageUploader";
import Select from "react-select";
import Switch from "react-switch";


const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const CourtCard = ({ GetMainCourtName }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const bookingFacilityOptions = [
    { value: "Book Now Facility", label: "Book Now Facility" },
    { value: "Call Now Facility", label: "Call Now Facility" },
  ];

  const [courtTypeList, setCourtTypeList] = useState([]);
  const [resources, setResources] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [times, setTimes] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
    "12:45 AM",
    "01:30 AM",
    "02:15 AM",
  ]);

  const timesAsOptions = times.map((time) => ({
    value: time,
    label: time,
  }));
  const editMainCourt = `${environment.baseUrl}maincourts/${id}`;

  const GetMainCourt = async () => {
    const result = await axios(editMainCourt);
    GetMainCourtName(result.data.courtName);
    setSelectedImage(result.data.maincourtimage);
    let imagesData = [];

    JSON.parse(result.data.maincourt_about_images).map((images) => {
      imagesData.push({ data_url: images.resource, resource: images.resource });
    });

    setResources(imagesData);

    let selectedFilteringCourtType = result.data.courttype_multiple.split(",");
    var selectedFilteringCourtTypeData = [];
    selectedFilteringCourtType.map((selected) => {
      var dd = courtTypeList.filter(
        (ctList) => ctList.value === parseFloat(selected)
      );
      selectedFilteringCourtTypeData.push(dd[0]);
    });

    reset({
      courtName: result.data.courtName,
      learn_more_text: result.data.learn_more_text,
      courtaddress: result.data.courtaddress,
      latitude: result.data.latitude,
      longitude: result.data.longitude,
      price: result.data.price,
      non_peak_hours_start: result.data.non_peak_hours_start,
      non_peak_hours_end: result.data.non_peak_hours_end,
      peak_hours_price_firstslot: result.data.peak_hours_price_firstslot,
      peak_hours_price_secondslot: result.data.peak_hours_price_secondslot,
      // rating: result.data.rating,
      about: result.data.about,
      timeslot: result.data.timeslot,
      features: result.data.features,
      position: result.data.position,
      city: result.data.city,
      country: result.data.country,
      booking_facility: bookingFacilityOptions.filter(
        (data) => data.value === result.data.booking_facility
      )[0],
      courttype_multiple: selectedFilteringCourtTypeData,
      courttype: courtTypeList.filter(
        (ctList) => ctList.value === result.data.courttype
      )[0],
      video_url: result.data.video_url,
      active: result?.data?.active,
    });
  };

  const GetCategory = async () => {
    const courtTypeUrl = `${environment.baseUrl}courttypes`;
    const result = await axios(courtTypeUrl);
    var newCourtType = [];
    await result.data.map((ctdata) => {
      newCourtType.push({ label: ctdata.courttype, value: ctdata.id });
    });
    setCourtTypeList(newCourtType);
  };

  const setImage = (imagesList) => {
    setResources(imagesList);
  };

  // const onSubmit = async (data) => {
  //   setIsLoading(true);
  //   if(resources.length<=0){
  //     setImageError(true);
  //     setIsLoading(false);
  //     return
  //   }

  //   var letFilter = [];
  //   data?.filter?.map((filteredData)=>{
  //     letFilter.push(filteredData.value);
  //   });

  //   let maincourtImg;

  //   if(data.maincourtimage[0]){
  //     await S3FileUpload.uploadFile(data.maincourtimage[0], config)
  //     .then((data) => {
  //       maincourtImg = data.location;
  //     })
  //     .catch((err) => {
  //       console.log("Error Info: ", err);
  //     });
  //   }else{
  //     maincourtImg = selectedImage
  //   }

  //   var letFilter = [];
  //   data?.courttype_multiple?.map((filteredData)=>{
  //     letFilter.push(filteredData.value);
  //   });

  //   data.maincourtimage = maincourtImg;
  //   data.filter = letFilter.toString();
  //   data.maincourt_about_images = JSON.stringify(resources);
  //   data.booking_facility = data.booking_facility.value;
  //   data.courttype_multiple = letFilter.toString();
  //   data.courttype = data.courttype.value;
  //   console.log("Update data payload",data);

  //   axios.put(editMainCourt, data).then((response) => {
  //     if (response.data.Message === "Court Updated Successfully") {
  //       swal({
  //         title: "Success",
  //         text: "Court details is Updated Successfully!",
  //         icon: "success",
  //         button: "Ok",
  //       }).then((value) => {
  //         navigate("/list_main_court");
  //       });
  //     } else if (
  //       response.data.Error === "While Inserting The Padel Court Details"
  //     ) {
  //       swal({
  //         title: "Error",
  //         text: "Something Goes wrong Court Details is not Updated!",
  //         icon: "error",
  //         button: "Ok",
  //       });
  //     } else {
  //       swal({
  //         title: "Error",
  //         text: "Error Updating the Court Details!",
  //         icon: "error",
  //         button: "Ok",
  //       });
  //     }
  //   });
  // };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    if (resources.length <= 0) {
      setImageError(true);
      setIsLoading(false);
      return;
    }

    var letFilter = [];
    formData?.courttype_multiple?.map((filteredData) => {
      letFilter.push(filteredData.value);
    });

    console.log(formData.maincourtimage[0])

    let maincourtImg;

    await S3FileUpload.uploadFile(formData.maincourtimage[0], config)
      .then((data) => {
        maincourtImg = data.location;
      })
      .catch((err) => {
        console.log("Error Info: ", err);
      });

    formData.maincourtimage = maincourtImg;
    formData.courttype_multiple = letFilter.toString();
    // formData.maincourt_about_images = JSON.stringify(resources);
    formData.booking_facility = formData.booking_facility.value;
    formData.courttype = formData.courttype.value;

    var UploadedImages = [];
    resources.map(async (img) => {
      if ("resource" in img) {
        UploadedImages.push({ resource: img.resource });
        if (resources?.length === UploadedImages?.length) {
          submitMainCourt(formData, UploadedImages);
        }
      } else {
        await S3FileUpload.uploadFile(img.file, config)
          .then((data) => {
            UploadedImages.push({ resource: data.location });
            if (resources?.length === UploadedImages?.length) {
              submitMainCourt(formData, UploadedImages);
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    });
  };


  const submitMainCourt = (formData, UploadedImages) => {
    formData.non_peak_hours_start = formData.non_peak_hours_start.value ? formData.non_peak_hours_start.value : formData.non_peak_hours_start
    formData.non_peak_hours_end = formData.non_peak_hours_end.value ? formData.non_peak_hours_end.value : formData.non_peak_hours_end

    formData.maincourt_about_images = JSON.stringify(UploadedImages);
    axios
      .put(editMainCourt, formData)
      .then((response) => {
        if (response.data.Message === "Court Updated Successfully") {
          swal({
            text: "Main Court Updated Successfully",
            icon: "success",
            buttons: true,
          }).then(function () {
            window.location = "/list_main_court";
          });
          setIsLoading(false);
        } else {
          swal({
            title: "Error.. While Updating Main Court",
            text: JSON.stringify(response.data),
            icon: "error",
            buttons: true,
          }).then(function () {
            console.log("");
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        swal({
          text: "Error.. While Updating Main Court",
          icon: "error",
          buttons: true,
        }).then(function () {
          console.log(err);
        });
        setIsLoading(false);
      });
  };

  useEffect(async () => {
    await GetCategory();
  }, []);

  useEffect(() => {
    GetMainCourt();
  }, [courtTypeList]);

  const BookingFacilitySelected = watch("booking_facility");

  return (
    <Col md={12} lg={12}>
      {isLoading && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}
      <Card>
        <CardBody>
          <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Previous Image
                </span>
                <div className="form__form-group-field">
                  <img
                    src={selectedImage}
                    alt={selectedImage}
                    style={{ borderRadius: 5, width: 312 }}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Main Court Name</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Main Court Name"
                    {...register("courtName", { required: true })}
                  />
                </div>
                {errors.courtName && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Name, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Main Court Address
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Main Court Address"
                    {...register("courtaddress", { required: true })}
                  />
                </div>
                {errors.courtaddress && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Address, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Latitude</span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Main Court Latitude"
                    step="any"
                    {...register("latitude", { required: true })}
                  />
                </div>
                {errors.latitude && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Latitude, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Longitude</span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Main Court Longitude"
                    step="any"
                    {...register("longitude", { required: true })}
                  />
                </div>
                {errors.longitude && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Longitude, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Price</span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Main Court Price"
                    {...register("price", { required: true })}
                  />
                </div>
                {errors.price && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Price, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Non Peak Hours Start
                </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="non_peak_hours_start"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      const defaultValueOption = timesAsOptions.find(
                        (option) => option.value === value
                      );
                      return (
                        <Select
                          options={timesAsOptions}
                          className=" w-100"
                          placeholder="Non Peak Hours Start"
                          onChange={onChange}
                          inputRef={ref}
                          value={defaultValueOption}
                        />
                      );
                    }}
                  />
                </div>
                {errors.non_peak_hours_start && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Non Peak Hours Start, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Non Peak Hours End
                </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="non_peak_hours_end"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      const defaultValueOption = timesAsOptions.find(
                        (option) => option.value === value
                      );
                      return (
                        <Select
                          options={timesAsOptions}
                          className=" w-100"
                          placeholder="Non Peak Hours End"
                          onChange={onChange}
                          inputRef={ref}
                          value={defaultValueOption}
                        />
                      )
                    }}
                  />
                </div>
                {errors.non_peak_hours_end && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Non Peak Hours End, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Peak Hours Price Firstslot
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Peak Hours Price Firstslot"
                    {...register("peak_hours_price_firstslot", {
                      required: true,
                    })}
                  />
                </div>
                {errors.peak_hours_price_firstslot && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Peak Hours Price Firstslot, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Peak Hours Price Secondslot
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Peak Hours Price Secondslot"
                    {...register("peak_hours_price_secondslot", {
                      required: true,
                    })}
                  />
                </div>
                {errors.peak_hours_price_secondslot && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Peak Hours Price Secondslot, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">About</span>
                <div className="form__form-group-field">
                  <textarea
                    placeholder="About"
                    {...register("about", { required: true })}
                  />
                </div>
                {errors.about && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter About Main Court, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Learn More </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="learn_more_text"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <JoditEditor
                        ref={ref}
                        value={value}
                        tabIndex={1}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                {errors.content && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Content of Main Court, It Should Not be Empty"
                    }
                  />
                )}
              </div>
            </div>

            <div className="form__half">


              <div className="form product-edit">
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Active Maincourt
                  </span>
                  <div className="form__form-group-field">
                    <Controller
                      name="active"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          onColor="#ffc107"
                          onHandleColor="#ffffff"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                          className="react-switch"
                          id="material-switch"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>



              <div className="form__form-group">
                <span className="form__form-group-label">Court Position</span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="add court position"
                    style={{ borderRadius: 5, borderColor: "#ccc" }}
                    {...register("position", { required: true })}
                  />
                </div>
                {errors.position && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court Position, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Opening and Closing Time
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Opening and Closing Time"
                    {...register("timeslot", { required: true })}
                  />
                </div>
                {errors.timeslot && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court Opening and Closing Time, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Features</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Features"
                    {...register("features", { required: true })}
                  />
                </div>
                {errors.features && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court Features, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Court Type</span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="courttype"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        value={value}
                        options={courtTypeList}
                        className="text-uppercase w-100"
                        placeholder="Select court type"
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
                {errors.courttype && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court Court-Type, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">City</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="City"
                    {...register("city", { required: true })}
                  />
                </div>
                {errors.city && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court City, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Country</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Country"
                    {...register("country", { required: true })}
                  />
                </div>
                {errors.country && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court country, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group" style={{ marginTop: 20 }}>
                <span
                  className="form__form-group-label "
                  style={{ fontSize: 15 }}
                >
                  Court Type for Filter
                </span>
                <div
                  className="form__form-group-field"
                  style={{ flexDirection: "column" }}
                >
                  <Controller
                    control={control}
                    name="courttype_multiple"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        value={value}
                        options={courtTypeList}
                        closeMenuOnSelect={true}
                        isMulti
                        className="text-uppercase w-100"
                        placeholder="Select Filtering Court Category"
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
                {errors.courttype_multiple && (
                  <ErrorMessageComponent
                    message={
                      "Please Select Main Court Filter Category, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Booking Facility</span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="booking_facility"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        value={value}
                        options={bookingFacilityOptions}
                        className="text-uppercase w-100"
                        placeholder="Select Booking Facility"
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
                {errors.booking_facility && (
                  <ErrorMessageComponent
                    message={
                      "Please Select Main Court Booking Facility, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              {BookingFacilitySelected?.value === "Call Now Facility" && (
                <div className="form__form-group">
                  <span className="form__form-group-label">Contact Number</span>
                  <div className="form__form-group-field">
                    <Controller
                      control={control}
                      name="contact_number"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <PhoneInput
                          defaultCountry="AE"
                          className="add-client-phone w-100"
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  {errors.contact_number && (
                    <ErrorMessageComponent
                      message={
                        "Please Select Main Court Booking Facility Phone No, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
              )}

              <div className="form__form-group">
                <span className="form__form-group-label">Upload Image</span>
                <div className="form__form-group-field">
                  <input
                    type="file"
                    className="form__form-group-field"
                    {...register("maincourtimage", { required: false })}
                  />
                </div>
                {errors.maincourtimage && (
                  <ErrorMessageComponent
                    message={
                      "Please Select Main Court Image, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Video URL</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    className="form__form-group-field"
                    {...register("video_url", { required: true })}
                  />
                </div>
                {errors.video_url && (
                  <ErrorMessageComponent
                    message={
                      "Please Select Main Court Video URL, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">About Us Images</span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="maincourt_about_images"
                    rules={{ required: false }}
                    render={({
                      field: { onChange, onBlur, value, name },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <ImageUploader
                        setImage={setImage}
                        resources={resources}
                      />
                    )}
                  />
                </div>
                {imageError && (
                  <ErrorMessageComponent
                    message={
                      "Please Add Main Court About Images, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <button
                  className="justpadel-buttons-save"
                  color="primary"
                  type="submit"
                  disabled={resources?.length <= 0}
                >
                  Save
                </button>
                <div className="justpadel-buttons-cancel" onClick={()=> window.location = "/list_main_court"}>Cancel</div>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CourtCard;

const ErrorMessageComponent = ({ message }) => {
  return (
    <div color="danger" className="form__form-group-error">
      <p style={{ color: "#dc3545" }}>
        <span className="bold-text">Warning! </span>
        {message}
      </p>
    </div>
  );
};
