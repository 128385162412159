import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CoponUsedList from './components/CoponUsedList';
import Coupons from './components/Coupons';
import CouponGroup from './components/CouponGroup';

const ExamplePage = () => (
  <Container className="dashboard">
    {/* <Row> */}
      <CouponGroup />
    {/* </Row> */}
    <Row className='p-0'>
      <CoponUsedList />
    </Row>
  </Container>
);

export default ExamplePage;
