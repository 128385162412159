import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../../customAxios";
import { environment } from "../../../env";
import _ from "lodash";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  CardBody,
  Card,
  Col,
  Spinner,
  Input,
  Row,
} from "reactstrap";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";

const SplitPointComponent = ({ playerId, totalPoints, userData }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  const [clearSearchPlayerTwo, setClearSearchPlayerTwo] = useState(false);
  const [enablePoints, setEnablePoints] = useState(true);
  const [search, setSearch] = useState(false);
  const [registeredUser, setRegisteredUser] = useState([]);

  // --------------------------------------------------------------------
  // ----------------- Player Second Functionality ----------------------
  // --------------------------------------------------------------------
  let playerSecValue;
  let splitPoints = watch("point");
  const getPlayerSecDetails = () => {
    const userDetailsUrl = `${environment.baseUrl}filter-user/${playerSecValue}`;
    axios.get(userDetailsUrl).then((response) => {
      setEnablePoints(false);
      setSearch(true);
      setRegisteredUser(response.data);
      setClearSearchPlayerTwo(true);
    });
  };

  const debouncedPlayerSecDetails = _.debounce(getPlayerSecDetails, 3000);
  const PlayerSecDetails = (playerSecId) => {
    if (playerSecId.length > 1) {
      playerSecValue = playerSecId;
      debouncedPlayerSecDetails();
    }
  };
  // --------------------------------------------------------------------
  // ----------------- Player Second Functionality ----------------------
  // --------------------------------------------------------------------

  const handleClearSearchPlayerTwo = (e) => {
    setEnablePoints(true);
    e.preventDefault();
    reset({ userDetails: "" });
    localStorage.removeItem("player" + playerId);
    setClearSearchPlayerTwo(false);
  };

  const handlePointChange = (e) => {
    let palyerData = JSON.parse(localStorage.getItem("player" + playerId));
    localStorage.setItem(
      "player" + playerId,
      JSON.stringify({ ...palyerData, point: parseInt(splitPoints) })
    );
  };

  const SetUserValues = (id, email, first_name, phone) => {
    reset({
      userDetails:
        first_name +
        " - " +
        email +
        " - " +
        phone,
      points: splitPoints,
    });

    localStorage.setItem(
      "player" + playerId,
      JSON.stringify({
        userid: id,
        email: email,
        point: totalPoints / 4,
        flag: "add",
      })
    );

    setSearch(false);

  }

  useEffect(() => {
    if (playerId == 1) {
      localStorage.setItem(
        "player" + playerId,
        JSON.stringify({
          userid: userData?.id,
          email: userData?.email,
          point: totalPoints / 4,
          flag: "sub",
        })
      );

      reset({
        userDetails:
          userData?.first_name +
          " - " +
          userData?.email +
          " - " +
          userData?.phone_no,
        points: totalPoints / 4,
      });
    } else {
      reset({
        point: totalPoints / 4
      });
    }

  }, []);

  return (
    <Row className="p-0">
      <Col sm={12} md={12} lg={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="p-0">
            <Col sm={2} md={2} lg={2} className="d-flex justify-content-start align-items-center p-0">
              <span className="font-weight-bold">Player {playerId}:</span>
            </Col>
            <Col sm={8} md={8} lg={8} className="p-0">
              <Row className="no-gutters bulk-d">
                <Col sm={1} md={1} lg={1}>
                  <button
                    type="button"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    {clearSearchPlayerTwo ? (
                      <AiOutlineCloseCircle
                        className="close"
                        onClick={(e) => handleClearSearchPlayerTwo(e)}
                      />
                    ) : (
                      <AiOutlineSearch
                        className="search-icon"
                        style={{ marginLeft: 0, fontSize: "24px" }}
                      />
                    )}
                  </button>
                  {search && (
                    <div className="email-list" id="data" style={{ width: "95%" }}>
                      {registeredUser?.map((item, key) => (
                        <div
                          key={key}
                          onClick={() =>
                            SetUserValues(item.id, item.email, item.first_name, item.phone_no)
                          }
                        >
                          <div className="single-emeil__data">{item.id}{" - "}
                            {item.first_name === "null" ? "No Data" : item.first_name}{" "}
                            {" - "} {item.email} {" - "}{" "}
                            {item.phone_no === "null" ? "No Data" : item.phone_no}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Col>
                <Col sm={11} md={11} lg={11}>
                  <div className="col">
                    <input
                      name="userDetails"
                      className="bulk-input"
                      type="text"
                      placeholder="search"
                      {...register("userDetails", { required: true })}
                      onChange={(e) => PlayerSecDetails(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="quantity width">
                <input
                  type="number"
                  disabled={enablePoints}
                  {...register("point", { required: true })}
                  defaultValue={totalPoints / 4}
                  onClick={(e) => handlePointChange(e)}
                />
              </div>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default SplitPointComponent;

{/* <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row
          md={12}
          lg={12}
          className="align-items-center justify-content-between"
        >
          <Col md={2} lg={2}>
            <p className="font-weight-bold">Player {playerId}:</p>
          </Col>
          <Col md={8} lg={8} className="marginleft">
            <div className="row no-gutters bulk-d">
              <div className="col">
                <input
                  name="userDetails"
                  className="bulk-input"
                  type="text"
                  placeholder="search"
                  {...register("userDetails", { required: true })}
                  onChange={(e) => PlayerSecDetails(e.target.value)}
                />
              </div>
              <div className="d-flex">
                <button
                  type="button"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {clearSearchPlayerTwo ? (
                    <AiOutlineCloseCircle
                      className="close"
                      onClick={(e) => handleClearSearchPlayerTwo(e)}
                    />
                  ) : (
                    <AiOutlineSearch
                      className="search-icon"
                      style={{ marginLeft: 0, fontSize: "24px" }}
                    />
                  )}
                </button>
              </div>
            </div>
            {search ? (
              <div className="email-list" id="data" style={{ width: "95%" }}>
                {registeredUser?.map((item, key) => (
                  <div
                    key={key}
                    onClick={() =>
                      SetUserValues(item.id, item.email, item.first_name, item.phone_no)
                    }
                  >
                    <div className="single-emeil__data">{item.id}{" - "}
                      {item.first_name === "null" ? "No Data" : item.first_name}{" "}
                      {" - "} {item.email} {" - "}{" "}
                      {item.phone_no === "null" ? "No Data" : item.phone_no}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </Col>
          <Col md={2} lg={2}>
            <div className="quantity width">
              <input
                type="number"
                disabled={enablePoints}
                {...register("point", { required: true })}
                defaultValue={totalPoints / 4}
                onClick={(e) => handlePointChange(e)}
              />
            </div>
          </Col>
        </Row>
      </form>
    </div> */}

