import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import axios from '../../customAxios';
import { environment } from '../env';
import AvailableModal from '../../shared/components/AailableModal';
import BookedDetail from './BookedDetail';
import BookingDetailsModalV2 from '../../shared/components/BookingDetailsModalV2';
import { CancelBooking, CancelCoacheBooking, CancelStaffBooking, GetMainCourt } from '../Reuse/ApiCalls';
import swal from "@sweetalert/with-react";
import CalendarSkaleton from '../Skaleton/CalendarSkaleton';
import { getAllPadelCourtData } from '../../redux/thunk/ConfigThunk';
import { setAdminMainCourt } from '../../redux/slice/GlobalConfigSlice';

const CalenderV3 = () => {
    const authslice = useSelector((state) => state.auth);
    const { mainCourtListAdmin } = useSelector((state) => state.config);
    const [selectedMainCourt, setSelectedMainCourt] = useState(authslice?.loggedUser?.default_court);
    const [bookingList, setBookingList] = useState();
    const [prePopulatedDated, setPrePopulatedDated] = useState();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bookedSlotDetails, setBookedSlotDetails] = useState();
    const [start, onChangeStart] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [isCalenderLoading, setIsCalenderLoading] = useState(false);
    const dispatch = useDispatch();

    const handleMainCourtSelection = (value) => {
        setSelectedMainCourt(value);
    };

    const filterBooking = () => {
        getBookingDetailsCalender();
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const getBookingDetailsCalender = () => {
        setIsCalenderLoading(true);
        try {
            const getBookingCalenderURL = `${environment.baseUrl}calenderv3`;
            const payload = {
                booking_date: moment(start).format('DD-MM-YYYY'),
                main_court_id: selectedMainCourt
            };
            axios.post(getBookingCalenderURL, payload).then((response) => {
                setBookingList(response?.data);
                setIsCalenderLoading(false);
            }).catch((error) => {
                setIsCalenderLoading(false);
            });
        } catch (error) {
            console.log("error--->", error);
            setIsCalenderLoading(false);
        }
    };

    const submitDate = () => {
        getBookingDetailsCalender();
    };

    const BookedModal = (timeslot, padelcourt, BookingData) => {
        let prePopulateData = {
            main_court_id: selectedMainCourt,
            padelcourtId: padelcourt,
            startTime: timeslot.split("-")[0],
            endTime: timeslot.split("-")[1],
            startDate: moment(start).format("yyyy-MM-DD"),
            endDate: moment(start).format("yyyy-MM-DD"),
        };

        let item = {
            maincourtId: BookingData.bookingdetails.maincourtId,
            booking_id: BookingData.bookingdetails.booking_id,
            id: BookingData.padelcourt,
            name: BookingData.bookingdetails.name,
            user: BookingData.bookingdetails.user,
            booking_category: BookingData.bookingdetails.booking_category,
            username: BookingData.bookingdetails.booked_by.customer_name,
            email: BookingData.bookingdetails.booked_by.email,
            phone_no: BookingData.bookingdetails.booked_by.phone_no,
            payment_type: BookingData.bookingdetails.booked_by.payment_type,
            additionalcomments:
                BookingData.bookingdetails.booked_by.additionalcomments,
        };

        setBookedSlotDetails(item);
        setPrePopulatedDated(prePopulateData);
        toggleModal();
        setIsLoading(false)
    };

    const categories = [
        "Bookings",
        "Events",
        "Tournaments",
        "Maintainence",
        "Coaching",
        "Coaching-Regular",
        "Coaching-Pro Tennis",
        "Coaching-Just Padel",
        "GTAP",
        "Matchmaking",
        "Others"
    ];

    const getBackgroundColor = (category) => {
        const colorMapping = {
            "Bookings": "#e15f40",
            "Events": "#686de0",
            "Tournaments": "#3868d6",
            "Maintainence": "#dd3444",
            "Coaching": "#f0932b",
            "Coaching-Regular": "#f0932b",
            "Coaching-Pro Tennis": "#ffbe76",
            "Coaching-Just Padel": "#f9ca24",
            "GTAP": "#22a6b3",
            "Matchmaking": "#30336b",
            "Others": "#6D214F",
        };
        return colorMapping[category] || "#e15f40";
    };

    const CancelBookings = (bookingid, logs) => {
        let newLog;
        if (logs === "") {
            let AdminUserName = localStorage.getItem("AdminUserName");
            newLog = JSON.stringify([
                {
                    created_or_edited_by: "Cancelled by user " + AdminUserName + "",
                    created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
                    created_or_edited_time: moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),
                },
            ]);
        } else {
            console.log("logs", typeof logs);
            const prevLog = typeof logs == "object" ? logs : JSON.parse(logs);
            let AdminUserName = localStorage.getItem("AdminUserName");
            newLog = JSON.stringify([
                {
                    created_or_edited_by: "Cancelled by user " + AdminUserName + "",
                    created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
                    created_or_edited_time: moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),
                },
                ...prevLog,
            ]);
        }
        swal({
            title: "Are you sure?",
            text: "You want to Cancel the Booking",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                CancelStaffBooking(bookingid);
                CancelCoacheBooking(bookingid);
                CancelBooking(bookingid, newLog).then((response) => {
                    if (response.Message === "Booking Cancelled!!!") {
                        swal({
                            icon: "success",
                            content: (
                                <div>
                                    <h1>Booking Cancelled</h1>
                                </div>
                            ),
                        }).then(() => {
                            // refreshDetailsData();
                        });
                    }
                });
            } else {
                swal("Booking is not cancelled");
            }
        });
    };

    useEffect(() => {
        setSelectedMainCourt(authslice?.loggedUser?.default_court)
    }, [authslice]);

    useEffect(() => {
        if (selectedMainCourt) {
            getBookingDetailsCalender();
        };
    }, [selectedMainCourt]);

    useEffect(() => {
        dispatch(getAllPadelCourtData());
        if (mainCourtListAdmin?.length === 0) {
            GetMainCourt().then((response) => {
                dispatch(setAdminMainCourt(response));
            });
        };
    }, []);

    return (
        <Container className="dashboard">
            <BookingDetailsModalV2
                color="primary"
                header
                title="Booking Details"
                userdetails={bookedSlotDetails?.user}
                transactionId={bookedSlotDetails?.booking_id}
                cancelBoooking={CancelBookings}
                showHide={showModal}
                prePopulatedDated={prePopulatedDated}
                toggleModal={toggleModal}
            />
            <Row>
                <Col sm={12} md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <h3>Calender</h3>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody className='mb-4'>
                            <Row className="align-items-end justify-content-center">
                                <Col sm={10} md={10} lg={10}>
                                    <div className="form form__form-group">
                                        <div className="form__form-group-field d-flex">
                                            <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                                                <span>Select Main Court</span>
                                                <select
                                                    className="select select-location"
                                                    style={{ width: '100%' }}
                                                    value={selectedMainCourt}
                                                    onChange={(e) => handleMainCourtSelection(e.target.value)}>
                                                    <option>Select Main Court</option>
                                                    {mainCourtListAdmin.length > 0 && mainCourtListAdmin?.map((court) => (
                                                        <option value={court.id} key={court.id}>
                                                            {court.courtName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="d-flex flex-column w-100" style={{ marginLeft: '5px' }}>
                                                <span>Select Date</span>
                                                <input
                                                    type="date"
                                                    value={start}
                                                    onChange={(e) => onChangeStart(e.target.value)}
                                                    className="custom-css-dp"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={2} md={2} lg={2} className="d-flex justify-content-start">
                                    <Button
                                        color="warning"
                                        size="sm"
                                        onClick={() => filterBooking()}
                                        className='mb-0'
                                    >
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                            {isCalenderLoading && <div className='mt-4' ><CalendarSkaleton /></div>}
                            {!isCalenderLoading && <Row className='mt-4'>
                                <Col sm={12} md={12} lg={12}>
                                    <Table className="calendar">
                                        <thead>
                                            <tr className="d-flex">
                                                {bookingList?.courtList?.map((data, index) => {
                                                    return (
                                                        <td className="calender__courts text-capitalize" key={index}>
                                                            {data}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookingList?.bookingDetails?.map((slot, index) => (
                                                <tr key={index} className="d-flex" >
                                                    <td className="calender__courts" key={index}>
                                                        {slot?.timeslots}
                                                    </td>
                                                    {slot?.bookingData?.map((booking, idx) => (
                                                        booking?.bookingdetails === "Available" ? (
                                                            <AvailableModal
                                                                key={idx}
                                                                submitDate={submitDate}
                                                                color="primary"
                                                                title={`Quick Booking`}
                                                                header
                                                                prePopulatedDated={{
                                                                    main_court_id: selectedMainCourt,
                                                                    padelcourtId: booking?.padelcourt,
                                                                    startTime: slot?.timeslots.split("-")[0],
                                                                    endTime: slot?.timeslots.split("-")[1],
                                                                    startDate: moment(start).format("yyyy-MM-DD"),
                                                                    endDate: moment(start).format("yyyy-MM-DD"),
                                                                }}
                                                            />
                                                        ) : (
                                                            <td
                                                                key={idx}
                                                                className="calender__booked"
                                                                onClick={() =>
                                                                    BookedModal(
                                                                        slot.timeslots,
                                                                        booking.padelcourt,
                                                                        booking
                                                                    )
                                                                }
                                                                style={{ backgroundColor: getBackgroundColor(booking?.bookingdetails?.booking_category) }}>
                                                                <BookedDetail
                                                                    details={{
                                                                        username: `${booking.bookingdetails.booked_by.customer_name}`,
                                                                        phone_no: `${booking.bookingdetails.booked_by.customer_phone}`,
                                                                    }}
                                                                />
                                                            </td>
                                                        )
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>}
                            <div className="d-flex flex-wrap">
                                {categories.map((category, index) => (
                                    <div key={index} className="rounded-flex">
                                        <div
                                            className="div-round"
                                            style={{ backgroundColor: getBackgroundColor(category) }}
                                        ></div>
                                        <span className="m-1" style={{ marginTop: "2px" }}>
                                            {category}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default CalenderV3