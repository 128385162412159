import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';

const PackagesSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Packages" icon="file-add">
            {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <SidebarLink title="Active Users" route="/user-packages" />}
        </SidebarCategory>
    )
}

export default PackagesSidebar;