import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Label, Button, ModalFooter, Modal, ModalHeader, ModalBody, Text } from 'reactstrap';

// import classNames from 'classnames';
// import { RTLProps } from '../prop-types/ReducerProps';
// import { BulkBookingApi } from '../../containers/Reuse/ApiCalls';
// import TransactionTabs from '../../containers/UI/Tabs/components/TransactionTabs';
// import './TransationModal.css';
import { AiFillEye } from 'react-icons/ai';
import { useNavigate } from "react-router-dom"


const Reportmodal = ({ UserData, show, CloseModal }) => {


  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  console.log(UserData);

  const ToggleHandle = () => {
    setShowModal(!showModal);
  };
  return (
    <div>
      <AiFillEye style={{ fontSize: 20 }} onClick={ToggleHandle} />
      <Modal isOpen={showModal}
        style={{ maxWidth: '900px' }}
        className="QuickPayModal"
      >
        <ModalHeader toggle={ToggleHandle} className="QuickPayModalHeader">
          <span>Client Details</span>
        </ModalHeader>

        <ModalBody style={{ textAlign: "initial", padding: 50 }}>
          <Row>
            <Col sm="4" md="4" lg="4">
              {
                (UserData?.postid?.padelgram_image).includes("mp4") || (UserData?.postid?.padelgram_image).includes("mov") ?
                  <video style={{ width: 130, borderRadius: 5 }} preload controls src={UserData?.postid?.padelgram_image}></video>
                  :
                  <img src={UserData?.postid?.padelgram_image} alt={UserData?.postid?.padelgram_image} style={{ width: 180, borderRadius: 5 }} />
              }
            </Col>
            <Col sm="8" md="8" lg="8">
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label>Caption : </Label>
                </Col>
                <Col sm="8" md="8" lg="8">
                  <Label>{UserData?.postid?.padelgram_image_comment}</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label>Location : </Label>
                </Col>
                <Col sm="8" md="8" lg="8">
                  <Label>{UserData?.postid?.location}</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label>Visibility : </Label>
                </Col>
                <Col sm="8" md="8" lg="8">
                  <Label className="text-capitalize">{UserData?.postid?.post_type}</Label>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col sm="4" md="4" lg="4">
                  <Label>Posted By : </Label>
                </Col>
                <Col sm="4" md="4" lg="4">
                  <Label>{UserData?.postid?.user?.first_name} {" "} {UserData?.postid?.user?.last_name}</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label></Label>
                </Col>
                <Col sm="4" md="4" lg="4">
                  <Label>{UserData?.postid?.user?.email}</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label></Label>
                </Col>
                <Col sm="4" md="4" lg="4">
                  <Label>{UserData?.postid?.user?.phone_no}</Label>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button onClick={ToggleHandle} className="cancel-btn">
            Close
          </Button>
        </ModalFooter>

      </Modal>
    </div>

  );
};


export default Reportmodal;
