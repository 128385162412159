/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Col, Button, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import axios from '../../../customAxios';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../env';
import { useForm } from "react-hook-form";
import swal from 'sweetalert'


const PadelholicCard = () => {
  const [padelholicLevel, setPadelholicLevel] = useState();
  const [game, setGames] = useState();
  const navigate = useNavigate();

  const {register,handleSubmit,reset,formState} = useForm({ mode: 'onChange' });
  const { touchedFields, errors, isDirty, isValid } = formState;
  const touched = Object.keys(touchedFields);

  // function handleChangePadelholicLevel(e) {
  //   setPadelholicLevel(e.target.value);
  // }

  // function handleChangePadelholicGames(e) {
  //   setGames(e.target.value);
  // }



  // const handleSubmit = () => {
  //   const padelholicData = {
  //     level: padelholicLevel,
  //     games: game,
  //   };

  //   console.log(padelholicData);
  //   axios
  //     .post(padelholicUrl, padelholicData)
  //     .then((response) => {
  //       console.log(response);
  //     });
  // };

  useEffect(() => {
    if (localStorage.getItem('Token')) {
      console.log('True');
    } else {
      alert('You are not logged In. Please LogIn');
      navigate('/');
    }
  }, []);


  const onSubmit = (value) =>{
    
    console.log("Value", value);
    
    const padelholicUrl = `${environment.baseUrl}getPadelholicsLevel`;
    axios
      .post(padelholicUrl, value)
      .then((response) => {
        console.log(response);
       if (response.data.msg === 'Level is added successfully') {
        swal({
          title: "Success!",
          text: "Level is Added Successfully!",
          icon: "success",
          button: "Close",
        }).then(() => {
          navigate('/listpadelholic');           
         });
       }
      });
    }


  const resetData = () =>{
   reset({});
  };

  

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Padelholic</h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Padelholic Level</span>
                <div className="form__form-group-field">
                  <input type="text" name="level" {...register("level", {required:true})} />
                </div>
                {errors.level && <span className="error-msg">Please Enter Padelholic Level</span>}

              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Padelholic Games</span>
                <div className="form__form-group-field">
                  <input type="number" name="games" {...register("games", {required:true})} />
                </div>
                {errors.games && <span className="error-msg">Please Enter Padelholic Games</span>}

              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Color Code</span>
                <div className="form__form-group-field">
                  <input type="text" name="color_code" {...register("color_code", {required:true})} />
                </div>
                {errors.color_code && <span className="error-msg">Please Enter Color Code</span>}

              </div>
            </div>
            <div className="form__half">
            <ButtonToolbar className="form__button-toolbar" style={{marginTop:40}}>
              <button  className='justpadel-buttons-save' type='submit'>
                Submit
              </button>
              <button type="button" className='justpadel-buttons-cancel' onClick={() => resetData()}>
                Cancel
              </button>
            </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default PadelholicCard;
