/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Col, Button, ButtonToolbar, Card, CardBody,Spinner
} from 'reactstrap';
import axios from '../../../customAxios';
import { useParams, useNavigate } from 'react-router-dom';
import { environment } from '../../env';
import swal from 'sweetalert';

const EditCourtCategoryEditCard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  const [Category, setCategory] = useState();
  function handleCategoryChange(e) {
    setCategory(e.target.value);
  }
  const categoryUrl = `${environment.baseUrl}category/${id}/`;
  const handleSubmit = () => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append('courtcategory', Category);
    axios
      .put(categoryUrl, formData)
      .then((response) => {
        if (response.data.Message === "Court Category Updated Successfully") {
          swal({
            title: "Success",
            text: response.data.Message,
            icon: "success",
            button: "Ok",
          }).then((value) => {
            navigate("/list_court_category");
          });
          setIsLoading(false);
        } else {
          swal({
            title: "Error",
            text: response.data.Message,
            icon: "error",
            button: "Ok",
          });
          setIsLoading(false);
        }
      }).catch((err) => {
        swal({
          title: "Error",
          text: err.messgae,
          icon: "error",
          button: "Ok",
        }).then(() => {
          navigate("/list_court_category");
        });
        setIsLoading(false);
      });
  };

  const editCategoryUrlId = `${environment.baseUrl}category/${id}/`;
  const getCategory = async () => {
    const result = await axios(editCategoryUrlId);
    setCategory(result.data.courtcategory);
  };

  useEffect(() => {
    if (localStorage.getItem('Token')) {
    } else {
      alert('You are not logged In. Please LogIn');
      navigate('/');
    }

    getCategory();
  }, []);

  const reset = () => {
    navigate('/list_court_category')
  };

  return (
    <Col md={12} lg={12}>
      {isLoading && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}
      <Card>
        <CardBody>
          <form className="form product-edit">
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label" style={{ fontSize: 15 }}>Enter Court Category</span>
                <div className="form__form-group-field">
                  <input type="text" value={Category} style={{ borderRadius: 5 }} onChange={handleCategoryChange} />
                </div>
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar" style={{ marginTop: 35 }}>
              <div className='justpadel-buttons-save' onClick={handleSubmit}>
                Update
              </div>
              <div className='justpadel-buttons-cancel' onClick={() => reset()}>
                Cancel
              </div>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EditCourtCategoryEditCard;
