/* eslint-disable no-loop-func */
import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Card,
  CardBody,
  Table,
  Spinner,
  Row,
  Button,
} from "reactstrap";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";
import DatePicker from "react-date-picker";
import moment from "moment";
import { environment } from "../../env";
import axios from "../../../customAxios";
import { useNavigate } from "react-router-dom";
import Modal from "../../../shared/components/Modal";
import SampleModalComponent from "../../../shared/components/SampleModalComponent";
import { GetUserDetailsByEmail, UpdateUserDetails, GetAdminBooking } from "../../Reuse/ApiCalls";
import UserDetails from "./UserDetails";
import AvailableModal from "../../../shared/components/AailableModal";
import ReactTooltip from "react-tooltip";
import './Calender.css'
import CalendarSkaleton from "../../Skaleton/CalendarSkaleton";
import Promotion from "./Promotion";
import momenttimezone from 'moment-timezone';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useSelector, useDispatch } from 'react-redux'
const ExamplePage = () => {
  const authslice = useSelector((state) => state.auth);

  const [reportsData, setReportsData] = useState([]);
  const [mainCourtList, setMainCourtList] = useState([]);
  const [start, onChangeStart] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [mainCourtId, setMainCourtId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [bookingDetails, setBookingDetails] = useState();
  const [showAvailableModal, setshowAvailableModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [prePopulatedDated, setPrePopulatedDated] = useState();
  const [defaultCourt, setDefaultCourt] = useState();
  const [userData, setUserData] = useState();
  const [promotionUsed, setPromotionUsed] = useState(false);
  const navigate = useNavigate();


  const filteredMainCourt = mainCourtList.filter((data) => (data.id !== 12));


  const courtIsAvailableOrNot = (data) => {
    let dubaiCurrentTimeZone;
    var dubaiCurrentTime = momenttimezone.tz('Asia/Dubai').format('HH:mm:ss');
    var bookingStartTime = moment(data.displayValue, ["hh:mm A"]).format("HH:mm:ss");

    if (new Date(moment(data.date, ["DD-MM-YYYY"]).format('MM-DD-YYYY')).getDate() == new Date().getDate()) {
      dubaiCurrentTimeZone = new Date(`${moment(data.date, ["DD-MM-YYYY"]).format('YYYY-MM-DD')}T${dubaiCurrentTime}.826Z`);
    } else {
      dubaiCurrentTimeZone = new Date(`${moment(new Date()).format('YYYY-MM-DD')}T${dubaiCurrentTime}.826Z`);
    }

    var start = new Date(`${moment(data.date, ["DD-MM-YYYY"]).format('YYYY-MM-DD')}T${bookingStartTime}.826Z`);

    if (start >= dubaiCurrentTimeZone) {
      return "Available"
    } else {
      return "Blocked"
    }
  };


  const newCol = [
    {
      Header: "Time Slots",
      accessor: "timeSlots",
      disableGlobalFilter: true,
      width: 10,
    },
  ];
  const dummyData = reportsData ? reportsData : [];
  let totalCourts = [];
  let bookedslots = [];
  let cols = [];
  for (
    var i = 0;
    i < dummyData[0]?.details[0]?.totalslots?.court?.length;
    i++
  ) {
    var item = {};
    // if(!dummyData[0]?.details[0]?.totalslots?.court[i].disabled){
    item["Header"] =
      "Court " + dummyData[0]?.details[0]?.totalslots?.court[i].name;
    item["accessor"] =
      "name" + dummyData[0]?.details[0]?.totalslots?.court[i].name;
    item["disableGlobalFilter"] = true;
    item["width"] = 65;
    item["id"] = dummyData[0]?.details[0]?.totalslots?.court[i].id;
    item["disabled"] = dummyData[0]?.details[0]?.totalslots?.court[i].disabled;
    totalCourts.push(dummyData[0].details[0].totalslots.court[i]);
    if (!cols.some((col) => col.accessor === item.accessor)) {
      cols.push(item);
    }
    // cols.push(item);
    cols.sort((a, b) => (a.accessor > b.accessor ? 1 : -1));
    // }
  }
  for (var n = 0; n < cols.length; n++) {
    newCol.push(cols[n]);
  }
  const newRow = [];
  for (var j = 0; j < dummyData.length; j++) {
    var itemRow = {};
    bookedslots = [];


    dummyData[j]?.details[1]?.bookedslots?.court.forEach((slot) => {
      bookedslots.push(slot);
    });


    itemRow["id"] = j + 1;
    itemRow["timeSlots"] = dummyData[j].timeSlots;

    // courtIsAvailableOrNot(dummyData[j])

    totalCourts.forEach((court) => {
      itemRow["name" + court.name] = court.disabled ? "Disabled" : "Available";
      bookedslots.forEach((bookedCourt) => {
        if (bookedCourt.name === court.name && !court.disabled) {
          itemRow["backgroundColor" + court.name] =
            bookedCourt?.booking_category === ("Default Bookings" || "Bookings")
              ? "#e15f40"
              : bookedCourt?.booking_category === "Events"
                ? "#686de0"
                : bookedCourt?.booking_category === "Tournaments"
                  ? "#3868d6"
                  : bookedCourt?.booking_category === "Maintainence"
                    ? "#dd3444"
                    : bookedCourt?.booking_category === ("Coaching" || "Coaching-Regular")
                      ? "#f0932b"
                      : bookedCourt?.booking_category === ("Coaching-Pro Tennis")
                        ? "#ffbe76"
                        : bookedCourt?.booking_category === ("Coaching-Just Padel")
                          ? "#f9ca24"
                          : bookedCourt?.booking_category === ("GTAP")
                            ? "#22a6b3"
                            : bookedCourt?.booking_category === ("Matchmaking")
                              ? "#30336b"
                              : bookedCourt?.booking_category === "Others"
                                ? "#6D214F"
                                : "#e15f41";
          itemRow[`bookedCourt${court.name}`] = bookedCourt;
          itemRow["name" + court.name] = (
            <span
              backgroundColor={
                bookedCourt?.booking_category === "Events"
                  ? "#940c3e"
                  : "#b50404"
              }
              // onClick={() => bookingCancel(bookedCourt)}
              style={{ textOverflow: "ellipsis", cursor: 'pointer' }}
            >
              {" "}
              {bookedCourt?.role === "customer" ? (
                bookedCourt?.username +
                " - " +
                bookedCourt?.phone_no +
                (bookedCourt?.coupon !== '' ? " - " : '') +
                (bookedCourt?.coupon !== '' ? bookedCourt?.coupon : '')
              ) : (
                <UserDetails
                  bookedCourt={bookedCourt.booking_id}
                  booking_category={bookedCourt?.booking_category}
                />
              )} <Promotion bookedCourt={bookedCourt.booking_id} slots={bookedslots} />{" "}
            </span>
          );
        }
      });
    });
    newRow.push(itemRow);
  }
  const data2 = {
    columns: newCol,
    rows: newRow,
  };
  const reportUrl = `${environment.baseUrl}getcourtdetailsbydate`;


  const getReport = async (start_dates, maincourtid) => {
    setSpinner(true);
    const calenderDataPayload = {
      start_date: start_dates,
      end_date: start_dates,
      maincourtId: maincourtid,
    };

    console.log("calenderDataPayload", calenderDataPayload);

    setReportsData([]);
    const result = axios
      .post(reportUrl, calenderDataPayload)
      .then((response) => {
        setReportsData(response?.data?.result[0]);
        setSpinner(false);
      })
      .catch((error) => {
        setReportsData([]);
      });
  };


  function handleSelectMainCourt(e) {
    setMainCourtId(e.target.value);
  }


  const bookingCancel = (item) => {
    setBookingDetails(item);
    setShowModal(!showModal);
  };


  const mainCourtUrl = `${environment.baseUrl}maincourts-admin/`;
  const GetMainCourt = async () => {
    let user = JSON.parse(localStorage.getItem("loggedUserDetails"));
    const result = await axios(mainCourtUrl);
    setMainCourtList(result.data);
    setMainCourtId(2);
    setTimeout(() => {
      let start_date = moment().format("DD-MM-YYYY");
      getReport(start_date, user?.default_court);
    }, 2000);
  };






  const submitDate = () => {
    setSpinner(true);
    getReport(moment(start).format("DD-MM-YYYY"), mainCourtId);
  };


  const submitDefault = async () => {
    const userPayload = { ...userData, default_court: defaultCourt }
    const Email = localStorage.getItem("Email");
    await UpdateUserDetails(Email, userPayload);
  };


  const AvailableSlot = (item, colItem) => {
    let prePopulateData = {
      main_court_id: mainCourtId,
      padelcourtId: colItem.id,
      startTime: item.timeSlots.split("-")[0],
      endTime: item.timeSlots.split("-")[1],
      startDate: moment(start).format("yyyy-MM-DD"),
      endDate: moment(start).format("yyyy-MM-DD"),
    };
    setPrePopulatedDated(prePopulateData);
    setshowAvailableModal(!showAvailableModal);
  };


  const testing = (item, colItem) => {
    let prePopulateData = {
      main_court_id: mainCourtId,
      padelcourtId: colItem.id,
      startTime: item.timeSlots.split("-")[0],
      endTime: item.timeSlots.split("-")[1],
      startDate: moment(start).format("yyyy-MM-DD"),
      endDate: moment(start).format("yyyy-MM-DD"),
    };
    setPrePopulatedDated(prePopulateData);
    // setShowModal(!showModal);
  };


  const GetUserDetail = async (email) => {
    await GetUserDetailsByEmail(email).then((response) => {
      setUserData(response);
      setDefaultCourt(response?.default_court)
      setMainCourtId(response?.default_court)
    })
  }




  const ColorDetails = [
    {
      category: "Bookings",
      color: "#e15f40",
    },
    {
      category: "Events",
      color: "#686de0",
    },
    {
      category: "Tournaments",
      color: "#3868d6",
    },
    {
      category: "Maintenance",
      color: "#dd3444",
    },
    {
      category: "Coaching - Regular",
      color: "#f0932b",
    },
    {
      category: "Coaching - Pro Tennis",
      color: "#ffbe76",
    },
    {
      category: "Coaching - Just Padel",
      color: "#f9ca24",
    },
    {
      category: "GTAP",
      color: "#22a6b3",
    },
    {
      category: "Matchmaking",
      color: "#30336b",
    },
    {
      category: "Others",
      color: "#6D214F",
    },
  ];


  useEffect(() => {
    setSpinner(true);
    let user = JSON.parse(localStorage.getItem("loggedUserDetails"));
    setMainCourtId(user?.default_court);
    const Email = localStorage.getItem("Email");
    GetUserDetail(Email);
    localStorage.setItem("CallCalender", true);
    GetMainCourt();
  }, []);


  useEffect(() => {
    setMainCourtId(mainCourtId);
  }, [mainCourtId]);


  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Row className="align-items-end justify-content-center">
            <Col md={10} lg={10}>
              <div className="form form__form-group">
                <div className="form__form-group-field d-flex">
                  <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                    <span>Select Main Court</span>
                    <select
                      className="select select-location"
                      style={{ width: '100%' }}
                      value={mainCourtId}
                      onChange={(e) => handleSelectMainCourt(e)}>
                      <option>Select Main Court</option>
                      {filteredMainCourt.length > 0 && filteredMainCourt?.map((court) => (
                        <option value={court.id} key={court.id}>
                          {court.courtName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex flex-column w-100" style={{ marginLeft: '5px' }}>
                    <span>Select Date</span>
                    <input
                      type="date"
                      value={start}
                      onChange={(e) => onChangeStart(e.target.value)}
                      className="custom-css-dp"
                    />
                  </div>
                </div>
              </div>

            </Col>
            <Col sm={2} md={2} lg={2} className="d-flex justify-content-start">
              <Button
                color="warning"
                size="sm"
                onClick={submitDate}
                className='mb-0 w-100'
              >
                Search
              </Button>
            </Col>
          </Row>
          {/* <div className="top-container">
            <div style={{ display: "flex" }}>
              <div className="inner-container">
                <span className="page-title">Select Location </span>
                <select
                  className="select select-location"
                  value={mainCourtId}
                  onChange={handleSelectMainCourt}>
                  <option>Select Main Court</option>
                  {filteredMainCourt &&
                    filteredMainCourt.map((court) => (
                      <option value={court.id}>{court.courtName}</option>
                    ))}
                </select>
              </div>
              <div className="inner-container">
                <span className="page-title">Select Date</span>
                <DatePicker
                  dateFormat="YYYY-MM-DD"
                  onChange={onChangeStart}
                  value={start}
                  className="custom-css-dp"
                />
              </div>
            </div>
            <div className="submit-btn-container">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    marginLeft: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                </div>
              </div>
              <div className="cal-top-submit-btn">
                <button
                  color="warning"
                  size="sm"
                  disabled= {mainCourtId === "Select Main Court" ? "disabled" : ""}
                  onClick={submitDate}
                  className={mainCourtId === "Select Main Court" ? "date-submit-disable" : "date-submit"}>
                  <p style={{ color: "#fff" }}>Submit</p>
                </button>
              </div>
            </div>
          </div> */}

          <SampleModalComponent
            showHide={showModal}
            bookingDetail={bookingDetails}
            prePopulatedDated={prePopulatedDated}
            submitDate={submitDate}
            header
            color="primary"
            title="Booking Information"
            btn="Edit Booking"
          />
          {/* <AvailableModal
            submitDate={submitDate}
            color="primary"
            title="Quick Booking"
            header
            availableShowHide={showAvailableModal}
            prePopulatedDated={prePopulatedDated}
          /> */}
          {spinner ? <CalendarSkaleton /> :
            <Table className="calendar">
              <thead>
                <tr style={{ display: "flex", }}>
                  {data2?.columns.length > 1 && data2.columns.map((item) => (
                    <th
                      style={item.disabled ? { display: 'none' } : { textAlign: "center", flexGrow: 1, width: 100, backgroundColor: '#f8f8f8', borderRadius: 4, margin: 1 }}
                      key={item.id}
                    >
                      {item.Header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data2.rows.map((item, index) => {
                  return (
                    (
                      <tr key={index + 1} style={{ display: "flex" }}>
                        <td className="cal-headerTD" style={{ textAlign: "center", marginRight: 1, backgroundColor: '#f8f8f8', paddingRight: 9, borderRadius: 4, margin: 1, width: '14.1%', minWidth: 150 }}>
                          {item.timeSlots}
                        </td>
                        {/* <ReactTooltip id="toolTip1" type="info" effect="solid" /> */}
                        {cols.map((colItem, id) => {
                          return (
                            (
                              <>
                                {item?.["name" + (id + 1)] === "Available" ? (
                                  // <td
                                  //   data-tip="Add Booking"
                                  //   data-for="toolTip1"
                                  //   data-place="top"
                                  //   onClick={() => AvailableSlot(item, colItem)}
                                  //   key={id + 1}
                                  //   className="AvailableSlot"
                                  // >
                                  //   <AvailableModal
                                  //     submitDate={submitDate}
                                  //     color="primary"
                                  //     title="Quick Booking"
                                  //     header
                                  //     availableShowHide={showAvailableModal}
                                  //     prePopulatedDated={prePopulatedDated}
                                  //   />
                                  //    {" "}
                                  //    {item?.["name" + (id + 1)]}
                                  //  </td>

                                  <AvailableModal
                                    submitDate={submitDate}
                                    color="primary"
                                    title="Quick Booking"
                                    header
                                    // availableShowHide={showAvailableModal}
                                    prePopulatedDated={{
                                      main_court_id: mainCourtId,
                                      padelcourtId: colItem.id,
                                      startTime: item.timeSlots.split("-")[0],
                                      endTime: item.timeSlots.split("-")[1],
                                      startDate: moment(start).format("yyyy-MM-DD"),
                                      endDate: moment(start).format("yyyy-MM-DD"),
                                    }}
                                  />
                                ) : item?.["name" + (id + 1)] === "Blocked" ? (
                                  <td
                                    key={id + 1}
                                    className="BlockedSlot"
                                  >
                                    {" "}
                                    {item?.["name" + (id + 1)]}
                                  </td>
                                ) :


                                  item?.["name" + (id + 1)] === "Disabled" ? (<td style={{ display: "none" }}></td>)
                                    :
                                    (
                                      // backgroundColor1
                                      // <td onClick={()=>testing(item,colItem)} style={{ backgroundColor: "#b50404", border: ".5px solid #fff", color: "#f8f9fa",textAlign:'center',width:100,flexGrow:1,padding:10}}>{item?.["name" + (id + 1)]}</td>
                                      // <td
                                      //    onClick={() => bookingCancel(item?.[`bookedCourt${id+1}`])}
                                      // style={{
                                      //   backgroundColor: `${item?.["backgroundColor" + (id + 1)]
                                      //     }`,
                                      //   border: ".5px solid #fff",
                                      //   color: "#f8f9fa",
                                      //   textAlign: "center",
                                      //   width: 100,
                                      //   flexGrow: 1,
                                      //   padding: 10,
                                      //   fontSize: 16,
                                      //   fontFamily: 'sans-serif',
                                      //   borderRadius: 4,
                                      //   cursor: 'pointer',
                                      // }}
                                      // >
                                      //   {<label>{item?.["name" + (id + 1)]}</label>}
                                      // </td>
                                      <SampleModalComponent
                                        // showHide={showModal}
                                        backgroundColor={`${item?.["backgroundColor" + (id + 1)]}`}
                                        bookingDetail={item?.[`bookedCourt${id + 1}`]}
                                        prePopulatedDated={{
                                          main_court_id: mainCourtId,
                                          padelcourtId: colItem.id,
                                          startTime: item.timeSlots.split("-")[0],
                                          endTime: item.timeSlots.split("-")[1],
                                          startDate: moment(start).format("yyyy-MM-DD"),
                                          endDate: moment(start).format("yyyy-MM-DD"),
                                        }}
                                        bookedBy={item?.["name" + (id + 1)]}
                                        submitDate={submitDate}
                                        header
                                        color="primary"
                                        title="Booking Information"
                                        btn="Edit Booking"
                                      />
                                    )}
                              </>
                            )
                          )
                        })}
                      </tr>
                    )
                  )
                })}
              </tbody>
            </Table >
          }
          <div className="rounded-flex">
            {ColorDetails.map((colorData, index) => {
              return (
                <div key={index} className="rounded-flex">
                  <div
                    className="div-round"
                    style={{ backgroundColor: `${colorData.color}` }}
                  ></div>
                  <span className="ml-2">{colorData.category}</span>
                </div>
              );
            })}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};


// export default ExamplePage;
ExamplePage.propTypes = {
  rtl: RTLProps.isRequired,
};


export default ExamplePage;

