import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import CouponModal from '../../../shared/components/CouponModal';
import { environment } from '../../env';
import axios from "../../../customAxios";
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactPaginate from 'react-paginate';
import CreateGroupModal from '../../../shared/components/CreateGroupModal';
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { CouponModalView } from '../../../shared/components/CouponModalView';
import CouponModalEdit from '../../../shared/components/CouponModalEdit';
import moment from 'moment';

const CouponGroup = () => {
    let navigate = useNavigate();
    const [couponGroupFilter, setCouponGroupFilter] = useState({ page: 1, coupon_group_name: '', page_size: 10 });
    const [couponFilter, setCouponFilter] = useState({ page: 1, name: '', page_size: 10, coupon_status: 'onetime' });
    const [couponGroupList, setCouponGroupList] = useState([]);
    const [isSortable, setIsSortable] = useState(true);
    const [isResizable, setIsResizable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [couponPageCount, setCouponPageCount] = useState(0);
    const [couponInitialPage, setCouponInitialPage] = useState(0);
    const [couponSearch, setCouponSearch] = useState();
    const [loading, setLoading] = useState(false);
    const [deleteing, setDeleteing] = useState(true);
    const [editing, setEditing] = useState(true);
    const [userRole, setUserRole] = useState("admin");
    const [activeTab, setActiveTab] = useState("1");
    const [url, setUrl] = useState("onetime");
    const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
    const currentDate = moment(new Date(), "YYYY-MM-DD");
    const [couponList, setCouponList] = useState([]);

    const ViewTemplate = (row) => (
        <button className="modal-view-btn" onClick={() => viewCouponGroup(row)} >
            <AiFillEye style={{ fontSize: 20, height: "19px", width: "19px" }} />
        </button>
    );

    const editTemplate = (row) => (
        <button className="modal-edit-btn" onClick={() => editCouponGroup(row)} >
            <AiOutlineEdit style={{ fontSize: 17, height: "19px", width: "19px" }} />
        </button>
    );

    const deleteTemplate = (row) => (
        <>
            <button className="modal-delete-btn" onClick={() => deleteCoupon(row?.id)} >
                <AiOutlineDelete style={{ fontSize: 17, height: "19px", width: "19px", color: "#da3300" }} />
            </button>
        </>
    );

    const deleteCoupon = (id) => {
        if (userRole === "admin") {
            const deleteCouponUrl = `${environment.baseUrl}editcoupan/${id}`;
            axios
                .delete(deleteCouponUrl)
                .then((response) => {
                    console.log(response);
                    getAllGroupCouponList();
                })
                .catch((error) => {
                    console.log(error);
                });

        } else {
            alert("You don't have the permissions to delete data");
        }
    };

    const data = {
        columns: [
            {
                Header: "#",
                accessor: "id",
                disableGlobalFilter: true,
                width: 65,
            },
            {
                Header: "Name",
                accessor: "coupon_group_name",
                disableGlobalFilter: true,
                width: 75,
            },
            {
                Header: "Coupons Count",
                accessor: "coupons_count",
                disableGlobalFilter: true,
                width: 70,
            },
            {
                Header: "Active Coupon",
                accessor: "active_coupons_count",
                disableGlobalFilter: true,
                width: 90,
            },
            {
                Header: "Expiry Coupons",
                accessor: "expired_coupons_count",
                disableGlobalFilter: true,
                width: 100,
            },
            {
                Header: "View",
                accessor: "view",
                disableGlobalFilter: true,
                disableSortBy: true,
                width: 20,
            },
            // {
            //     Header: "Edit",
            //     accessor: "edit",
            //     disableGlobalFilter: true,
            //     width: 20,
            // }
        ],
        coupon_columns: [
            {
                Header: "#",
                accessor: "id",
                disableGlobalFilter: true,
                width: 65,
            },
            {
                Header: "Name",
                accessor: "name",
                disableGlobalFilter: true,
                width: 75,
            },
            {
                Header: "Percentage Of Discount",
                accessor: "percentage_of_discount",
                disableGlobalFilter: true,
                width: 70,
            },
            {
                Header: "Coupon Type / Category",
                accessor: "coupon_type",
                disableGlobalFilter: true,
                width: 90,
            },
            {
                Header: "Expiry Date / Active",
                accessor: "active_or_expired_coupon",
                disableGlobalFilter: true,
                width: 100,
            },
            {
                Header: "View",
                accessor: "view",
                disableGlobalFilter: true,
                disableSortBy: true,
                width: 20,
            },
            {
                Header: "Edit",
                accessor: "edit",
                disableGlobalFilter: true,
                width: 20,
            },
            {
                Header: "Delete",
                accessor: "delete",
                disableGlobalFilter: true,
                width: 20,
            },
        ]
    };



    const tableConfig = {
        isEditable: false,
        isResizable,
        isSortable,
        withPagination,
        withSearchEngine,
        manualPageSize: [10000, 20000, 30000, 40000],
        placeholder: "Search by Coupon...",
    };

    const viewCouponGroup = (row) => {
        navigate(`/coupon-group/${row?.id}`);
    };

    const editCouponGroup = (row) => {
        console.log(row);
    };

    const filterCoupon = () => {
        if (activeTab == "1") {
            setCouponGroupFilter({ ...couponGroupFilter, coupon_group_name: couponSearch, page: 1 })
        } else {
            setCouponFilter({ ...couponFilter, name: couponSearch });
        }
    };

    const handleLimitChange = (e) => {
        setCouponGroupFilter({ ...couponGroupFilter, page_size: parseInt(e.target.value) });
    };

    const handleCouponPageClick = (pageClicked) => {
        setCouponGroupFilter({ ...couponGroupFilter, page: pageClicked.selected + 1 });
    };

    const toggle = (tab) => {
        const urlMap = {
            "1": "coupons_group",
            "2": "onetime",
            "3": "multiple",
            "4": "expired"
        };

        setUrl(urlMap[tab] || "onetime");
        setCouponFilter({ ...couponFilter, coupon_status: urlMap[tab] });

        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const getAllCouponDetails = () => {
        try {
            const couponGroupsURL = `${environment.baseUrl}coupon-group-pagination`;
            axios.get(couponGroupsURL, { params: couponGroupFilter }).then((response) => {
                const groupData = response?.data?.results.map((cgData) => {
                    return { ...cgData, view: ViewTemplate(cgData), edit: editTemplate(cgData) }
                })
                // ViewTemplate
                setCouponGroupList(groupData);
                setCouponPageCount(response?.data?.count / couponGroupFilter?.page_size);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };

    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };

    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const getAllGroupCouponList = () => {
        try {
            const getGroupCouponURL = `${environment.baseUrl}group-coupon-pagination`;
            axios.get(getGroupCouponURL, { params: couponFilter }).then((response) => {
                const couponData = response?.data?.results.map((cData) => {
                    return {
                        ...cData,
                        view: <CouponModalView
                            btn="View Coupon"
                            color="warning"
                            title="View Coupon Details"
                            header
                            couponId={cData?.id}
                        />,
                        edit: <CouponModalEdit
                            btn="Add Coupon"
                            color="warning"
                            title="Edit Coupon"
                            header
                            couponId={cData?.id}
                        />,
                        delete: deleteTemplate(cData),
                        active_or_expired_coupon: (
                            (
                                <div className="right-space">
                                    {cData.expiry_date}
                                    <br />
                                    <div className="Booking-details-td-right-space">
                                        <div className="active-color-bg canceled-color-bg">
                                            <span
                                                className={
                                                    new Date(currentDate) <= new Date(cData.expiry_date)
                                                        ? "active-color-bg"
                                                        : "canceled-color-bg"
                                                }
                                            >
                                                {new Date(currentDate) <= new Date(cData.expiry_date) ? "active" : "inactive"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                });
                setCouponList(couponData);
                setCouponPageCount(response?.data?.count / couponFilter?.page_size);
            });
        } catch (error) {
            console.log(error);
        };
    };

    useEffect(() => {
        getAllCouponDetails();
    }, [couponGroupFilter]);

    useEffect(() => {
        if (activeTab !== "1") {
            getAllGroupCouponList();
        }
    }, [couponFilter])

    return (
        <Row>
            <Col sm={12} md={12} lg={12}>
                <div className="tabs__wrap">
                    <Nav tabs className="all-tabs" style={{ width: "100%" }}>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    "active-tab": activeTab === "1",
                                })}
                                onClick={() => toggle("1")}
                            >
                                Coupons Group List
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    "active-tab": activeTab === "2",
                                })}
                                onClick={() => toggle("2")}
                            >
                                One Time
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    "active-tab": activeTab === "3",
                                })}
                                onClick={() => {
                                    toggle("3");
                                }}
                            >
                                Multiple Time
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({
                                    "active-tab": activeTab === "4",
                                })}
                                onClick={() => {
                                    toggle("4");
                                }}
                            >
                                Expired
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <Row className="d-flex justify-content-between">
                    <Col>
                        <h3 className="page-title">{activeTab === "1" ? "Coupons Group List" : activeTab === "2" ? "One Time Coupons" : activeTab === "3" ? "Multiple Time Coupons" : "Expired Coupons"}</h3>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-end" style={{ gap: '1rem' }}>
                        <CreateGroupModal
                            btn="Create Group"
                            color="warning"
                            title="Create Group"
                            header
                        />
                        <CouponModal
                            btn="Create Coupon"
                            color="warning"
                            title="Create Coupon"
                            header
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm={12} md={12} lg={12}>
                <Card>
                    <CardBody className='mb-4'>
                        <Row className="mt-4">
                            <Col sm={10} md={10} lg={10}>
                                <div className="form form__form-group">
                                    <div className="form__form-group-field">
                                        <input
                                            placeholder={`Search coupon ${activeTab == "1" ? "group" : ""} here ...`}
                                            onChange={(e) => setCouponSearch(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                                <Button
                                    color="warning"
                                    size='sm'
                                    onClick={() => filterCoupon()}
                                >
                                    search
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <div className="react-table__wrapper hide-table-costomizer">
                                <ReactTableCustomizer
                                    handleClickIsSortable={handleClickIsSortable}
                                    handleClickWithPagination={
                                        handleClickWithPagination
                                    }
                                    handleClickWithSearchEngine={
                                        handleClickWithSearchEngine
                                    }
                                    handleClickIsResizable={handleClickIsResizable}
                                    isResizable={isResizable}
                                    isSortable={isSortable}
                                    withPagination={withPagination}
                                    withSearchEngine={withSearchEngine}
                                />
                            </div>
                            {activeTab === "1" && <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                                <ReactTableBase
                                    id="table-to-xls"
                                    key={withSearchEngine ? "searchable" : "common"}
                                    columns={data.columns}
                                    data={couponGroupList}
                                    tableConfig={tableConfig}
                                />
                            </div>}
                            {activeTab !== "1" && <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                                <ReactTableBase
                                    id="table-to-xls"
                                    key={withSearchEngine ? "searchable" : "common"}
                                    columns={data.coupon_columns}
                                    data={couponList}
                                    tableConfig={tableConfig}
                                />
                            </div>}
                        </Row>
                        <div className="custom-pagination">
                            <div className="records-per-page">
                                <select
                                    value={couponGroupFilter.page_size}
                                    onChange={handleLimitChange}
                                    name="limit"
                                    id="select-pagination"
                                >
                                    {mannualLimits?.map((mannualLimits) => (
                                        <option value={mannualLimits}>{mannualLimits}</option>
                                    ))}
                                </select>
                                &nbsp;&nbsp;&nbsp;records per page
                            </div>
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={couponPageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handleCouponPageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                forcePage={couponInitialPage}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default CouponGroup;