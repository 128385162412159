import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import {
    Nav, NavItem, NavLink, TabContent, TabPane, Table, Col, Card, CardBody, Button,
    Row,
    Container,
    Label
} from 'reactstrap';
import moment from 'moment';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FcCheckmark } from 'react-icons/fc';
import { FcCancel } from 'react-icons/fc';
import { FiUser } from 'react-icons/fi';
import { environment } from '../../containers/env';
import axios from '../../customAxios';

const CouponBookingTransactionTab = ({ transaction }) => {
    const [activeTab, setActiveTab] = useState('1');
    const [transactionLogs, setTransactionLogs] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [vatAmount, setVatAmount] = useState();
    const [discountedAmount, setDiscountedAmount] = useState();
    const [payFacilityAmount, setPayFacilityAmount] = useState();
    const [couponPercentageOfDiscount, setCouponPercentageOfDiscount] = useState(0);
    const [applyForAddon, setApplyForAddon] = useState(false);
    const [couponDetails, setCouponDetails] = useState({
        "name": "",
        "percentage_of_discount": 0,
        "apply_for_addons": false
    });

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const formatKeyName = (key) => {
        return key
            .replace(/_/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    useEffect(() => {
        setTransactionLogs(JSON.parse(transaction?.transaction_log?.transaction_log));
    }, [transaction]);

    const getSafeValue = (value) => value ?? 'N/A';
    const TableRow = ({ icon, label, value }) => (
        <tr>
            <td className="first-td-width">
                <div className="trans-client-name">
                    {icon}
                    <h5>{label}</h5>
                </div>
            </td>
            <td className="client-detail">{getSafeValue(value)}</td>
        </tr>
    );

    //
    const calculateDiscountAmount = (courtamount, addonAmount, couponDetails, coupon) => {
        if (coupon === ("null" || "" || undefined)) {
            return 0.00;
        } else {
            if (couponDetails?.apply_for_addons) {
                return ((((courtamount + addonAmount) / 100) * couponDetails?.percentage_of_discount).toFixed(2));
            } else {
                if (couponDetails?.percentage_of_discount === 100) {
                    return (Math.round((courtamount / 100) * couponDetails?.percentage_of_discount));
                } else {
                    return ((courtamount / 100) * couponDetails?.percentage_of_discount);
                }
            }
        }
    };

    const VatCalculation = (courtamount, addonAmount, couponDetails, coupon) => {
        if (coupon === ("null" || "" || undefined)) {
            return Math.ceil((courtamount + addonAmount) * 0.05);
        } else {
            if (couponDetails?.apply_for_addons) {
                return Math.ceil(((courtamount + addonAmount) - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon)) * 0.05)
            } else {
                return Math.ceil(((courtamount - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon)) + addonAmount) * 0.05)
            }
        }
    };

    const CalculateTotalAmount = (courtamount, addonamount, coupondetails, coupon) => {
        if (coupon === ("null" || "" || undefined)) {
            return Math.floor(courtamount + addonamount + VatCalculation(courtamount, addonamount, coupondetails, coupon));
        } else {
            if (coupondetails?.apply_for_addons) {
                return Math.floor(courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon) - calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon)));
            } else {
                return courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon) - calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon));
            }
        }
    };

    const PaidToFacultyCalculation = (courtamount, addonamount, coupondetails, Coupon, amountPaid) => {
        return CalculateTotalAmount(courtamount, addonamount, coupondetails, Coupon) - amountPaid;
    };

    const bookedSlotsPrice = (bookedSlots) => {
        const totalAmount = bookedSlots.reduce((acc, booking) => acc + booking.Price, 0);
        return totalAmount.toFixed(2)
    };

    const bookedAddonsPrice = (bookedAddons) => {
        const totalAmount = bookedAddons.reduce((acc, booking) => acc + booking.amount, 0);
        return totalAmount.toFixed(2)
    };

    const getCouponDetails = (couponname) => {
        try {
            const getCouponDetailsURL = `${environment.baseUrl}getcoupan/${couponname}/`;
            axios.get(getCouponDetailsURL).then((response) => {
                setCouponDetails(response?.data);
                setCouponPercentageOfDiscount(response?.data?.percentage_of_discount);
                setApplyForAddon(response?.data?.apply_for_addons);
            }).catch((error) => {
                console.log("error", error);
            });
        } catch (error) {
            console.log("error--->", error);
        };
    };

    const FinalCalculation = (coupon) => {
        let NewTotlaCourtPrice = parseFloat(bookedSlotsPrice(transaction?.booked_slots));
        let NewTotlaAddonPrice = parseFloat(bookedAddonsPrice(transaction?.addonsbookedlist));
        const calculatedTotalAmounts = CalculateTotalAmount(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", transaction?.coupan_applied ? transaction?.coupan_applied : "null");
        setDiscountedAmount(calculateDiscountAmount(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", transaction?.coupan_applied ? transaction?.coupan_applied : "null"));
        setVatAmount(VatCalculation(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", transaction?.coupan_applied ? transaction?.coupan_applied : "null"));
        setTotalAmount(parseInt(calculatedTotalAmounts).toFixed(2));
        setPayFacilityAmount(PaidToFacultyCalculation(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", transaction?.coupan_applied ? transaction?.coupan_applied : "null", transaction?.amount_paid));
        if (transaction?.booking?.packages > 0) {
            setPayFacilityAmount(0);
        };
    };

    useEffect(() => {
        if (transaction?.coupan_applied) {
            getCouponDetails(transaction?.coupan_applied);
        }
    }, [transaction]);

    useEffect(() => {
        if (transaction) {
            FinalCalculation(couponDetails);
        }
    }, [couponDetails, transaction]);

    return (
        <div className="tabs__wrap" >
            <Nav tabs className="all-tabs" style={{ width: 420 }}>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1') }}
                    >
                        Details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink style={{ width: 175 }}
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggle('2')}
                    >
                        Client
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink style={{ width: 175 }}
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => toggle('3')}>
                        Transaction Logs
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div className="booking-id">
                        <label style={{ fontSize: 17, fontWeight: 'bold' }}>Booking ID:</label>
                        <label style={{ fontSize: 17, fontWeight: 'bold' }}>&nbsp;&nbsp;#{transaction?.booking_id ?? "N/A"}</label>
                    </div>
                    <div className="payment-datails">
                        <div className="payment-datails-lhs">
                            <div style={{ display: 'flex' }}>
                                <label style={{ fontSize: 15, width: "37%" }}>Status: </label> {'  '}
                                {transaction?.cancelled ? <label style={{ fontSize: 15 }}>Cancelled</label> :
                                    <label style={{ fontSize: 15 }}>{(transaction?.amount_percentage === 1 || transaction?.amount_percentage === 100) ? "100% Paid" : transaction?.amount_percentage === 0.5 ? "50% Paid" : ''}</label>
                                }
                            </div>
                            <div style={{ display: 'flex' }}>
                                <label style={{ fontSize: 15, width: "37%" }}>Payment processor: </label> {'  '}
                                <label style={{ fontSize: 15, fontWeight: 'bold' }}>Payfort</label>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <label style={{ fontSize: 15, width: "37%" }}>Payment received:  </label> {'  '}
                                <label style={{ fontSize: 15, fontWeight: 'bold' }}>{transaction?.amount_paid.toFixed(2)} AED</label>
                            </div>
                        </div>
                        <div className="payment-datails-rhs">
                            <div style={{ display: 'flex' }}>
                                <label style={{ fontSize: 15, width: "25%" }}>Invoice date: </label> {'  '}
                                <label style={{ fontSize: 15, fontWeight: 'bold' }}>{moment(transaction?.created_at.split('T')[0]).format("DD-MM-YYYY")}{' '}{moment(transaction?.created_at.split('T')[1].split('.')[0], "HH:mm:ss").format("hh:mm A")}</label>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <label style={{ fontSize: 15, width: "25%" }}>Due date: </label> {'  '}
                                <label style={{ fontSize: 15, fontWeight: 'bold' }}> {moment(transaction?.booking_date).format("DD-MM-YYYY")} {transaction?.timeslot.split("-")[0]}</label>
                            </div>
                        </div>
                    </div>
                    <div className="user-datails">
                        <div className="user-datails-lhs">
                            <Table responsive hover>
                                <tbody className="table-trans-user-details">
                                    <TableRow
                                        icon={<FiUser className="client-icon" />}
                                        label="Client Name"
                                        value={transaction?.booked_by?.customer_name}
                                    />
                                    <TableRow
                                        icon={<AiOutlineMail className="client-icon" />}
                                        label="Email"
                                        value={transaction?.booked_by?.customer_email}
                                    />
                                    <TableRow
                                        icon={<AiOutlinePhone className="client-icon" />}
                                        label="Phone"
                                        value={transaction?.booked_by?.customer_phone}
                                    />
                                </tbody>
                            </Table>
                        </div>
                        <div className="user-datails-lhs">
                            <h5>Payment received by payment system</h5>
                        </div>
                    </div>
                    <div className="user-datails-lhs">
                        {transaction?.booked_slots?.length > 0 && <h5>Current Bookings</h5>}
                    </div>
                    {transaction?.booked_slots?.length > 0 && <div className="trans-total-details">
                        <Table responsive hover>
                            <thead className="trans-total-table-header">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    transaction?.booked_slots?.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {data?.main_court_name} - {" "}
                                                    {data?.padel_court_name}
                                                    {" "}({data?.date + "  " + data?.time})
                                                </td>
                                                <td>{data.Price.toFixed(2)} AED</td>
                                                <td>1</td>
                                                <td> <FcCheckmark size={25} className='fc-check' style={{ marginLeft: "10px" }} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>}
                    <div className="user-datails-lhs">
                        {transaction?.logs?.length > 0 && <h5>Logs</h5>}
                    </div>
                    {transaction?.logs?.length > 0 && <div className="trans-total-details">
                        <Table responsive hover>
                            <thead className="trans-total-table-header">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    transaction?.logs?.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {data?.main_court_name} - {" "}
                                                    {data.padel_court_name}
                                                    {" "}({data?.date + "  " + data?.time})
                                                </td>
                                                <td>{data.Price.toFixed(2)} AED</td>
                                                <td>1</td>
                                                <td><FcCancel size={25} className='faCancel' style={{ marginLeft: '10px' }} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>}
                    <div className="trans-total-amount">
                        <div>
                            <label style={{ fontSize: 15 }}>VAT 5%: </label> {' '}
                            {/* <label style={{ fontSize: 15 }}>{((totalCourtAmount + totalAddonAmount) / 100 * 5).toFixed(2)} AED</label> */}
                            <label style={{ fontSize: 15 }}>{Math.round(vatAmount)?.toFixed(2)} AED</label>
                        </div>
                        <div>
                            <label style={{ fontSize: 15, fontWeight: 'bold' }}>Total amount: </label> {' '}
                            {/* <label style={{ fontSize: 15, fontWeight: 'bold' }}>{(totalCourtAmount + totalAddonAmount + (totalCourtAmount + totalAddonAmount) / 100 * 5).toFixed(2)} AED</label> */}
                            <label style={{ fontSize: 15, fontWeight: 'bold' }}>{Math.floor(totalAmount)?.toFixed(2)} AED</label>
                        </div>
                    </div>

                    <div className="trans-data-container">
                        <div className="trans-data-lhs">
                            {
                                transaction?.addonsbookedlist.length <= 0 ?
                                    <div className="no-addons">
                                        <h4>No Addons</h4>
                                    </div>
                                    :
                                    <Table hover>
                                        <thead className="trans-total-table-header">
                                            <tr>
                                                <th>#</th>
                                                <th>Item</th>
                                                <th>Price </th>
                                                <th>Quantity </th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                transaction?.addonsbookedlist.map((data, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{data?.addonname}</td>
                                                            <td>{data?.price} AED</td>
                                                            <td>{data?.qty}</td>
                                                            <td>{data?.price * data?.qty} AED</td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </Table>
                            }
                        </div>
                        <div className="trans-data-rhs">
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>Coupon Applied: </label> {'  '}
                                <label className="pay-result">{transaction?.coupan_applied === "" ? "NO" : `(${transaction?.coupan_applied})`}</label>
                            </div>
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>Discounted Price: </label> {'  '}
                                <label className="pay-result">{Math.ceil(discountedAmount)?.toFixed(2)} AED</label>
                            </div>
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>Packages Used: </label> {'  '}
                                <label className="pay-result">{transaction?.booking?.packages == 0 ? "N0" : transaction?.booking?.packages}</label>
                            </div>
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>Amount Paid: </label> {'  '}
                                {transaction?.booking?.cancelled ?
                                    <label className="pay-result">Cancelled</label>
                                    :
                                    <label className="pay-result">{transaction?.booking?.packages == 0 ? (transaction?.booking?.amount_percentage === 1 || transaction?.booking?.amount_percentage === 100) ? "100% Paid" : transaction?.booking?.amount_percentage === 0.5 ? "50% Paid" : '' : "Paid by Packages"}</label>
                                }
                            </div>
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>Advance Paid: </label> {'  '}
                                <label className="pay-result">{Math.round(transaction?.amount_paid).toFixed(2)} AED</label>
                            </div>
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>To be Paid at Facility:</label> {'  '}
                                <label className="pay-result">{Math.floor(payFacilityAmount).toFixed(2)} AED</label>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <h4 className='mb-4'>Client Info</h4>
                    {transaction && <Table>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    Name
                                </th>
                                <td>
                                    {transaction?.booked_by?.customer_name}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Email
                                </th>
                                <td>
                                    {transaction?.booked_by?.customer_email}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Phone No
                                </th>
                                <td>
                                    {transaction?.booked_by?.customer_phone}
                                </td>
                            </tr>
                        </tbody>
                    </Table>}
                </TabPane>
                <TabPane tabId="3">
                    {transactionLogs?.length > 0 && <Container>
                        <h4 className='mb-4'>Transaction Logs</h4>
                        <Row>
                            {/* Loop through each key-value pair and render it in a row */}
                            {Object.keys(transactionLogs[0]).map((key) => (
                                <Row key={key} style={{ marginBottom: '10px' }}>
                                    {/* Left Column for the Field Name */}
                                    <Col sm={4} style={{ fontWeight: 'bold' }}>
                                        {formatKeyName(key)}:
                                    </Col>

                                    {/* Right Column for the Field Value */}
                                    <Col sm={8}>
                                        {transactionLogs[0][key] === null ? 'N/A' : transactionLogs[0][key]}  {/* Display 'N/A' for null values */}
                                    </Col>
                                </Row>
                            ))}
                        </Row>
                    </Container>}
                </TabPane>
            </TabContent>
        </div>
    );
};

export default CouponBookingTransactionTab;
