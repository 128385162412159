import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import CouponModal from '../../../shared/components/CouponModal';
import { environment } from '../../env';
import axios from "../../../customAxios";
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactPaginate from 'react-paginate';
import CreateGroupModal from '../../../shared/components/CreateGroupModal';
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useHistory, useParams } from "react-router-dom";
import CouponModalEdit from '../../../shared/components/CouponModalEdit';
import { CouponModalView } from '../../../shared/components/CouponModalView';
import CoponUsedList from './CoponUsedList';
import classnames from "classnames";
import moment from 'moment';
import CouponGroupBookings from './CouponGroupBookings';

const CouponGroupDetails = () => {
    const { id } = useParams();
    const [couponFilter, setCouponFilter] = useState({ page: 1, coupon_group: id, name: '', page_size: 10, coupon_status: 'onetime' });
    const [couponList, setCouponList] = useState([]);
    const [couponSearch, setCouponSearch] = useState();
    const [isSortable, setIsSortable] = useState(true);
    const [isResizable, setIsResizable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [couponPageCount, setCouponPageCount] = useState(0);
    const [couponInitialPage, setCouponInitialPage] = useState(0);
    const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
    const [userRole, setUserRole] = useState("admin");
    const [activeTab, setActiveTab] = useState("1");
    const [url, setUrl] = useState("onetime");

    const currentDate = moment(new Date(), "YYYY-MM-DD");

    const deleteTemplate = (row) => (
        <>
            <button className="modal-delete-btn" onClick={() => deleteCoupon(row?.id)} >
                <AiOutlineDelete style={{ fontSize: 17, height: "19px", width: "19px", color: "#da3300" }} />
            </button>
        </>
    );

    const viewCoupon = (row) => {
        console.log(row);
    };

    const editCoupon = (row) => {
        console.log(row);
    };

    const deleteCoupon = (id) => {
        if (userRole === "admin") {
            const deleteCouponUrl = `${environment.baseUrl}editcoupan/${id}`;
            axios
                .delete(deleteCouponUrl)
                .then((response) => {
                    console.log(response);
                    getAllGroupCouponList();
                })
                .catch((error) => {
                    console.log(error);
                });

        } else {
            alert("You don't have the permissions to delete data");
        }
    };

    const data = {
        columns: [
            {
                Header: "#",
                accessor: "id",
                disableGlobalFilter: true,
                width: 65,
            },
            {
                Header: "Name",
                accessor: "name",
                disableGlobalFilter: true,
                width: 75,
            },
            {
                Header: "Percentage Of Discount",
                accessor: "percentage_of_discount",
                disableGlobalFilter: true,
                width: 70,
            },
            {
                Header: "Coupon Type / Category",
                accessor: "coupon_type",
                disableGlobalFilter: true,
                width: 90,
            },
            {
                Header: "Expiry Date / Active",
                accessor: "active_or_expired_coupon",
                disableGlobalFilter: true,
                width: 100,
            },
            {
                Header: "View",
                accessor: "view",
                disableGlobalFilter: true,
                disableSortBy: true,
                width: 20,
            },
            {
                Header: "Edit",
                accessor: "edit",
                disableGlobalFilter: true,
                width: 20,
            },
            {
                Header: "Delete",
                accessor: "delete",
                disableGlobalFilter: true,
                width: 20,
            },
        ]
    };

    const tableConfig = {
        isEditable: false,
        isResizable,
        isSortable,
        withPagination,
        withSearchEngine,
        manualPageSize: [10000, 20000, 30000, 40000],
        placeholder: "Search by Coupon...",
    };

    const filterCoupon = () => {
        setCouponFilter({ ...couponFilter, name: couponSearch, page: 1 })
    };

    const handleLimitChange = (e) => {
        setCouponFilter({ ...couponFilter, page_size: parseInt(e.target.value), page: 1 });
    };

    const handleCouponPageClick = (pageClicked) => {
        setCouponFilter({ ...couponFilter, page: pageClicked.selected + 1 });
    };

    const toggle = (tab) => {
        const urlMap = {
            "1": "onetime",
            "2": "multiple",
            "3": "expired"
        };

        setUrl(urlMap[tab] || "onetime");
        setCouponFilter({ ...couponFilter, coupon_status: urlMap[tab] });

        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const getAllGroupCouponList = () => {
        try {
            const getGroupCouponURL = `${environment.baseUrl}group-coupon-pagination`;
            axios.get(getGroupCouponURL, { params: couponFilter }).then((response) => {
                const couponData = response?.data?.results.map((cData) => {
                    return {
                        ...cData,
                        view: <CouponModalView
                            btn="View Coupon"
                            color="warning"
                            title="View Coupon Details"
                            header
                            couponId={cData?.id}
                        />,
                        edit: <CouponModalEdit
                            btn="Add Coupon"
                            color="warning"
                            title="Edit Coupon"
                            header
                            couponId={cData?.id}
                        />,
                        delete: deleteTemplate(cData),
                        active_or_expired_coupon: (
                            (
                                <div className="right-space">
                                    {cData.expiry_date}
                                    <br />
                                    <div className="Booking-details-td-right-space">
                                        <div className="active-color-bg canceled-color-bg">
                                            <span
                                                className={
                                                    new Date(currentDate) <= new Date(cData.expiry_date)
                                                        ? "active-color-bg"
                                                        : "canceled-color-bg"
                                                }
                                            >
                                                {new Date(currentDate) <= new Date(cData.expiry_date) ? "active" : "inactive"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                });
                setCouponList(couponData);
                setCouponPageCount(response?.data?.count / couponFilter?.page_size);
            });
        } catch (error) {
            console.log(error);
        };
    };

    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };

    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };

    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    useEffect(() => {
        getAllGroupCouponList();
    }, [couponFilter])


    return (
        <Row>
            <Col sm={12} md={12} lg={12}>
                <Row className="d-flex justify-content-between">
                    <Col>
                        <h3 className="page-title">Coupons Group Details</h3>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-end" style={{ gap: '1rem' }}>
                        <CreateGroupModal
                            btn="Create Group"
                            color="warning"
                            title="Create Group"
                            header
                        />
                        <CouponModal
                            btn="Create Coupon"
                            color="warning"
                            title="Create Coupon"
                            header
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm={12} md={12} lg={12}>
                <Card>
                    <CardBody>

                        <div className="tabs__wrap">
                            <Nav tabs className="all-tabs" style={{ width: "100%" }}>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            "active-tab": activeTab === "1",
                                        })}
                                        onClick={() => toggle("1")}
                                    >
                                        One Time
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            "active-tab": activeTab === "2",
                                        })}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        Multiple Time
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            "active-tab": activeTab === "3",
                                        })}
                                        onClick={() => {
                                            toggle("3");
                                        }}
                                    >
                                        Expired
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                {/* <TabPane tabId="1"> */}
                                <Row className="mt-4">
                                    <Row className="mt-4">
                                        <Col sm={10} md={10} lg={10}>
                                            <div className="form form__form-group">
                                                <div className="form__form-group-field">
                                                    <input
                                                        placeholder="Search coupon here ..."
                                                        onChange={(e) => setCouponSearch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={2} md={2} lg={2}>
                                            <Button
                                                color="warning"
                                                size='sm'
                                                onClick={() => filterCoupon()}
                                            >
                                                Search Coupon
                                            </Button>
                                        </Col>
                                    </Row>
                                </Row>
                                <Row>
                                    <div className="react-table__wrapper hide-table-costomizer">
                                        <ReactTableCustomizer
                                            handleClickIsSortable={handleClickIsSortable}
                                            handleClickWithPagination={
                                                handleClickWithPagination
                                            }
                                            handleClickWithSearchEngine={
                                                handleClickWithSearchEngine
                                            }
                                            handleClickIsResizable={handleClickIsResizable}
                                            isResizable={isResizable}
                                            isSortable={isSortable}
                                            withPagination={withPagination}
                                            withSearchEngine={withSearchEngine}
                                        />
                                    </div>
                                    <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                                        <ReactTableBase
                                            id="table-to-xls"
                                            key={withSearchEngine ? "searchable" : "common"}
                                            columns={data.columns}
                                            data={couponList}
                                            tableConfig={tableConfig}
                                        />
                                    </div>
                                </Row>
                                <div className="custom-pagination">
                                    <div className="records-per-page">
                                        <select
                                            value={couponFilter.page_size}
                                            onChange={handleLimitChange}
                                            name="limit"
                                            id="select-pagination"
                                        >
                                            {mannualLimits?.map((mannualLimits) => (
                                                <option value={mannualLimits}>{mannualLimits}</option>
                                            ))}
                                        </select>
                                        &nbsp;&nbsp;&nbsp;records per page
                                    </div>
                                    <ReactPaginate
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={couponPageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handleCouponPageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        forcePage={couponInitialPage}
                                    />
                                </div>
                            </TabContent>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={12} md={12} lg={12}>
                <CouponGroupBookings couponGroupId={id} />
            </Col>
        </Row>
    )
}

export default CouponGroupDetails;