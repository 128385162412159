import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Row, Card, CardBody, Col, Spinner, Container } from "reactstrap";
import { GetTournamentsPaymentDetails } from "../Reuse/ApiCalls";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import ReactPaginate from "react-paginate";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import { useForm } from "react-hook-form";
import { environment } from "../env";
import axios from "../../customAxios";
import QuickPayModel from "../Quickpay/QuickPayModel";
import { AiFillEye } from "react-icons/ai";
import { useSelector } from 'react-redux';

const Tournaments = () => {
  const filterTournamentsObject = {
    amount: "",
    created_date_after: "",
    created_date_before: "",
    email: "",
    first_name: "",
    id: "",
    last_name: "",
    page: 1,
    payment_status: "",
    phone_no: "",
    page_size: 10
  };

  const [pageCount, setPageCount] = useState(0);
  const [view, setView] = useState(false);
  const [transactionLog, setTransactionLog] = useState();
  const [tshirtData, setTshirtData] = useState();
  const [filterTournaments, setFilterTournaments] = useState(filterTournamentsObject);
  const [tournamentPaymentList, setTournamentPaymentList] = useState([]);
  const [paginationKey, setPaginationKey] = useState(0);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const { pageLimits } = useSelector((state) => state.config);

  // templates
  const paymentIdTemplate = (data) => (data?.id);
  const firstNameTemplate = (data) => (<div className="bookingId right-space">{data.first_name}</div>);
  const lastNameTemplate = (data) => (<div className="bookingId right-space">{data.last_name}</div>);
  const emailTemplate = (data) => (<div className="bookingId right-space">{data.email}</div>);
  const phoneTemplate = (data) => (<div className="right-space"><div className="userEmail">{data?.phone}</div></div>);
  const amountTemplate = (data) => (<div className="right-space"><div className="amountPaid">{(data?.amount) + "AED"}<span className="amountPaidPayform"> / payfort</span></div></div>);
  const tournamentTemplate = (data) => (<div className="right-space"><div className="right-space text-capitalize">{data?.tournament}</div></div>);
  const categoryTemplate = (data) => (<div className="right-space"><div className="right-space text-capitalize">{data?.category}</div></div>);
  const ageTemplate = (data) => (<div className="right-space"><div className="right-space text-capitalize">{data?.age}</div></div>);
  const paymentStatusTemplate = (data) => (<div className="right-space"><div className="right-space text-capitalize">{data.payment_status === "completed" ? <h3 className='amountStatusCompleted'>Completed</h3> : <h3 className='amountStatusCancelled mt-1'>Pending</h3>}</div><div className="right-space text-capitalize"><span className='text-capitalize app-bg'>{data.event_regestration_using == "WEB" ? "Website" : data.event_regestration_using}</span></div></div>);
  const createdDateTemplate = (data) => (<div className="right-space"><div className="payment-status">{moment(data?.created_date.split('T')[0]).format("DD-MM-YYYY")}<br />{moment(data?.created_date.split('T')[1].split('.')[0], "HH:mm:ss").format("hh:mm A")}</div></div>);
  const transactionLogTemplate = (data) => (<div className="right-space"><button className='modal-view-btn' onClick={() => OpenModal(data?.transaction_log, data?.tshirt_brought)}><AiFillEye style={{ fontSize: 20, height: "19px", width: "19px" }} /></button></div>);

  const GetTournamentsPaymentsList = (filterParams) => {
    try {
      axios.get(`${environment.baseUrl}tournaments-filter`, { params: filterParams }).then((response) => {
        const tournamentList = response.data.results.map((data) => {
          return {
            ...data,
            paymentId: paymentIdTemplate(data),
            firstName: firstNameTemplate(data),
            lastName: lastNameTemplate(data),
            email: emailTemplate(data),
            phone: phoneTemplate(data),
            tournament: tournamentTemplate(data),
            category: categoryTemplate(data),
            amount: amountTemplate(data),
            age: ageTemplate(data),
            paymentStatus: paymentStatusTemplate(data),
            createdDate: createdDateTemplate(data),
            transactionLog: transactionLogTemplate(data)
          }
        })
        setTournamentPaymentList(tournamentList);
        setPageCount(Math.ceil(response.data.count / filterTournaments?.page_size));
      });
    } catch (error) {
      console.log("error", error);
    };
  };

  const tournamentsColumns = [
    {
      Header: 'Transaction Id s',
      accessor: "payment_ids",
      disableGlobalFilter: false,
      width: 100,
    },
    {
      Header: 'Transaction Id',
      accessor: "paymentId",
      disableGlobalFilter: true,
      width: 100,
    },
    {
      Header: "First Name",
      accessor: "firstName",
      disableGlobalFilter: true,
      width: 40,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      disableGlobalFilter: true,
      width: 40,
    },
    {
      Header: "Email",
      accessor: "email",
      disableGlobalFilter: true,
      width: 160,
    },
    {
      Header: "Phone No",
      accessor: "phone",
      disableGlobalFilter: true,
      width: 160,
    },
    {
      Header: "Tournament",
      accessor: "tournament",
      disableGlobalFilter: true,
      width: 120,
    },
    {
      Header: "Category",
      accessor: "category",
      disableGlobalFilter: true,
      width: 120,
    },
    {
      Header: "Amount",
      accessor: "amount",
      disableGlobalFilter: true,
      width: 110,
    },
    {
      Header: "Age",
      accessor: "age",
      disableGlobalFilter: true,
      width: 110,
    },
    {
      Header: "Payment Status",
      accessor: "paymentStatus",
      disableGlobalFilter: true,
      width: 120,
    },
    {
      Header: 'Date',
      accessor: "createdDate",
      disableGlobalFilter: true,
      width: 110,
    },
    {
      Header: 'Actions',
      accessor: 'transactionLog',
      disableGlobalFilter: true,
      width: 120,
    },
  ]

  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10000, 20000, 30000, 40000],
    placeholder: "Search by Name...",
  };

  const handlePageClick = async (data) => {
    setFilterTournaments({ ...filterTournaments, page: data.selected + 1 });
  };

  const handleLimitChange = (e) => {
    setPaginationKey((prevKey) => prevKey + 1);
    setFilterTournaments({ ...filterTournaments, page_size: e.target.value, page: 1 });
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFilterTournaments({ ...filterTournaments, ...data, page: 1 });
  };

  const OpenModal = (transactionLogData, TShirtData) => {
    setView();
    setTransactionLog();
    setTshirtData()
    setView(!view);
    if (transactionLogData !== "NO Transacation Log") {
      setTransactionLog(JSON.parse(transactionLogData)[0]);
      setTshirtData(TShirtData)
    } else {
      setTransactionLog({ Data: "No Transaction log" });
      setTshirtData()
    }
  };
  const CloseModal = () => {
    setView(false);
  };

  const resetSearch = () => {
    reset();
    setFilterTournaments(filterTournamentsObject);
  };

  useEffect(() => {
    GetTournamentsPaymentsList(filterTournaments);
  }, [filterTournaments]);

  return (
    <Container className="dashboard">
      <QuickPayModel
        transactionLog={transactionLog}
        show={view}
        CloseModal={CloseModal}
        tshirtData={tshirtData}
        color="primary"
        header
        title="Tournaments"
      />
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Card>
            <CardBody>
              <h3>JP Open</h3>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12} md={12} lg={12}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className='align-items-end'>
                  <Col sm={11} md={11} lg={11}>
                    <div className="form form__form-group">
                      <div className="form__form-group-field d-flex">
                        <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                          <span>Transaction Id</span>
                          <input
                            type="text"
                            className="custom-css-dp"
                            name="id"
                            {...register("id", { required: false })}
                            placeholder="Transaction Id"
                          />
                        </div>
                        <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                          <span>Payment Date</span>
                          <input
                            type="date"
                            className="custom-css-dp"
                            {...register("created_at_after", { required: false })}
                          />
                          <input
                            type="date"
                            className="custom-css-dp mt-1"
                            {...register("created_at_before", { required: false })}
                            placeholder='j'
                          />
                        </div>
                        <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                          <span>User Name</span>
                          <input
                            type="text"
                            className="custom-css-dp"
                            {...register("first_name", { required: false })}
                            placeholder="First Name"
                          />
                          <input
                            type="text"
                            className="custom-css-dp mt-1"
                            {...register("last_name", { required: false })}
                            placeholder='Last Name'
                          />
                        </div>
                        <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                          <span>User Email</span>
                          <input
                            type="text"
                            className="custom-css-dp"
                            {...register("email", { required: false })}
                            placeholder="Email"
                          />
                        </div>
                        <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                          <span>Payment Status</span>
                          <select
                            {...register("payment_status")}
                            className="filter-client-email-select-option"
                            style={{ marginRight: 0, borderColor: '#f2f4f7' }}
                          >
                            <option value="">Payment Status</option>
                            <option value="completed">Completed</option>
                            <option value="pending">Pending</option>
                          </select>
                        </div>
                        <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                          <span>Phone No</span>
                          <input
                            type="text"
                            className="custom-css-dp"
                            {...register("phonenumber", { required: false })}
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={1} md={1} lg={1} className="p-0">
                    <Row>
                      <Col sm={12} md={12} lg={12} className='p-0 px-1'>
                        <Button size='sm' color="warning" className='m-1 w-100 px-2'>Apply</Button>
                      </Col>
                      <Col sm={12} md={12} lg={12} className='p-0 px-1'>
                        <Button size='sm' color="secondary" className='m-1 w-100 px-2' onClick={() => resetSearch()} >Clear</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md={12} lg={12}>
          <Card>
            <CardBody className="mb-4">
              <div className="table-id-desabled pagination-hide hover-effect hide-filtername second-child-space">
                <ReactTableBase
                  id="table-to-xls"
                  key={withSearchEngine ? "searchable" : "common"}
                  columns={tournamentsColumns}
                  data={tournamentPaymentList}
                  tableConfig={tableConfig}
                />
              </div>
              <div className="custom-pagination">
                <div className="records-per-page">
                  <select
                    value={filterTournaments?.page_size}
                    onChange={handleLimitChange}
                    name="limit"
                    id="select-pagination"
                  >
                    {pageLimits.map((limits) => (
                      <option value={limits}>{limits}</option>
                    ))}
                  </select>
                  &nbsp;&nbsp;&nbsp;records per page
                </div>
                <ReactPaginate
                  key={paginationKey}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={0}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Tournaments;