import React from 'react'
import { Button, Card, CardBody, CardFooter, CardText, CardTitle, Col, Row } from 'reactstrap'

const NoSelectedUserComponent = ({title="Title", subtitle="Subtitle", btn="Button Text", onClick}) => {
    return (
        <Row className='d-flex justify-content-center' >
            <Col md={12} sm={12} xs={12} lg={12}>
                <Card className="d-flex">
                    <CardBody>
                        <CardTitle tag="h5">
                            {title}
                        </CardTitle>
                        <CardText>
                            {subtitle}
                        </CardText>
                        <Button className='mt-4' color="warning" onClick={onClick} >
                            {btn}
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default NoSelectedUserComponent;