/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import {
	Col, Button, ButtonToolbar, Card, CardBody,Spinner
} from 'reactstrap';
import axios from '../../../customAxios';
import { useParams, useNavigate } from 'react-router-dom';
import { environment } from '../../env';
import swal from 'sweetalert';

const EditCourtTypeCard = () => {
	const [courtType, setCourtType] = useState();
	const [isLoading, setIsLoading] = useState(false)
	const { id } = useParams();
	const navigate = useNavigate();

	function handleChangeCourtType(e) {
		setCourtType(e.target.value);
	}

	const editCourtTypeUrl = `${environment.baseUrl}courttypes/${id}`;

	const getCourtType = async () => {
		const result = await axios(editCourtTypeUrl);
		setCourtType(result.data.courttype);
	};

	const handleSubmit = () => {
		setIsLoading(true);

		const formData = new FormData();
		formData.append('courttype', courtType);
		axios
			.put(`${editCourtTypeUrl}`, formData)
			.then((response) => {
				console.log(response);
				if (response.data.Message === "Court Type Updated Successfully") {
					swal({
						title: "Success",
						text: response.data.Message,
						icon: "success",
						button: "Ok",
					}).then((value) => {
						navigate("/list_court_type");
					});
					setIsLoading(false);
				} else {
					swal({
						title: "Error",
						text: response.data,
						icon: "error",
						button: "Ok",
					});
					setIsLoading(false);
				}
			}).catch((err) => {
				swal({
					title: "Error",
					text: err.messgae,
					icon: "error",
					button: "Ok",
				}).then((value) => {
					navigate("/list_court_type");
				});
				setIsLoading(false);
			});
	};

	const reset = () => {
		navigate('/list_court_type')
	};

	useEffect(() => {
		if (localStorage.getItem('Token')) {
		} else {
			alert('You are not logged In. Please LogIn');
			navigate('/');
		}

		getCourtType();
	}, []);

	return (
		<Col md={12} lg={12}>
			{isLoading && (
				<div class="loader-div">
					<span class="loader">
						<div className="center-spinner">
							<Spinner className="table-fetch-spinner" />
						</div>
					</span>
				</div>
			)}
			<Card>
				<CardBody>
					<form className="form product-edit">
						<div className="form__half">
							<div className="form__form-group">
								<span className="form__form-group-label" style={{ fontSize: 15 }}>Enter Court Type</span>
								<div className="form__form-group-field">
									<input type="text" value={courtType} style={{ borderRadius: 5 }} onChange={handleChangeCourtType} />
								</div>
							</div>
						</div>
						<ButtonToolbar className="form__button-toolbar" style={{ marginTop: 35 }}>
							<div className='justpadel-buttons-save' onClick={handleSubmit}>
								Update
							</div>
							<div className='justpadel-buttons-cancel' onClick={() => reset()}>
								Cancel
							</div>
						</ButtonToolbar>
					</form>
				</CardBody>
			</Card>
		</Col>
	);
};
export default EditCourtTypeCard;
