import React, { useState, useEffect } from "react";
import axios from '../../../customAxios';
import { Col, Button, Card, CardBody, Row } from "reactstrap";
import { environment } from "../../env";
import { useNavigate, Link } from "react-router-dom";
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon'
import SettingsIcon from 'mdi-react/SettingsIcon';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import { AiFillEye, AiOutlineEdit, AiOutlineDelete, AiFillSetting } from 'react-icons/ai';
import { useSelector } from 'react-redux';

const CourtList = () => {
  const [allCourts, setAllCourts] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [role, setRole] = useState();
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const navigate = useNavigate();

  // template list

  const padelCourtImage = (rowData) => (<img src={rowData.courtimage} alt={rowData.courtimage} style={{ width: 150, borderRadius: 5 }} />);
  const padelCourtCourtName = (rowData) => (<div className="right-space">{"Court " + rowData.courtname}</div>);
  const padelCourtMainCourtName = (rowData) => (<div className="right-space">{rowData.maincourt.courtName}</div>);
  const padelCourtViewTemplate = (rowData) => (<div className="right-space"><div className="modal-view-btn" size="sm" onClick={() => handleViewDetails(rowData.id)}><AiFillEye style={{ fontSize: 20 }} /></div></div>);
  const padelCourtEditTemplate = (rowData) => (<div className="right-space"><div className="modal-edit-btn" size="sm" onClick={() => handleEdit(rowData.id)}><AiOutlineEdit style={{ fontSize: 20 }} /></div></div>);
  const padelCourtManageTimeSlotsTemplate = (rowData) => (<div className="right-space"> <div className="modal-manage-btn" size="sm" onClick={() => handleManageTimeslot(rowData.id)}><AiFillSetting style={{ fontSize: 20 }} /></div></div>)

  const authslice = useSelector((state) => state.auth);
  const canDelete = authslice.loggedAdminRole === "admin";

  const getMainCourt = `${environment.baseUrl}padelcourt`;
  const sendGetRequest = async () => {
    try {
      const resp = await axios.get(getMainCourt);
      const getAllPadelCourtData = resp.data.map((padelCourt, i) => {
        return {
          ...padelCourt,
          image: padelCourtImage(padelCourt), 
          courtname: padelCourtCourtName(padelCourt), 
          maincourt: padelCourtMainCourtName(padelCourt), 
          view: padelCourtViewTemplate(padelCourt), 
          edit: padelCourtEditTemplate(padelCourt), 
          manage_timeslot: padelCourtManageTimeSlotsTemplate(padelCourt) 
        };
      });

      setAllCourts(getAllPadelCourtData);
    } catch (err) {
      console.error(err);
    }
  };

  function handleEdit(id) {
    navigate(`/edit_courts/${id}`);
  };

  const setPermissions = () => {
    const padelCourtPermission = authslice?.loggedAdminPermission.find(item => item.padel_court);
    const padelCourtId = padelCourtPermission ? padelCourtPermission.padel_court : null;

    if ([2, 3, "2", "3"].includes(padelCourtId)) {
      setEditing(true);
      setReload(prev => !prev);
    }
    if (padelCourtId === 3 || padelCourtId === "3") {
      setDeleteing(true);
      setReload(prev => !prev);
    }
  };

  useEffect(() => {
    sendGetRequest();
    setPermissions();
  }, []);

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Image",
        accessor: "image",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 70,
      },
      {
        Header: "Name",
        accessor: "courtname",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Main Court",
        accessor: "maincourt",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "View Details",
        accessor: "view",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
      {
        Header: "Edit",
        accessor: "edit",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
      // {
      //   Header: "Delete",
      //   accessor: "delete",
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      //   width: 30,
      // },
      {
        Header: "Manage Timeslots",
        accessor: "manage_timeslot",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
    ],
  };

  const handleDelete = (id) => {
    const deleteMainCourt = `${environment.baseUrl}padelcourt/${id}`;
    axios.delete(deleteMainCourt)
    setAllCourts(() => allCourts.filter((court) => court.id !== id));
  };

  const handleManageTimeslot = (id) => {
    navigate(`/managetimeslot/${id}`);
  };
  const handleViewDetails = (id) => {
    navigate(`/all_courts_view_details/${id}`);
  };

  const handleAddPadelCourt = () => {
    navigate("/court_court");
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
  };

  return (
    <>
      <Card className="mb-4">
        <CardBody>
          {!deleteing && (<Row>
            <Col md={9} lg={9} />
            <Col md={3} lg={3}>
              <div className="card__title">
                <div className="justpadel-buttons" onClick={() => handleAddPadelCourt()}>Add Padel Court</div>
              </div>
            </Col>
          </Row>)}
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="react-table__wrapper hide-table-costomizer">
                    <ReactTableCustomizer
                      handleClickIsSortable={handleClickIsSortable}
                      handleClickWithPagination={handleClickWithPagination}
                      handleClickWithSearchEngine={handleClickWithSearchEngine}
                      handleClickIsResizable={handleClickIsResizable}
                      isResizable={isResizable}
                      isSortable={isSortable}
                      withPagination={withPagination}
                      withSearchEngine={withSearchEngine}
                    />
                  </div>
                  <div className="table-id-desabled hover-effect second-child-space">
                    <ReactTableBase
                      key={withSearchEngine ? 'searchable' : 'common'}
                      columns={data.columns}
                      data={allCourts}
                      tableConfig={tableConfig}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* <Col md={12} lg={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          alignItems: 'flex-end',
        }}>
        {deleteing ? (
          <div className="justpadel-buttons btn-position" onClick={() => handleAddPadelCourt()}>Add Padel Court</div>
        ) : (
          ""
        )}
      </Col>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="react-table__wrapper hide-table-costomizer">
              <ReactTableCustomizer
                handleClickIsSortable={handleClickIsSortable}
                handleClickWithPagination={handleClickWithPagination}
                handleClickWithSearchEngine={handleClickWithSearchEngine}
                handleClickIsResizable={handleClickIsResizable}
                isResizable={isResizable}
                isSortable={isSortable}
                withPagination={withPagination}
                withSearchEngine={withSearchEngine}
              />
            </div>
            <div className="table-id-desabled hover-effect second-child-space">
              <ReactTableBase
                key={withSearchEngine ? 'searchable' : 'common'}
                columns={data.columns}
                data={data.rows}
                tableConfig={tableConfig}
              />
            </div>

          </CardBody>
        </Card>
      </Col> */}
    </>
  );
};
export default CourtList;
