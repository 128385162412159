import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button, Row } from 'reactstrap';
import { GetMainCourtForPromotion, AddPromotionV2Api, GetPadelCourt } from '../../Reuse/ApiCalls';
import { useForm } from "react-hook-form";
import swal from '@sweetalert/with-react';
import { useNavigate } from 'react-router-dom';


const PromotionsV2 = () => {

  const [mainCourtList, setMainCourtList] = useState([]);
  const [padelCourtList, setPadelCourtList] = useState([]);
  const { register, handleSubmit, reset, formState: { errors }, } = useForm();
  const navigate = useNavigate();
 

  const onSubmit = (data) => {
    console.log(data);
    // AddPromotionV2Api(data).then((response)=>{
    //   console.log("response", response);
    // })

    AddPromotionV2Api(data).then((resposne) => {
      if (resposne.msg === "Date is already used" || resposne.msg === "error") {
        swal({
          icon: "error",
          content: (<div>
            <h1 style={{ color: "darkred" }}>Error</h1>
            <p>
              This date is already exist in promotion, Please Chose other date
            </p>
          </div>)
        })
      } else {
        swal(
          {
            icon: "success",
            content: (<div>
              <h1 style={{ color: "#28a745" }}>Promotion is created sucessfully</h1>
            </div>)
          }
        ).then(() => {
          navigate("/promotionsv2-list");
        })
      }
    })
  };

  const mainCourtListData = () => {
    GetMainCourtForPromotion().then((resposne) => {
      setMainCourtList(resposne);
    })
  };

  useEffect(() => {
    mainCourtListData();
  }, [])

  const Back = () => {
    navigate("/promotionsv2-list");
    { reset({ date: "", price: "", maincourt_id: "" }) }
  }


  return (
    <Col md={12}>
      <Card>
        <CardBody>
          {/* <div className="card__title">
          </div> */}
          <div className="card__title">
            <h5 className="bold-text">Add Promotion</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className='form__half'>
              <div className="form__form-group">
                <span className="form__form-group-label">Date</span>
                <div className="form__form-group-field">
                  <input type="date" name="date" id="" {...register("date", { required: true })} />
                </div>
                {errors.date && <span className='error-msg'>Date Is required</span>}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">MainCourt Id</span>
                <div className="form__form-group-field">
                  <select name="maincourt" className="select" {...register("maincourt", { required: true })}>
                    <option className='error-msg' value='' hidden>Please Select Main Court</option>
                    {
                      mainCourtList.map((data, index) => {
                        return (
                          <option key={index} value={data.id}>{data.courtName}</option>
                        )
                      })
                    }
                  </select>
                </div>
                {errors.maincourt && <span className='error-msg'>Main Court Is required</span>}
              </div>

              <div className="form__form-group">
              <span className="form__form-group-label">Time</span>
              <div className="form__form-group-field">
                <select name="times" className="select" {...register("times", { required: true })}>
                  <option className='error-msg' value='' hidden>Please Select Times</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              {errors.times && <span className='error-msg'>Times Is required</span>}
            </div>

             

            </div>

            <div className='form__half'>
              <div className="form__form-group">
                <span className="form__form-group-label">Price</span>
                <div className="form__form-group-field">
                  <input type="number" name="price" id=""  {...register("price", { required: true })} />
                </div>
                {errors.price && <span className='error-msg'>Price Is required</span>}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Additional Slot Price</span>
                <div className="form__form-group-field">
                  <input type="number" name="additional_slot_price" id=""  {...register("additional_slot_price", { required: true })} />
                </div>
                {errors.additional_slot_price && <span className='error-msg'>Additional Slot Price Is required</span>}
              </div>

              
            

            <div className='form__form-group mt-5'>
            <ButtonToolbar className="form__button-toolbar">
              <button className='justpadel-buttons-save' type="submit" >Submit</button>
              <div className='justpadel-buttons-cancel' onClick={() => Back()}
              >
                Cancel
              </div>
            </ButtonToolbar>
            </div>




            </div>

















            

           
          </form>
        </CardBody>
      </Card>
    </Col>
  )
};

export default PromotionsV2;
