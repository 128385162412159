import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import CourtCard from './components/CourtCard';

const ListIndex = () => (
  <Container>
    <Row>
      <Col sm={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <h3>Main Court List</h3>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className='mt-2'>
      <CourtCard />
    </Row>
  </Container>
);

export default ListIndex;
