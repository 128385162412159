import React, { useEffect, useState } from 'react'
import { CardBody, Container, Col, Row, Card, Spinner } from 'reactstrap'
import { useParams, useNavigate } from "react-router-dom";
import axios from '../../customAxios';
import { environment } from '../env';
import moment from 'moment';
export const ViewEventTournament = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState([])
    const [eventMerchandise, setEventMerchandise] = useState()

    const GetEventDetails = async () => {
        setIsLoading(true)
        const eventDetailsURL = `${environment.baseUrl}edit-event-registration/${id}`
        axios.get(eventDetailsURL).then(res => {
            setEventDetails(res.data.data)
            const data = res?.data?.data
            setEventMerchandise(JSON.parse(data?.event_merchandise))
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })
    }

    const formatDate = (date) => {
        if (date?.length > 9) {
            const date_arr = date?.split(",")
            const new_arr = date_arr?.map((newdate) => {
                return moment(newdate).format('MMM D')
            })
            return new_arr.join("-")
        } else {
            return moment(date).format('MMM D')
        }
    }

    useEffect(() => {
        GetEventDetails()
    }, [])

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Events Details</h3>
                </Col>
            </Row>

            <Row>
                <Col md={12} lg={12}>
                    {isLoading && (
                        <div class="loader-div">
                            <span class="loader">
                                <div className="center-spinner">
                                    <Spinner className="table-fetch-spinner" />
                                </div>
                            </span>
                        </div>
                    )}
                </Col>

                <Card>
                    <CardBody>
                        <div className="card__title"></div>
                        <table
                            style={{
                                padding: 13,
                                borderRadius: "10px",
                                borderCollapse: "inherit",
                            }}
                        >
                            <></>
                            <tbody style={{ fontSize: 15 }}>
                                <tr>
                                    <th style={{ padding: 10, width: 200 }}>Image</th>
                                    <td>
                                        <img
                                            src={eventDetails?.event_image}
                                            alt={eventDetails?.event_image}
                                            style={{ width: 180, borderRadius: 10 }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ padding: 10, width: 200 }}>Event name</th>
                                    <td >{eventDetails?.event_name}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: 10, width: 200 }}>Event Location</th>
                                    <td>{eventDetails?.event_location}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: 10, width: 200 }}>Event Price</th>
                                    <td>{eventDetails?.event_price?.toFixed(2)} AED</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: 10, width: 200 }}>Event Date </th>
                                    <td>{formatDate(eventDetails?.event_date)}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: 10, width: 200 }}>Event Registration Start Date </th>
                                    <td>{formatDate(eventDetails?.event_registration_start_date)}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: 10, width: 200 }}>Event Registration End Date </th>
                                    <td>{formatDate(eventDetails?.event_registration_end_date)}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: 10, width: 200 }}>Location URL</th>
                                    <td>{eventDetails?.event_location_url}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: 10, width: 200 }}>About</th>
                                    <td>{eventDetails?.event_about}</td>
                                </tr>
                                {eventMerchandise?.event_merchandise_name && (
                                    <tr>
                                        <th style={{ padding: 10, width: 200 }}>Merchandise Name</th>
                                        <td>{eventMerchandise?.event_merchandise_name}</td>
                                    </tr>
                                )}
                                {eventMerchandise?.event_merchandise_price && (
                                    <tr>
                                        <th style={{ padding: 10, width: 200 }}>Merchandise Price</th>
                                        <td>{eventMerchandise?.event_merchandise_price}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="justpadel-buttons-cancel float-right" style={{ width: 100 }} onClick={() => navigate.goBack()}>Back</div>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    )
}
