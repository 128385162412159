import React, { useState, useEffect, useReducer, useMemo } from "react";
import { Row, Card, CardBody, Col, Spinner, Button } from "reactstrap";

import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../../shared/components/table/components/ReactTableCustomizer";
import { AiOutlineEdit, AiOutlineDelete, AiFillEye } from "react-icons/ai";
import CouponModal from "../../../shared/components/CouponModal";
import CouponModalEdit from "../../../shared/components/CouponModalEdit";
import ReactPaginate from "react-paginate";
import TransactionModel from "../../../shared/components/TransactionModel";
import BookingList from "../../Transactions/components/BookingList";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import axios from "../../../customAxios";
import { environment } from "../../env";

const CouponGroupBookings = ({ couponGroupId }) => {

    const [transactionLogs, setTransactionLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setpageCount] = useState(0);
    const [isResizable, setIsResizable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [isSortable, setIsSortable] = useState(true);
    const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
    const [initialPage, setInitialPage] = useState(0);
    const [searchValue, setSearchValue] = useState();
    const [couponBookingFilter, setCouponBookingFilter] = useState({ coupon_name: '', booking_id: '' });
    const [groupCouponBookingSearch, setGroupCouponBookingSearch] = useState({ coupon_name: '', booking_id: '', page_size: 10, page: 1 });

    const tableConfigCouponLog = {
        isEditable: false,
        isResizable,
        isSortable,
        withPagination,
        withSearchEngine,
        manualPageSize: [1000, 2000, 3000, 4000],
        placeholder: "Search by Name...",
    };

    const ViewTemplate = (row) => (
        <div className="right-space">
            <div className="dropdown trans-buttons">
                <TransactionModel
                    color="primary"
                    header
                    title="Transaction Details"
                    transactionId={row.id}
                />
            </div>
        </div>
    );

    // Template function for rendering client_name
    const renderClientName = (item) => (
        <div className="right-space">
            <div>
                {item.user?.first_name === "null" ? "" : item.user?.first_name}
            </div>
            <div className="userEmail">{item.user?.email}</div>
            <div className="userPhone">
                {item.user?.phone_no === "null" ? " " : item.user?.phone_no}
            </div>
        </div>
    );

    // Template function for rendering court_name
    const renderCourtName = (item) => (
        <div className="right-space">
            <div className="item-data">
                <BookingList
                    main_court={item.booking?.main_court?.courtName}
                    padel_court={item.booking?.padel_court?.courtname}
                    booking_id={item.booking?.booking_id}
                    className="item-data-more-or-less"
                />
            </div>
        </div>
    );

    // Template function for rendering amount_paid
    const renderAmountPaid = (item) => (
        <div className="right-space">
            <div className="amountPaid">
                {item.booking?.amount_paid?.toFixed(2) + " AED"}
                <span className="amountPaidPayform"> / {item.booking.payment_processor}</span>
            </div>
            <div>
                {item.booking?.amount_percentage === 0.5 ? (
                    <h3 className="amountPaidPaidHalf">50% Paid</h3>
                ) : item.booking?.amount_percentage === 1 ? (
                    <h3 className="amountPaidPaid">Paid</h3>
                ) : (
                    " "
                )}
            </div>
            <div>
                <h3
                    className={
                        item.booking?.coupan_applied === "" ? " " : "amountPaidCoupanCode"
                    }
                >
                    {item.booking?.coupan_applied}
                </h3>
            </div>
        </div>
    );

    // Template function for rendering payment_status
    const renderPaymentStatus = (item) => (
        <div className="right-space">
            <div className="payment-status">
                {moment(item.booking?.updated_at.split("T")[0]).format("DD-MM-YYYY")}
                <br />
                {moment(
                    item.booking?.updated_at.split("T")[1].split(".")[0],
                    "HH:mm:ss"
                ).format("hh:mm A")}
            </div>
            <h3 className="amountStatusCompleted">
                {item.payment_status}
            </h3>
        </div>
    );

    const viewBookingDetails = (row) => {
        console.log(row);
    };

    const couponLogData = {
        columns: [
            {
                Header: "#",
                accessor: "id",
                disableGlobalFilter: true,
                width: 65,
            },
            {
                Header: "ID",
                accessor: "booking_id",
                disableGlobalFilter: true,
                width: 40,
            },
            {
                Header: "Coupon Code",
                accessor: "coupan_applied",
                disableGlobalFilter: true,
                width: 120,
            },
            {
                Header: "Client name",
                accessor: "first_name",
                disableGlobalFilter: true,
                width: 160,
            },
            {
                Header: "Item",
                accessor: "main_court",
                disableGlobalFilter: true,
                width: 160,
            },
            {
                Header: "Amount / Paid With",
                accessor: "amount_paid",
                disableGlobalFilter: true,
                width: 130,
            },
            {
                Header: "Payment Status",
                accessor: "payment_status",
                disableGlobalFilter: true,
                width: 120,
            },
            {
                Header: "Actions",
                accessor: "transaction_log",
                disableGlobalFilter: true,
                width: 110,
            },
        ]
    };

    const getCouponGroupBookingDetails = async () => {
        try {
            const getCouponGroupBookingDetailsURL = `${environment.baseUrl}group-coupon-booking-pagination/${couponGroupId}`;
            axios.get(getCouponGroupBookingDetailsURL, { params: groupCouponBookingSearch }).then((response) => {
                const couponLogData = response?.data?.results.map(item => ({
                    ...item,
                    id: item.id,
                    booking_id: item.booking.booking_id,
                    coupan_applied: item.booking.coupan_applied,
                    first_name: renderClientName(item),
                    main_court: renderCourtName(item),
                    amount_paid: renderAmountPaid(item),
                    payment_status: renderPaymentStatus(item),
                    transaction_log: ViewTemplate(item),
                }));
                setTransactionLogs(couponLogData);
                setpageCount(response?.data?.count / groupCouponBookingSearch?.page_size);
            });
        } catch (error) {
            console.log(error);
        };
    };

    const handleGroupCouponBookingPageClick = (pageClicked) => {
        setGroupCouponBookingSearch({ ...groupCouponBookingSearch, page: pageClicked.selected + 1 });
    };

    const handleRecordsPerPageChange = (value) => {
        setGroupCouponBookingSearch({ ...groupCouponBookingSearch, page_size: value });
    };

    const filterGroupBookingCoupon = () => {
        setGroupCouponBookingSearch({ ...groupCouponBookingSearch, page: 1, ...couponBookingFilter });
    };

    const clearSearch = () => {
        setCouponBookingFilter({ coupon_name: '', booking_id: '' });
        setGroupCouponBookingSearch({ ...groupCouponBookingSearch, page: 1 });
    };

    useEffect(() => {
        getCouponGroupBookingDetails();
    }, [groupCouponBookingSearch]);

    return (
        <Row>
            <Col sm={12} md={12} lg={12} className="mt-5">
                <h3 className="page-title">Coupons Booking List</h3>
            </Col>
            <Col sm={12} md={12} lg={12} className="mb-4">
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm={5} md={5} lg={5}>
                                <div className="form form__form-group">
                                    <div className="form__form-group-field">
                                        <input
                                            placeholder="Search coupon here ..."
                                            onChange={(e) => setCouponBookingFilter({ ...couponBookingFilter, coupon_name: e.target.value })}
                                            value={couponBookingFilter?.coupon_name}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm={5} md={5} lg={5}>
                                <div className="form form__form-group">
                                    <div className="form__form-group-field">
                                        <input
                                            placeholder="Search Booking here ..."
                                            onChange={(e) => setCouponBookingFilter({ ...couponBookingFilter, booking_id: e.target.value })}
                                            type="number"
                                            value={couponBookingFilter?.booking_id}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm={1} md={1} lg={1}>
                                <Button color="warning"
                                    size="sm"
                                    onClick={() => filterGroupBookingCoupon()}
                                >Search</Button>
                            </Col>
                            <Col sm={1} md={1} lg={1}>
                                <Button color="warning"
                                    size="sm"
                                    onClick={() => clearSearch()}
                                    style={{ marginLeft: '4px' }}
                                >Clear</Button>
                            </Col>
                        </Row>
                        <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                            <ReactTableBase
                                id="table-to-xls"
                                key={withSearchEngine ? "searchable" : "common"}
                                columns={couponLogData?.columns}
                                data={transactionLogs}
                                tableConfig={tableConfigCouponLog}
                            />
                        </div>
                        <div className="custom-pagination">
                            <div className="records-per-page">
                                <select value={groupCouponBookingSearch?.page_size}
                                    onChange={(e) => handleRecordsPerPageChange(e.target.value)}
                                    name="limit" id="select-pagination">
                                    {mannualLimits && mannualLimits.map((mannualLimits) => (
                                        <option value={mannualLimits}>
                                            {mannualLimits}
                                        </option>
                                    ))}
                                </select>&nbsp;&nbsp;&nbsp;records per page
                            </div>
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handleGroupCouponBookingPageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                forcePage={initialPage}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default CouponGroupBookings