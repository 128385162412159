import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import axios from '../../../customAxios';
import { useNavigate } from "react-router-dom";
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import { environment } from "../../env";
import { AiFillEye, AiOutlineEdit } from 'react-icons/ai';
import './CourtCard.css'
import { useSelector } from 'react-redux';
import { BiTrash } from "react-icons/bi";

const ListCourtCard = () => {
  const [mainCourtList, setMainCourtList] = useState([]);
  const [editing, setEditing] = useState(false);
  const [reload, setReload] = useState(false);
  const [userRole, setUserRole] = useState();
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [deleting, setDeleting] = useState(false);

  // template list

  const mainCourtimageTemplate = (rowData) => (<img src={rowData.maincourtimage} alt={rowData.maincourtimage} style={{ width: 130, borderRadius: 5 }} />);
  const courtName = (rowData) => (<div className="right-space">{rowData.courtName}</div>);

  const timeSlotTemplate = (rowData) => (
    <div className="right-space">
      {rowData?.timeslot.split(",").map((timeslot, index) => (
        <p key={index}>{timeslot?.trim()}</p>
      ))}
    </div>
  );

  const featureTemplate = (rowData) => (<div className="right-space">{rowData.features}</div>);

  const viewTemplate = (rowData) => (
    <div className="right-space">
      <div className="modal-view-btn" size="sm" onClick={() => handleView(rowData.id)}>
        <AiFillEye style={{ fontSize: 20 }} />
      </div>
    </div>
  );

  const editTemplate = (rowData) => (
    <div className="right-space">
      <div className="modal-edit-btn" size="sm" onClick={() => handleEdits(rowData.id)}>
        <AiOutlineEdit style={{ fontSize: 20 }} />
      </div>
    </div>
  );

  const deleteTemplate = (rowData) => (
    <div className="right-space">
      <div className="modal-delete-btn" size="sm"
        onClick={() => handleDeletes(rowData.id)}>
        <BiTrash style={{ fontSize: 20 }} />
      </div>
    </div>
  );

  const authslice = useSelector((state) => state.auth);
  const canDelete = authslice.loggedAdminRole === "admin";

  const navigate = useNavigate();
  const mainCourtUrl = `${environment.baseUrl}maincourts-admin-new/`;

  const GetMainCourt = async () => {
    const result = await axios(mainCourtUrl);

    const mainCourtList = result.data.map((mc, i) => {
      return {
        ...mc,
        image: mainCourtimageTemplate(mc),
        courtname: courtName(mc),
        timeslot: timeSlotTemplate(mc),
        features: featureTemplate(mc),
        view: viewTemplate(mc),
        link: editTemplate(mc),
        delete: deleteTemplate(mc)
      };
    });
    setMainCourtList(mainCourtList);
  };

  function handleEdit(courtId) {
    navigate(`/edit_main_court/${courtId}`);
  }

  useEffect(() => {
    GetMainCourt();
    setUserRole(localStorage.getItem("Role"));
    setPermissions()
    setReload(!reload);
  }, []);

  const setPermissions = () => {
    const mainCourtPermission = authslice?.loggedAdminPermission.find(item => item.main_court);
    const mainCourtId = mainCourtPermission ? mainCourtPermission.main_court : null;

    if ([2, 3, "2", "3"].includes(mainCourtId)) {
      setEditing(true);
      setReload(prev => !prev);
    }
    if (mainCourtId === 3 || mainCourtId === "3") {
      setDeleting(true);
      setReload(prev => !prev);
    }
  };

  const handleAddMainCourt = () => {
    navigate("/main_court");
  };

  const handleDeletes = async (id) => {
    if (authslice.loggedAdminRole !== "admin") {
      return alert("You don't have the permissions to delete data");
    };

    const mainCourtDeleteUrl = `${environment.baseUrl}maincourts/${id}`;

    try {
      const response = await axios.delete(mainCourtDeleteUrl);
      setMainCourtList(prevList => prevList.filter(court => court.id !== id));
    } catch (error) {
      alert('An error occurred while trying to delete the court.');
    };

  };

  const handleEdits = (id) => {
    if (userRole === "admin") {
      const mainCourtEditUrl = `/edit_main_court/${id}`;
      navigate(mainCourtEditUrl);
    } else {
      alert("You don't have the permissions to Edit data");
    };
  };

  const handleView = (id) => {
    if (userRole === "admin") {
      const mainCourtEditUrl = `/main_courts_view_details/${id}`;
      navigate(mainCourtEditUrl);
    } else {
      alert("You don't have the permissions to View data");
    };
  };

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Image",
        accessor: "image",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 60,
      },
      {
        Header: "Court Name",
        accessor: "courtname",
        disableGlobalFilter: true,
        width: 40,
      },
      {
        Header: "Timeslot",
        accessor: "timeslot",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Features",
        accessor: "features",
        disableGlobalFilter: true,
        width: 70,
      },
      ...(editing ?[{
        Header: "View",
        accessor: "view",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 20,
      }]:[]),
      ...(editing ? [{
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 20,
      }]:[]),
      // ...(canDelete ? [{
      //   Header: "Delete",
      //   accessor: "delete",
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      //   width: 20,
      // }] : []),
    ]
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine:false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by name...',
  };

  return (
    <>
      <Card className="mb-4">
        <CardBody>
          <Row>
            <Col md={10} lg={10} />
            <Col md={2} lg={2}>
              {deleting && (
                <div className="justpadel-buttons " onClick={handleAddMainCourt}>
                  Add Main Court
                </div>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12} lg={12}>
              <div className="react-table__wrapper hide-table-costomizer ">
                <div className="pagination-dropdown ">
                  <ReactTableCustomizer
                    handleClickIsSortable={handleClickIsSortable}
                    handleClickWithPagination={handleClickWithPagination}
                    handleClickWithSearchEngine={handleClickWithSearchEngine}
                    handleClickIsResizable={handleClickIsResizable}
                    isResizable={isResizable}
                    isSortable={isSortable}
                    withPagination={withPagination}
                    withSearchEngine={withSearchEngine}
                  />
                </div>
              </div>
              <div className="hover-effect table-id-desabled second-child-space">
                <ReactTableBase
                  key={withSearchEngine ? 'searchable' : 'common'}
                  columns={data.columns}
                  data={mainCourtList}
                  tableConfig={tableConfig}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ListCourtCard;
