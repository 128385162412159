// export default EditPromotionV2

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { GetMainCourtForPromotion,UpdatePromotionV2ById,GetPromotionByIdV2 } from '../Reuse/ApiCalls';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import { useNavigate } from 'react-router-dom';


const EditPromotionV2 = () => {
  const { id } = useParams();

  const [mainCourtList, setMainCourtList] = useState([]);
  const navigate = useNavigate();
  const { register, handleSubmit,reset, formState: { errors } } = useForm();

  const onSubmit = (data) => { 
    UpdatePromotionV2ById(id,data).then((response)=>{
      if(response.msg === "Promotion is not updated"){
        swal({
          icon:"error",       
          content: (<div>
            <h1 style={{color:"darkred"}}>Error</h1>
            <p>
              Error While Updating Promotion
            </p>
          </div>)          
        })
      }else if(response.msg === "Date is already used"){
        swal({
          icon:"error",       
          content: (<div>
            <h1 style={{color:"darkred"}}>Error</h1>
            <p>
            This date is already exist in promotion, Please Chose other date
            </p>
          </div>)          
        })
      }
      else{
        swal(
          {
            icon:"success",       
            content: (<div>
              <h1 style={{color:"#28a745"}}>Promotion is updated sucessfully</h1>
            </div>)          
          }
        ).then(() => {
          navigate("/promotionsv2-list");
        })
      }
    })
  };    

  const GetPromotionByID = (id) =>{
    GetPromotionByIdV2(id).then((response)=>{
      setTimeout(() => {
        reset({
          date:response?.date,
          price:response?.price,
          maincourt:response?.maincourt,
          additional_slot_price:response?.additional_slot_price,
          times:response?.times
        })
      }, 1000);
    })
  };

  const mainCourtListData = () => {
    GetPromotionByID(id);
    GetMainCourtForPromotion().then((resposne) => {
      setMainCourtList(resposne);      
    })
  };

  useEffect(() => {
    mainCourtListData();
  }, [])

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
          </div>
          <div className="card__title">
            <h5 className="bold-text">Edit Promotion</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__form-group">
              <span className="form__form-group-label">Date edit</span>
              <div className="form__form-group-field">
                <input type="date"  name="date" id="" {...register("date",{ required: true })}/>
              </div>
              {errors.date && <span style={{color:"#bc4040"}}>Date Is require</span>}
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">MainCourt Id</span>
              <div className="form__form-group-field">
                <select name="maincourt" className="select" {...register("maincourt",{ required: true })}>
                  <option>Please Select Main Court</option>
                  {
                    mainCourtList.map((data, index) => {
                      return (
                        <option value={data?.id}>{data?.courtName}</option>
                      )
                    })
                  }
                </select>
              </div>
              {errors.maincourt && <span style={{color:"#bc4040"}} >Main Court Is require</span>}
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Price</span>
              <div className="form__form-group-field">
                <input type="number" name="price" id=""  {...register("price",{ required: true })}/>
              </div>
              {errors.price && <span style={{color:"#bc4040"}}>Price Is require</span>}
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Additional Slot Price</span>
              <div className="form__form-group-field">
                <input type="number" name="additional_slot_price" id=""  {...register("additional_slot_price",{ required: true })}/>
              </div>
              {errors.additional_slot_price && <span style={{color:"#bc4040"}}>Additional Slot Price Is require</span>}
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Time</span>
              <div className="form__form-group-field">
              <select name="times" className="select" {...register("times", { required: true })}>
              <option className='error-msg' value=''>Please Select Times</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              </div>
              {errors.times && <span className='error-msg'>Times Is required</span>}
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <div  className='justpadel-buttons-cancel' onClick={() => {reset({date: "",price:"",maincourt_id:""})}}>
                Reset
              </div>
              <button  className='justpadel-buttons-save' type="submit">Submit</button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  )
};

export default EditPromotionV2;
