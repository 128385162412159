/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { Col, Button, Card, CardBody, Row } from "reactstrap";
// import axios from "axios";
import axios from '../../../customAxios';

import { useNavigate, Link } from "react-router-dom";
import { environment } from "../../env";
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';

const SlideList = () => {
  const [slides, setSlides] = useState([]);
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [role, setRole] = useState();
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const slidesUrl = `${environment.baseUrl}getslides`;

  const getCourtType = async () => {
    const result = await axios(slidesUrl);
    setSlides(result.data);
  };

  useEffect(() => {
    if (localStorage.getItem("Token")) {
    } else {
      alert("You are not logged In. Please LogIn");
      navigate("/");
    }

    getCourtType();
  }, []);

  const setPermissions = (roledata) => {
    const localrole = localStorage.getItem("Role");
    let newRoleId;

    if (localrole === roledata.role_name) {
      let permission = JSON.parse(roledata.permissions);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "slides"
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
    }
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
  };

  var jsonObj = [];  
  for(let i=0; i<slides.length; i++){
    var item = {}
    item["id"] = i+1;
    item["image"] = <div className="right-space"><img src={slides[i].image_url} style={{width:180, borderRadius:5}} alt={slides[i].image_url} /></div>;
    item["redirectUrl"] = <div className="right-space">{slides[i].redirect_url}</div>;
    item["title"] = <div className="right-space">{slides[i].title}</div>;
    item["sub_title"] = <div className="right-space">{slides[i].sub_title}</div>;
    item["button_text"] = <div className="right-space">{slides[i].button_text}</div>;
    if (deleteing === true){
      item["link"] =  <div className="right-space"><div onClick={() => handleEdit(slides[i].id)} className="modal-edit-btn"><EditOutlineIcon style={{fontSize:20}}/> </div></div>;
      item["delete"] = <div className="right-space"><div className="modal-delete-btn" onClick={()=>handleDelete(slides[i].id)}><DeleteOutlineIcon style={{fontSize:20}} /></div></div>;
    } else if (editing === true) {
      item["link"] =  <div className="right-space"><div onClick={() => handleEdit(slides[i].id)} className="modal-edit-btn"><EditOutlineIcon style={{fontSize:20}}/> </div></div>;
    }
    jsonObj.push(item);
}

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Images",
        accessor: "image",
        disableGlobalFilter: true,
        width: 180,
      },
      {
        Header: "Redirect Url",
        accessor: "redirectUrl",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Title",
        accessor: "title",
        disableGlobalFilter: true,
        width: 90,
      },
      {
        Header: "Sub Title",
        accessor: "sub_title",
        disableGlobalFilter: true,
        width: 140,
      },
      {
        Header: "Button Text",
        accessor: "button_text",
        disableGlobalFilter: true,
        width: 140,
      },      
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 60,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 60,
      },      
    ],
    rows: jsonObj,
  };

  function handleEdit(slideId) {
    navigate(`/edit_slides/${slideId}`);
  }
  const handleAddSlider = () => {
    navigate("/slides");
  }

  const handleDelete = (id) => {
    const slidesDeleteUrl = `${environment.baseUrl}deleteslides/${id}/`;
    axios(slidesDeleteUrl).then((response) => {
    });
    setSlides(() => slides.filter((slide) => slide.id !== id));
  };

  const handleAddSlides = () => {
    navigate("/slides");
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };
  
  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };
  return (
    <>
    <Col md={12} lg={12} 
    style={{
      justifyContent:'flex-end',
      display:'flex',
      alignItems:'flex-end',
      }}>
      {deleteing ? (
        <div className="justpadel-buttons btn-position" onClick={() => handleAddSlider()}>Add Slider</div>
      ) : (
        ""
      )}
    </Col>
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>
          <div className="hover-effect table-id-width">
            <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
            />
          </div>
          
        </CardBody>
      </Card>
    </Col>
    </>
  );
};
export default SlideList;
