import React from 'react'
import SidebarCategory from '../SidebarCategory';
import SidebarLink from '../SidebarLink';

const PaymentSidebar = () => {
    return (
        <SidebarCategory title="Payment" icon="cart">
            <SidebarLink title="Transaction's" route="/transactions" />
            <SidebarLink title="Quick Pay" route="/quick-pay-details" />
            <SidebarLink title="Gift Card" route="/e-giftcard" />
            <SidebarLink title="Tournaments" route="/tournaments" />
            <SidebarLink title="Kids Camp" route="/kids-summer-camp" />
        </SidebarCategory>
    )
}

export default PaymentSidebar;