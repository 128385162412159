import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import axios from "../../customAxios";
import { adminEndPoints } from '../../endPoints/AdminEndPoints';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TransactionModel from '../../shared/components/TransactionModel';
import BookingListTemplate from './components/BookingListTemplate';
import ReactPaginate from 'react-paginate';
import InvoiceModalV2 from '../../shared/components/InvoiceModalV2';
import { GetMainCourt, GetRegisteredUser } from '../Reuse/ApiCalls';
import LoadingConponent from '../../shared/components/LoadingConponent';

const TransactionsList = () => {
    // columns
    const transactionListDataHeaders = [
        {
            Header: '#',
            accessor: 'id',
            disableGlobalFilter: true,
            width: 30,
        },
        {
            Header: 'ID',
            accessor: 'bookingId',
            disableGlobalFilter: true,
            width: 40,
        },
        {
            Header: 'Booking Date',
            accessor: 'bookingDate',
            disableGlobalFilter: true,
            width: 120,
        },
        {
            Header: 'Client name',
            accessor: "firstName",
            disableGlobalFilter: true,
            width: 140,
        },
        {
            Header: 'Item',
            accessor: "bookedSlots",
            disableGlobalFilter: true,
            width: 200,
        },
        {
            Header: 'Amount / Paid With',
            accessor: "amountPaid",
            disableGlobalFilter: true,
            width: 150,
        },
        {
            Header: 'Payment Status',
            accessor: 'paymentStatus',
            disableGlobalFilter: true,
            width: 100,
        },
        {
            Header: 'Actions',
            accessor: 'transactionLogAction',
            disableGlobalFilter: true,
            width: 100
        },
    ];

    // initial filter for the transactions lists
    const initialFilter = {
        booking: '',
        booking_date_after: '',
        booking_date_before: '',
        created_at_after: '',
        created_at_before: '',
        main_court: '',
        padel_court: '',
        phone_no: '',
        username: '',
        page: 1,
        page_size: 10
    };

    // Reusable template functions
    const idTemplate = (data) => data;

    const bookingIdTemplate = (data) => (
        <span className="bookingId created-date right-space">
            {"#" + data?.booking?.booking_id}
        </span>
    );

    const bookingDateTemplate = (data) => (
        <div className="created-date right-space">
            {data?.booking?.booking_date}
            <br />
            {data?.booking?.timeslot.split('-')[0]}
        </div>
    );

    const clientNameTemplate = (data) => (
        <div className="right-space">
            <div>{data?.user?.first_name === "null" ? "" : data?.user?.first_name}</div>
            <div className="userEmail">{data?.user?.email}</div>
            <div className="userPhone">{data?.user?.phone_no === "null" ? " " : data?.user?.phone_no}</div>
        </div>
    );

    const mainCourtTemplate = (data) => (
        <div className="right-space">
            <BookingListTemplate
                booked_slots={data?.booked_slots}
                addonsbookedlist={data?.addonsbookedlist}
            />
        </div>
    );

    const amountPaidTemplate = (data) => (
        <div className="right-space">
            <div className="amountPaid">
                {data?.booking?.amount_paid?.toFixed(2) + " AED"}
                <span className="amountPaidPayform"> / {data?.booking?.payment_processor}</span>
            </div>
            <div className="paid-book__using">
                {data?.booking?.amount_percentage === 0.5 ? <h3 className="amountPaidPaidHalf">50% Paid</h3> : data?.booking?.amount_percentage === 1 ? <h3 className="amountPaidPaid">Paid</h3> : " "}
                <span className="booked-using">{data?.booking?.boooked_using}</span>
                {/* <Promotion bookedCourt={data?.booking?.booking_id} /> */}
            </div>
            <div>
                <span className={data?.booking?.coupan_applied === "" ? "" : "amountPaidCoupanCode"}>
                    {data?.booking?.coupan_applied}
                </span>
            </div>
        </div>
    );

    const paymentStatusTemplate = (data) => (
        <div className="right-space">
            <div className="payment-status">
                {moment(data?.booking?.updated_at.split('T')[0]).format("DD-MM-YYYY")}
                <br />
                {moment(data?.booking?.updated_at.split('T')[1].split('.')[0], "HH:mm:ss").format("hh:mm A")}
            </div>
            {data?.booking?.cancelled ? (
                <h3 className="amountStatusCancelled mt-1">Cancelled</h3>
            ) : (
                <h3 className="amountStatusCompleted">{data?.payment_status}</h3>
            )}
        </div>
    );

    const transactionLogActionTemplate = (data) => (
        <div className="right-space">
            <div className="dropdown trans-buttons">
                <TransactionModel color="primary" header title="Transaction Details" transactionId={data.id} />
                <InvoiceModalV2 color="primary" header title="Download Invoice" data={data} />
            </div>
        </div>
    );

    const { register, control, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const authslice = useSelector((state) => state.auth);
    const { pageLimits, padelCourtList } = useSelector((state) => state.config);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [transactiondData, setTransactiondData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [initialPage, setInitialPage] = useState(0);
    const [transactionFilter, setTransactionFilter] = useState(initialFilter);
    const [paginationKey, setPaginationKey] = useState(0);
    const [registerdUser, setRegisterdUser] = useState([]);
    const [mainCourt, setMainCourt] = useState([]);
    const [mainCourtId, setMainCourtId] = useState('');
    const [mainCourtPadelCourtList, setMainCourtPadelCourt] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // table config
    const tableConfig = {
        isEditable: false,
        isResizable: true,
        isSortable: true,
        withPagination: true,
        withSearchEngine: true,
        manualPageSize: [1000, 2000, 3000, 4000],
        placeholder: 'Search by Name...',
    };

    const onSubmit = (filterData) => {
        filterData.mainCourtId = mainCourtId;
        setTransactionFilter({ ...transactionFilter, ...filterData, page: 1 });
    };

    const clear = () => {
        reset();
    };

    const clearFilter = () => {
        reset();
        setTransactionFilter(initialFilter);
    };

    const handleEmailChange = (e) => {
        console.log("handleEmailChange", e.target.value);
    };

    const mainCourtChangeHandler = (e) => {
        if (!e.target.value) return;
        const filteredMainCourt = mainCourt?.filter(item => item["courtName"] === e.target.value);
        setMainCourtId(filteredMainCourt[0]?.id);
        const padelcourtsListBasedMC = padelCourtList.filter((padelcourt) => padelcourt.maincourt.id === filteredMainCourt[0]?.id);
        setMainCourtPadelCourt(padelcourtsListBasedMC);
    };

    const filteredMainCourt = mainCourt.filter((data) => (data.id !== 12));

    const gettransactions = async (payload) => {
        try {
            setIsLoading(true);
            let mainCourtIdFilter;
            const Role = authslice.loggedAdminRole;
            switch (Role) {
                case 'brightlearner':
                    mainCourtIdFilter = 12;
                    break;
                case 'brightonadmin':
                    mainCourtIdFilter = 11;
                    break;
                case 'alnoor':
                    mainCourtIdFilter = 13;
                    break;
                default:
                    mainCourtIdFilter = payload?.mainCourtId;
            };
            delete payload.mainCourtId;
            // Add the main_court filter to the base filter
            const filterPayload = { ...payload, main_court: mainCourtIdFilter };
            const bookingListPaginatedFilterResponse = await axios.get(adminEndPoints.userTransactionLogsPagination, { params: filterPayload });
            const usersTransactionsData = bookingListPaginatedFilterResponse.data.results.map((data, index) => {
                return {
                    id: idTemplate(index + 1),
                    bookingId: bookingIdTemplate(data),
                    bookingDate: bookingDateTemplate(data),
                    firstName: clientNameTemplate(data),
                    bookedSlots: mainCourtTemplate(data),
                    amountPaid: amountPaidTemplate(data),
                    paymentStatus: paymentStatusTemplate(data),
                    transactionLogAction: transactionLogActionTemplate(data)
                }
            });
            setTransactiondData(usersTransactionsData);
            setPageCount(Math.ceil(bookingListPaginatedFilterResponse.data.count / payload?.page_size));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("error----->", error);
        };
    };

    const handlePageClick = (pageclicked) => {
        setTransactionFilter({ ...transactionFilter, page: pageclicked.selected + 1 });
    };

    const handleLimitChange = (limit) => {
        setInitialPage(0);
        setPaginationKey((prevKey) => prevKey + 1);
        setTransactionFilter({ ...transactionFilter, page_size: limit, page: 1 });
    };

    const downloadTransactionExcel = async () => {
        try {
            axios({
                url: adminEndPoints.excelReportSearch,
                method: 'GET',
                responseType: 'blob',
                params: transactionFilter
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `TransactionLog.csv`);
                document.body.appendChild(link);
                link.click();
            });
        } catch (error) {
            console.log("error------->", error);
        };
    }

    useEffect(() => {
        gettransactions(transactionFilter);
    }, [transactionFilter]);

    useEffect(() => {
        GetRegisteredUser().then((response) => {
            const results = response.map((item) => {
                return {
                    ...item,
                    label: item.first_name,
                    value: item.id
                }
            });
            setRegisterdUser(results);
        });
        GetMainCourt().then((response) => {
            setMainCourt(response);
        });
    }, []);

    return (
        <Container className="dashboard">
            <Row>
                <Col sm={12} md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <h3>Transaction Log's</h3>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className={`${isLoading ? 'mb-2 mt-2' : 'mt-2'}`}>
                <Col sm={12} md={12} lg={12}>
                    <Card className=''>
                        <CardBody className={`${isLoading ? 'mt-0' : ''}`}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row className='align-items-end'>
                                    <Col sm={10} md={10} lg={10}>
                                        <div className="form form__form-group">
                                            <div className="form__form-group-field d-flex">
                                                <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                                                    <span>Booking Id</span>
                                                    <input
                                                        type="text"
                                                        className="custom-css-dp"
                                                        {...register("booking", { required: false })}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                                                    <span>Booking Date</span>
                                                    <input
                                                        type="date"
                                                        className="custom-css-dp"
                                                        {...register("booking_date_after", { required: false })}
                                                    />
                                                    <input
                                                        type="date"
                                                        className="custom-css-dp mt-1"
                                                        {...register("booking_date_before", { required: false })}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                                                    <span>Payment Date</span>
                                                    <input
                                                        type="date"
                                                        className="custom-css-dp"
                                                        {...register("created_at_after", { required: false })}
                                                    />
                                                    <input
                                                        type="date"
                                                        className="custom-css-dp mt-1"
                                                        {...register("created_at_before", { required: false })}
                                                        placeholder='j'
                                                    />
                                                </div>
                                                <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                                                    <span>Select User</span>
                                                    <input name="user_name" list="user_names" id="user_name" className="filter-name" placeholder="Name" {...register("username")} />
                                                    <datalist id="user_names">
                                                        {registerdUser.map((data) => {
                                                            return (
                                                                <option>{data.first_name}</option>
                                                            )
                                                        })}
                                                    </datalist>
                                                    <input list="emails" type="email" name="email" id="email" className="filter-client-email" placeholder="Email" {...register("user")} onChange={handleEmailChange} onClick={clear} />
                                                    <datalist id="emails">
                                                        {registerdUser.map((data) => {
                                                            return (
                                                                <option data-value={data.id}>{data.email}</option>
                                                            )
                                                        })}
                                                    </datalist>
                                                </div>
                                                <div className='filter-fields Padel-court-feilds'>
                                                    <input list="main_courts" name="main_court" id="main_court" className="filter-main-court" placeholder="Main Court" {...register("main_court")} onClick={clear} onChange={mainCourtChangeHandler} />
                                                    <datalist id="main_courts">
                                                        {filteredMainCourt.map((data) => {
                                                            return (<option data-value={data.id}>{data.courtName}</option>)
                                                        })}
                                                    </datalist>
                                                    <select className="trans-filter-service-provider mt-1" name="padel_court" {...register("padel_court")} style={{ marginRight: 0, borderColor: '#f2f4f7' }} >
                                                        <option value="">Select Padel Court</option>
                                                        {mainCourtPadelCourtList?.map((data) => {
                                                            return (
                                                                <option value={data.id}>{data.courtname}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={2} md={2} lg={2}>
                                        <Row>
                                            <Col sm={6} md={6} lg={6} className='px-1'>
                                                <Button size='sm' color="warning" className='m-1 w-100 px-2'>Apply</Button>
                                            </Col>
                                            <Col sm={6} md={6} lg={6} className='px-1'>
                                                <Button size='sm' color="secondary" className='m-1 w-100 px-2' onClick={clearFilter} >Clear</Button>
                                            </Col>
                                        </Row>
                                        <Button size='sm' color="warning" className='m-1 w-100' onClick={() => downloadTransactionExcel()}>Download as XLS</Button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {isLoading && <LoadingConponent message='Fetch Transaction Data ...' />}
            {!isLoading && <Row>
                <Col>
                    <Card>
                        <CardBody className='mb-4 mt-2'>
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    <div className="table-id-desabled pagination-hide hover-effect hide-filtername second-child-space">
                                        <ReactTableBase
                                            id="table-to-xls"
                                            key={withSearchEngine ? 'searchable' : 'common'}
                                            columns={transactionListDataHeaders}
                                            data={transactiondData}
                                            tableConfig={tableConfig}
                                        />
                                    </div>
                                    {<div className="custom-pagination">
                                        <div className="records-per-page">
                                            <select value={transactionFilter?.page_size} onChange={(e) => handleLimitChange(e.target.value)} name="limit" id="select-pagination">
                                                {pageLimits && pageLimits.map((pageLimits) => (
                                                    <option value={pageLimits}>
                                                        {pageLimits}
                                                    </option>
                                                ))}
                                            </select>&nbsp;&nbsp;&nbsp;records per page
                                        </div>
                                        <ReactPaginate
                                            key={paginationKey}
                                            previousLabel={"previous"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination justify-content-center"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            forcePage={initialPage}
                                        />
                                    </div>}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>}
        </Container>
    )
};

export default TransactionsList;