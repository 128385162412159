import { createSlice } from "@reduxjs/toolkit";

const userSliceInitialState = {
    selectedUserUpdateId : 0,
    selectedUserUpdateDetails : null,
};

export const UserSlice = createSlice({
  name: "userslice",
  initialState: userSliceInitialState,
  reducers: {
    setSelectedUserUpdateId: (state, action) => {
      state.selectedUserUpdateId = action.payload;
    },
    setSelectedUserUpdateDetails: (state, action) => {
      state.selectedUserUpdateDetails = action.payload;
    },
  },
})

export const { setSelectedUserUpdateId, setSelectedUserUpdateDetails } = UserSlice.actions;
export default UserSlice.reducer