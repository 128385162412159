import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';

const Dashboard = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Dashboard" icon="chart-bars">
            {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <SidebarLink title="Report" route="/report" />}
            {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <SidebarLink title="Bookings Report" route="/get-slotsbookingreport" />}
        </SidebarCategory>
    );
};

export default Dashboard;