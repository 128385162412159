import React, { useState, useEffect,useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../customAxios";
import { environment } from '../env';
import JoditEditor from "jodit-react";
import { Col, Button, ButtonToolbar, Card, CardBody } from "reactstrap";
import swal from '@sweetalert/with-react';


const EditPage = () => {
  let { name } = useParams();
  const [pageName, setPageName] = useState();
  const [pageContent, setPageContent] = useState();
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const navigate = useNavigate();


  const reset = () =>{
    navigate('/getpage')
  }
  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }

  const getPageDetails = () => {
    const editpageUrl = `${environment.baseUrl}getpage/${name}/`;
    axios
      .get(editpageUrl)
      .then((response) => {
        console.log("response", response.data);
        setContent(response.data.content);
        setPageName(response.data.name);
      })
      .catch((error) => {
        console.log("Error" + error);
      });
  }
  useEffect(() => {
    getPageDetails();
  }, []);


  const Submit = () => {
    console.log("page Name", pageName);
    console.log("page pageContent", pageContent);
    const pageData = {
      name:pageName,
      content:pageContent
    }
    console.log("pageData",pageData);
    const editPageURL = `${environment.baseUrl}getpage/${name}/`;
    console.log("editPageURL",editPageURL);
    axios.put(editPageURL,pageData)
    .then((response)=>{
      if(response.data.msg === "Page Updated Successfully"){
        swal({
          icon: "success",
          content: (<div>
            <h1 style={{ color: "#28a745" }}>Sucessfully</h1>
            <p>
              Data Updated successfully
            </p>
          </div>)
        }).then(() =>{
          navigate('/getpage');
        })
      }
    })
    .catch((error)=>{
      console.log(error);
    })
    
  }

  return (
    <div>
      <Col md={12} lg={12}>
      <Card>
        <CardBody>
        <div className="card__title">
          <h5 className="bold-text" style={{ color: "#acacac" }}>
          Edit User&apos;s Data Here
          </h5>
        </div>
        <form  className="form product-edit">
        <div className="form__half" style={{width: "calc(60% - 15px)",marginLeft: "20px"}}>
        <div className="form__form-group">
          <span className="form__form-group-label" style={{ fontSize: 14 }}>Enter Page Name</span>
          <div className="form__form-group-field" style={{marginBottom:15}}>
            <input type="text" name="pageName" value={pageName} onChange={(e) => setPageName(e.target.value)} readOnly />
          </div>
        </div>
            <JoditEditor
              ref={editor}
              value={content}
              // config={config}
              tabIndex={1} // tabIndex of textarea
              // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={newContent => setPageContent(newContent) }
            />
          <ButtonToolbar className="form__button-toolbar"   style={{ marginTop: 30 }}>
            <div className='justpadel-buttons-save' onClick={() => Submit()}>
              Save
            </div>
            <div className='justpadel-buttons-cancel' onClick={() => reset()}>
              Cancel
            </div>
          </ButtonToolbar>
        </div>
        </form>
        </CardBody>
      </Card>
    </Col>
  
       {/* <input type="text" name="pageName" value={pageName} onChange={(e) => setPageName(e.target.value)} readOnly />
      <JoditEditor
        ref={editor}
        value={content}
        // config={config}
        tabIndex={1} // tabIndex of textarea
        // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={newContent => setPageContent(newContent) }
      />
      <button onClick={() => Submit()}>Submit</button> */}
    </div>
  )
};

export default EditPage;
