import React, { useState, useEffect, useReducer, useMemo } from "react";
import { Row, Card, CardBody, Col, Spinner } from "reactstrap";

import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import CouponModal from "../../shared/components/CouponModal";
import CouponModalEdit from "../../shared/components/CouponModalEdit";
import ReactPaginate from "react-paginate";
import TransactionModel from "../../shared/components/TransactionModel";
import BookingList from "../Transactions/components/BookingList";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import axios from "../../customAxios";
import { environment } from ".././env";

const initialState = {
  pageLimits: 10,
};

function reducer(state, action) {
  switch (action.type) {
    case "pageLimits":
      return {
        ...state,
        pageLimits: parseInt(action.payload),
      };
    default:
      return {
        ...state,
      };
  }
}

const CoponUsedList = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [transactionLogs, setTransactionLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [isSortable, setIsSortable] = useState(true);
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [initialPage, setInitialPage] = useState(0);
  let navigate = useNavigate();

  const GetTransactionLog = (pageNo, pageLimit) => {
    dispatch({ type: "pageLimits", payload: pageLimit });
    setLoading(true);
    const transactionLog = `${environment.baseUrl}coupon_log?page=${pageNo}&page_size=${pageLimit}`;
    const result = axios(transactionLog)
      .then((response) => {
        console.log("response", response.data);
        setTransactionLogs(response.data.results);
        setpageCount(Math.ceil(response.data.count / pageLimit));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetTransactionLog(1, state.pageLimits);
  }, []);

  var couponLog = [];

  for (let i = 0; i < transactionLogs?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    //---------This is For Booking ID --------------
    item["booking_id"] = (
      <div className="bookingId right-space">
        {"#" + transactionLogs[i]?.booking?.booking_id}
      </div>
    );

    //---------This is For date and time --------------
    item["booking_date"] = (
      <div className="ceated-date right-space">
        {transactionLogs[i]?.booking?.coupan_applied}
      </div>
    );

    //---------This is For Client name --------------
    item["first_name"] = (
      <div className="right-space">
        <div>
          {transactionLogs[i]?.user?.first_name === "null"
            ? ""
            : transactionLogs[i]?.user?.first_name}
        </div>
        <div className="userEmail">{transactionLogs[i]?.user?.email}</div>
        <div className="userPhone">
          {transactionLogs[i]?.user?.phone_no === "null"
            ? " "
            : transactionLogs[i]?.user?.phone_no}
        </div>
      </div>
    );

    //-------------- This is For Item -----------------

    item["main_court"] = (
      <div className="right-space">
        <div className="item-data">
          <BookingList
            main_court={transactionLogs[i]?.booking?.main_court?.courtName}
            padel_court={transactionLogs[i]?.booking?.padel_court?.courtname}
            booking_id={transactionLogs[i]?.booking?.booking_id}
            className="item-data-more-or-less"
          />
        </div>
      </div>
    );

    //--------- This is Amount / Paid With--------------
    item["amount_paid"] = (
      <div className="right-space">
        <div className="amountPaid">
          {transactionLogs[i]?.booking?.amount_paid?.toFixed(2) + " AED"}
          <span className="amountPaidPayform"> / payfort</span>
        </div>
        <div>
          {transactionLogs[i]?.booking?.amount_percentage === 0.5 ? (
            <h3 className="amountPaidPaidHalf">50% Paid</h3>
          ) : transactionLogs[i]?.booking?.amount_percentage === 1 ? (
            <h3 className="amountPaidPaid">Paid</h3>
          ) : (
            " "
          )}
        </div>
        <div>
          <h3
            className={
              transactionLogs[i]?.booking?.coupan_applied === ""
                ? " "
                : "amountPaidCoupanCode"
            }
          >
            {transactionLogs[i]?.booking?.coupan_applied}
          </h3>
        </div>
      </div>
    );

    //---------This is Amount / Paid With--------------
    item["payment_status"] = (
      <div className="right-space">
        <div className="payment-status">
          {moment(transactionLogs[i]?.booking?.updated_at.split("T")[0]).format(
            "DD-MM-YYYY"
          )}
          <br />
          {moment(
            transactionLogs[i]?.booking?.updated_at.split("T")[1].split(".")[0],
            "HH:mm:ss"
          ).format("hh:mm A")}
        </div>
        <h3 className="amountStatusCompleted">
          {transactionLogs[i].payment_status}
        </h3>
      </div>
    );

    // item["transaction_log"] = <Button onClick={() => handleViewTransaction(transactionLogs[i].id)} color="primary" size="sm">View Details</Button>;
    item["transaction_log"] = (
      <div className="right-space">
        <div className="dropdown trans-buttons">
          <TransactionModel
            color="primary"
            header
            title="Transaction Details"
            transactionId={transactionLogs[i].id}
          />
          {/* <InvoiceModal color="primary" header title="Download Invoice" transactionId={transactionLogs[i].id} /> */}
          {/* <div className="downloadpdf" onClick={()=>printDocument()}>
              Download PDF
          </div> */}
        </div>
      </div>
    );

    couponLog.push(item);
  }

  const couponLogData = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "ID",
        accessor: "booking_id",
        disableGlobalFilter: true,
        width: 40,
      },
      {
        Header: "Coupon Code",
        accessor: "booking_date",
        disableGlobalFilter: true,
        width: 120,
      },
      {
        Header: "Client name",
        accessor: "first_name",
        disableGlobalFilter: true,
        width: 160,
      },
      {
        Header: "Item",
        accessor: "main_court",
        disableGlobalFilter: true,
        width: 160,
      },
      {
        Header: "Amount / Paid With",
        accessor: "amount_paid",
        disableGlobalFilter: true,
        width: 130,
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        disableGlobalFilter: true,
        width: 120,
      },
      {
        Header: "Actions",
        accessor: "transaction_log",
        disableGlobalFilter: true,
        width: 110,
      },
    ],
    rows: couponLog,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfigCouponLog = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [1000, 2000, 3000, 4000],
    placeholder: "Search by Name...",
  };

  async function handleLimitChange(e) {
    dispatch({ type: "pageLimits", payload: e.target.value });
    localStorage.setItem("TransactionListCurrentPageLimit", e.target.value);
    setInitialPage(0);
    GetTransactionLog(1, e.target.value);
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setInitialPage(data.selected);
    let newlimit = parseInt(localStorage.getItem("TransactionListCurrentPageLimit"));
    localStorage.setItem("TransactionListCurrentPage", currentPage);
    GetTransactionLog(currentPage, newlimit);
  };

  return (
    <Row>
      <Col sm={12} md={12} lg={12} className="mt-5">
        <h3 className="page-title">Coupons Booking List</h3>
      </Col>
      <Col sm={12} md={12} lg={12} className="mb-4">
        <Card>
          <CardBody>
            <div className="table-id-desabled pagination-hide hover-effect hide-filtername table-top-space">
              <ReactTableBase
                id="table-to-xls"
                key={withSearchEngine ? "searchable" : "common"}
                // columns={data.columns}
                // data={data.rows}
                columns={couponLogData.columns}
                data={couponLogData.rows}
                tableConfig={tableConfigCouponLog}
              />
            </div>
            <div className="custom-pagination">
              <div className="records-per-page">
                <select value={localStorage.getItem("TransactionListCurrentPageLimit")} onChange={handleLimitChange} name="limit" id="select-pagination">
                  {mannualLimits && mannualLimits.map((mannualLimits) => (
                    <option value={mannualLimits}>
                      {mannualLimits}
                    </option>
                  ))}
                </select>&nbsp;&nbsp;&nbsp;records per page
              </div>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={initialPage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CoponUsedList;
