import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../customAxios";
import { environment } from "../env";
import JoditEditor from "jodit-react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  Spinner
} from "reactstrap";
import swal from "sweetalert";
import { NewsGetAPI, UpdateNewsAPI } from "../Reuse/ApiCalls";
import S3FileUpload from "react-s3";
import { useForm, Controller } from "react-hook-form";

const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const EditNews = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [pageContent, setPageContent] = useState();
  const [spinner, setSpinner] = useState(false);

  const [btnInternalLink, setBtnInternalLink] = useState(false);
  const [btnExternalLink, setBtnExternalLink] = useState(false);
  const [btnRedirectLink, setBtnRedirectLink] = useState();

  const [IntBtnRedirectValue, setIntBtnRedirectValue] = useState();
  const [ExtBtnRedirectValue, setExtBtnRedirectValue] = useState();
  
  const [newsImage, setNewsImage] = useState();
  const { newsId } = useParams();
 
  const navigate = useNavigate();

  const NewsImage = watch("news_image");

  let objectUrl;
  if(NewsImage && NewsImage.length === 1){
    objectUrl = URL.createObjectURL(NewsImage[0]);
  };


   const handleBtnLinkSelect = (val)=>{
    setBtnInternalLink(false);
    setBtnExternalLink(false);

    if(val === "1"){
      setBtnInternalLink(true);
    }
    if(val === "2"){
      setBtnExternalLink(true);
    }
   }

  const GetNewsData =  () => {
    NewsGetAPI(newsId).then((newsResponse) => {
      setNewsImage(newsResponse.image);
      setContent(newsResponse?.content);
      reset(newsResponse);
      
      if (newsResponse.button_url.includes("http")){
        setBtnExternalLink(true);
        setExtBtnRedirectValue(newsResponse.button_url);
      } else {
        setBtnInternalLink(true);
        setIntBtnRedirectValue(newsResponse.button_url);
      }  
    });
  }

  const onSubmit = async (NewsData) => {
    console.log("NewsData",NewsData);

    setSpinner(true);
    NewsData.image = newsImage;
    NewsData.button_url = btnRedirectLink ;
    NewsData.content = pageContent;
    

    if (!NewsImage || NewsImage.length <= 0) {
      UpdateNewsAPI(newsId, NewsData).then((newsResponse) => {
        if (newsResponse.Message === "NEWS is updated successfully") {
          setSpinner(false)
          swal({
            title: "Success",
            text: 'News Updated  Successfully',
            icon: "success",
            button: "Ok",
          })
            .then(() => {
              navigate('/news-list');
            })
        }
      });
    } else {
      await S3FileUpload.uploadFile(NewsImage[0], config)
        .then((data) => {
          NewsData.image = data.location;
          UpdateNewsAPI(newsId, NewsData).then(
            (newsResponse) => {
              console.log("newsResponse", newsResponse);
              setSpinner(false)
              swal({
                title: "Success",
                text: 'News Updated  Successfully',
                icon: "success",
                button: "Ok",
              })
                .then(() => {
                  navigate('/news-list');
                })
            }
          );
        })
        .catch((err) => {
          console.log("Error Info: ", err);
        });
    }
  };

  useEffect(() => {
    GetNewsData();
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Edit NEWS</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="center-spinner">
                {spinner ? <Spinner className="table-fetch-spinner" /> : ''}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          News Title
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="NEWS Title"
                            name="title"
                            {...register("title", { required: true })}
                          />
                        </div>
                        {errors.title && <span className="error-msg">Please Enter News Title</span>}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                   {/* delete sub Title field */}
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Button Label
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Button Label"
                            name="button_label"
                            {...register("button_label", { required: true })}
                          />
                        </div>
                        {errors.button_label && (
                          <span className="error-msg">Please Enter Button Label</span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>

                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label links-container">
                          <input type="radio"  
                            className="redirectRadio"
                            onChange={() => handleBtnLinkSelect("1")}
                            checked={btnInternalLink && "checked"} 
                          />Button Internal Redirection
                          <input type="radio"  
                          className="redirectRadio" 
                          onChange={() => handleBtnLinkSelect("2")}
                          checked={btnExternalLink && "checked"}/> Button External Redirection
                        </span>
                       
                        {btnInternalLink && <div className="form__form-group-field">
                           <select className="form__form-group-field select__option"
                           name="button_url"
                           value={IntBtnRedirectValue}
                           onChange={ e => setBtnRedirectLink(e.target.value)}>
                             <option>Select Internal Redirection</option>
                             <option value="Home">Home</option>
                             <option value="booknow">Book Now</option>
                             <option value="ContactUsScreen">Contact Us</option>
                             <option value="packages">Packages</option>
                             <option value="offer">offer</option>
                             <option value="MyProfileScreen">Profile Page</option>
                           </select>
                        </div>}
                       
                         {btnExternalLink && 
                        <div className="form__form-group-field">
                          <input
                          type="text"
                          placeholder=" Button Redirect Link"
                          name="button_url"
                          value={ExtBtnRedirectValue}
                          onChange={ e => setBtnRedirectLink( e.target.value)}
                        />
                        </div>}
                        {errors.button_url && (
                          <span className="error-msg">Please Enter Redirect Link</span>
                        )}
                      </div>
                    </div>
                    </Col>
                </Row>

                <Row>
                  <Col sm={6} md={6} lg={6}>
                      <div className="form product-edit">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            News Image
                          </span>
                          
                          <div className="form__form-group-field news-image" >
                          <img src={objectUrl ? objectUrl: newsImage} alt={objectUrl ? objectUrl: newsImage} style={{ width: 100, borderRadius: 5, marginBottom: 10}}/>

                          {/* <input id="profilePic" type="file" name="news_image" onChange={onChangePicture}/> */}
                            <input
                              type="file"
                              placeholder="NEWS Title"
                              name="news_image"
                              {...register("news_image", { required: false })}
                            />
                          </div>
                          {errors.news_image && (
                            <span className="error-msg">Please Enter News Image</span>
                          )}
                        </div>
                      </div>
                    </Col>
                </Row>
                <Row className="w-100">
                  <Col sm={12} md={12} lg={12}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          News Content
                        </span>
                        <div className="form__form-group-fields">
                          <JoditEditor
                            ref={editor}
                            value={content}
                            tabIndex={1}
                            onChange={(newContent) =>
                              setPageContent(newContent)
                            }
                          />
                        </div>
                        {errors.news_content && (
                          <span className="error-msg">Please Enter News Content</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group mt-2">
                        <div className="form__form-group-field">
                          <Button type="submit" color="warning">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditNews;
