import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Calender from './components/Calender';

const CalenderPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className='mb-2'>
            <h3>Calender</h3>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      <Calender />
    </Row>
  </Container>
);

export default CalenderPage;
