import React, { useState } from 'react';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Button, Row } from 'reactstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { environment } from '../env';
import 'react-calendar/dist/Calendar.css';

const EmailConfiguration = () => {
    const fieldUser = 'Email Id';
    const [email, setEmail] = useState();
    const navigate = useNavigate();

    function handleUsernameChange(e) {
        setEmail(e.target.value);
    }

    const handleEmailVerify = () => {
        if (!email) {
            alert("Please enter email id");
        } else {
            const data = { email: email }
            localStorage.setItem('forgetEmail', email);
            const forgotPasswordUrl = `${environment.baseUrl}forgetpassword`;
            axios.post(forgotPasswordUrl, data)
                .then((response) => {
                    if (response.status === 200) {
                        navigate('verify_otp');
                    }
                    else {
                    }
                })
        }
    }

    return (
        <div className="account">
            <div className="account__wrapper">
                <div className="account__card">
                    <div className="account__head">
                        <h3 className="account__title">Welcome to
                            <span className="account__logo"> JUST
                                <span className="account__logo-accent"> PADEL</span>
                            </span>
                        </h3>
                        <h4 className="account__subhead subhead">Padel Courts of Choice</h4>
                    </div>
                    <Row>
                        <div className="form login-form">
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontFamily: 'sans-serif', fontSize: 15 }}>{fieldUser}</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <input
                                        name="username"
                                        component="input"
                                        placeholder={fieldUser}
                                        className="input-without-border-radius"
                                        onChange={handleUsernameChange}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group" style={{ marginTop: 30 }}>
                                <Button className="account__btn" color="warning" onClick={() => handleEmailVerify()}>Verify Email</Button>

                                <Link className="btn btn-outline-primary account__btn" to="/register" style={{ display: 'none' }}>Create
                                    Account
                                </Link>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default EmailConfiguration