// CouponBookingTransactionModel
import React, { useState } from 'react';
import { Button, CardBody, Modal, Card, Col, } from 'reactstrap';
import classNames from 'classnames';
import './TransationModal.css';
import { AiFillEye } from 'react-icons/ai';
import CouponBookingTransactionTab from './CouponBookingTransactionTab';


const CouponBookingTransactionModel = ({
    color, btn, colored, header, title, data
}) => {
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(prevState => !prevState);
    };

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    return (
        <div>
            <div className="modal-view-btn" onClick={toggle}>
                <AiFillEye style={{ fontSize: 20 }} />{btn}
            </div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass} transactionModalWidth`}
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody>
                            <div className="tabs tabs--justify tabs--bordered-bottom">
                                <CouponBookingTransactionTab transaction={data} />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Modal>
        </div>
    );
};

export default CouponBookingTransactionModel;
