import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ExampleCard from './components/NotifyMe';

const ExamplePage = () => (
  <Container className="dashboard">
    <Row>
      <Col sm={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <h3>Notify Me</h3>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className='mt-2'>
      <ExampleCard />
    </Row>
  </Container>
);

export default ExamplePage;
