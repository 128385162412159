import React, { useEffect, useState, Fragment } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import { useNavigate } from "react-router-dom";
import SidebarLink from "../sidebar/SidebarLink";
import moment from "moment";
import { environment } from "../../env";
import axios from "../../../customAxios";
import { GetMainCourt, GetUserDetailsByEmail, UpdateUserDetails } from "../../Reuse/ApiCalls";
import { logged, userLogout } from "../../../redux/slice/AuthSlice";
import { useSelector, useDispatch } from 'react-redux'
import { persistor } from "../../../redux/AppStore";
import { setAdminMainCourt } from "../../../redux/slice/GlobalConfigSlice";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = () => {
  const { mainCourtListAdmin } = useSelector((state) => state.config);
  const { loggedUser } = useSelector((state) => state.auth);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [loggedUSer, setLoggedUSer] = useState();
  const [mainCourtList, setMainCourtList] = useState([]);
  const [defaultCourt, setDefaultCourt] = useState();
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const filteredMainCourt = mainCourtListAdmin.length>0 ?  mainCourtListAdmin?.filter((data) => data.id !== 12) : [];

  const mainCourtUrl = `${environment.baseUrl}maincourts-admin/`;

  const handleDefaultMainCourt = async (e) => {
    const defaultSelectedCourt = e.target.value;
    setDefaultCourt(e.target.value);
    const userPayload = { ...userData, default_court: e.target.value };
    const Email = localStorage.getItem("Email");
    await UpdateUserDetails(Email, userPayload).then((response) => {
      if (response?.Message == "User Updated Successfully") {
        let user = JSON.parse(localStorage.getItem("loggedUserDetails"));
        user.default_court = parseInt(defaultSelectedCourt);
        localStorage.setItem("loggedUserDetails", JSON.stringify(user));
        const updatedLoggedUser = {
          ...loggedUser,
          default_court: parseInt(defaultSelectedCourt)
        };
        dispatch(logged(updatedLoggedUser));
      } else {
        console.log(response.data);
      }
    });
  };


  useEffect(() => {
    setLoggedUSer(localStorage.getItem("Email"));
  }, []);

  const logout = () => {
    localStorage.removeItem("Token");
    dispatch(userLogout());
    navigate("/log_in");
  };

  useEffect(() => {
    if (mainCourtListAdmin?.length == 0) {
      GetMainCourt().then((response) => {
        dispatch(setAdminMainCourt(response));
      });
    }
  }, [mainCourtListAdmin])

  return (
    <Fragment>
      <form>
        <div className="nav-courtContainer d-flex">
          <div className="default-title ">Default Location</div>
          <select
            className="select select-location mr-10"
            value={loggedUser?.default_court}
            onChange={handleDefaultMainCourt}
          >
            <option>Select Main Court</option>
            {filteredMainCourt &&
              filteredMainCourt.map((court, index) => (
                <option value={court.id} key={index}>{court.courtName}</option>
              ))}
          </select>
        </div>
      </form>

      <div className="topbar__profile">
        <button
          type="button"
          className="topbar__avatar"
          onClick={handleToggleCollapse}
        >
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{loggedUSer}</p>
          <DownIcon className="topbar__icon" />
        </button>

        {isCollapsed && (
          <button
            type="button"
            aria-label="button collapse"
            className="topbar__back"
            onClick={handleToggleCollapse}
          />
        )}

        <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
          <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" /> */}
            {/* <div className="topbar__menu-divider" /> */}
            <SidebarLink title="Log Out" icon="exit" onClick={logout} />
          </div>
        </Collapse>
      </div>
    </Fragment>
  );
};

export default TopbarProfile;
