import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, ButtonToolbar } from "reactstrap";
import { useForm } from "react-hook-form";
import S3FileUpload from "react-s3";
import { environment } from "../env";
import axios from "../../customAxios";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";

const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const EditRewards = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const rewardsImageUrls = register("image", { required: false });
  const [iconImageUrl, setIconImageUrl] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const { rewardId } = useParams();

  function handleChangeImageUpload(e) {
    setButtonDisabled(true);
    S3FileUpload.uploadFile(e.target.files[0], config)
      .then((data) => {
        setIconImageUrl(data.location);
        setButtonDisabled(false);
      })
      .catch((err) => {
        setButtonDisabled(false);
      });
  }

  const onSubmit = (data) => {
    data.image = iconImageUrl;
    const rewardsUrl = `${environment.baseUrl}rewards/${rewardId}/`;
    axios.put(rewardsUrl, data).then((response) => {
      if (response.data.status === "success") {
        swal({
          title: "Success",
          text: "Rewards is updated successfully!",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          navigate("/rewards-list");
        });
      }
    });
  };

  const GetRewardDetails = () => {
    const rewardsUrl = `${environment.baseUrl}rewards/${rewardId}/`;
    axios.get(rewardsUrl).then((response) => {
      reset({ title: response.data.title, description: response.data.description, sessions: response.data.sessions, bgcolor:response.data.bgcolor });
      setIconImageUrl(response.data.image);
    });
  };

  useEffect(() => {
    GetRewardDetails();
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={6}>
          <h3 className="page-title">Rewards</h3>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col sm="6">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Title</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        {...register("title", { required: true })}
                      />
                    </div>
                    {errors.title && (
                      <span className="error-msg">Title Is required</span>
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Sessions</span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        name="sessions"
                        className="form-control"
                        {...register("sessions", { required: true })}
                      />
                    </div>
                    {errors.sessions && (
                      <span className="error-msg">Session Is required</span>
                    )}
                  </div>
                  <div className="form__form-group">
                      <span className="form__form-group-label">Background Color</span>
                      <div className="form__form-group-field">
                        <input
                          type="color"
                          name="bgcolor"
                          className="form-control"
                          {...register("bgcolor", { required: true })}
                        />
                      </div>
                      {errors.bgcolor && (
                        <span className="error-msg">Background Color Is required</span>
                      )}
                    </div>
                </Col>
                <Col sm="6">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Description</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        {...register("description", { required: true })}
                      />
                    </div>
                    {errors.description && (
                      <span className="error-msg">Description Is required</span>
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Icon</span>
                    <div className="form__form-group-field">
                      <input
                        type="file"
                        {...rewardsImageUrls}
                        onChange={(e) => {
                          handleChangeImageUpload(e);
                          rewardsImageUrls.onChange(e);
                        }}
                        className="form-control"
                        name="image"
                      />
                    </div>
                    {errors.icon && (
                      <span className="error-msg">Icon Is required</span>
                    )}
                  </div>
                  <div className="form__form-group pt-5">
                      <ButtonToolbar className="form__button-toolbar">
                        <button
                          className={`${!buttonDisabled
                            ? "justpadel-buttons-save"
                            : "btn btn-outline-warning disabled text-dark"
                            } `}
                          type="submit"
                          disabled={buttonDisabled}
                        >
                          Submit
                        </button>
                      </ButtonToolbar>
                    </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default EditRewards;
