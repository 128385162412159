import React from 'react';
import { createRoot } from 'react-dom/client'; // Use createRoot in React 18
import App from './containers/App/App';
import store from './store';
import { Provider } from "react-redux";
import AppStore, { persistor } from './redux/AppStore';
import { PersistGate } from 'redux-persist/integration/react';
import { injectStore } from './customAxios';

const root = createRoot(document.getElementById('root'));
injectStore(AppStore);
root.render(
  <Provider store={AppStore}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);