import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminEndPoints } from "../../endPoints/AdminEndPoints";
import axios from "../../customAxios";

// thunk to get the data of all padelcourt 
export const getAllPadelCourtData = createAsyncThunk(
    'padelCourt/getAllPadelCourtData',
    async () => {
        try {
            const allPadelcourtResponse = await axios.get(adminEndPoints.padelcourt);
            return allPadelcourtResponse?.data
        } catch (error) {
            throw Error(error.response.data.message || error.message);
        }
    }
);