// CreateGroupModal
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import AddCoupon from '../../containers/CreateCoupon/components/AddCoupon';
import AddCouponGroup from '../../containers/CreateCoupon/components/AddCouponGroup';

const CreateGroupModal = ({
    color, btn, colored, header, title, getCoupon
}) => {

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(prevState => !prevState);
    };

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    const AddCoupanReload = () => {
        toggle();
    };

    return (
        <div>
            <Button color={color} onClick={toggle}>{btn}</Button>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass}`}
            >
                <div className="modal__header" style={{ backgroundColor: '#70bbfd' }}>
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <div>
                    <AddCouponGroup AddCoupanReload={AddCoupanReload} />
                </div>
            </Modal>
        </div>
    );
};

CreateGroupModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired,
};

CreateGroupModal.defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
};

export default CreateGroupModal;