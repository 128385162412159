import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { useForm, Controller } from "react-hook-form";
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { adminEndPoints } from '../../endPoints/AdminEndPoints';
import axios from '../../customAxios';
import moment from 'moment';
import { AiFillEye } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate';
import QuickPayModel from './QuickPayModel';

const QuickPayPaymentList = () => {
    const tableConfig = {
        isEditable: false,
        isResizable: true,
        isSortable: true,
        withPagination: true,
        withSearchEngine: true,
        manualPageSize: [1000, 2000, 3000, 4000],
        placeholder: "Search by Name...",
    };

    const columns = [
        {
            Header: '#',
            accessor: 'ids',
            disableGlobalFilter: true,
            width: 10,
        },
        {
            Header: '#',
            accessor: 'id',
            disableGlobalFilter: true,
            width: 10,
        },
        {
            Header: 'Name',
            accessor: 'first_name',
            disableGlobalFilter: true,
            width: 70,
        },
        {
            Header: 'Category',
            accessor: 'category',
            disableGlobalFilter: true,
            width: 65,
        },
        {
            Header: 'Location',
            accessor: 'location',
            disableGlobalFilter: true,
            width: 70,
        },
        {
            Header: 'Email',
            accessor: 'email',
            disableGlobalFilter: true,
            width: 100,
        },
        {
            Header: 'Phone No',
            accessor: 'phone_no',
            disableGlobalFilter: true,
            width: 75,
        },
        {
            Header: 'Amount',
            accessor: 'role',
            disableGlobalFilter: true,
            width: 50,
        },
        {
            Header: 'Created Time',
            accessor: 'date',
            disableGlobalFilter: true,
            width: 65,
        },

        {
            Header: 'Payment Status',
            accessor: 'payment_status',
            disableGlobalFilter: true,
            disableSortBy: true,
            width: 55,
        },
        {
            Header: 'View',
            accessor: 'view',
            disableGlobalFilter: true,
            disableSortBy: true,
            width: 30,
        }

    ];

    const defaultTemplate = (data) => (data);

    const idTemplate = (data, index) => (index + 1);

    const emailTemplate = (data) => (
        <div className="right-space">{data.email.toString()}</div>
    );

    const firstNameTemplate = (data) => (
        <div className="right-space">{data?.name === "null" ? '' : data?.name}</div>
    );

    const phoneNoTemplate = (data) => (
        <div className="right-space user-phone">{data.phone === "null" ? '' : data.phone}</div>
    );

    const roleTemplate = (data) => (
        <div className="right-space">{data.amount} AED</div>
    );

    const dateTemplate = (data) => (
        <div className="right-space">
            {moment(data.created_date).format("DD-MM-YYYY")}<br />
            {moment(data.created_date.split('T')[1], "HH:mm:ss").format("hh:mm A")}
        </div>
    );

    const categoryTemplate = (data) => (
        <div className="right-space">{data.subject}</div>
    );

    const locationTemplate = (data) => (
        <div className="right-space">{data.location}</div>
    );

    const paymentStatusTemplate = (data) => (
        <div className="right-space text-capitalize">
            {data.payment_status === "completed" ? (
                <h3 className="amountStatusCompleted">Completed</h3>
            ) : (
                <h3 className="amountStatusCancelled mt-1">Pending</h3>
            )}
        </div>
    );

    const viewTemplate = (data) => (
        <div className="right-space">
            <button className="modal-view-btn"
                onClick={() => OpenModal(data.transaction_log, data.additional_comments)}
            >
                <AiFillEye style={{ fontSize: 20, height: "19px", width: "19px" }} />
            </button>
        </div>
    );

    const { register, handleSubmit, reset, control, formState: { errors } } = useForm();
    const quickPayInitialFilter = {
        name: '',
        email: '',
        phone: '',
        payment_status: '',
        location: '',
        page: 1,
        page_size: 10
    };

    const [paymentFilter, setPaymentFilter] = useState(quickPayInitialFilter);
    const [quickPayPaymentData, setQuickPayPaymentData] = useState([]);
    const [pageCount, setpageCount] = useState(0);
    const [view, setView] = useState(false);
    const [transactionLog, setTransactionLog] = useState();
    const [category, setCategory] = useState();
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [paginationKey, setPaginationKey] = useState(0);

    const { pageLimits, mainCourtListAdmin } = useSelector((state) => state.config);

    const filterQuickPayPayment = (filterData) => {
        setPaymentFilter({ ...paymentFilter, ...filterData, page: 1 });
    };

    const quickPayPaymentList = async (payload) => {
        try {
            const quickpayPaginationResponse = await axios.get(adminEndPoints.quickPayPagination, { params: payload });
            const quickPayData = quickpayPaginationResponse.data.results.map((data, index) => {
                return {
                    id: idTemplate(data, ((paymentFilter?.page - 1) * paymentFilter?.page_size + index)),
                    email: emailTemplate(data),
                    first_name: firstNameTemplate(data),
                    phone_no: phoneNoTemplate(data),
                    role: roleTemplate(data),
                    date: dateTemplate(data),
                    category: categoryTemplate(data),
                    location: locationTemplate(data),
                    payment_status: paymentStatusTemplate(data),
                    view: viewTemplate(data)
                };
            });
            setpageCount(Math.ceil(quickpayPaginationResponse?.data?.count / paymentFilter?.page_size));
            setQuickPayPaymentData(quickPayData);
        } catch (error) {
            console.log("error--->", error);
        };
    };

    const handleLimitChange = (e) => {
        setPaginationKey((prevKey) => prevKey + 1);
        setPaymentFilter({ ...paymentFilter, page_size: parseInt(e.target.value), page: 1 });
    };

    const handlePageClick = (selectedPage) => {
        setPaymentFilter({ ...paymentFilter, page: parseInt(selectedPage.selected + 1) });
    };

    const clearFilters = () => {
        reset()
        setPaymentFilter({ ...paymentFilter, name: '', email: '', phone: '', payment_status: '', location: '' });
    };

    const OpenModal = (transactionLogData, paymentCategory) => {
        setView();
        setTransactionLog();
        setCategory();
        setView(!view);
        if (transactionLogData !== "No Transaction log") {
            setTransactionLog(JSON.parse(transactionLogData)[0]);
            setCategory(paymentCategory);
        } else {
            setTransactionLog({ "Data": "No Transaction log" })
        }
    };

    const CloseModal = () => {
        setView(false);
    };

    useEffect(() => {
        quickPayPaymentList(paymentFilter);
    }, [paymentFilter]);

    return (
        <Row>
            <Col md={12} sm={12} lg={12}>
                <Card>
                    <CardBody>
                        <h3>Quick Pay Payment List</h3>
                    </CardBody>
                </Card>
            </Col>
            <Col md={12} sm={12} lg={12} className='mt-2'>
                <QuickPayModel transactionLog={transactionLog} category={category} show={view} CloseModal={CloseModal} color="primary" header title="Transaction Details" />
                <Card>
                    <CardBody className="mb-4">
                        <form onSubmit={handleSubmit(filterQuickPayPayment)}>
                            <Row className="align-items-end justify-content-center">
                                <Col md={9} sm={9} lg={9}>
                                    <div className="form form__form-group">
                                        <div className="form__form-group-field d-flex">
                                            <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                                                <span>Name</span>
                                                <input
                                                    type="text"
                                                    className="custom-css-dp"
                                                    {...register("name", { required: false })}
                                                    placeholder='Name'
                                                />
                                            </div>
                                            <div className="d-flex flex-column w-100" style={{ marginLeft: '5px' }}>
                                                <span>Email</span>
                                                <input
                                                    type="text"
                                                    className="custom-css-dp"
                                                    {...register("email", { required: false })}
                                                    placeholder='Email'
                                                />
                                            </div>
                                            <div className="d-flex flex-column w-100" style={{ marginLeft: '5px' }}>
                                                <span>Phone</span>
                                                <input
                                                    type="text"
                                                    className="custom-css-dp"
                                                    {...register("phone", { required: false })}
                                                    placeholder='Phone'
                                                />
                                            </div>
                                            <div className="d-flex flex-column w-100" style={{ marginLeft: '5px' }}>
                                                <span>Location</span>
                                                <select className="select select-location" {...register("location", { required: false })}>
                                                    <option value="">Select Main Court</option>
                                                    {mainCourtListAdmin.length > 0 && mainCourtListAdmin.map((court, index) => (
                                                        <option key={index} value={court.courtName}>
                                                            {court.courtName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="d-flex flex-column w-100" style={{ marginLeft: '5px' }}>
                                                <span>Payment</span>
                                                <select className="select select-location" {...register("payment_status", { required: false })}>
                                                    <option value="">Select Payment Status</option>
                                                    {["Completed", "Pending"].map((status, index) => (
                                                        <option key={index} value={status}>
                                                            {status}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={3} md={3} lg={3} className='p-0'>
                                    <Row>
                                        <Col sm={6} md={6} lg={6} className='p-0 px-1'>
                                            <Button
                                                color="warning"
                                                size="sm"
                                                type='submit'
                                                className='m-0 w-100'
                                            >
                                                Search
                                            </Button>
                                        </Col>
                                        <Col sm={6} md={6} lg={6} className='p-0 px-1'>
                                            <Button
                                                color="secondary"
                                                size="sm"
                                                onClick={() => clearFilters()}
                                                className='m-0 w-100'
                                            >
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                        <Row>
                            <Col md={12} sm={12} lg={12}>
                                <div className="table-id-desabled pagination-hide hover-effect hide-filtername second-child-space">
                                    <ReactTableBase
                                        id="table-to-xls"
                                        responsive={true}
                                        key={withSearchEngine ? 'searchable' : 'common'}
                                        columns={columns}
                                        data={quickPayPaymentData}
                                        tableConfig={tableConfig}
                                    />
                                </div>
                            </Col>
                            <Col md={12} sm={12} lg={12}>
                                <div className="custom-pagination">
                                    <div className="records-per-page">
                                        <select
                                            onChange={handleLimitChange}
                                            name="limit"
                                            id="select-pagination"
                                            value={paymentFilter?.page_size || ''}
                                        >
                                            {pageLimits?.map((limit) => (
                                                <option key={limit} value={limit}>
                                                    {limit}
                                                </option>
                                            ))}
                                        </select>
                                        &nbsp;&nbsp;&nbsp;records per page
                                    </div>

                                    <ReactPaginate
                                        key={paginationKey}
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        forcePage={0}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default QuickPayPaymentList;