// const Unsuccess

import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Button, Spinner } from "reactstrap";

// import axios from "axios";
import axios from "../../customAxios";
import { environment } from "../env";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import { useNavigate, Link } from "react-router-dom";
import BookingList from "../Transactions/components/BookingList";
import "../Transactions/components/Transaction.css";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import InvoiceModal from "../../shared/components/InvoiceModal";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import {
  GetRegisteredUser,
  GetMainCourt,
  GetPadelCourt,
} from "../Reuse/ApiCalls";
import UnsucessTransactionModal from "./UnsucessTransactionModal";

const Unsuccess = () => {
  const [transactionLogs, setTransactionLogs] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [isActie, setIsActive] = useState(false);
  const [excelResult, setExcelResult] = useState([]);
  /* -----------------------------------------------------------------------States for page count---------------------------------------------------------------------------- */
  const [pageCount, setpageCount] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [searchData, setSearchData] = useState(false);
  const [mainCourtId, setMainCourtId] = useState("");
  const [userId, setUserId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [registerdUser, setRegisterdUser] = useState([]);
  const [mainCourt, setMainCourt] = useState([]);
  const [padelCourtList, setPadelCourtList] = useState();
  const [displayPagination, setDisplayPagination] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const filteredMainCourt = mainCourt.filter((data) => data.id !== 12);

  let limit;
  // = parseInt(localStorage.getItem("TransactionListCurrentPageLimit"))=== undefined || null ? parseInt(localStorage.getItem("TransactionListCurrentPageLimit")) : 10;
  if (
    localStorage.getItem("TransactionListCurrentPageLimit") === null ||
    undefined
  ) {
    limit = 10;
  } else {
    limit = parseInt(localStorage.getItem("TransactionListCurrentPageLimit"));
  }

  const getTransactionLog = (page, limit) => {
    const Role = localStorage.getItem("Role");
    let transactionLog;
    if (Role === "admin") {
      transactionLog = `${environment.baseUrl}unsucessfull-transactionlog/?page=${page}&page_size=${limit}`;
    } else if (Role === "brightlearner") {
      transactionLog = `${environment.baseUrl}unsucessfull-transactionlog/?page=${page}&page_size=${limit}&main_court=12`;
    } else if (Role === "brightonadmin") {
      transactionLog = `${environment.baseUrl}unsucessfull-transactionlog/?page=${page}&page_size=${limit}&main_court=11`;
    } else if (Role === "alnoor") {
      transactionLog = `${environment.baseUrl}unsucessfull-transactionlog/?page=${page}&page_size=${limit}&main_court=13`;
    } else {
      transactionLog = `${environment.baseUrl}unsucessfull-transactionlog/?page=${page}&page_size=${limit}`;
    }

    const result = axios(transactionLog)
      .then((response) => {
        setTransactionLogs(response?.data?.results);
        setpageCount(Math.ceil(response?.data?.count / limit));
        setExcelResult(response?.data?.results);
        setDisplayPagination(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fileteredMainCourt = mainCourt.filter((data) => data.id !== 12);

  useEffect(() => {
    localStorage.setItem("TransactionListCurrentPageLimit", 10);
    getTransactionLog(1, limit);
    GetRegisteredUser().then((response) => {
      setRegisterdUser(response);
    });

    GetMainCourt().then((response) => {
      setMainCourt(response);
    });
  }, []);

  var jsonObj = [];
  for (let i = 0; i < transactionLogs?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    //---------This is For Booking ID ---------------
    item["booking_id"] = (
      <div className="bookingId trans-left-space">
        {"#" + transactionLogs[i]?.booking?.booking_id}
      </div>
    );

    //---------This is For date and time -------------
    item["booking_date"] = (
      <div className="ceated-date right-space">
        {transactionLogs[i]?.booking?.booking_date}
        <br />
        {transactionLogs[i]?.booking?.timeslot.split("-")[0]}
      </div>
    );

    //---------This is For Client name ---------------
    item["first_name"] = (
      <div className="right-space">
        <div>
          {transactionLogs[i]?.user?.first_name === "null"
            ? ""
            : transactionLogs[i]?.user?.first_name}
        </div>
        <div className="userEmail">{transactionLogs[i]?.user?.email}</div>
        <div className="userPhone">
          {transactionLogs[i]?.user?.phone_no === "null"
            ? " "
            : transactionLogs[i]?.user?.phone_no}
        </div>
      </div>
    );

    //-------------- This is For Item -----------------
    item["main_court"] = (
      <div className="right-space">
        <div className="item-data">
          <BookingList
            main_court={transactionLogs[i]?.booking?.main_court?.courtName}
            padel_court={transactionLogs[i]?.booking?.padel_court?.courtname}
            booking_id={transactionLogs[i]?.booking?.booking_id}
            className="item-data-more-or-less"
          />
        </div>
      </div>
    );

    //--------- This is Amount / Paid With--------------
    item["amount_paid"] = (
      <div className="right-space">
        <div className="amountPaid">
          {transactionLogs[i]?.booking?.amount_paid?.toFixed(2) + " AED"}
          <span className="amountPaidPayform"> / payfort</span>
        </div>
        <div className="paid-book__using">
          {transactionLogs[i]?.booking?.amount_percentage === 0.5 ? (
            <h3 className="amountPaidPaidHalf">50% Paid</h3>
          ) : transactionLogs[i]?.booking?.amount_percentage === 1 ? (
            <h3 className="amountPaidPaid">Paid</h3>
          ) : (
            " "
          )}
          <span className="booked-using">
            {transactionLogs[i]?.booking?.boooked_using}
          </span>
        </div>
        <div>
          <span
            className={
              transactionLogs[i]?.booking?.coupan_applied === ""
                ? " "
                : "amountPaidCoupanCode"
            }
          >
            {transactionLogs[i]?.booking?.coupan_applied}
          </span>
        </div>
      </div>
    );

    //---------This is Amount / Paid With--------------
    item["payment_status"] = (
      <div className="right-space">
        <div className="payment-status">
          {moment(transactionLogs[i]?.booking?.updated_at.split("T")[0]).format(
            "DD-MM-YYYY"
          )}
          <br />
          {moment(
            transactionLogs[i]?.booking?.updated_at.split("T")[1].split(".")[0],
            "HH:mm:ss"
          ).format("hh:mm A")}
        </div>
        <h3 className="amountStatusCancelled">
          {transactionLogs[i].payment_status}
        </h3>
      </div>
    );

    // item["transaction_log"] = <Button onClick={() => handleViewTransaction(transactionLogs[i].id)} color="primary" size="sm">View Details</Button>;
    item["transaction_log"] = (
      <div className="right-space">
        <div className="dropdown trans-buttons">
          <UnsucessTransactionModal
            color="primary"
            header
            title="Transaction Details"
            transactionId={transactionLogs[i].booking.booking_id}
            clientDetails={transactionLogs[i].user}
            transactionLog={transactionLogs[i].transaction_log}
          />
          {/* <InvoiceModal color="primary" header title="Download Invoice" transactionId={transactionLogs[i].id} /> */}
        </div>
      </div>
    );

    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "ID",
        accessor: "booking_id",
        disableGlobalFilter: true,
        width: 40,
      },
      {
        Header: "Booking Date",
        accessor: "booking_date",
        disableGlobalFilter: true,
        width: 120,
      },
      {
        Header: "Client name",
        accessor: "first_name",
        disableGlobalFilter: true,
        width: 140,
      },
      {
        Header: "Item",
        accessor: "main_court",
        disableGlobalFilter: true,
        width: 160,
      },
      {
        Header: "Amount / Paid With",
        accessor: "amount_paid",
        disableGlobalFilter: true,
        width: 150,
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Actions",
        accessor: "transaction_log",
        disableGlobalFilter: true,
        width: 100,
      },
    ],
    rows: jsonObj,
  };
  const handleViewTransaction = (id) => {
    navigate(`transactions_details/${id}`);
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [1000, 2000, 3000, 4000],
    placeholder: "Search by Name...",
  };

  const handlePageClick = async (data) => {
    setSpinner(true);
    setTransactionLogs([]);
    let currentPage = data.selected + 1;
    setInitialPage(data.selected);
    let newlimit = parseInt(
      localStorage.getItem("TransactionListCurrentPageLimit")
    );
    localStorage.setItem("TransactionListCurrentPage", currentPage);
    if (!searchData) {
      setSpinner(false);
      getTransactionLog(currentPage, newlimit);
    } else {
      let data = JSON.parse(localStorage.getItem("transactionSearchdata"));
      const result = axios(
        `${environment.baseUrl}unsucessfull-transactionlog/?user=${data.email}&username=${data.user_name}&booking=${data.booking_id}&booking_date_after=${data.created_at_after}&booking_date_before=${data.created_at_before}&created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&phone_no=${data.phonenumber}&page=${currentPage}&page_size=${newlimit}&user_name=${data.user_name}`
      ).then((response) => {
        if (response?.data?.count > 0) {
          setTransactionLogs(response?.data?.results);
          setSpinner(false);
          setpageCount(Math.ceil(response?.data?.count / limit));
        } else {
          setSpinner(false);
          alert("Data is not available for search");
        }
      });
    }
  };

  const onSubmit = (data) => {
    setTransactionLogs([]);
    setSpinner(true);
    localStorage.setItem("transactionSearchdata", JSON.stringify(data));
    setSearchData(true);
    let page = 1;
    let pageSize = parseInt(
      localStorage.getItem("TransactionListCurrentPageLimit")
    );
    const Role = localStorage.getItem("Role");
    let SearchUrl;
    if (Role === "admin") {
      SearchUrl = `${environment.baseUrl}unsucessfull-transactionlog/?user=${data.email}&username=${data.user_name}&booking=${data.booking_id}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&phone_no=${data.phonenumber}&page=${page}&page_size=${pageSize}&user_name=${data.user_name}`;
    } else if (Role === "brightlearner") {
      SearchUrl = `${environment.baseUrl}unsucessfull-transactionlog/?user=${data.email}&username=${data.user_name}&booking=${data.booking_id}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&main_court=12&padel_court=${data.padel_court}&phone_no=${data.phonenumber}&page=${page}&page_size=${pageSize}&user_name=${data.user_name}`;
    } else if (Role === "brightonadmin") {
      SearchUrl = `${environment.baseUrl}unsucessfull-transactionlog/?user=${data.email}&username=${data.user_name}&booking=${data.booking_id}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&main_court=11&padel_court=${data.padel_court}&phone_no=${data.phonenumber}&page=${page}&page_size=${pageSize}&user_name=${data.user_name}`;
    } else if (Role === "alnoor") {
      SearchUrl = `${environment.baseUrl}unsucessfull-transactionlog/?user=${data.email}&username=${data.user_name}&booking=${data.booking_id}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&main_court=13&padel_court=${data.padel_court}&phone_no=${data.phonenumber}&page=${page}&page_size=${pageSize}&user_name=${data.user_name}`;
    } else {
      SearchUrl = `${environment.baseUrl}unsucessfull-transactionlog/?user=${data.email}&username=${data.user_name}&booking=${data.booking_id}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&phone_no=${data.phonenumber}&page=${page}&page_size=${pageSize}&user_name=${data.user_name}`;
    }
    // const SearchUrl = `${environment.baseUrl}unsucessfull-transactionlog/?user=${userId}&booking=${data.booking_id}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&phone_no=${data.phonenumber}&page=${page}&page_size=${pageSize}&user_name=${data.user_name}`;

    const result = axios.get(SearchUrl).then((response) => {
      setInitialPage(0);
      if (response?.data?.count > 0) {
        setSpinner(false);
        setTransactionLogs(response?.data?.results);
        setpageCount(Math.ceil(response?.data?.count / limit));
      } else {
        setSpinner(false);
        setTransactionLogs([]);
        setpageCount(Math.ceil(response?.data?.count / limit));
      }
    });
  };

  async function handleLimitChange(e) {
    localStorage.setItem("TransactionListCurrentPageLimit", e.target.value);
    setInitialPage(0);
    getTransactionLog(1, parseInt(e.target.value));
  }

  function handleEmailChange(e) {
    const filteredEmail = registerdUser?.filter(
      (item) => item["email"] === e.target.value
    );
    setUserId(filteredEmail[0]?.id);
  }

  const clear = (event) => {
    event.target.value = "";
    setUserId("");
  };

  function mainCourtChangeHandler(e) {
    const filteredMainCourt = mainCourt?.filter(
      (item) => item["courtName"] === e.target.value
    );
    setMainCourtId(filteredMainCourt[0]?.id);
    GetPadelCourt().then((response) => {
      const padelCourt = [];
      let padelCourts = response;
      let padelCourtFilter = padelCourts.filter(function (padelCourts) {
        return padelCourts.maincourt.id === filteredMainCourt[0]?.id;
      });
      padelCourtFilter.map((data) => {
        padelCourt.push({ id: data.id, courtname: data.courtname });
      });
      setPadelCourtList(padelCourt);
    });
  }

  const handleResetSearchForm = () => {
    reset({});
    setSearchData(false);
    localStorage.removeItem("transactionSearchdata");
    getTransactionLog(1, 10);
    setMainCourtId("");
  };

  const downloadTransactionExcel = () => {
    if (searchData) {
      let data = JSON.parse(localStorage.getItem("transactionSearchdata"));
      let page = 1;
      let pageSize = parseInt(
        localStorage.getItem("TransactionListCurrentPageLimit")
      );
      const SearchUrl = `${environment.baseUrl}transaction_log_excel_report_search?user=${data.email}&username=${data.user_name}&booking=${data.booking_id}&booking_date_after=${data.created_at_after}&booking_date_before=${data.created_at_before}&created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&phone_no=${data.phonenumber}&page=${page}&page_size=${pageSize}&user_name=${data.user_name}`;
      axios({
        url: SearchUrl,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `TransactionLog.csv`);
        document.body.appendChild(link);
        link.click();
      });
    } else {
      const SearchUrl = `${environment.baseUrl}transaction_log_excel_report`;
      axios({
        url: SearchUrl,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `TransactionLog.csv`);
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <div className="card__title bookings">
             
              <div className="center-spinner">
                {spinner ? <Spinner className="table-fetch-spinner" /> : ""}
              </div>
            </div>

            

            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withSearchEngine={withSearchEngine}
            />
          </div>
          <div className="table-id-desabled pagination-hide hover-effect hide-filtername table-top-space second-child-space">
            <ReactTableBase
              id="table-to-xls"
              key={withSearchEngine ? "searchable" : "common"}
              columns={data.columns}
              data={data.rows}
              tableConfig={tableConfig}
            />
          </div>

          {/* ----------------------------------------------------------------------  New Pagination code starts from here ---------------------------------------------- */}
          {displayPagination && (
            <div className="custom-pagination">
              <div className="records-per-page">
                <select
                  value={parseInt(
                    localStorage.getItem("TransactionListCurrentPageLimit")
                  )}
                  onChange={handleLimitChange}
                  name="limit"
                  id="select-pagination"
                >
                  {mannualLimits &&
                    mannualLimits.map((mannualLimits) => (
                      <option value={mannualLimits}>{mannualLimits}</option>
                    ))}
                </select>
                &nbsp;&nbsp;&nbsp;records per page
              </div>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={initialPage}
              />
            </div>
          )}
          {/* ----------------------------------------------------------------------  New Pagination code ends from here ---------------------------------------------- */}
        </CardBody>
      </Card>
    </Col>
  );
};
export default Unsuccess;
