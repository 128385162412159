/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import axios from '../../../customAxios';
import { environment } from "../../env";
import { useNavigate, useParams } from "react-router-dom";

const ExampleCard = () => {

  const [transactionDetails, setTransactionDetails] = useState();
  const [transactionLogs, setTransactionLogs] = useState();
  const [bookedSlots, setBookedSlots] = useState([]);
  const { id } = useParams();
  const transactionLog = `${environment.baseUrl}transaction_log/${id}`;
  const getTransactionLog = async () => {
    const result = await axios.get(transactionLog)
      .then((response) => {
        setTransactionDetails(response?.data);
        setTransactionLogs(JSON.parse(response?.data?.transaction_log)[0]);
        setBookedSlots(JSON.parse(response?.data?.booking?.booked_slots.replace(/'/g, '"')));
        // console.log("Transaction Details", response?.data);
        // console.log("Booking Slots Details", JSON.parse(response?.data?.booking?.booked_slots.replace(/'/g, '"')));
        // console.log("Transaction Log Details", JSON.parse(response.data.transaction_log)[0]);
      })
      .catch((error) => {
        console.log(error);
      })
  };
  useEffect(() => {
    getTransactionLog();
  }, []);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h3>Transaction {id} Details</h3>
          </div>
          <Row>
            <Col md={4}>
              <div className="card__title">
                <h3>User Details</h3>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>User ID:  </label> {' '}
                <label style={{fontSize:15}}>{transactionDetails?.user?.id === "null" || "" ? " " : transactionDetails?.user?.id}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>First Name:  </label>{' '}
                <label style={{fontSize:15}}>{transactionDetails?.user?.first_name === "null" ? " " : transactionDetails?.user?.first_name}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Last Name:  </label> {' '}
                <label style={{fontSize:15}}>{transactionDetails?.user?.last_name === "null" ? " " : transactionDetails?.user?.last_name}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Gender:  </label> {' '}
                <label style={{fontSize:15}}>{transactionDetails?.user?.gender === "null" ? " " : transactionDetails?.user?.gender}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Email:  </label> {' '}
                <label style={{fontSize:15}}>{transactionDetails?.user?.email === "null" ? " " : transactionDetails?.user?.email}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>No Of Games Played:  </label> {' '}
                <label style={{fontSize:15}}>{transactionDetails?.user?.no_of_games === "null" ? " " : transactionDetails?.user?.no_of_games}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Contact No:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionDetails?.user?.phone_no === "null" ? " " : transactionDetails?.user?.phone_no}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Address:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionDetails?.user?.address === "null" ? " " : transactionDetails?.user?.address}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>City:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionDetails?.user?.city === "null" ? " " : transactionDetails?.user?.city}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>State:  </label> {' '}
                <label style={{fontSize:15}}>{transactionDetails?.user?.state === "null" ? " " : transactionDetails?.user?.state}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Country:  </label> {' '}
                <label style={{fontSize:15}}>{transactionDetails?.user?.country === "null" ? " " : transactionDetails?.user?.country}</label>
              </div>
            </Col>
            <Col md={4}>
              <div className="card__title">
                <h3>Booking Details</h3>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Booking ID:</label> {' '}
                <label style={{fontSize:15}}>{transactionDetails?.booking?.id === "null" || "" ? " " : transactionDetails?.booking?.id}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Booking Date:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionDetails?.booking?.booking_date === "null" ? " " : transactionDetails?.booking?.booking_date}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Main Court:  </label> {' '}
                <label style={{fontSize:15}}>{transactionDetails?.booking?.main_court?.courtName === "null" ? " " : transactionDetails?.booking?.main_court?.courtName}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Padel Court:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionDetails?.booking?.padel_court?.courtname === "null" ? " " : "Court " + transactionDetails?.booking?.padel_court?.courtname}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Amount Paid:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionDetails?.booking?.amount_paid === "null" ? " " : transactionDetails?.booking?.amount_paid}</label>
              </div>
              <div  style={{display:'flex',justifyContent:'flex-start',flexDirection:'column'}}>
                <label style={{fontSize:15, fontWeight:'bold',}}>Booked Slot </label>{' '} 
                <label style={{fontSize:15}}>{transactionDetails?.booking?.booked_slots === "null" ? " " : bookedSlots.map((data, key) => {
                  return (
                    <>
                      <div>
                        <label style={{fontSize:15, fontWeight:'bold'}}>Date:  </label>{' '} 
                        <label style={{fontSize:15}}>{data?.date === "null" ? " " : data?.date}</label>
                      </div>
                      <div>
                        <label style={{fontSize:15, fontWeight:'bold'}}>Time:  </label>{' '} 
                        <label style={{fontSize:15}}>{data?.time === "null" ? " " : data?.time}</label>
                      </div>
                      <div>
                        <label style={{fontSize:15, fontWeight:'bold'}}>Main Court:  </label> {' '}
                        <label style={{fontSize:15}}>{data?.main_court === "null" ? " " : data?.main_court}</label>
                      </div>
                      <div>
                        <label style={{fontSize:15, fontWeight:'bold'}}>Padel Court:  </label>{' '} 
                        <label style={{fontSize:15}}>{data?.courtname === "null" ? " " : "Court "+ data?.courtname}</label>
                      </div>
                      <div>
                        <label style={{fontSize:15, fontWeight:'bold'}}>Price:  </label> {' '}
                        <label style={{fontSize:15}}>{data?.Price === "null" ? " " : data?.Price}</label>
                      </div>
                      <div>
                        <label style={{fontSize:15, fontWeight:'bold'}}>Ladies Only:  </label>{' '} 
                        <label style={{fontSize:15}}>{data?.ladiesOnly === "null" ? " " : data?.ladiesOnly}</label>
                      </div>
                    </>
                  )
                })}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Coupon Applied:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionDetails?.booking?.coupan_applied === "null" ? " " : transactionDetails?.booking?.coupan_applied}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Cancelled:  </label>{' '}
                <label style={{fontSize:15}}>{transactionDetails?.booking?.cancelled === false ? "Cancelled" : "Not Cancelled"}</label>
              </div>
            </Col>
            <Col md={4}>
              <div className="card__title">
                <h3>Transaction Log Details</h3>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Amount:  </label> {' '}
                <label style={{fontSize:15}}>{transactionLogs?.amount === "null" || "" ? " " : transactionLogs?.amount}</label> <label>{transactionLogs?.currency === "null" ? " " : transactionLogs?.currency}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Response Code:  </label>{' '}
                <label style={{fontSize:15}}>{transactionLogs?.response_code === "null" ? " " : transactionLogs?.response_code}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Card Number:  </label> {' '}
                <label style={{fontSize:15}}>{transactionLogs?.card_number === "null" ? " " : transactionLogs?.card_number}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Card Holder Name:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.card_holder_name === "null" ? " " : transactionLogs?.card_holder_name}</label>
              </div>
              <div style={{display:'flex', flexDirection:'row'}}>
                <label style={{fontSize:15, fontWeight:'bold'}}>Signature:  </label> {' '}
                <label style={{fontSize:15, width:200}}>{transactionLogs?.signature === "null" ? " " : transactionLogs?.signature}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Merchant Identifier:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.merchant_identifier === "null" ? " " : transactionLogs?.merchant_identifier}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Access Code:  </label> {' '}
                <label style={{fontSize:15}}>{transactionLogs?.access_code === "null" ? " " : transactionLogs?.access_code}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Expiry Date:  </label> {' '}
                <label style={{fontSize:15}}>{transactionLogs?.expiry_date === "null" ? " " : transactionLogs?.expiry_date}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Customer IP:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.customer_ip === "null" ? " " : transactionLogs?.customer_ip}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Language:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.language === "null" ? " " : transactionLogs?.language}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>ECI:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.eci === "null" ? " " : transactionLogs?.eci}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Fort Id:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.fort_id === "null" ? " " : transactionLogs?.fort_id}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Command:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.command === "null" ? " " : transactionLogs?.command}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Response Message:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.response_message === "null" ? " " : transactionLogs?.response_message}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Merchant Reference:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.merchant_reference === "null" ? " " : transactionLogs?.merchant_reference}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Authorization Code:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.authorization_code === "null" ? " " : transactionLogs?.authorization_code}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Currency:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.currency === "null" ? " " : transactionLogs?.currency}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Acquirer Response Code:  </label> {' '}
                <label style={{fontSize:15}}>{transactionLogs?.acquirer_response_code === "null" ? " " : transactionLogs?.acquirer_response_code}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Payment Option:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.payment_option === "null" ? " " : transactionLogs?.payment_option}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Customer Email:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.customer_email === "null" ? " " : transactionLogs?.customer_email}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Order Description:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.order_description === "null" ? " " : transactionLogs?.order_description}</label>
              </div>
              <div>
                <label style={{fontSize:15, fontWeight:'bold'}}>Status:  </label>{' '} 
                <label style={{fontSize:15}}>{transactionLogs?.status === "null" ? " " : transactionLogs?.status}</label>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
export default ExampleCard;
