import React from 'react'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'

const LoadingConponent = ({ message="Fetching Data ..." }) => {
    return (
        <Row>
            <Col>
                <Card>
                    <CardBody className='d-flex align-items-center'>
                        <Spinner className="table-fetch-spinner" />
                        <h4 className='mx-4'>{message}</h4>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
};

export default LoadingConponent;