import React, { useState } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import axios from '../../../customAxios';
import { environment } from "../../env";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';

const Miscellenious = () => {
  const [inputValues, setInputValues] = useState();
  const navigate = useNavigate();

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const miscURL = `${environment.baseUrl}misc`;
  const handleSubmit = () => {
    console.log("Inputs", inputValues);
    const result = axios
      .post(miscURL, inputValues)
      .then((response) => {
        swal({
          title: "SuccessFully Added Miscellenious",
          icon: "success",
        });
        console.log("Response", response.data);
        navigate("/misc-list");
      })
      .catch((error)=>{
        if (error) {
          swal("Oh noes!", "The Miscellenious Submission is failed!", "error");
        } else {
          swal.stopLoading();
          swal.close();
        }
      })
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <p>Your content here</p>
          <form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Task</span>
              <div className="form__form-group-field">
                <input type="text" name="task" id="task" onChange={handleOnChange} />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Value</span>
              <div className="form__form-group-field">
                <input type="text" name="value" id="value" onChange={handleOnChange} />
              </div>
            </div>
            <div className="form__form-group form__form-group--forgot">
              <span className="form__form-group-label">Slug</span>
              <div className="form__form-group-field">
                <input type="text" name="slug" id="slug" onChange={handleOnChange} />
              </div>
            </div>
            <div className="account__btns register__btns" style={{width:'100%',display:'flex',justifyContent:'flex-end',marginBottom:10}}>
            <div className='justpadel-buttons-save' onClick={() => handleSubmit()}>
              Submit
            </div>
          </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Miscellenious;
