import React, { useState, useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
// import axios from "axios";
import axios from '../../../customAxios';
import { useNavigate } from "react-router-dom";

import { environment } from "../../env";
import "./style.css";
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';

const UserRatings = () => {
  const [allRatings, setAllRatings] = useState([]);
  const navigate = useNavigate();
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const usersUrl = `${environment.baseUrl}ratingaverage`;
  // const usersUrl = 'http://127.0.0.1:8000/courtbooking';
  const getUser = async () => {
    const result = await axios(usersUrl);
    console.log(result.data);
    setAllRatings(result.data);
    console.log(result.data);
  };
  useEffect(() => {
    getUser();
  }, []);

  var jsonObj = [];
  console.log("NEw JSON ",jsonObj)
  for(let i=0; i<allRatings.length; i++){
    var item = {}
    item["id"] = i+1;

    item["main_court"] = <div className="right-space">{allRatings[i].maincourt.courtName}</div>;
    item["rating"] = <div className="right-space">{allRatings[i].rating + "/5"}</div>;
    item["email"] = <div className="right-space user-email">{allRatings[i].user.email}</div>;
    jsonObj.push(item);
}
  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Rating",
        accessor: "rating",
        disableGlobalFilter: true,
        width: 150,
      },
      {
        Header: "Main Court",
        accessor: "main_court",
        disableGlobalFilter: true,
        width: 210,
      },
      {
        Header: "User",
        accessor: "email",
        disableGlobalFilter: true,
        width: 200,
      },
    ],
    rows: jsonObj,
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };
  
  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>
          
          <div className="hover-effect table-id-width">
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
          />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserRatings;
