import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import { persistor } from '../../redux/AppStore';

const LogIn = () => {
  
  useEffect(() => {
    localStorage.clear();
    persistor.purge();
  }, []);

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Welcome to
              <span className="account__logo"> JUST
                <span className="account__logo-accent"> PADEL</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">Padel Courts of Choice</h4>
          </div>
          <LogInForm />
          <div className="account__or" style={{ display: 'none' }}>
            <p>Or Easily Using</p>
          </div>
          <div className="account__social" style={{ display: 'none' }}>
            <Link
              className="account__social-btn account__social-btn--facebook"
              to="/pages/one"
            >
              <FacebookIcon />
            </Link>
            <Link
              className="account__social-btn account__social-btn--google"
              to="/pages/one"
            >
              <GooglePlusIcon />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
