import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  Spinner
} from "reactstrap";
import S3FileUpload from "react-s3";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import {
  GetPackageById,
  PackagesPostAPI,
  UpdatePackageById,
} from "../containers/Reuse/ApiCalls";
import { useParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";

const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const EditPackages = () => {
  
  const { packageId } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [pageContent, setPageContent] = useState();
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const PackageImage = watch("packageImage");

  const onSubmit = async (PackageData) => {
    setSpinner(true)
    PackageData.offers = pageContent;
    PackageData.price = parseInt(PackageData.price);
    PackageData.slot = parseInt(PackageData.slot);
    PackageData.freeslot = parseInt(PackageData.freeslot);

    if (!PackageImage || PackageImage.length <= 0) {
      UpdatePackageById(packageId, PackageData).then(
        (updatedPackageResponse) => {
          console.log("updatedPackageResponse", updatedPackageResponse);
          if (updatedPackageResponse.Message === 'Package is updated successfully'){
            setSpinner(false)
            swal({
              title: "Success",
              text: 'News Updated  Successfully',
              icon: "success",
              button: "Ok",
            })
              .then(() => {
                navigate('/package-list');
                reset();         
              })
          }
        }
      );
    } else {
      await S3FileUpload.uploadFile(PackageImage[0], config)
        .then((data) => {
          PackageData.image = data.location;
          console.log("PackageData", PackageData);
          UpdatePackageById(packageId, PackageData).then(
            (updatedPackageResponse) => {
              console.log("updatedPackageResponse", updatedPackageResponse);
            }
          );
        })
        .catch((err) => {
          console.log("Error Info: ", err);
        });
    }
  };

  useEffect(() => {
    GetPackageById(packageId).then((packageResponse) => {
      setContent(packageResponse.offers);
      reset(packageResponse);
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Edit Packages </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
            <div className="center-spinner">
              {spinner ? <Spinner className="table-fetch-spinner" /> : ''}
            </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Title
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Package Title"
                            name="title"
                            {...register("title", { required: true })}
                          />
                        </div>
                        {errors.title && <span>This field is required</span>}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Slot
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="number"
                            placeholder="Package Slot"
                            name="slot"
                            {...register("slot", { required: true })}
                          />
                        </div>
                        {errors.slot && <span>This field is required</span>}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Free Slot
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="number"
                            placeholder="Free Slot"
                            name="freeslot"
                            {...register("freeslot", { required: true })}
                          />
                        </div>
                        {errors.freeslot && <span>This field is required</span>}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Price
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="number"
                            placeholder="Package Price"
                            name="price"
                            {...register("price", { required: true })}
                          />
                        </div>
                        {errors.price && <span>This field is required</span>}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Description
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Package Description"
                            name="description"
                            {...register("description", { required: false })}
                          />
                        </div>
                        {errors.description && (
                          <span className="error-msg">Please Insert Description </span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Image
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="file"
                            placeholder="Package Image"
                            name="packageImage"
                            {...register("packageImage", { required: false })}
                          />
                        </div>
                        {errors.image && <span>This field is required</span>}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col sm={12} md={12} lg={12}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Offer
                        </span>
                        <div className="form__form-group-fields">
                          <Controller
                            control={control}
                            name="offers"
                            render={({ field }) => (
                              <JoditEditor
                                ref={editor}
                                value={content}
                                tabIndex={1}
                                onChange={(newContent) =>
                                  setPageContent(newContent)
                                }
                              />
                            )}
                          />
                        </div>
                        {errors.packageoffer && (
                          <span>This field is required</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group mt-2">
                        <div className="form__form-group-field">
                          <Button type="submit" color="warning">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditPackages;
