/* eslint-disable no-alert */
import React from 'react';
import {
  Card,
  CardBody,
  Col, Container, Row,
} from 'reactstrap';
import BookingListCard from './components/BookingDetails';
import UsersBookingsList from './components/UsersBookingsList';

const BookingDetailsV2 = () => (
  <Container>
    <div className='mt-2'>
      <UsersBookingsList />
    </div>
  </Container>
);
export default BookingDetailsV2;
