import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';

const SettingSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Setting" icon="cog">
            {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <SidebarLink title="Calender" route="/calender" />}
        </SidebarCategory>
    );
};

export default SettingSidebar;