import React, { useState, useEffect } from "react";
// import axios from "axios";
import axios from "../../../customAxios";
import { environment } from "../../env";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";

const ExampleCard = () => {
  const [allCourts, setAllCourts] = useState();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getMainCourt = `${environment.baseUrl}padelcourt/${id}`;
    const sendGetRequest = async () => {
      try {
        const resp = await axios.get(getMainCourt);
        setAllCourts(resp.data);
        console.log(resp.data);
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    if (localStorage.getItem("Token")) {
      console.log("True");
    } else {
      alert("You are not logged In. Please LogIn");
      navigate("/");
    }
  }, []);
  
  return (
    <Row>
      <Col md={12}>
        <h3 className="page-title w-100">Details Of {allCourts?.maincourt?.courtName} Padel Court {allCourts?.courtname}</h3>
      </Col>

      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title"></div>
            <table
              style={{
                border: "2px solid #ccc",
                padding: 12,
                borderRadius: "10px",
                borderCollapse: "inherit",
              }}
            >
              <tbody style={{ fontSize: 15 }}>
                <tr>
                  <th style={{ padding: 10 }}>Image</th>
                  <img
                    src={allCourts?.courtimage}
                    alt={allCourts?.courtimage}
                    style={{ width: 150, borderRadius: 10 }}
                  />
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Court Address</th>
                  <td>{allCourts?.courtaddress}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Main Court</th>
                  <td>{allCourts?.maincourt.courtName}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Court Name</th>
                  <td>{allCourts?.courtname}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Court Display Name</th>
                  <td>{allCourts?.display_name}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Court Type</th>
                  <td>{allCourts?.courtType.courttype}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Free slots</th>
                  <td>{allCourts?.enable_free_slot ? "True" : "False"}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Ladies Only</th>
                  <td>{allCourts?.ladies_only ? "True" : "False"}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Ladies Time Slot</th>
                  <td>{allCourts?.ladies_time_slot}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Latitude</th>
                  <td>{allCourts?.latitude}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Longitude</th>
                  <td>{allCourts?.longitude}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>price</th>
                  <td>{allCourts?.price}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Addditional slot price</th>
                  <td>{allCourts?.additional_slot_price}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Rating</th>
                  <td>{allCourts?.rating}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10 }}>Timeslot</th>
                  <td>{allCourts?.timeslot}</td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ExampleCard;
