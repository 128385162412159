import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import MakingFileDownload from './MakingFileDownload';
import { getAllPadelCourtData } from '../../../redux/thunk/ConfigThunk';
import { getPaginatedFilteredBookingList } from '../../../redux/thunk/BookingListThunk';
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import moment from 'moment';
import axios from '../../../customAxios';
import { adminEndPoints } from '../../../endPoints/AdminEndPoints';
import BookingDetailsModal from '../../../shared/components/BookingDetailsModal';
import { CancelBooking, CancelCoacheBooking, CancelStaffBooking, GetMainCourt } from '../../Reuse/ApiCalls';
import { MdCancel, MdPayment } from 'react-icons/md';
import EditBookingModal from '../../../shared/components/EditBookingModal';
import swal from "@sweetalert/with-react";
import ReactPaginate from 'react-paginate';
import { setBookingListFilter } from '../../../redux/slice/BookingListSlice';
import { setAdminMainCourt } from '../../../redux/slice/GlobalConfigSlice';
import { environment } from '../../env';

const UsersBookingsList = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const { loggedUser } = useSelector((state) => state.auth);
  const { mainCourtListAdmin, padelCourtList, pageLimits } = useSelector((state) => state.config);
  const { bookingList, bookingListFilter, bookingListInitialFilter } = useSelector((state) => state.bookingList);
  const [registerdUser, setRegisterdUser] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [downloadFile, setDownloadFile] = useState(false);
  const [selectedMainCourt, setSelectedMainCourt] = useState(0);
  const [bookingLists, setBookingLists] = useState({});
  const [pageCount, setpageCount] = useState(0);
  const [initialPage, setInitialPage] = useState(0);

  const onSubmit = (filterData) => {
    dispatch(setBookingListFilter({ ...bookingListFilter, ...filterData }));
  };

  const mainCourtChangeHandler = (e) => {
    if (!e.target.value) {
      setSelectedMainCourt(0);
      return
    } else {
      const filteredMainCourtId = mainCourtListAdmin.length == 0 ? [] : mainCourtListAdmin?.filter((mc) => mc['courtName'] === e.target.value)[0]?.id;
      setSelectedMainCourt(filteredMainCourtId);
    }
  };

  const clear = () => {
    console.log("clear");
  };

  const filteredMainCourt = [];

  const handleEmailChange = () => {
    console.log("handleEmailChange");
  };

  const handleResetSearchForm = () => {
    reset();
    dispatch(setBookingListFilter({ ...bookingListInitialFilter }));
  };

  const DownloadAllBookingsReport = () => {
    const win = window.open("/download-excel", "_blank");
    win.focus();
  };

  const downloadExcel = () => {
    try {
      axios({
        url: adminEndPoints.bookingList,
        method: "GET",
        responseType: "blob",
        params: bookingListFilter,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `BookingList-${bookingListFilter?.page}To${bookingListFilter?.page_size}.csv`
        );
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log("error-->", error);
    }
  };

  // Templates 
  // Utility function to determine payment status
  const PaymentStatus = (availability, cancelled, payment_status) => {
    if (payment_status === "Failed") return "Timeout";
    if (availability || cancelled) return "Cancelled";
    return payment_status;
  };

  // Utility function to determine class name for status
  const getStatusClassName = (payment_status, availability) => {
    return (payment_status === "Completed" || payment_status === "completed") && !availability
      ? "active-color-bg"
      : "canceled-color-bg";
  };

  // Template for displaying created date
  const createdDateTemplate = (data) => (
    <div className="Booking-details-td-right-space">
      {data?.created_at.split("T")[0]}{" "}
      {moment(data?.created_at.split("T")[1], "HH:mm:ss").format("hh:mm A")}
    </div>
  );

  // Template for displaying booking date and time
  const bookingDataTimeTemplate = (data) => (
    <div className="Booking-details-td-right-space">
      {data?.booking_date} <br />
      {data?.timeslot}
    </div>
  );

  // Template for displaying service provider details
  const serviceProviderTemplate = (data) => (
    <div className="Booking-details-td-right-space">
      {data?.main_court.courtName + " -"}
      <br />
      {"COURT " + data?.padel_court?.courtname}
    </div>
  );

  // Template for displaying client details
  const clientTemplate = (data) => (
    <div className="Booking-details-td-right-space">
      <>
        {data?.booked_by?.customer_name}<br />
        <span className="user-email">{data?.booked_by?.customer_email}</span><br />
        <span className="user-phone">{data?.booked_by?.customer_phone}</span>
      </>
    </div>
  );

  // Template for displaying payment status
  const paymentStatusTemplate = (data) => {
    const { availability, cancelled, payment_status, boooked_using } = data;
    const statusClass = getStatusClassName(payment_status, availability);

    return (
      <div className="Booking-details-td-right-space">
        <div className={`active-color-bg ${statusClass}`}>
          <span>{PaymentStatus(availability, cancelled, payment_status)}</span>
        </div>
        <span className={data?.user?.role !== "customer" ? "admin-bg" : "app-bg"}>
          {boooked_using}
        </span>
      </div>
    );
  };


  // Template to show user details

  const viewDetailsTemaplte = (data) => (
    <div style={{ display: "flex" }} className="booking-action-column" >
      <BookingDetailsModal
        color="primary"
        header
        title="Booking Details"
        userdetails={data?.user}
        transactionId={data?.booking_id}
        bookedUsing={data.boooked_using}
        cancelBoooking={() =>
          CancelBookings(data.booking_id, data.logs)
        }
      />
      {data?.availability ? (
        <div
          className="modal-delete-btn"
          style={{ marginTop: 5, marginLeft: 5 }}
          onClick={() => handleConfirmPayment(data?.booking_id)}
        >
          <MdPayment style={{ fontSize: 17 }} />
        </div>
      ) : (
        <div
          className="modal-delete-btn"
          style={{ marginTop: 5, marginLeft: 5 }}
          onClick={() =>
            CancelBookings(data.booking_id, data.logs)
          }
        >
          <MdCancel style={{ fontSize: 17 }} />
        </div>
      )}
      <EditBookingModal
        color="primary"
        header
        title="Edit Booking"
        transactionId={data?.booking_id}
        refreshData={refreshDetailsData}
      />
    </div>
  );


  const CancelBookings = (bookingid, logs) => {
    let newLog;
    if (logs === "") {
      let AdminUserName = localStorage.getItem("AdminUserName");
      newLog = JSON.stringify([
        {
          created_or_edited_by: "Cancelled by user " + AdminUserName + "",
          created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
          created_or_edited_time: moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),
        },
      ]);
    } else {
      const prevLog = JSON.parse(logs);
      let AdminUserName = localStorage.getItem("AdminUserName");
      newLog = JSON.stringify([
        {
          created_or_edited_by: "Cancelled by user " + AdminUserName + "",
          created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
          created_or_edited_time: moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),
        },
        ...prevLog,
      ]);
    }
    swal({
      title: "Are you sure?",
      text: "You want to Cancel the Booking",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        CancelStaffBooking(bookingid);
        CancelCoacheBooking(bookingid);
        CancelBooking(bookingid, newLog).then((response) => {
          if (response.Message === "Booking Cancelled!!!") {
            swal({
              icon: "success",
              content: (
                <div>
                  <h1>Booking Cancelled</h1>
                </div>
              ),
            }).then(() => {
              refreshDetailsData();
            });
          }
        });
      } else {
        swal("Booking is not cancelled");
      }
    });
  };

  const refreshDetailsData = () => {
    getPaginatedFilteredBookingListData(bookingListFilter);
  };

  const bookingListColumns = [
    {
      Header: "id",
      accessor: "id",
      disableGlobalFilter: false,
      width: 65,
    },
    {
      Header: "ID",
      accessor: "booking_id",
      disableGlobalFilter: true,
      width: 45,
    },
    {
      Header: "Created Date",
      accessor: "created_date",
      disableGlobalFilter: true,
      width: 140,
    },
    {
      Header: "Booking Date",
      accessor: "booking_date_time",
      disableGlobalFilter: true,
      width: 140,
    },
    {
      Header: "Service Provider",
      accessor: "service_provider",
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: "Client",
      accessor: "client",
      disableGlobalFilter: true,
      width: 180,
    },
    {
      Header: "Payment Status",
      accessor: "status",
      disableGlobalFilter: true,
      width: 140,
    },

    {
      Header: "View Details",
      accessor: "view",
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 150,
    },
  ];

  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [100, 200, 300, 400],
    placeholder: "Search by Name...",
  };

  const getPaginatedFilteredBookingListData = async (payload) => {
    try {
      const bookingListPaginatedFilterResponse = await axios.get(adminEndPoints.bookingList, { params: payload });

      const bookingResults = bookingListPaginatedFilterResponse?.data?.results.map((data) => {
        return {
          ...data,
          created_date: createdDateTemplate(data),
          booking_date_time: bookingDataTimeTemplate(data),
          service_provider: serviceProviderTemplate(data),
          client: clientTemplate(data),
          status: paymentStatusTemplate(data),
          view: viewDetailsTemaplte(data)
        };
      });

      const returnData = {
        ...bookingListPaginatedFilterResponse?.data,
        results: bookingResults,
      };

      setBookingLists(returnData);
      setpageCount(Math.ceil(bookingListPaginatedFilterResponse?.data?.count / bookingListFilter?.page_size));
      return returnData;
    } catch (error) {
      console.log("error------>", error);
    };
  };


  const handlePageClick = (selectedPage) => {
    dispatch(setBookingListFilter({ ...bookingListFilter, page: selectedPage?.selected + 1 }))
  };

  const handleLimitChange = (e) => {
    dispatch(setBookingListFilter({ ...bookingListFilter, page_size: parseInt(e.target.value) }))
  };

  const handleConfirmPayment = (bookingId) => {
    try {
      swal({
        title: "Are you sure?",
        text: "You want to Confirm Payment for the Booking",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((res) => {
        if (res) {
          confirmFailedPayment(bookingId)
        } else {
          console.log("close");
        };
      })
    } catch (error) {
      console.log("error---1");
    };
  };

  const confirmFailedPayment = (bookingId) => {
    try {
      console.log("Booking ID for payment confirmation:", bookingId);
      const confirmPaymentURL = `${environment.baseUrl}make-payment-success`;
  
      // Send the request to confirm the payment
      axios.post(confirmPaymentURL, { bookingId })
        .then((response) => {
          console.log("Response:", response);
  
          // If the payment confirmation was successful
          if (response.data.status === 'success') {
            swal({
              title: "Payment Confirmed",
              text: `Payment has been successfully confirmed for Booking ID: ${bookingId}`,
              icon: "success",
              buttons: true, // This will show the default "OK" button
              dangerMode: false,
            }).then(() => {
              // Refresh the booking list after successful payment confirmation
              getPaginatedFilteredBookingListData(bookingListFilter);
            });
          } else {
            // If the payment confirmation failed
            swal({
              title: "Payment Confirmation Failed",
              text: `There was an error confirming the payment for Booking ID: ${bookingId}. Please try again.`,
              icon: "error",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          // Handle network or other errors
          console.error("Error confirming payment:", error);
          swal({
            title: "Payment Confirmation Error",
            text: `There was an unexpected error while confirming the payment for Booking ID: ${bookingId}. Please try again later.`,
            icon: "error",
            buttons: true,
            dangerMode: true,
          });
        });
    } catch (error) {
      // Catch any errors in the try block
      console.error("Error in confirmFailedPayment:", error);
      swal({
        title: "Error",
        text: "An unexpected error occurred while confirming the payment. Please try again later.",
        icon: "error",
        buttons: true,
        dangerMode: true,
      });
    }
  };
  

  useEffect(() => {
    if (padelCourtList.length === 0) {
      dispatch(getAllPadelCourtData());
    };
    if (mainCourtListAdmin?.length === 0) {
      GetMainCourt().then((response) => {
        dispatch(setAdminMainCourt(response));
      });
    }
    getPaginatedFilteredBookingListData(bookingListFilter);
  }, [bookingListFilter]);

  useEffect(() => {
    getPaginatedFilteredBookingListData(bookingListInitialFilter);
  }, []);
  
  return (
    <Row>
      <Col sm={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col sm={8} md={8} lg={8}>
                <h3>Bookings List</h3>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col sm={12} md={12} lg={12} className='mt-2'>
        <Card>
          <CardBody className='mb-4'>
            <div className="react-table__wrapper">
              <div className="card__title bookings">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="filter-fields">
                    <input
                      type="number"
                      className="filter-code"
                      name="booking_id"
                      placeholder="Id"
                      {...register("booking_id")}
                    />
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        width: "13.5%",
                        marginRight: 7,
                      }}
                    >
                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        {...register("created_at_after")}
                        name="created_at_after"
                        className="filter-created"
                        placeholder="From"
                      />
                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        name="created_at_before"
                        className="filter-created"
                        {...register("created_at_before")}
                        style={{ marginTop: 6 }}
                        placeholder="To"
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        width: "13.5%",
                        marginRight: 7,
                      }}
                    >
                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        className="filter-date"
                        name="booking_date_after"
                        {...register("booking_date_after")}
                        placeholder="From"
                      />

                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        className="filter-date"
                        name="booking_date_before"
                        {...register("booking_date_before")}
                        placeholder="To"
                        style={{ marginTop: 6 }}
                      />
                    </div>
                    <input
                      list="main_courts"
                      name="main_court"
                      id="main_court"
                      className="filter-service"
                      placeholder="Main Court"
                      {...register("main_court")}
                      onChange={mainCourtChangeHandler}
                      onClick={clear}
                    />
                    {mainCourtListAdmin.length > 0 && <datalist id="main_courts">
                      {mainCourtListAdmin?.map((data) => {
                        return (
                          <option data-value={data.id}>{data.courtName}</option>
                        );
                      })}
                    </datalist>}
                    <select
                      className="filter-service-provider"
                      name="padel_court"
                      {...register("padel_court")}
                    >
                      <option value="">Padel Court </option>
                      {padelCourtList?.filter((pc) => pc?.maincourt?.id == selectedMainCourt).map((data) => {
                        return (
                          <option value={data.id}>{data.courtname}</option>
                        );
                      })}
                    </select>
                    <input
                      list="emails"
                      type="email"
                      name="email"
                      id="email"
                      className="filter-client"
                      placeholder="Email"
                      {...register("email")}
                      onChange={handleEmailChange}
                    />
                    <datalist id="emails">
                      {registerdUser.map((data) => {
                        return (
                          <option data-value={data.id}>{data.email}</option>
                        );
                      })}
                    </datalist>
                    <input
                      list="payment_status"
                      name="payment_status"
                      id="payment"
                      className="filter-service"
                      placeholder="Payment Status"
                      {...register("payment_status")}
                    />
                    <datalist id="payment_status">
                      <option value="">Select Payment Status</option>
                      <option data-value="Completed">Completed</option>
                      <option data-value="Pending">Pending</option>
                      <option data-value="Failed">Failed</option>
                    </datalist>
                    <div className="btns-container">
                      <div
                        className="clear-btn"
                        onClick={() => handleResetSearchForm()}
                      >
                        Clear
                      </div>
                      <button
                        className="apply-btn"
                        type="submit"
                      >
                        Apply
                      </button>
                      <div className="center-spinner">
                        {spinner && (
                          <Spinner className="table-fetch-spinner" />
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {(loggedUser?.role == "admin" || loggedUser?.role == "manager") && <div className="dowloadbtn">
                <div>
                  <div className="download-booking-table-xls-button-all" color="warning" onClick={() => DownloadAllBookingsReport()}>
                    Download Booking Report
                  </div>
                </div>
                {downloadFile && <MakingFileDownload />}
                <div className="download-booking-table-xls-button" onClick={() => downloadExcel()}>
                  Download as XLS
                </div>
              </div>}
            </div>
            {/* code for listing booking */}
            <div className="table-id-desabled pagination-hide hide-filtername hover-effect hide-table-costomizer second-child-space">
              <ReactTableBase
                id="table-to-xls"
                responsive={true}
                key={"common"}
                columns={bookingListColumns}
                data={bookingLists?.results}
                tableConfig={tableConfig}
              />
            </div>
            {/* Code for paginations */}
            <div className="custom-pagination">
              <div className="records-per-page">
                <select
                  onChange={handleLimitChange}
                  name="limit"
                  id="select-pagination"
                  value={bookingListFilter?.page_size || ''}
                >
                  {pageLimits?.map((limit) => (
                    <option key={limit} value={limit}>
                      {limit}
                    </option>
                  ))}
                </select>
                &nbsp;&nbsp;&nbsp;records per page
              </div>

              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={initialPage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default UsersBookingsList