import { faCalendar, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { FiEdit } from 'react-icons/fi';
import { RiFileEditLine, RiPencilLine, RiUser3Line } from 'react-icons/ri';
import { Col } from 'reactstrap'
import { environment } from '../../../env';
import axios from '../../../../customAxios';
import moment from 'moment';
import { FaMoneyBillWave, FaRegComments } from 'react-icons/fa';
import { AiOutlineEye } from 'react-icons/ai';
import CommentModal from '../../../../shared/components/CommentModal';
import swal from "@sweetalert/with-react";

const BookingDetailTabPane = ({ bookingId, toggle, cancelBoooking, toggletab, bookingDetails, displayComment, GetBookingDetails, SplitPoints }) => {

    const [showAllComments, setShowAllComments] = useState(true);
    const [showCommentModal, setshowCommentModal] = useState(false);

    const StartandEndTime = (data) => {
        const startTimes = data?.booked_slots[0]?.time?.split("-")[0];
        const endsTimes = data?.booked_slots.slice(-1)[0]?.time?.split("-")[1];

        let startTime = moment(startTimes, "hh:mm A");
        let endTime = moment(endsTimes, "hh:mm A");

        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'days');
        }

        const duration = moment.duration(endTime.diff(startTime));
        const hours = parseInt(duration.asHours());
        const minutes = parseInt(duration.asMinutes()) % 60;

        const hoursToPlay = `${hours} hr . ${minutes} mins.`;

        return {
            startEndTime: `${startTimes} - ${endsTimes}`,
            hoursToPlay: hoursToPlay,
        };
    };


    const toggleShowAllComments = () => {
        setShowAllComments(!showAllComments);
    };

    const ShowModal = () => {
        setshowCommentModal(!showCommentModal);
    };

    const commentsToDisplay = showAllComments ? displayComment : [displayComment[0]];

    const HandleCompleted = () => {
        try {
            swal({
                icon: "success",
                title: "Points Already Splited",
                content: (
                    <div>
                        <h2 style={{ color: "#28a745", fontWeight: "bold" }}>
                            Split points already shared with players!
                        </h2>
                        <p style={{ fontSize: "12px", color: "#4a4a4a" }}>
                            The points have been successfully distributed to all the players.
                            No further action is needed at this time.
                        </p>
                    </div>
                ),
                buttons: true,
                timer: 5000
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {bookingDetails && <> <div className="detail-modal-container">
                <div className="inner-modal-container">
                    <div className="container-lhs">
                        <div className="time-date-container">
                            <div className="court-name">
                                <div className="court-name-edit">
                                    <RiUser3Line className="user-icon" />
                                    <h5 className="court-name-text">
                                        {bookingDetails.main_court.courtName} - Court {bookingDetails.padel_court.courtname}
                                    </h5>
                                    <FiEdit className="user-edit-icon" />
                                </div>
                            </div>

                            <div className="calender-time-date">
                                <div className="calender-icon">
                                    <FontAwesomeIcon
                                        icon={faCalendar}
                                        className="cal-icon"
                                    />
                                    <h4>{bookingDetails.booking_date}</h4>
                                </div>
                                <h4>{StartandEndTime(bookingDetails).startEndTime}</h4>
                            </div>
                            <div className="calender-time-date">
                                <div className="calender-icon">
                                    <FontAwesomeIcon
                                        icon={faHourglassHalf}
                                        className="cal-icon"
                                    />
                                    <h4 className="cal-time">{StartandEndTime(bookingDetails).hoursToPlay}</h4>
                                </div>
                                <h4>( Service duration: 45 mins. )</h4>
                            </div>
                        </div>
                    </div>
                    <div className="container-rhs">
                        <div className="payment-details">
                            <label className="payment-details-text">
                                Invoice date:{" "}
                            </label>{" "}
                            {"  "}
                            <label className="payment-details-result">
                                {moment(
                                    bookingDetails?.created_at.split("T")[0]
                                ).format("DD-MM-YYYY")}{" "}
                                {moment(
                                    bookingDetails?.created_at
                                        .split("T")[1]
                                        .split(".")[0],
                                    "HH:mm:ss"
                                ).format("hh:mm A")}
                            </label>
                        </div>
                        <div className="payment-details">
                            <label className="payment-details-text">Due date: </label>{" "}
                            {"  "}
                            <label className="payment-details-result">
                                {" "}
                                {moment(bookingDetails?.booking_date).format(
                                    "DD-MM-YYYY"
                                )}{" "}
                                {bookingDetails?.timeslot.split("-")[0]}
                            </label>
                        </div>
                        <div className="payment-details">
                            <label className="payment-details-text">Status:</label>{" "}
                            {bookingDetails?.cancelled ? (
                                <label className="payment-details-result">Cancelled</label>
                            ) : bookingDetails?.packages > 0 ? (
                                <label className="payment-details-result">Paid by Packages</label>
                            ) : bookingDetails?.boooked_using === "admin" ? (
                                <label className="payment-details-result">{bookingDetails?.payment_processor}</label>
                            ) : (bookingDetails?.amount_percentage === 1 || bookingDetails?.amount_percentage == 100) ? (
                                <label className="payment-details-result">Paid 100%</label>
                            ) : (
                                <label className="payment-details-result">Paid 50%</label>
                            )}
                        </div>
                        <div className="payment-details">
                            <label className="payment-details-text">
                                Payment received:{" "}
                            </label>{" "}
                            {"  "}
                            <label className="payment-details-result">
                                {bookingDetails?.payment_status === "Failed"
                                    ? "0.00"
                                    : bookingDetails?.amount_paid.toFixed(2)}{" "}
                                AED
                            </label>
                        </div>
                        <div className="payment-details">
                            <label className="payment-details-text">Platform:</label>{" "}
                            {"  "}
                            <label
                                className="payment-details-result"
                                style={{ textTransform: "capitalize" }}
                            >
                                {" "}
                                {bookingDetails?.boooked_using}
                            </label>
                        </div>
                        <div className="payment-details">
                            <label className="payment-details-text">Category:</label>{" "}
                            {"  "}
                            <label
                                className="payment-details-result"
                                style={{ textTransform: "capitalize" }}
                            >
                                {bookingDetails?.booking_category}{" "}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
                {bookingDetails.promotion_used && <div className="d-flex">
                    <img src="https://padelgram-media.s3.ap-south-1.amazonaws.com/promotion-verified.png" alt=" Promotion Used Image" style={{ height: '25px', width: '25px' }} />
                    <label style={{ fontSize: 17, fontWeight: "bold", marginLeft: "10px" }}>Promotions Used</label>
                </div>}
                {bookingDetails.coach_or_staff_booked && <div style={{ marginBottom: "15px" }}>
                    <div className="user-datails-lhs">
                        <h5>Coach/Staff Details</h5>
                    </div>
                    <div className="innerContainer">
                        <div>
                            <img
                                src={bookingDetails.booked_coach_or_staff_details[0].coaches.image}
                                alt="Coach/Staff Image"
                                style={{
                                    height: "80px",
                                    width: "80px",
                                    borderRadius: "70px",
                                    marginRight: "20px",
                                }}
                            />
                        </div>

                        <div style={{ alignself: "center" }}>
                            <text className="username">{bookingDetails.booked_coach_or_staff_details[0].coaches.name}</text>

                            <div className="nationalty">
                                <text
                                    className="details"
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {bookingDetails.booked_coach_or_staff_details[0].coaches.nationality}
                                    {" | "}{" "}
                                </text>
                                <text style={{ color: "#4B4B4B" }}>
                                    {bookingDetails?.booked_coach_or_staff_details[0].coaches.languages &&
                                        JSON.parse(bookingDetails?.booked_coach_or_staff_details[0].coaches.languages).map((language, index) => {
                                            return (
                                                <text
                                                    key={index}
                                                    className="details"
                                                    style={{
                                                        textTransform: "capitalize",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    {index > 0 ? ", " : " "}
                                                    {language?.value}
                                                </text>
                                            );
                                        })}
                                </text>
                            </div>

                            <div>
                                <text className="pricedetails">
                                    AED {(bookingDetails?.booked_coach_or_staff_details[0].coaches.Pricing).toFixed(2)}
                                </text>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="booking-id">
                    <label style={{ fontSize: 17, fontWeight: "bold" }}>
                        Booking ID:
                    </label>
                    <label style={{ fontSize: 17, fontWeight: "bold" }}>
                        &nbsp;&nbsp;#{bookingId}
                    </label>
                </div>

                <div className="comment-container">
                    <div className="comment-container-inner my-2">
                        {!bookingDetails.logs == "" && JSON.parse(bookingDetails.logs).map((data, index) => {
                            return (
                                <>
                                    <div className="user-comments">
                                        <RiFileEditLine className="editFile-comments-icon" />
                                        <div className="edited-user">
                                            <span>{data?.created_or_edited_by}</span>
                                            <span>
                                                {data?.created_or_edited_date}{" "}
                                                {data?.created_or_edited_time}
                                            </span>
                                        </div>
                                    </div>
                                    {index !== JSON.parse(bookingDetails.logs).length - 1 && (
                                        <hr className="edit-middle-line" />
                                    )}
                                </>
                            );
                        })}
                    </div>

                    {commentsToDisplay?.map((comment) => {
                        return (
                            <div className='d-flex justify-content-between align-items-lg-center py-2 px-4 my-1 rounded' style={{ backgroundColor: '#f8f8f8' }}>
                                <div>
                                    <FaRegComments className="comment-Icon" />
                                    <span className='font-weight-normal'>{comment.comment}</span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span className='font-weight-light form__form-group-description'>{comment?.date}</span>
                                    <span className='font-weight-light form__form-group-description'>{comment?.time}</span>
                                    <span className='font-weight-light form__form-group-description'>{comment?.user ? " - Created by " + comment?.user : ""}</span>
                                </div>
                            </div>
                        )
                    })}

                    <div className='d-flex justify-content-start align-items-lg-center'>
                        {showAllComments ? (<div className='d-flex justify-content-between align-items-lg-center'>
                            <AiOutlineEye className="eye-icon" />
                            <span className="show-all-text" onClick={() => toggleShowAllComments()}>
                                Hide All Comments
                            </span>
                        </div>) :
                            (<div className='d-flex justify-content-between align-items-lg-center'>
                                <AiOutlineEye className="eye-icon" />
                                <span className="show-all-text " onClick={() => toggleShowAllComments()}>
                                    Show All Comments
                                </span>
                            </div>)}
                        {!bookingDetails?.cancelled && (<div className='d-flex justify-content-between align-items-lg-center'>
                            <RiPencilLine className="pencil-icon" />{" "}
                            <span className="show-all-text" onClick={ShowModal}>
                                Comments
                            </span>
                        </div>)}
                    </div>

                    <CommentModal
                        userCommentModal={showCommentModal}
                        bookingId={bookingId}
                        commentRefresh={GetBookingDetails}
                        header
                        color="primary"
                        title="Booking Comment"
                    />

                </div>
                <Col md={12} lg={12}>
                    <div className="d-flex justify-content-between">
                        <div>
                            {bookingDetails?.boooked_using === "admin" && bookingDetails?.booking_category === "Bookings" && (
                                <button
                                    className="btn btn-success"
                                    onClick={() => {
                                        if (bookingDetails?.loyaltypoint_eligibility) {
                                            SplitPoints();
                                        } else {
                                            HandleCompleted();
                                        }
                                    }}
                                >
                                    {bookingDetails?.loyaltypoint_eligibility ? "Complete" : "Completed"}
                                </button>
                            )}
                        </div>
                        <div>
                            <button className="btn-close-booking" onClick={toggle}>
                                Close
                            </button>
                            <button
                                className="btn-cancel-booking"
                                onClick={() => cancelBoooking(bookingId, bookingDetails?.logs ? JSON.parse(bookingDetails?.logs) : [])}>
                                Cancel Booking
                            </button>
                            <button className="btn-edit" onClick={() => toggletab("4")}>
                                Edit
                            </button>
                        </div>
                    </div>
                </Col>
            </>}
        </>
    )
}

export default BookingDetailTabPane