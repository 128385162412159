import React, { useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  Spinner
} from "reactstrap";
import S3FileUpload from "react-s3";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import { PackagesPostAPI } from "../containers/Reuse/ApiCalls";
import { reset } from "redux-form";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const Package = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [pageContent, setPageContent] = useState();
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const PackageImage = watch("image");


  const onSubmit = async (PackageData) => {
    setSpinner(true)

    PackageData.offers = pageContent;
    PackageData.price = parseInt(PackageData.price);
    PackageData.slot = parseInt(PackageData.slot);
    // PackageData.freeslot = parseInt(PackageData.free_slot)

    await S3FileUpload.uploadFile(PackageImage[0], config)
      .then((data) => {
        PackageData.image = data.location;
        console.log("PackageData", PackageData);
        PackagesPostAPI(PackageData).then((newsPostResponse) => {
          console.log("newsPost666^^^^^Response", newsPostResponse);
          if (newsPostResponse.Message === 'Package is saved successfully') {
            setSpinner(false)
            swal({
              title: "Success",
              text: 'News Updated  Successfully',
              icon: "success",
              button: "Ok",
            })
              .then(() => {
                navigate('/package-list');
                reset();
              })
          }
        })
      })
      .catch((err) => {
        console.log("Error Info: ", err);
      });
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Add Packages</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="center-spinner">
                {spinner ? <Spinner className="table-fetch-spinner" /> : ''}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Title
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Package Title"
                            name="title"
                            {...register("title", { required: true })}
                          />
                        </div>
                        {errors.title && (
                          <span className="error-msg">Please Insert Package Title</span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Slot
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="number"
                            placeholder="Package Slot"
                            name="slot"
                            {...register("slot", { required: true })}
                          />
                        </div>
                        {errors.slot && (
                          <span className="error-msg">Please Insert Package Slot</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Free Slot
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="number"
                            placeholder="Free Slot"
                            name="free_slot"
                            {...register("free_slot", { required: true })}
                          />
                        </div>
                        {errors.free_slot && (
                          <span className="error-msg">Please Insert Free Slot</span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Price
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="number"
                            placeholder="Package Price"
                            name="price"
                            {...register("price", { required: true })}
                          />
                        </div>
                        {errors.price && (
                          <span className="error-msg">Please Insert Package Price</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Description
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Package Description"
                            name="description"
                            {...register("description", { required: true })}
                          />
                        </div>
                        {errors.description && (
                          <span className="error-msg">Please Insert Description </span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Image
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="file"
                            placeholder="Package Image"
                            name="image"
                            {...register("image", { required: true })}
                          />
                        </div>
                        {errors.image && (
                          <span className="error-msg">Please Insert Package Image</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col sm={12} md={12} lg={12}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Package Offer
                        </span>
                        <div className="form__form-group-fields">
                          <Controller
                            control={control}
                            name="packageoffer"
                            render={({ field }) => (
                              <JoditEditor
                                ref={editor}
                                value={content}
                                tabIndex={1}
                                onChange={(newContent) =>
                                  setPageContent(newContent)
                                }
                              />
                            )}
                          />
                        </div>
                        {errors.packageoffer && (
                          <span className="error-msg">Please Insert Package Offer</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group mt-2">
                        <div className="form__form-group-field">
                          <Button type="submit" color="warning" >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Package;