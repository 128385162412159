import React, { useState } from 'react'
import { Row, Button } from 'reactstrap'
import { useForm } from "react-hook-form";
import axios from 'axios';
import { environment } from '../env';
import { useNavigate } from 'react-router';

const ResetPassword = () => {
    const navigate = useNavigate();

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [PasswordValid, setPasswordValid] = useState(true);
    const password = watch("password");
    const confirm_password = watch("confirm_password");

    const onSubmit = (data) => {
        if (password !== confirm_password) {
            setPasswordValid(false);
        } else {
            setPasswordValid(true);
            const forgetEmail = localStorage.getItem('forgetEmail');
            const password_is = {
                email: forgetEmail,
                password: confirm_password
            }
            const resetPasswordUrl = `${environment.baseUrl}resetpassword`;
            axios.post(resetPasswordUrl, password_is)
                .then((response) => {
                    if (response.status !== 200) {
                        alert("There is a problem while updating the password")
                    }
                    else {
                        alert("Password has updated Successfully")
                        navigate("/")
                    }
                })
        }
    };

    // const PasswordChanged = async () =>{
    //     const resetPasswordUrl = config.API_URL + "resetpassword";
    //     const forgotEmail = await AsyncStorage.getItem('@forgetEmail');
    //     if(confirmPassword !== password){
    //       alert("please enter same password")
    //     }
    //     else{
    //       const password_is = {
    //         email: forgotEmail,
    //         password: confirmPassword
    //       }
    //       axios.post(resetPasswordUrl,password_is)
    //       .then((response)=>{
    //         if(response.status !== 200){
    //           alert("There is a problem while updating the password")
    //         }
    //         else{
    //           alert("Password has updated Successfully")
    //           navigation.navigate("passwordchangessuccessfully")
    //         }
    //       })      
    //     }
    //     }

    return (
        <div className="account">
            <div className="account__wrapper">
                <div className="account__card">
                    <div className="account__head">
                        <h3 className="account__title">Welcome to
                            <span className="account__logo"> JUST
                                <span className="account__logo-accent"> PADEL</span>
                            </span>
                        </h3>
                        <h4 className="account__subhead subhead">Padel Courts of Choice</h4>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <div className="form login-form">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontFamily: 'sans-serif', fontSize: 15 }}>Password</span>
                                    <div className="form__form-group-field justify-content-center">
                                        <input type="password" className="input-without-border-radius" placeholder="Enter Password" {...register("password", { required: true })} />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontFamily: 'sans-serif', fontSize: 15 }}>Confirm Password</span>
                                    <div className="form__form-group-field justify-content-center">
                                        <input type="password" className="input-without-border-radius" placeholder="Conform Password" name="confirm_password"
                                            {...register("confirm_password", { required: true })}
                                        />
                                    </div>
                                    {PasswordValid ? '' : "Please Enter Matching Password"}
                                </div>
                                <div className="form__form-group" style={{ marginTop: 30 }}>
                                    <Button className="account__btn" color="warning" >Reset Password</Button>
                                </div>
                            </div>
                        </Row>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword