import React, { useState, useEffect, Fragment } from "react";
import { Card, CardBody, Col, Row, ButtonToolbar, Button } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// import axios from "axios";
import axios from "../../customAxios";
import swal from "sweetalert";
import { useNavigate, Link } from "react-router-dom";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import { environment } from "../env";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import parse from "html-react-parser";

const ListLTO = () => {
  const [LTOData, setLTOData] = useState([]);
  const [userRole, setUserRole] = useState();
  const [role, setRole] = useState();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  let navigate = useNavigate();
  const LtoURL = `${environment.baseUrl}lto-api`;
  const getLTOList = () => {
    const result = axios
      .get(LtoURL)
      .then((response) => {
        setLTOData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const setPermissions = (roledata) => {
    const localrole = localStorage.getItem("Role");

    let newRoleId;

    if (localrole === roledata.role_name) {
      let permission = JSON.parse(roledata.permissions);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "manual_bookings";
          if (e === page_name) {
            newRoleId = a[e];
          }
        });
      });
    } else {
      console.log("false");
    }

    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
  };

  useEffect(() => {
    let localrole = localStorage.getItem("Role");
    setUserRole(localStorage.getItem("Role"));
    getLTOList();
  }, []);

  var jsonObj = [];

  for (let i = 0; i < LTOData.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["name"] = <div className="right-space">{LTOData[i].title}</div>;

    item["image"] = (
      <div>
        <img
          src={LTOData[i].image}
          alt={LTOData[i].image}
          style={{ width: 80, borderRadius: 5 }}
        />
      </div>
    );

    item["content"] = parse(LTOData[i].content);
    if (deleteing === true) {
      item["link"] = (
        <div className="right-space">
          <div
            className="modal-edit-btn"
            size="sm"
            onClick={() => handleEdit(LTOData[i].id)}
          >
            <AiOutlineEdit style={{ fontSize: 20 }} />
          </div>
        </div>
      );
      item["delete"] = (
        <div className="right-space">
          <div
            className="modal-delete-btn"
            size="sm"
            onClick={() => handleDelete(LTOData[i].id)}
          >
            <AiOutlineDelete AiOutlineDelete style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    } else if (editing === true) {
      item["link"] = (
        <div className="right-space">
          <div
            className="modal-edit-btn"
            size="sm"
            onClick={() => handleEdit(LTOData[i].id)}
          >
            <AiOutlineEdit style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    }
    jsonObj.push(item);
  }

  const handleEdit = (offerId) => {
    navigate(`/edit_limited_time_offers/${offerId}`);
  };

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Title",
        accessor: "name",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Image",
        accessor: "image",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Content",
        accessor: "content",
        disableGlobalFilter: true,
        width: 300,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 50,
      },
    ],
    rows: jsonObj,
  };
  const handleDelete = (offerId) => {
    const deleteLTOURL = `${environment.baseUrl}lto-api/${offerId}`;
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Limited Time Offer!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const result = axios.delete(deleteLTOURL).then((response) => {
          console.log("response", response);
          setLTOData(() => LTOData.filter((data) => data.id !== offerId));
        })
        swal("Limited Time Offer has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your News Post is safe!");
      }
    });

  };
  const handleCreateLTORedirect = () => {
    navigate("/create_limited_time_offers");
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Name...",
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Limited Time Offers</h3>
        </Col>
      </Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="react-table__wrapper hide-table-costomizer">
              <div style={{ position: "absolute", top: 35, right: 20 }}>
                <div
                  className="justpadel-buttons"
                  onClick={() => handleCreateLTORedirect()}
                >
                  Add Limited Time Offer
                </div>
              </div>
              <ReactTableCustomizer
                handleClickIsSortable={handleClickIsSortable}
                handleClickWithPagination={handleClickWithPagination}
                handleClickWithSearchEngine={handleClickWithSearchEngine}
                handleClickIsResizable={handleClickIsResizable}
                isResizable={isResizable}
                isSortable={isSortable}
                withPagination={withPagination}
                withSearchEngine={withSearchEngine}
              />
            </div>

            <div className="hover-effect table-id-width">
              <ReactTableBase
                key={withSearchEngine ? "searchable" : "common"}
                columns={data.columns}
                data={data.rows}
                tableConfig={tableConfig}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default ListLTO;
