import { createSlice } from '@reduxjs/toolkit'
import { getAllPadelCourtData } from '../thunk/ConfigThunk';

const globalConfigSliceInitialState = {
    mainCourtListAdmin: [],
    padelCourtList: [],
    pageLimits: [10, 20, 30, 40, 50],
    status: 'idle',
    error: null,
};

export const globalConfigSlice = createSlice({
    name: 'globalconfigslice',
    initialState: globalConfigSliceInitialState,
    reducers: {
        setAdminMainCourt: (state, action) => {
            state.mainCourtListAdmin = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllPadelCourtData.pending, (state, action) => {
            state.status = "loading";
            state.error = null;
        })
        builder.addCase(getAllPadelCourtData.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.padelCourtList = action.payload
        })
        builder.addCase(getAllPadelCourtData.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message
        })
    },
});

export const { setAdminMainCourt } = globalConfigSlice.actions;

export default globalConfigSlice.reducer;