import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import axios from '../../../customAxios';
import { environment } from "../../env";
import { useNavigate } from "react-router-dom";
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon'
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';

const ExampleCard = () => {
  const [miscelleniousList, setMiscelleniousList] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  let navigate = useNavigate();

  const miscURL = `${environment.baseUrl}misc`;
  const getMiscelleniousList = () => {
    axios.get(miscURL)
      .then((response) => {
        console.log(response.data);
        setMiscelleniousList(response.data);
      });
  };

  useEffect(() => {
    getMiscelleniousList();
  }, []);


  var jsonObj = [];
  for (let i = 0; i < miscelleniousList.length; i++) {
    var item = {}
    item["id"] = i + 1;
    item["task"] = <div className="right-space">{miscelleniousList[i].task}</div>;
    item["value"] = <div className="right-space">{miscelleniousList[i].value}</div> ;
    item["slug"] =<div className="right-space">{miscelleniousList[i].slug}</div> ;
    // if (deleteing === true) {
    item["link"] = <div className="right-space"><div className="modal-edit-btn" size="sm" onClick={() => handleEdit(miscelleniousList[i].id)}><EditOutlineIcon style={{ fontSize:20}} /></div></div>;
    // item["delete"] = <div className="right-space"><div className="modal-delete-btn" size="sm" onClick={() => handleDelete(miscelleniousList[i].id)}><DeleteOutlineIcon style={{ fontSize:20}} /></div></div>;
    // } else if (editing === true) {
    //   item["link"] = <Button className="btn btn-primary" size="sm" onClick={() => handleEdit(miscelleniousList[i].id)}><EditOutlineIcon style={{ width: '15px', height: '15px', marginTop: -3, marginLeft: 3 }} /></Button>;
    // }
    jsonObj.push(item);
  }

  const handleEdit = (id) => {
    navigate(`/misc-edit/${id}`);
  };

  const handleDelete = (id) => {
    const miscDeleteURL = `${environment.baseUrl}edit_misc/${id}`;
    axios.delete(miscDeleteURL)
    .then((response)=>{
      console.log("Response", response);
      setMiscelleniousList(() => miscelleniousList.filter((data) => data.id !== id));
    })
  }

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Task",
        accessor: "task",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Value",
        accessor: "value",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Slug",
        accessor: "slug",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 50,
      },
      // {
      //   Header: "Delete",
      //   accessor: "delete",
      //   disableGlobalFilter: true,
      //   width: 50,
      // },
    ],
    rows: jsonObj,
  };

  const handleMisc = () => {
    navigate("/misc");
  }
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by offer...',
  };

  return (
    <>
    <Col md={12} lg={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }} >
          <div className="justpadel-buttons btn-position" onClick={() => handleMisc()}>Add Miscellenious</div>
      </Col>
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>
          
          <div className="hover-effect table-id-width">
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
          />
          </div>
        </CardBody>
      </Card>
    </Col>
    </>
  )
};

export default ExampleCard;
