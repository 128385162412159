import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLogged: false,
    loggedUser: null,
    loggedAdminDetails: null,
    loggedAdminRole: null,
    loggedAdminPermission: null,
};

export const authSlice = createSlice({
    name: 'authslice',
    initialState,
    reducers: {
        logged: (state, action) => {
            state.isLogged = true;
            state.loggedUser = action.payload
        },
        userLogout: (state) => {
            state.isLogged = false;
            state.loggedUser = null
        },
        setAdminRole: (state, action) => {
            state.loggedAdminRole = action.payload
        },
        setAdminPermission: (state, action) => {
            state.loggedAdminPermission = action.payload
        }
    },
})

export const { logged, userLogout, setAdminRole, setAdminPermission } = authSlice.actions

export default authSlice.reducer