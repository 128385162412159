import axios from 'axios';
import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router';
import { Button, Row } from 'reactstrap';
import { environment } from '../env';

const OTPVerification = () => {
    const [OTP, setOTP] = useState();
    const navigate = useNavigate();

    const handleOTP = (e) => {
        setOTP(e)
    }

    const handleOTPVerify = async () => {
        const verifyOTPUrl = `${environment.baseUrl}verify_otp`;
        const forgetEmail = localStorage.getItem('forgetEmail');
        const otpData = {
            email: forgetEmail,
            otp: OTP
        }
        await axios.post(verifyOTPUrl, otpData)
            .then((response) => {
                if (response.status === 200 && response.data.msg === "Verified") {
                    navigate("/reset_password")
                } else {
                    alert(response.data.msg);
                }
            })
    }

    return (
        <div className="account">
            <div className="account__wrapper">
                <div className="account__card">
                    <div className="account__head">
                        <h3 className="account__title">Welcome to
                            <span className="account__logo"> JUST
                                <span className="account__logo-accent"> PADEL</span>
                            </span>
                        </h3>
                        <h4 className="account__subhead subhead">Padel Courts of Choice</h4>
                    </div>
                    <Row>
                        <div className="form login-form">
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontFamily: 'sans-serif', fontSize: 15 }}></span>
                                <div className="form__form-group-field justify-content-center">
                                    <OtpInput
                                        value={OTP}
                                        onChange={handleOTP}
                                        numInputs={4}
                                        separator={<span>{" "}</span>}
                                        inputStyle={{
                                            width: "3rem",
                                            height: "3rem",
                                            margin: "0 1rem",
                                            fontSize: "2rem",
                                            borderRadius: 4,
                                            border: "1px solid rgba(0,0,0,0.3)"
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group" style={{ marginTop: 30 }}>
                                <Button className="account__btn" color="warning" onClick={() => handleOTPVerify()}>Verify OTP</Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default OTPVerification