import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Button, ButtonToolbar, Card, CardBody, Spinner } from "reactstrap";
import axios from "../../../customAxios";
import S3FileUpload from "react-s3";
import { environment } from "../../env";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import ImageUploader from "../../MainCourt/components/ImageUploader";
import swal from "sweetalert";

const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const initialState = {
  mainCourtList: [],
  courtCategoryList: [],
  courtTypeList: [],
  padelCourtDetails: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'mainCourtList':
      return {
        ...state,
        mainCourtList: action.payload
      };
    case 'courtCategoryList':
      return {
        ...state,
        courtCategoryList: action.payload
      };
    case 'courtTypeList':
      return {
        ...state,
        courtTypeList: action.payload
      };
    case 'padelCourtDetails':
      return {
        ...state,
        padelCourtDetails: action.payload
      };
    default:
      throw new Error();
  }
}

const AllCourtsEdit = () => {
  const { id } = useParams();
  const [padelCourtDetails, setPadelCourtDetails] = useState();
  const [mainCourtList, setMainCourtList] = useState();
  const [courtCategoryList, setCourtCategoryList] = useState();
  const [courtTypeList, setCourtTypeList] = useState([]);
  const [padelCourtImage, setPadelCourtImage] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [resources, setResources] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm();


  const boolenaOptions = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];

  const GetPadelCourt = async () => {
    const editPadelCourtUrl = `${environment.baseUrl}padelcourt/${id}`;
    const result = await axios(editPadelCourtUrl);
    dispatch({type: 'padelCourtDetails', payload: result.data});
    setPadelCourtDetails(result.data);
    setResources([{data_url:result.data.courtimage, resource: result.data.courtimage}]);
  
    reset({
      courtname: result.data.courtname,
      display_name: result.data.display_name,
      courtaddress: result.data.courtaddress,
      latitude: result.data.latitude,
      longitude: result.data.longitude,
      rating: result.data.rating,
      price: result.data.price,
      additional_slot_price: result.data.additional_slot_price,
      timeslot: result.data.timeslot,
      about: result.data.about,
      courtimage: result.data.courtimage,
      maincourt: result.data.maincourt,
      courtcategory: result.data.courtcategory,
      enable_free_slot: result.data.enable_free_slot,
      ladies_only: result.data.ladies_only,
      ladies_time_slot: result.data.ladies_time_slot,
      courtType: result.data.courtType,
    });
    
  };

  const GetCourtType = async () => {
    const courtTypeUrl = `${environment.baseUrl}courttypes`;
    const result = await axios(courtTypeUrl);
    var courtType = [];
    result.data.map((courtTypes) => {
      courtType.push({
        label: courtTypes.courttype.toUpperCase(),
        value: courtTypes.id,
      });
    });
    dispatch({type: 'courtTypeList', payload: courtType});
    setCourtTypeList(courtType);
  };

  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts-admin/`;
    const result = await axios(mainCourtUrl);
    var mainCourts = [];
    result.data.map((maincourt) => {
      mainCourts.push({ label: maincourt.courtName, value: maincourt.id });
    });
    
    dispatch({type: 'mainCourtList', payload: mainCourts});
    setMainCourtList(mainCourts);
  };

  const GetCategory = async () => {
    const categoryUrl = `${environment.baseUrl}category/`;
    const result = await axios(categoryUrl);
    
    var categoriesList = [];
    result.data.map((categories) => {
      categoriesList.push({
        label: categories.courtcategory,
        value: categories.id,
      });
    });
    dispatch({type: 'courtCategoryList', payload: categoriesList});
    setCourtCategoryList(categoriesList);
  };

  const setImage = (imagesList) => {
    if(imagesList?.length>0){
      setImageError(false);
    }else{
      setImageError(true);
    }
    setPadelCourtImage(imagesList);
  };

  useMemo(() => {
    
    reset({
      maincourt: state?.mainCourtList?.filter((mainCourt)=> mainCourt.value == state?.padelCourtDetails?.maincourt?.id)[0],
      courtcategory: state?.courtCategoryList?.filter((category)=> category.value == state?.padelCourtDetails?.courtcategory?.id)[0],
      courtType: state?.courtTypeList?.filter((courtType)=> courtType.value == state?.padelCourtDetails?.courtType?.id)[0],
      enable_free_slot: boolenaOptions.filter((boolean)=>boolean.value == state?.padelCourtDetails?.enable_free_slot)[0],
      ladies_only: boolenaOptions.filter((boolean)=>boolean.value == state?.padelCourtDetails?.ladies_only)[0],
    });

  }, [state.mainCourtList]);

  const onSubmit = formData => {
    setIsLoading(true);
    if(padelCourtImage.length<=0){
      setImageError(true);
      setIsLoading(false);
      return
    }
    
    var UploadedImages = [];
    padelCourtImage.map(async (img)=>{
      if("resource" in img){
        UploadedImages.push({"resource":img.resource})
        if(padelCourtImage?.length === UploadedImages?.length){
          submitPadelCourt(formData, UploadedImages);
        }
      }else{
        await S3FileUpload.uploadFile(img.file, config)
        .then((data) => {
          UploadedImages.push({"resource":data.location});
          if(padelCourtImage?.length === UploadedImages?.length){
            submitPadelCourt(formData, UploadedImages);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error Info: ", err);
        });
      }
    });

  };

  const submitPadelCourt = (formData, images) => {
    formData.courtcategory = formData.courtcategory.value;
    formData.enable_free_slot = formData.enable_free_slot.value;
    formData.ladies_only = formData.ladies_only.value;
    formData.courtType = formData.courtType.value;
    formData.maincourt = formData.maincourt.value;
    formData.courtimage = images[0].resource;

    const padelCourtUrl = `${environment.baseUrl}padelcourt/${id}`;

    axios
      .put(padelCourtUrl, formData)
      .then((response) => {
        if (response.data.Message == "Court Updated Successfully") {
          swal({
            title: "Success",
            text: "Padel Court details is updated successfully!",
            icon: "success",
            button: "Ok",
          }).then((value) => {
            navigate("/all_courts");
          });
          setIsLoading(false);
        } else {
          swal({
            title: "Error",
            text: response.data,
            icon: "error",
            button: "Ok",
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: err.messgae,
          icon: "error",
          button: "Ok",
        }).then((value) => {
          navigate("/all_courts");
        });
        setIsLoading(false);
      });

  };


  useEffect(() => {
    GetPadelCourt();
    // GetMainCourt();
    GetCategory();
    GetCourtType();
  }, []);

  return (
    <>
      <Row>
        <Col md={12}>
          <h3 className="page-title w-100">
            Edit {padelCourtDetails?.maincourt?.courtName} Padel Court{" "}
            {padelCourtDetails?.courtname}
          </h3>
        </Col>
        <Col md={12} lg={12}>
          {isLoading && (
            <div class="loader-div">
              <span class="loader">
                <div className="center-spinner">
                  <Spinner className="table-fetch-spinner" />
                </div>
              </span>
            </div>
          )}
          <Card>
            <CardBody>
              <form
                className="form product-edit"
                encType="multipart/form-data"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Enter Court Name
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Enter Court Name"
                        {...register("courtname", { required: true })}
                      />
                    </div>
                    {errors.courtname && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter Court Name, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Enter Court Display Name
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Enter Court Display Name"
                        {...register("display_name", { required: true })}
                      />
                    </div>
                    {errors.display_name && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter Court Display Name, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Select Main Court
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="maincourt"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Select
                            value={value}
                            options={mainCourtList}
                            className="text-captialize w-100"
                            placeholder="Select Main Court"
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                      />
                    </div>
                    {errors.maincourt && (
                      <ErrorMessageComponent
                        message={
                          "Please Select Main Court, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Select Court Category
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="courtcategory"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Select
                            value={value}
                            options={courtCategoryList}
                            className="text-captialize w-100"
                            placeholder="Select Court Category"
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                      />
                    </div>
                    {errors.courtcategory && (
                      <ErrorMessageComponent
                        message={
                          "Please Select Court Category, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Enter Address
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Enter Padel Court Address"
                        {...register("courtaddress", { required: true })}
                      />
                    </div>
                    {errors.courtaddress && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Address, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Enter Latitude
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Enter Padel Court Latitude"
                        step="any"
                        {...register("latitude", { required: true })}
                      />
                    </div>
                    {errors.latitude && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Latitude, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Enter Longitude
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Enter Padel Court Longitude"
                        step="any"
                        {...register("longitude", { required: true })}
                      />
                    </div>
                    {errors.latitude && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Longitude, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Opening and Closing Time
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Enter Padel Court Opening and Closing Time"
                        {...register("timeslot", { required: true })}
                      />
                    </div>
                    {errors.latitude && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Opening and Closing Time, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Enter Court Price
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Enter Padel Court Price"
                        {...register("price", { required: true })}
                      />
                    </div>
                    {errors.latitude && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Price, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Enter Additional Slot Price
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Enter Additional Slot Price"
                        {...register("additional_slot_price", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.latitude && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Additional Slot Price, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Something About Court
                    </span>
                    <div className="form__form-group-field">
                      <textarea
                        className="rounded"
                        placeholder="Something About Padel Court"
                        rows="10"
                        cols="50"
                        style={{ height: "110px" }}
                        {...register("about", {
                          required: true,
                          maxLength: 150,
                        })}
                      />
                    </div>
                    {errors.about && errors.about.type === "required" && (
                      <ErrorMessageComponent
                        message={
                          "Please Write Something About Padel Court, It Should Not be Empty"
                        }
                      />
                    )}
                    {/* {aboutPadelCourt?.length > 150 && (
                      <ErrorMessageComponent message={"Max length exceeded"} />
                    )} */}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Enter Raitings
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        min="1"
                        max="5"
                        placeholder="Enter Padel Court Rating"
                        {...register("rating", { required: true })}
                      />
                    </div>
                    {errors.latitude && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Rating, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Enable Free Slot
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="enable_free_slot"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Select
                            value={value}
                            options={boolenaOptions}
                            className="text-captialize w-100"
                            placeholder="Enable Free Slots"
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                      />
                    </div>
                    {errors.enable_free_slot && (
                      <ErrorMessageComponent
                        message={
                          "Please Select Enable Free Slots, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Ladies Only</span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="ladies_only"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Select
                            value={value}
                            options={boolenaOptions}
                            className="text-captialize w-100"
                            placeholder="Ladies Only"
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                      />
                    </div>
                    {errors.ladies_only && (
                      <ErrorMessageComponent
                        message={
                          "Please Select Padel Court Ladies Only Type, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Ladies Time Slot
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Enter Timing For Ladies Court"
                        {...register("ladies_time_slot", { required: true })}
                      />
                    </div>
                    {errors.ladies_time_slot && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Ladies Time Slot, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Court Type</span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="courtType"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Select
                            value={value}
                            options={courtTypeList}
                            className="text-captialize w-100"
                            placeholder="Court Time"
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                      />
                    </div>
                    {errors.courtType && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Longitude, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label mb-1">
                      Select Court Image
                    </span>
                    <div className="form__form-group-field">
                      <ImageUploader
                        setImage={setImage}
                        resources={resources}
                      />
                    </div>
                    {imageError && (
                      <ErrorMessageComponent
                        message={
                          "Please Add Padel Court Image, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <ButtonToolbar
                    className="form__button-toolbar"
                    style={{ marginTop: 25 }}
                  >
                    <button
                      type="submit"
                      className="justpadel-buttons-save"
                      color="primary"
                    >
                      Save
                    </button>
                    <div className="justpadel-buttons-cancel" onClick={() => navigate("/all_courts") }>Cancel</div>
                  </ButtonToolbar>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllCourtsEdit;

const ErrorMessageComponent = ({ message }) => {
  return (
    <div color="danger" className="form__form-group-error">
      <p style={{ color: "#dc3545" }}>
        <span className="bold-text">Warning! </span>
        {message}
      </p>
    </div>
  );
};