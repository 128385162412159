import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';

const Homepage = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Homepage" icon="home">
            {getValueByKey(authslice.loggedAdminPermission, "slides") != 4 && <SidebarLink title="Home Slides" route="/list_slides" />}
            {getValueByKey(authslice.loggedAdminPermission, "slides") != 4 && <SidebarLink title="Home Slides V2" route="/list_slides-v2" />}
            {getValueByKey(authslice.loggedAdminPermission, "slides") != 4 && <SidebarLink title="Offers List" route="/limited_time_offers" />}
            {getValueByKey(authslice.loggedAdminPermission, "slides") != 4 && <SidebarLink title="News List" route="/news-list" />}
        </SidebarCategory>
    )
};

export default Homepage;