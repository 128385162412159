import React from 'react'
import { CardBody, Card, Col } from 'reactstrap'
import {
  Container,
  Row,
  CardTitle,
  Button,
} from "reactstrap";
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import swal from 'sweetalert'
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import ReactTableCustomizer from "../../../shared/components/table/components/ReactTableCustomizer";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import { AdSpacesGetAPI, DeleteAdSpace } from '../../Reuse/ApiCalls';

const AdSpaceList = () => {
  const [adSpaceList, setAdSpaceList] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  let navigate = useNavigate();

  const getAdsList = () => {
    AdSpacesGetAPI().then(res => {
      console.log("adSpaceList", res);
      setAdSpaceList(res);
    }).catch(err => {
      console.log("err", err);
    })
  }

  const handleDeleteAds = (delId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Ads Space!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DeleteAdSpace(delId).then((response) => {
          console.log("response", response);
          setAdSpaceList(() => adSpaceList.filter((data) => data.id !== delId));
        })
        swal("Your Ads Space has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Ads Space is safe!");
      }
    });
  }

  useEffect(() => {
    getAdsList();
  }, []);
  var jsonObj = [];
  for (let i = 0; i < adSpaceList.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["image"] = (
      <div className="right-space">
        <img
          src={adSpaceList[i].image}
          style={{ width: 180, borderRadius: 5 }}
          alt={adSpaceList[i].image}
        />
      </div>
    );
    item["redirectUrl"] = (
      <div className="right-space">{adSpaceList[i].redirect_url}</div>
    );
    item["screen"] = `${adSpaceList[i].screen}_${adSpaceList[i].position}`;
    item["redirectlink"] = <div className="right-space">{adSpaceList[i].redirectlink}</div>;
    // item["freeslot"] = (
    //   <div className="right-space">{adSpaceList[i].freeslot}</div>
    // );
    // item["position"] = <div className="right-space">{adSpaceList[i].position}</div>;
    item["visibility"] = <div className="right-space">{(adSpaceList[i].visibility).toString()}</div>;

    item["link"] = (
      <div className="right-space">
        <div
          onClick={() => handleEdit(adSpaceList[i].id)}
          className="modal-edit-btn"
        >
          <EditOutlineIcon style={{ fontSize: 20 }} />{" "}
        </div>
      </div>
    );
    item["delete"] = (
      <div className="right-space">
        <div
          className="modal-delete-btn"
        >
          <DeleteOutlineIcon style={{ fontSize: 20 }} onClick={() => handleDeleteAds(adSpaceList[i].id)} />
        </div>
      </div>
    );

    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Images",
        accessor: "image",
        disableGlobalFilter: true,
        width: 180,
      },
      {
        Header: "Screen",
        accessor: "screen",
        disableGlobalFilter: false,
        width: 90,
      },
      {
        Header: "Redirectlink",
        accessor: "redirectlink",
        disableGlobalFilter: true,
        width: 140,
      },

      // {
      //   Header: "Position",
      //   accessor: "position",
      //   disableGlobalFilter: true,
      //   width: 140,
      // },
      {
        Header: "Visibility",
        accessor: "visibility",
        disableGlobalFilter: true,
        width: 140,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 60,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 60,
      },
    ],
    rows: jsonObj,
  };

  function handleEdit(adSpaceId) {
    navigate(`/edit-adSpace/${adSpaceId}`);
  }

  function handleAddAdsSpace() {
    navigate(`/ad-space`);
  }
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Package Name...",
  };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <h3 className="page-title">AdSpace List</h3>
        </Col>
        <Col md={6}>
          {/* <Link to="ad-space" className="btn btn-primary float-right add-packages" role="button">Add AdSpace</Link> */}
          <button className="btn btn-primary float-right add-packages" onClick={handleAddAdsSpace}>AdSpace</button>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardBody>
                <div className="react-table__wrapper hide-table-costomizer">
                  <ReactTableCustomizer
                    handleClickIsSortable={handleClickIsSortable}
                    handleClickWithPagination={handleClickWithPagination}
                    handleClickWithSearchEngine={handleClickWithSearchEngine}
                    handleClickIsResizable={handleClickIsResizable}
                    isResizable={isResizable}
                    isSortable={isSortable}
                    withPagination={withPagination}
                    withSearchEngine={withSearchEngine}
                  />
                </div>
                <div className="hover-effect table-id-width">
                  {adSpaceList && adSpaceList ? (
                    <ReactTableBase
                      key={withSearchEngine ? "searchable" : "common"}
                      columns={data.columns}
                      data={data.rows}
                      tableConfig={tableConfig}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default AdSpaceList
