// InvoiceModalV2
import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar, Col, Modal, Row, Table, Spinner } from 'reactstrap';
import classNames from 'classnames';
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas';
import './InvoiceModal.css'
import axios from '../../customAxios';
import { environment } from '../../containers/env';
import { FiUser } from 'react-icons/fi';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { BsDownload } from 'react-icons/bs';
import moment from 'moment';

const InvoiceModalV2 = ({ color, btn, title, colored, header, data }) => {
    const [modal, setModal] = useState(false);
    const [couponPercentageOfDiscount, setCouponPercentageOfDiscount] = useState(0);
    const [applyForAddon, setApplyForAddon] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [vatAmount, setVatAmount] = useState(0);
    const [discountedAmount, setDiscountedAmount] = useState(0);
    const [payFacilityAmount, setPayFacilityAmount] = useState(0);
    const [couponDetails, setCouponDetails] = useState({
        "name": "",
        "percentage_of_discount": 0,
        "apply_for_addons": false
    });
    const [downloadingInvoice, setDownloadingInvoice] = useState(false);

    const toggle = () => setModal(!modal);

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    };

    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    //
    const calculateDiscountAmount = (courtamount, addonAmount, couponDetails, coupon) => {
        if (coupon === ("null" || "" || undefined)) {
            return 0.00;
        } else {
            if (couponDetails?.apply_for_addons) {
                return ((((courtamount + addonAmount) / 100) * couponDetails?.percentage_of_discount).toFixed(2));
            } else {
                if (couponDetails?.percentage_of_discount === 100) {
                    return (Math.round((courtamount / 100) * couponDetails?.percentage_of_discount));
                } else {
                    return ((courtamount / 100) * couponDetails?.percentage_of_discount);
                }
            }
        }
    };

    const VatCalculation = (courtamount, addonAmount, couponDetails, coupon) => {
        if (coupon === ("null" || "" || undefined)) {
            return Math.ceil((courtamount + addonAmount) * 0.05);
        } else {
            if (couponDetails?.apply_for_addons) {
                return Math.ceil(((courtamount + addonAmount) - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon)) * 0.05)
            } else {
                return Math.ceil(((courtamount - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon)) + addonAmount) * 0.05)
            }
        }
    };

    const CalculateTotalAmount = (courtamount, addonamount, coupondetails, coupon) => {
        if (coupon === ("null" || "" || undefined)) {
            return Math.floor(courtamount + addonamount + VatCalculation(courtamount, addonamount, coupondetails, coupon));
        } else {
            if (coupondetails?.apply_for_addons) {
                return Math.floor(courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon) - calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon)));
            } else {
                return courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon) - calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon));
            }
        }
    };

    const PaidToFacultyCalculation = (courtamount, addonamount, coupondetails, Coupon, amountPaid) => {
        return CalculateTotalAmount(courtamount, addonamount, coupondetails, Coupon) - amountPaid;
    };

    const bookedSlotsPrice = (bookedSlots) => {
        const totalAmount = bookedSlots.reduce((acc, booking) => acc + booking.Price, 0);
        return totalAmount.toFixed(2)
    };

    const bookedAddonsPrice = (bookedAddons) => {
        const totalAmount = bookedAddons.reduce((acc, booking) => acc + booking.amount, 0);
        return totalAmount.toFixed(2)
    };

    const getCouponDetails = (couponname) => {
        try {
            const getCouponDetailsURL = `${environment.baseUrl}getcoupan/${couponname}/`;
            axios.get(getCouponDetailsURL).then((response) => {
                setCouponDetails(response?.data);
                setCouponPercentageOfDiscount(response?.data?.percentage_of_discount);
                setApplyForAddon(response?.data?.apply_for_addons);
            }).catch((error) => {
                console.log("error", error);
            });
        } catch (error) {
            console.log("error--->", error);
        };
    };

    const FinalCalculation = (coupon) => {
        let NewTotlaCourtPrice = parseFloat(bookedSlotsPrice(data?.booked_slots));
        let NewTotlaAddonPrice = parseFloat(bookedAddonsPrice(data?.addonsbookedlist));
        const calculatedTotalAmounts = CalculateTotalAmount(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", data?.booking?.coupan_applied ? data?.booking?.coupan_applied : "null");
        setDiscountedAmount(calculateDiscountAmount(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", data?.booking?.coupan_applied ? data?.booking?.coupan_applied : "null"));
        setVatAmount(VatCalculation(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", data?.booking?.coupan_applied ? data?.booking?.coupan_applied : "null"));
        setTotalAmount(parseInt(calculatedTotalAmounts).toFixed(2));
        setPayFacilityAmount(PaidToFacultyCalculation(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", data?.booking?.coupan_applied ? data?.booking?.coupan_applied : "null", data?.booking?.amount_paid));
        if (data?.booking?.packages > 0) {
            setPayFacilityAmount(0);
        };
    };

    const printDocument = (bookingId) => {
        try {
            setDownloadingInvoice(true);
            const input = document.getElementById('divToPrint');
            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF();
                    pdf.addImage(imgData, 'JPEG', 20, 20, 170, 260);
                    pdf.autoPrint();
                    pdf.save(`#${bookingId}.pdf`);
                    setDownloadingInvoice(false);
                })
                .catch((err) => {
                    setDownloadingInvoice(false);
                    console.log(err);
                });
        } catch (error) {
            setDownloadingInvoice(false);
        };
    };

    useEffect(() => {
        if (modal && data?.booking?.coupan_applied) {
            getCouponDetails(data?.booking?.coupan_applied);
        }
    }, [data, modal]);

    useEffect(() => {
        if (modal && data) {
            FinalCalculation(couponDetails);
        }
    }, [modal, couponDetails, data]);

    return (
        <div>
            <div className="modal-download-btn mt-1" onClick={toggle}>
                <BsDownload style={{ fontSize: 20 }} />
            </div>
            <Modal isOpen={modal} className={`modal-dialog--${color} ${modalClass}`}>
                <Row className='justify-content-between px-1 py-2 modal__header' >
                    <Col sm={11} md={11} lg={11} className='flex-1' >
                        <h4 className="text-modal modal__title text-start">{title}</h4>
                    </Col>
                    <Col sm={1} md={1} lg={1}>
                        <button
                            className="lnr lnr-cross modal__close-btn text-dark position-relative top-0 fs-5 "
                            aria-label="modal__close-btn"
                            type="button"
                            onClick={() => toggle()}
                        />
                    </Col>
                </Row>
                <Row className='p-2'>
                    {downloadingInvoice ? <Col sm={8} md={8} lg={8}></Col> : <Col sm={9} md={9} lg={9}></Col>}
                    <Col sm={3} md={3} lg={3}>
                        <Button
                            color="warning"
                            className="m-0 d-flex align-items-center justify-content-center"
                            onClick={() => printDocument(data?.booking?.booking_id)}
                            style={{ gap: '0.5rem' }}
                        >
                            {downloadingInvoice ? <><Spinner size="sm" />Downloading</> : 'Download'}
                        </Button>
                    </Col>
                </Row>
                <div id="divToPrint">
                    <Row className='border-bottom border-2 border-info-subtle mx-2 mt-4'>
                        <Col sm={6} md={6} lg={6}>
                            <div className="provide-name text-start">Just Padel Team</div>
                        </Col>
                        <Col sm={6} md={6} lg={6} className='d-flex justify-content-end'>
                            <label className='fs-6 fw-bold'>Booking ID:</label>
                            <label className='fs-6 fw-bold'># {data?.booking?.booking_id}</label>
                        </Col>
                    </Row>
                    <Row className='mt-4 mx-2'>
                        <Col className="text-start p-0">
                            Status:
                        </Col>
                        <Col className="text-start fw-bold p-0">
                            {data?.booking?.amount_percentage === 0.5 ? "Half Paid" : data?.booking?.amount_percentage === 1 ? "Full Paid" : "Pending"}
                        </Col>
                        <Col sm={2} md={2} lg={2} className="text-end p-0">
                            Invoice date:
                        </Col>
                        <Col sm={4} md={4} lg={4} className="text-end fw-bold p-0">
                            {moment(data?.booking?.created_at.split('T')[0]).format("DD-MM-YYYY")}{' '}{moment(data?.booking?.created_at.split('T')[1].split('.')[0], "HH:mm:ss").format("hh:mm A")}
                        </Col>
                    </Row>
                    <Row className='mx-2'>
                        <Col className="text-start p-0">
                            Payment processor:
                        </Col>
                        <Col className="text-start fw-bold p-0">
                            Payfort
                        </Col>
                        <Col sm={2} md={2} lg={2} className="text-end p-0">
                            Due Date:
                        </Col>
                        <Col sm={4} md={4} lg={4} className="text-end fw-bold p-0">
                            {moment(data?.booking?.booking_date).format("DD-MM-YYYY")} {data?.booking?.timeslot.split("-")[0]}
                        </Col>
                    </Row>
                    <Row className='mx-2'>
                        <Col className="text-start p-0">
                            Payment received:
                        </Col>
                        <Col className="text-start fw-bold p-0">
                            {data?.booking?.amount_paid.toFixed(2)} AED
                        </Col>
                        <Col className="text-end p-0">
                        </Col>
                        <Col className="text-end fw-bold p-0">
                        </Col>
                    </Row>
                    <Row className='border border-1 mx-2 mt-4 rounded'>
                        <Row className=''>
                            <Col className="p-2">
                                <div className="trans-client-name"><FiUser className="client-icon" /><h5>Client Name</h5></div>
                            </Col>
                            <Col className="text-start p-2">
                                {data?.user?.first_name === "null" ? " " : data?.user?.first_name}{' '}
                                {data?.user?.last_name === "null" ? " " : data?.user?.last_name}
                            </Col>
                        </Row>
                        <Row className=''>
                            <Col className="p-2">
                                <div className="trans-client-name"><AiOutlineMail className="client-icon" /><h5>Email</h5></div>
                            </Col>
                            <Col className="text-start p-2">
                                {data?.user?.email === "null" ? " " : data?.user?.email}
                            </Col>
                        </Row>
                        <Row className=''>
                            <Col className="p-2">
                                <div className="trans-client-name"><AiOutlinePhone className="client-icon" /><h5>Phone</h5></div>
                            </Col>
                            <Col className="text-start p-2">
                                {data?.user?.phone_no === "null" ? " " : data?.user?.phone_no}
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col className="p-2 mt-2">
                            <h5>Payment received by payment system</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-start p-2 fw-bold ">
                            Booked Slots
                        </Col>
                        <div className="trans-total-details">
                            <Table responsive hover>
                                <thead className="trans-total-table-header">
                                    <tr style={{ fontSize: 13 }}>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data?.booked_slots?.map((data, index) => {
                                            return (
                                                <tr key={index} style={{ fontSize: 12 }}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ paddingRight: 10 }}>
                                                        {data.main_court_name} - {" "}
                                                        {" Court "}{data.padel_court_name}
                                                        {" "}({data?.date + "  " + data?.time})
                                                    </td>
                                                    <td>{data.Price.toFixed(2)} AED</td>
                                                    <td style={{ minWidth: 50, marginLeft: 10 }}>1</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Row>
                    {data?.addonsbookedlist.length > 0 && <Row>
                        <Col className="text-start p-2 fw-bold ">
                            Booked Addons
                        </Col>
                        <div className="trans-total-details">
                            <Table responsive hover>
                                <thead className="trans-total-table-header">
                                    <tr style={{ fontSize: 13 }}>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Qty</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data?.addonsbookedlist?.map((data, index) => {
                                            return (
                                                <tr key={index} style={{ fontSize: 12 }}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ paddingRight: 10 }}>
                                                        {data.addonname}
                                                    </td>
                                                    <td>{data.price.toFixed(2)} AED</td>
                                                    <td style={{ minWidth: 50, marginLeft: 10 }}>{data.quantity}</td>
                                                    <td style={{ minWidth: 50, marginLeft: 10 }}>{data.amount.toFixed(2)} AED</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Row>}
                    <Row>
                        <Col></Col>
                        <Col>
                            <Row>
                                <Col sm={7} md={7} lg={7} className='text-end'>VAT 5%:</Col>
                                <Col className='text-start fw-bold'>{vatAmount.toFixed(2)} AED</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col >
                            <Row>
                                <Col sm={7} md={7} lg={7} className='text-end'>Total Amount:</Col>
                                <Col className='text-start fw-bold'>{bookedSlotsPrice(data?.booked_slots)} AED</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col>
                            <Row>
                                <Col sm={7} md={7} lg={7} className='text-end'>Addon Amount:</Col>
                                <Col className='text-start fw-bold'>{bookedAddonsPrice(data?.addonsbookedlist)} AED</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col sm={5} md={5} lg={5} ></Col>
                        <Col>
                            <Row>
                                <Col sm={7} md={7} lg={7} className='text-end'>Coupon Applied:</Col>
                                <Col sm={5} md={5} lg={5} className='text-start fw-bold'>{data?.booking?.coupan_applied === "" ? "NO" : `YES (${data?.booking?.coupan_applied})`}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={5} md={5} lg={5} ></Col>
                        <Col>
                            <Row>
                                <Col sm={7} md={7} lg={7} className='text-end'>Discounted Price:</Col>
                                <Col sm={5} md={5} lg={5} className='text-start fw-bold'>{discountedAmount.toFixed(2)} AED</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={5} md={5} lg={5} ></Col>
                        <Col>
                            <Row>
                                <Col sm={7} md={7} lg={7} className='text-end'>Amount Paid:</Col>
                                <Col sm={5} md={5} lg={5} className='text-start fw-bold'>
                                    {{
                                        1: "100%",
                                        0.5: "50%"
                                    }[data?.booking?.amount_percentage] || ''} Paid
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={5} md={5} lg={5} ></Col>
                        <Col>
                            <Row>
                                <Col sm={7} md={7} lg={7} className='text-end'>Advanced Paid:</Col>
                                <Col sm={5} md={5} lg={5} className='text-start fw-bold'>{data?.booking?.amount_paid.toFixed(2)} AED</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={5} md={5} lg={5} ></Col>
                        <Col>
                            <Row>
                                <Col sm={7} md={7} lg={7} className='text-end'>To be Paid at Facility:</Col>
                                <Col sm={5} md={5} lg={5} className='text-start fw-bold'>{payFacilityAmount.toFixed(2)} AED</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <ButtonToolbar className="modal__footer invoice-btn">
                            <Button onClick={() => toggle()}>Close</Button>{' '}
                            <Button color='warning' outline={colored}>Ok</Button>
                        </ButtonToolbar>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

export default InvoiceModalV2;
