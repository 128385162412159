import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminEndPoints } from "../../endPoints/AdminEndPoints";
import axios from "../../customAxios";
import moment from "moment";
import AdminUserDetails from "../../containers/Booking Details/components/AdminUserDetails";
import UserDetails from "../../containers/Booking Details/components/UserDetails";
import { AiFillEye } from "react-icons/ai";
import BookingDetailsModal from "../../shared/components/BookingDetailsModal";
import EditBookingModal from "../../shared/components/EditBookingModal";
import { MdCancel } from "react-icons/md";

// Utility function to determine payment status
const PaymentStatus = (availability, cancelled, payment_status) => {
    if (payment_status === "Failed") return "Timeout";
    if (availability || cancelled) return "Cancelled";
    return payment_status;
};

// Utility function to determine class name for status
const getStatusClassName = (payment_status, availability) => {
    return (payment_status === "Completed" || payment_status === "completed") && !availability
        ? "active-color-bg"
        : "canceled-color-bg";
};

// Template for displaying created date
const createdDateTemplate = (data) => (
    <div className="Booking-details-td-right-space">
        {data?.created_at.split("T")[0]}{" "}
        {moment(data?.created_at.split("T")[1], "HH:mm:ss").format("hh:mm A")}
    </div>
);

// Template for displaying booking date and time
const bookingDataTimeTemplate = (data) => (
    <div className="Booking-details-td-right-space">
        {data?.booking_date} <br />
        {data?.timeslot}
    </div>
);

// Template for displaying service provider details
const serviceProviderTemplate = (data) => (
    <div className="Booking-details-td-right-space">
        {data?.main_court.courtName + " -"}
        <br />
        {"COURT " + data?.padel_court?.courtname}
    </div>
);

// Template for displaying client details
const clientTemplate = (data) => (
    <div className="Booking-details-td-right-space">
        <>
            {data?.user?.first_name}<br />
            <span className="user-email">{data?.user?.email}</span><br />
            <span className="user-phone">{data?.user?.phone_no}</span>
        </>
    </div>
);

// Template for displaying payment status
const paymentStatusTemplate = (data) => {
    const { availability, cancelled, payment_status, boooked_using } = data;
    const statusClass = getStatusClassName(payment_status, availability);

    return (
        <div className="Booking-details-td-right-space">
            <div className={`active-color-bg ${statusClass}`}>
                <span>{PaymentStatus(availability, cancelled, payment_status)}</span>
            </div>
            <span className={data?.user?.role !== "customer" ? "admin-bg" : "app-bg"}>
                {boooked_using}
            </span>
        </div>
    );
};

// Template to show user details

const viewDetailsTemaplte = (data) => (
    <div style={{ display: "flex" }} className="booking-action-column" >
      <BookingDetailsModal
        color="primary"
        header
        title="Booking Details"
        userdetails={data?.user}
        transactionId={data?.booking_id}
        bookedUsing={data.boooked_using}
        // cancelBoooking={() =>
        //   CancelBookings(data.booking_id, data.logs)
        // }
      />
      {data?.availability ? (
        ""
      ) : (
        <div
          className="modal-delete-btn"
          style={{ marginTop: 5, marginLeft: 5 }}
        //   onClick={() =>
        //     CancelBookings(data.booking_id, data.logs)
        //   }
        >
          <MdCancel style={{ fontSize: 17 }} />
        </div>
      )}
      <EditBookingModal
        color="primary"
        header
        title="Edit Booking"
        transactionId={data?.booking_id}
        // refreshData={refreshDetailsData}
      />
    </div>
  );

// Thunk to get the booking list based on filter
export const getPaginatedFilteredBookingList = createAsyncThunk(
    'booking/getPaginatedFilteredBookingList',
    async (payload, { dispatch }) => {
        try {
            const bookingListPaginatedFilterResponse = await axios.get(adminEndPoints.bookingList, { params: payload });

            const bookingResults = bookingListPaginatedFilterResponse?.data?.results.map((data) => {
                return {
                    ...data,
                    created_date: createdDateTemplate(data),
                    booking_date_time: bookingDataTimeTemplate(data),
                    service_provider: serviceProviderTemplate(data),
                    client: clientTemplate(data),
                    status: paymentStatusTemplate(data),
                    view: viewDetailsTemaplte(data)
                };
            });

            const returnData = {
                ...bookingListPaginatedFilterResponse?.data,
                results: bookingResults,
            };

            return returnData;
        } catch (error) {
            throw Error(error.response?.data?.message || error.message);
        }
    }
);
