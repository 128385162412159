import React from 'react';
import {
  Card,
  CardBody,
  Col, Container, Row,
} from 'reactstrap';
import UsersListCard from './components/CourtTypeEditCard';

const UsersIndex = () => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h3>User&apos;s List</h3>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <UsersListCard />
      </Row>
    </Container>
  );
}
export default UsersIndex;
