import React from 'react'
import SidebarCategory from '../SidebarCategory';
import SidebarLink from '../SidebarLink';
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';

const PermissionsSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Permissions" icon="checkmark-circle">
            {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <SidebarLink title="Create User Role's" route="/role" />}
            {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <SidebarLink title="User Role's List" route="/role_list" />}
        </SidebarCategory>
    );
};

export default PermissionsSidebar;