import React from 'react'
import SidebarCategory from './SidebarCategory'
import SidebarLink from './SidebarLink'
import Dashboard from './SidebarList/Dashboard'
import BookingsSidebar from './SidebarList/BookingsSidebar'
import PaymentSidebar from './SidebarList/PaymentSidebar'
import MatchMakingsidebar from './SidebarList/MatchMakingsidebar'
import PackagesSidebar from './SidebarList/PackagesSidebar'
import UserSidebar from './SidebarList/UserSidebar'
import AdsSpaceSidebar from './SidebarList/AdsSpaceSidebar'
import Homepage from './SidebarList/Homepage'
import ManageUser from './SidebarList/ManageUser'
import PermissionsSidebar from './SidebarList/PermissionsSidebar'
import PadelgramSidebar from './SidebarList/PadelgramSidebar'
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../Utils/AWSBucket/AWSConfiguration'
import SettingSidebar from './SidebarList/SettingSidebar'

const SidebarContent = () => {
  const authslice = useSelector((state) => state.auth);
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <Dashboard />}
        {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <BookingsSidebar />}
        {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <PaymentSidebar />}
        {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <MatchMakingsidebar />}
        {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <PackagesSidebar />}
        {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <UserSidebar />}
        {getValueByKey(authslice.loggedAdminPermission, "slides") != 4 && <AdsSpaceSidebar />}
        {getValueByKey(authslice.loggedAdminPermission, "slides") != 4 && <Homepage />}
        {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <ManageUser />}
        {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <PermissionsSidebar />}
        {getValueByKey(authslice.loggedAdminPermission, "registered_user") != 4 && <PadelgramSidebar />}
        {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <SettingSidebar />}
      </ul>
      <SidebarLink title="Log Out" icon="exit" />
    </div>
  )
};

export default SidebarContent;