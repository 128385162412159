import React, { useState } from 'react';
import './Transaction.css';

const BookingListTemplate = ({ booked_slots, addonsbookedlist }) => {
  const [noOfElements, setNoOfElements] = useState(1);
  const [isMore, setIsMore] = useState(false);

  const slice = booked_slots?.slice(0, noOfElements);

  const loadMore = () => {
    setNoOfElements(noOfElements + 2);
  };

  const loadLess = () => {
    setNoOfElements(1);
  };

  const toggleMoreOrLess = () => {
    setIsMore(!isMore);
    isMore ? loadLess() : loadMore();
  };

  return (
    <>
      {slice?.map((data, index) => (
        <div key={index}>
          <h5 className="TextShouldBeThreeLines" style={{ fontSize: 12 }}>
            {data?.main_court_name} {' '} {data?.padel_court_name}
            <br />
            ({data?.date} {' '} {data?.time.split('-')[0]}{" to "}{data?.time.split('-')[1]}, {' '} {data?.courtname})
          </h5>
        </div>
      ))}

      {booked_slots?.length > 2 && (
        <span
          style={{ color: '#ccc', cursor: 'pointer' }}
          onClick={toggleMoreOrLess}
        >
          {isMore ? "Read Less...." : "Read More...."}
        </span>
      )}

      {addonsbookedlist?.map((addon, index) => (
        <div key={index}>
          {addon.addonname} {" x "} {addon.quantity}
        </div>
      ))}
    </>
  );
};

export default BookingListTemplate;
