/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from "react";
import axios from "../../../customAxios";
import {
  Col,
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  ButtonDropdown,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { environment } from "../../env";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const EditAddonsEditCard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [mainCourtList, setMainCourtList] = useState([]);
  const [addonData, setAddonData] = useState();

  const { register, handleSubmit, reset, control, formState } = useForm({
    mode: "onChange",
  });
  const { touchedFields, errors, isDirty, isValid } = formState;

  const editAddonUrl = `${environment.baseUrl}addon/${id}`;
  const getAddon = async () => {
    const result = await axios(editAddonUrl);
    reset(result.data);
    setAddonData(result.data);
  };

  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts-list`;
    await axios.get(mainCourtUrl).then((response) => {
      setMainCourtList(
        response?.data.map((court) => ({
          label: court.courtName,
          value: court.id,
        }))
      );
    });
  };

  useEffect(() => {
    getAddon();
    if (localStorage.getItem("Token")) {
    } else {
      alert("You are not logged In. Please LogIn");
      navigate("/");
    }
    GetMainCourt();
  }, []);

  const onSubmit = (value) => {
    let selectMainCourt = value.maincourt_availability.map(court => court.value);
    value.maincourt_availability = selectMainCourt;
    
    const addonUrl = `${environment.baseUrl}addon/${id}`;
    axios.put(addonUrl, value).then((response) => {
      if (response.data.Message === "Addon Updated Successfully") {
        swal({
          title: "Success",
          text: "Addons Updated Successfully!",
          icon: "success",
          button: "Close",
        });
        navigate("/list_addons");
      }
    });
  };

  const resetData = () => {
    reset({});
    navigate("/list_addons");
  };

  useMemo(() => {
    if (addonData) {
      var maincourt = [];
      addonData?.maincourt_availability.map((courtIs) => {
        maincourt.push(
          mainCourtList.filter((court) => court.value == courtIs)[0]
        );
      });
      reset({
        maincourt_availability: maincourt,
      });
    }
  }, [mainCourtList]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 14 }}
                >
                  Enter Addons Name
                </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="maincourt_availability"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        value={value}
                        isMulti
                        options={mainCourtList}
                        className="basic-multi-select w-100"
                        components={animatedComponents}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                {errors.maincourt_availability && (
                  <span className="error-msg">Please Select Main Court</span>
                )}
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Enter Addons Name
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    style={{ borderRadius: 5 }}
                    name="addonname"
                    {...register("addonname", { required: true })}
                  />
                </div>
                {errors.addonname && (
                  <span className="error-msg">Please Enter Addon Name</span>
                )}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Enter Addons Price
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    style={{ borderRadius: 5 }}
                    name="price"
                    {...register("price", { required: true })}
                  />
                </div>
                {errors.price && (
                  <span className="error-msg">Please Enter Addon Name</span>
                )}
              </div>
            </div>
            <div className="form__half" style={{ marginLeft: "30px" }}>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Enter Addons Description
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    style={{ borderRadius: 5 }}
                    name="description"
                    {...register("description", { required: true })}
                  />
                </div>
                {errors.description && (
                  <span className="error-msg">Please Enter Addon Name</span>
                )}
              </div>
            </div>
            <div className="form__half"></div>
            <div className="form__half" style={{ marginLeft: "30px" }}>
              <ButtonToolbar
                className="form__button-toolbar"
                style={{ marginTop: 50, marginLeft: 30 }}
              >
                <button className="justpadel-buttons-save" type="submit">
                  Update
                </button>
                <button
                  className="justpadel-buttons-cancel"
                  onClick={() => resetData()}
                >
                  Cancel
                </button>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default EditAddonsEditCard;
