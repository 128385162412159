import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { FiUser } from 'react-icons/fi'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { FcCancel, FcCheckmark } from 'react-icons/fc'
import BookingCalculations from './BookingCalculations'
import MatchMakingBookingCalculation from './MatchMakingBookingCalculation'
import SplitPayBookingCalculation from './SplitPayBookingCalculation'

const BookingDetailTransactionTabPane = ({ bookingDetails, bookedUsing }) => {

    return (
        <>
            <div className="booking-id">
                <label style={{ fontSize: 17, fontWeight: "bold" }}>Booking ID:</label>
                <label style={{ fontSize: 17, fontWeight: "bold" }}>
                    #{bookingDetails?.booking_id}
                </label>
            </div>

            <div className="payments-tatus-container">
                <div>
                    <div className="payment-details">
                        <label className="payment-details-text">Status: </label>
                        {"  "}
                        {bookingDetails?.cancelled ? (
                            <label className="payment-details-result">Cancelled</label>
                        ) : bookingDetails?.packages > 0 ? (
                            <label className="payment-details-result">Paid by Packages</label>
                        ) : bookingDetails?.boooked_using === "admin" ? (
                            <label className="payment-details-result">{bookingDetails?.payment_processor}</label>
                        ) : (bookingDetails?.amount_percentage === 1 || bookingDetails?.amount_percentage == 100) ? (
                            <label className="payment-details-result">Paid 100%</label>
                        ) : (
                            <label className="payment-details-result">Paid 50%</label>
                        )}
                    </div>

                    <div className="payment-details">
                        <label className="payment-details-text">Payment received: </label>{" "}
                        {"  "}
                        <label className="payment-details-result">
                            {bookingDetails?.payment_status === "Failed"
                                ? "0.00"
                                : bookingDetails?.amount_paid.toFixed(2)} AED
                        </label>
                    </div>
                </div>

                <div>
                    <div className="payment-details">
                        <label className="invoice-text">Invoice date: </label> {"  "}
                        <label className="payment-details-result">
                            {moment(bookingDetails?.created_at.split("T")[0]).format(
                                "DD-MM-YYYY"
                            )}{" "}
                            {moment(
                                bookingDetails?.created_at.split("T")[1].split(".")[0],
                                "HH:mm:ss"
                            ).format("hh:mm A")}
                        </label>
                    </div>
                    <div className="payment-details">
                        <label className="due-text">Due date: </label> {"  "}
                        <label className="payment-details-result">
                            {" "}
                            {moment(bookingDetails?.booking_date).format(
                                "DD-MM-YYYY"
                            )}{" "}
                            {bookingDetails?.timeslot.split("-")[0]}
                        </label>
                    </div>
                </div>
            </div>

            <div className="user-datails">
                <div className="user-datails-lhs">
                    <Table responsive hover>
                        <tbody className="table-trans-user-details">
                            <tr>
                                <td className="first-td-width">
                                    <div className="trans-client-name"><FiUser className="client-icon" /><span className='bold-text fa-1x'>Client Name</span></div>
                                </td>
                                <td className="client-name">
                                    {bookingDetails?.user?.first_name}
                                </td>
                            </tr>
                            <tr>
                                <td className="first-td-width">
                                    <div className="trans-client-name"><AiOutlineMail className="client-icon" /> <span className='bold-text fa-1x'>Email</span></div>
                                </td>
                                <td className="client-email">{bookingDetails?.user?.email}</td>
                            </tr>
                            <tr>
                                <td className="first-td-width">
                                    <div className="trans-client-name"><AiOutlinePhone className="client-icon" /><span className='bold-text fa-1x'>Phone</span></div>
                                </td>
                                <td className="client-Ph-no">{bookingDetails?.user?.phone_no}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="user-datails-lhs">
                    <h5>Payment received by payment system</h5>
                </div>
            </div>
            {bookingDetails?.booking_category == "Matchmaking" ?
                <MatchMakingBookingCalculation bookingDetails={bookingDetails} />
                :
                bookingDetails?.booking_category == "SplitPay" ?
                    <SplitPayBookingCalculation bookingDetails={bookingDetails} />
                    :
                    <BookingCalculations bookingDetails={bookingDetails} bookedUsing={bookedUsing} />
            }
        </>
    )
}

export default BookingDetailTransactionTabPane;