import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { getValueByKey } from '../../../../Utils/AWSBucket/AWSConfiguration';

const BookingsSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Bookings" icon="calendar-full">
            {getValueByKey(authslice.loggedAdminPermission, "calender") != 4 && <SidebarLink title="Calender" route="/calenderv2" />}
            {getValueByKey(authslice.loggedAdminPermission, "booking_list") != 4 && <SidebarLink title="Booking's List" route="/bookings" />}
            {getValueByKey(authslice.loggedAdminPermission, "notify_me") != 4 && <SidebarLink title="Notify Me" route="/notify_me" />}
        </SidebarCategory>
    )
}

export default BookingsSidebar