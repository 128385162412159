import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap'
import TransactionModel from '../../../shared/components/TransactionModel';
import BookingList from '../../Transactions/components/BookingList';
import moment from 'moment';
import axios from '../../../customAxios';
import { adminEndPoints } from '../../../endPoints/AdminEndPoints';
import { useEffect } from 'react';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import BookingListTemplate from '../../Transactions/components/BookingListTemplate';
import CouponBookingTransactionModel from '../../../shared/components/CouponBookingTransactionModel';

const CouponBookingList = () => {
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const initialCouponBookingListFilter = {
        booking_date_after: "",
        booking_date_before: "",
        coupan_applied: "",
        page: 1,
        page_size: 10
    };

    const tableConfigCouponLog = {
        isEditable: false,
        isResizable: true,
        isSortable: true,
        withPagination: true,
        withSearchEngine: true,
        manualPageSize: [1000, 2000, 3000, 4000],
        placeholder: "Search by Name...",
    };

    // template for booking
    const idTemplate = (data) => (data.id);
    const bookingIdTemplate = (data) => (<div className="bookingId right-space">#{data?.booking_id}</div>);
    const bookedCouponTemplate = (data) => (<div className="ceated-date right-space">{data?.coupan_applied}</div>);
    const userInfoTemplate = (data) => {
        const firstName = data?.user?.first_name === "null" ? "" : data?.user?.first_name;
        const phoneNo = data?.user?.phone_no === "null" ? " " : data?.user?.phone_no;
        return (
            <div className="right-space">
                <div>{firstName}</div>
                <div className="userEmail">{data?.user?.email}</div>
                <div className="userPhone">{phoneNo}</div>
            </div>
        );
    };

    const bookedCourtTemplate = (data) => (<div className="right-space">
        <div className="item-data">
            <BookingListTemplate
                booked_slots={data?.booked_slots}
                addonsbookedlist={data?.addonsbookedlist}
            />
        </div>
    </div>);

    const amountPaidTemplate = (booking) => {
        const amountPaid = booking?.amount_paid?.toFixed(2) || 0;
        const amountPercentage = booking?.amount_percentage;
        const couponApplied = booking?.coupan_applied || "";
        const bookedUsing = booking?.boooked_using || "";

        // Determine payment status
        let paymentStatus = "";
        if (amountPercentage === 0.5) {
            paymentStatus = "50% Paid";
        } else if (amountPercentage === 1) {
            paymentStatus = "Paid";
        }

        return (
            <div className="right-space">
                <div className="amountPaid">
                    {amountPaid + " AED"}
                    <span className="amountPaidPayform"> / payfort</span>
                </div>
                <div>
                    {paymentStatus && <h3 className={`amountPaidPaid${amountPercentage === 0.5 ? "Half" : ""} w-75`}>{paymentStatus}</h3>}
                </div>
                <div>
                    <h3 className="amountPaidCoupanCode w-75">{couponApplied}</h3>
                </div>
                <div>
                    <h3
                        className="amountPaidCoupanCode booked-using text-capitalize w-75 m-0 my-1"
                        style={{ width: "fit-content" }}
                    >
                        {bookedUsing}
                    </h3>
                </div>
            </div>
        );
    };

    const paymentStatusTemplate = (booking) => {
        const updatedAt = booking?.updated_at || "";
        const formattedDate = updatedAt
            ? moment(updatedAt.split("T")[0]).format("DD-MM-YYYY")
            : "";
        const formattedTime = updatedAt
            ? moment(updatedAt.split("T")[1].split(".")[0], "HH:mm:ss").format("hh:mm A")
            : "";

        return (
            <div className="right-space">
                <div className="payment-status">
                    {formattedDate}
                    <br />
                    {formattedTime}
                </div>
                <h3 className="amountStatusCompleted">{booking?.payment_status}</h3>
            </div>
        );
    };

    const actionTemplates = (data) => (
        <div className="right-space">
            <div className="dropdown trans-buttons">
                <CouponBookingTransactionModel
                    color="primary"
                    header
                    title="Transaction Details"
                    data={data}
                />
            </div>
        </div>
    );

    const [couponBookingFilter, setCouponBookingFilter] = useState(initialCouponBookingListFilter);
    const [couponBookings, setCouponBookings] = useState([]);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [initialPage, setInitialPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [paginationKey, setPaginationKey] = useState(0);
    const [downloadFilter, setDownloadFilter] = useState(initialCouponBookingListFilter);
    const [downloading, setDownloading] = useState(false);

    const { pageLimits } = useSelector((state) => state.config);

    const couponHearders = [
        {
            Header: "#",
            accessor: "id",
            disableGlobalFilter: true,
            width: 65,
        },
        {
            Header: "ID",
            accessor: "bookingId",
            disableGlobalFilter: true,
            width: 40,
        },
        {
            Header: "Coupon Code",
            accessor: "couponCode",
            disableGlobalFilter: true,
            width: 120,
        },
        {
            Header: "Client name",
            accessor: "userInfo",
            disableGlobalFilter: true,
            width: 160,
        },
        {
            Header: "Item",
            accessor: "mainCourt",
            disableGlobalFilter: true,
            width: 160,
        },
        {
            Header: "Amount / Paid With",
            accessor: "amountPaid",
            disableGlobalFilter: true,
            width: 130,
        },
        {
            Header: "Payment Status",
            accessor: "paymentStatus",
            disableGlobalFilter: true,
            width: 120,
        },
        {
            Header: "Actions",
            accessor: "actions",
            disableGlobalFilter: true,
            width: 110,
        },
    ];

    const getCouponBookedList = (filterPayload) => {
        try {
            axios.get(`${adminEndPoints.couponBasedBookings}`, {
                params: filterPayload
            }).then((response) => {
                const couponBookingData = response.data.results.map((data) => {
                    return {
                        ...data,
                        id: idTemplate(data),
                        bookingId: bookingIdTemplate(data),
                        couponCode: bookedCouponTemplate(data),
                        userInfo: userInfoTemplate(data),
                        mainCourt: bookedCourtTemplate(data),
                        amountPaid: amountPaidTemplate(data),
                        paymentStatus: paymentStatusTemplate(data),
                        actions: actionTemplates(data),
                    }
                })
                setCouponBookings(couponBookingData);
                setPageCount(Math.ceil(response.data.count / couponBookingFilter?.page_size));
            })
        } catch (error) {
            console.log("error", error);
        };
    };

    const handleLimitChange = (e) => {
        setInitialPage(0);
        setPaginationKey((prevKey) => prevKey + 1);
        setCouponBookingFilter({ ...couponBookingFilter, page_size: parseInt(e.target.value) });
    };

    const handlePageClick = (selectedPage) => {
        setCouponBookingFilter({ ...couponBookingFilter, page: selectedPage.selected + 1 })
    };

    const resetSearch = () => {
        reset();
        setInitialPage(0);
        setPaginationKey((prevKey) => prevKey + 1);
        setCouponBookingFilter({ ...initialCouponBookingListFilter, page: 1 });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDownloadFilter((prevFilter) => ({
            ...prevFilter,
            [name]: value,
        }));
    };

    const handleDownloadAsXLSX = () => {
        setDownloading(true);
        try {
            axios({
                url: adminEndPoints.downloadCouponReportExcel,
                method: "GET",
                responseType: "blob",
                params: downloadFilter,
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `BookingList-${couponBookingFilter?.page}To${couponBookingFilter?.page_size}.csv`
                );
                document.body.appendChild(link);
                link.click();
                setDownloading(false);
            });
        } catch (error) {
            console.log("error", error);
            setDownloading(false);
        }
    };

    const onSubmit = (data) => {
        try {
            setCouponBookingFilter({ ...couponBookingFilter, ...data, page: 1 });
        } catch (error) {
            console.log("error-->", error);
        }
    };

    useEffect(() => {
        getCouponBookedList(couponBookingFilter);
    }, [couponBookingFilter]);

    return (
        <Container className="dashboard">
            <Row>
                <Col sm={12} md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <h3>Coupon Booking List</h3>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col sm={12} md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row className='align-items-end'>
                                    <Col sm={10} md={10} lg={10}>
                                        <div className="form form__form-group">
                                            <div className="form__form-group-field d-flex">
                                                <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                                                    <span>Coupon Name</span>
                                                    <input
                                                        type="text"
                                                        className="custom-css-dp"
                                                        {...register("coupan_applied", { required: false })}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                                                    <span>Booking Date From</span>
                                                    <input
                                                        type="date"
                                                        className="custom-css-dp"
                                                        {...register("booking_date_after", { required: false })}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                                                    <span>Booking Date To</span>
                                                    <input
                                                        type="date"
                                                        className="custom-css-dp mt-1"
                                                        {...register("booking_date_before", { required: false })}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={2} md={2} lg={2}>
                                        <Row>
                                            <Col sm={6} md={6} lg={6} className='px-1'>
                                                <Button size='sm' color="warning" className='m-1 w-100 px-2'>Apply</Button>
                                            </Col>
                                            <Col sm={6} md={6} lg={6} className='px-1'>
                                                <Button size='sm' color="secondary" className='m-1 w-100 px-2' onClick={() => resetSearch()}>Clear</Button>
                                            </Col>
                                        </Row>
                                        <Button size='sm' color="warning" className='m-1 w-100 px-0' onClick={() => handleDownloadAsXLSX()}>
                                            {downloading ? <><Spinner size={'sm'} className='mx-1' />Downloading...</> : "Download as XLSX"}
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12} lg={12}>
                    <Card>
                        <CardBody className='mt-2 mb-4'>
                            <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                                <ReactTableBase
                                    id="table-to-xls"
                                    key={withSearchEngine ? "searchable" : "common"}
                                    columns={couponHearders}
                                    data={couponBookings}
                                    tableConfig={tableConfigCouponLog}
                                />
                            </div>
                            <div className="custom-pagination">
                                <div className="records-per-page">
                                    <select value={couponBookingFilter?.page_size} onChange={handleLimitChange} name="limit" id="select-pagination">
                                        {pageLimits && pageLimits.map((page) => (
                                            <option value={page}>
                                                {page}
                                            </option>
                                        ))}
                                    </select>&nbsp;&nbsp;&nbsp;records per page
                                </div>
                                <ReactPaginate
                                    key={paginationKey}
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    forcePage={initialPage}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CouponBookingList;