import axios from "axios";
import { environment } from "../src/containers/env";
import swal from "sweetalert";
import { userLogout } from "./redux/slice/AuthSlice";
// const axios = require('axios');
// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const customAxios = axios.create({
  baseURL: `${environment.baseUrl}`,
})

let store;
export const injectStore = _store => {
    store = _store;
};

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  const userToken = localStorage.getItem("Token");
  request.headers.Authorization = `Bearer ${userToken}`;
  return request;
};

const responseHandler = (response) => {
  // console.log("Response >>", response);
  if (response.status === 401) {
    return response;
    window.location.href = "/login";
  }
  return response;
};

const errorHandler = (error) => {
  
  if (error.response.data.code === "token_not_valid") {
    store.dispatch(userLogout());
    swal({
      title: "Session has expired, you will be loged out!",
      text: `${error.response.data.messages[0].message}`,
      icon: "warning",
      timer: 5000,
      // showConfirmButton: false,
      buttons: false,
    }).then(function () {
      window.location = "/";
      store.dispatch(userLogout());
    });
    return;
  }

  swal({  
    title: "Something went wrong!",
    text: `${error}`,
    icon: "warning",
    // timer: 5000,
    button: "Ok",
    // showConfirmButton: true,
  })
  return;
  
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
