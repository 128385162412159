import React from 'react'
import { useForm, Controller } from "react-hook-form";
import { ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { environment } from '../../env';
import axios from '../../../customAxios';

const AddCouponGroup = ({ AddCoupanReload }) => {
    const { register, setError, handleSubmit, control, reset, formState, watch } = useForm({
        mode: "onChange",
    });
    const { touchedFields, errors } = formState;
    const onSubmit = (data) => {
        try {
            const createCouponGroupURL = `${environment.baseUrl}coupon-group/`;
            axios.post(createCouponGroupURL, data).then((response) => {
                if (response?.data?.status == "success") {
                    AddCoupanReload()
                } else {
                    setError('coupon_group_name', { type: 'manual', message: response?.data?.message })
                }
            })
        } catch (error) {
            console.log(error);
        };
    };

    return (
        <Col md={12} sm={12} lg={12}>
            <Card>
                <CardBody>
                    <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form__form-group">
                            <span className="form__form-group-label">Coupon Group Name</span>
                            <div className="form__form-group-field">
                                <input
                                    type="text"
                                    placeholder="Coupon Group Name"
                                    name="coupon_group_name"
                                    {...register("coupon_group_name", { required: 'Please enter coupon group name' })}
                                />
                            </div>
                            {errors.coupon_group_name && (
                                <span className="error-msg">{errors?.coupon_group_name?.message}</span>
                            )}
                        </div>
                        <ButtonToolbar
                            className="form__form-group justify-content-end"
                        >
                            <button
                                className="justpadel-buttons-save"
                                type="submit"
                                style={{ border: "none", backgroundColor: "#1bb4f0" }}
                            >
                                Save
                            </button>
                            <button
                                className="justpadel-buttons-cancel"
                                style={{ backgroundColor: "#FDAE00", border: "none" }}
                                onClick={() => {
                                    reset({ coupon_group_name: "" });
                                }}
                            >
                                Clear
                            </button>
                        </ButtonToolbar>
                    </form>
                </CardBody>
            </Card>
        </Col>
    )
}

export default AddCouponGroup