/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Button, ButtonToolbar } from "reactstrap";
import { useNavigate } from 'react-router-dom';
// import axios from "axios";
import axios from '../../../customAxios';
import { environment } from "../../env";
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import { useSelector } from 'react-redux';

const Role = () => {
  const authslice = useSelector((state) => state.auth);
  const canDelete = authslice.loggedAdminRole === "admin";
  const [roleType, setRoleType] = useState("");
  const [mainCourt, setMainCourt] = useState("");
  const [padelCourt, setPadelCourt] = useState("");
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState();
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [pages, setPages] = useState({
    calender: '',
    manual_bookings: '',
    booking_list: '',
    notify_me: '',
    transaction: '',
    main_court: '',
    padel_court: '',
    add_on: '',
    court_type: '',
    court_category: '',
    slides: '',
    ratings: '',
    padelholic: '',
    users_ratings: '',
    coupon: '',
    registered_user: '',
    create_user: '',
    user_role_list: '',
    create_user_role: '',
    offer_doc: '',
    skill_verify_coach: ''
  });

  const handleChange = event => {
    const { name, value } = event.target;
    setPages({ ...pages, [name]: value });
  };

  const handleEdit = (id) => {
    navigate(`edit_role/${id}`);
  }
  // function handleChangeMainCourt(e) {
  //   setMainCourt(e.target.value);
  // }

  // function handlePagePadelCourt(e) {
  //   setPadelCourt(e.target.value);
  // }

  // function handleChangeRoleType(e) {
  //   setRoleType(e.target.value);
  // }

  const handleSubmitRole = () => {
    var permissiondata = [
      { calender: pages.calender },
      { manual_bookings: pages.manual_bookings },
      { booking_list: pages.booking_list },
      { notify_me: pages.notify_me },
      { transaction: pages.transaction },
      { main_court: pages.main_court },
      { padel_court: pages.padel_court },
      { add_on: pages.add_on },
      { court_type: pages.court_type },
      { court_category: pages.court_category },
      { slides: pages.slides },
      { ratings: pages.ratings },
      { padelholic: pages.padelholic },
      { users_ratings: pages.users_ratings },
      { coupon: pages.coupon },
      { registered_user: pages.registered_user },
      { create_user: pages.create_user },
      { user_role_list: pages.user_role_list },
      { create_user_role: pages.create_user_role },
      { offer_doc: pages.offer_doc ? pages.offer_doc : '' },
      { skill_verify_coach: pages.skill_verify_coach ? pages.skill_verify_coach : '' },
    ];
    var per = JSON.stringify(permissiondata);
    const roleData = {
      role_name: pages.role,
      permissions: per,
    };
    console.log(roleData);
    const roleUrl = `${environment.baseUrl}role`;
    const result = axios.post(roleUrl, roleData).then((response) => {
      console.log(response);
    });
    console.log(result);
  };

  const getRoles = () => {
    const getRoleUrl = `${environment.baseUrl}role`;
    const userRoles = axios
      .get(getRoleUrl)
      .then((response) => {
        setRoles(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log("Error" + err);
      });
    console.log(userRoles);
  };


  useEffect(() => {
    getRoles();
    console.log(roles);
  }, []);
  const setPermissions = () => {
    const padelCourtPermission = authslice?.loggedAdminPermission.find(item => item.padel_court);
    const padelCourtId = padelCourtPermission ? padelCourtPermission.padel_court : null;

    if ([2, 3, "2", "3"].includes(padelCourtId)) {
      setEditing(true);
      setReload(prev => !prev);
    }
    if (padelCourtId === 3 || padelCourtId === "3") {
      setDeleteing(true);
      setReload(prev => !prev);
    }
  };


  const reset = () => {
    document.getElementById("reset-form").reset();
    setPages("");
  };

  useEffect(() => {
    setPermissions();
  }, []);
  
  return (
    <>
      <Col md={12}>
        <Card>
          <CardBody className="create-card-body">
            {deleteing ?
              <form className="form product-edit" id="reset-form" style={{ display: "flex", flexDirection: "column" }}>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Role Name</span>
                    <div className="form__form-group-field">
                      <input type="text" value={pages.role} name="role" onChange={handleChange} style={{ borderRadius: 5, borderColor: "#646777" }} />
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Calender</span>
                    <div className="form__form-group-field">
                      <select
                        name="calender"
                        className="select"
                        onChange={handleChange}
                        value={pages.calender}
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Manual Booking</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.manual_bookings}
                        name="manual_bookings"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Booking List</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.booking_list}
                        name="booking_list"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Notify Me</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.notify_me}
                        name="notify_me"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Transactions</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.transaction}
                        name="transaction"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Main Court</span>
                    <div className="form__form-group-field">
                      <select
                        name="main_court"
                        className="select"
                        onChange={handleChange}
                        value={pages.main_court}
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Padel Court</span>
                    <div className="form__form-group-field">
                      <select
                        name="padel_court"
                        className="select"
                        onChange={handleChange}
                        value={pages.padel_court}
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Add on's</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.add_on}
                        name="add_on"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Court Type</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.court_type}
                        name="court_type"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Court Category</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.court_category}
                        name="court_category"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Slides</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.slides}
                        name="slides"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Ratings</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.ratings}
                        name="ratings"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Padelholic</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.padelholic}
                        name="padelholic"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Users Ratings</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.users_ratings}
                        name="users_ratings"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Coupon</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.coupon}
                        name="coupon"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Registered User</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.registered_user}
                        name="registered_user"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Create User</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.create_user}
                        name="create_user"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>User Role List</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.user_role_list}
                        name="user_role_list"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Create User List</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.create_user_role}
                        name="create_user_role"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Offer Doc</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.offer_doc}
                        name="offer_doc"
                      >
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form__half" style={{ marginTop: 15 }}>
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Skill Verify Coach</span>
                    <div className="form__form-group-field">
                      <select
                        className="select"
                        onChange={handleChange}
                        value={pages.skill_verify_coach}
                        name="skill_verify_coach">
                        <option value="1">Read</option>
                        <option value="2">Read/Edit</option>
                        <option value="3">Read/Edit/Delete</option>
                        <option value="4">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
                <Col md={12} lg={12}>
                  <Card style={{ marginTop: 30 }}>
                    <div className="form__half">
                      <ButtonToolbar className="form__button-toolbar" style={{ display: 'flex', justifyContent: "flex-end" }}>
                        <div className='justpadel-buttons-save' onClick={() => handleSubmitRole()}>
                          Create Role
                        </div>
                        <div className='justpadel-buttons-cancel' onClick={reset}>
                          Cancel
                        </div>
                      </ButtonToolbar>
                    </div>
                  </Card>
                </Col>
              </form>
              :
              "You Don't have the permission's to create role"
            }
          </CardBody>
        </Card>
      </Col>


      {/* <Col md={12} style={{marginTop: '5px'}}>
    <Card>
      <CardBody>
        <table className="react-table resizable-table" style={{border:'2px solid #ccc',padding:12 ,borderRadius:'10px',borderCollapse:'inherit'}}>
          <thead className="thead th">
            <tr
              role="row"
              className="react-table thead tr"
              style={{ fontSize: 15 }}
            >
              <th colSpan="1">
                <span className="react-table__column-header">
                  <span className="" style={{ padding: 12 }}>
                    #
                  </span>
                </span>
              </th>
              <th colSpan="1">
                <span className="react-table__column-header">
                  <span className="" style={{ padding: 12 }}>
                    Role Name
                  </span>
                </span>
              </th>
              <th colSpan="1">
                <span className="react-table__column-header">
                  <span className="" style={{ padding: 12 }}>
                    #
                  </span>
                </span>
              </th>
              <th colSpan="1">
                <span className="react-table__column-header">
                  <span className="" style={{ padding: 12 }}>
                    Page Name
                  </span>
                </span>
              </th>
              {/* <th colSpan="1"><span className="react-table__column-header"><span className="">About</span></span>
            </th> */}
      {/*<th colSpan="1">
                <span className="react-table__column-header">
                  <span className="" style={{ padding: 12 }}>
                    Role Type
                  </span>
                </span>
              </th>
              <th colSpan="1">
                <span className="react-table__column-header">
                  <span className="" style={{ padding: 12 }}>
                    Edit
                  </span>
                </span>
              </th>
              <th colSpan="1">
                <span className="react-table__column-header">
                  <span className="" style={{ padding: 12 }}>
                    Delete
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="table table--bordered">
            {
              roles.map((data,key)=>{
                return (
              <tr role="row" style={{ fontSize: 15 }}>
                <td role="cell">{key+1}</td>
                <td role="cell">
                {data.role_name}
                </td>
                {/* <td role="cell" className="textHide">{data.about}</td> */}
      {/*<td role="cell" style={{ width: 180, fontSize: 15 }}>
                  {JSON.parse(data.permissions).map((data,keys)=>{
                    return(
                      <>
                      <tr>
                        <td role="cell">{key=keys+1}</td>
                      </tr>
                      </>
                    )
                  })}
                </td>
                <td role="cell" style={{ width: 180, fontSize: 15 }}>
                  {JSON.parse(data.permissions).map((data,keys)=>{
                    return(
                      <>
                      <tr>
                        <td role="cell">{Object.keys(data)[0]}</td>
                      </tr>
                      </>
                    )
                  })}
                </td>
                <td role="cell">
                {JSON.parse(data.permissions).map((data,keys)=>{
                    return(
                      <tr>
                        <td role="cell">{Object.values(data)[0]}</td>
                      </tr>
                    )
                  })}
                </td>
                <td role="cell">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={()=>handleEdit(data.id)}
                      >
                        <EditOutlineIcon style={{width:'15px',height:'15px', marginTop:-3,marginLeft:3}}/>
                      </Button>
                </td>
                <td role="cell">
                     <Button
                        color="danger"
                        size="sm"
                      >
                        <DeleteOutlineIcon style={{width:'15px',height:'15px', marginTop:-3, marginLeft:3}} />
                      </Button>
                </td>
            </tr>
                )
              })
            }
          </tbody>
        </table>
      </CardBody>
    </Card>
  </Col> */}
    </>
  );
};
export default Role;
