import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Table,
  Spinner,
  Container,
  Row,
} from "reactstrap";
import moment from "moment";
import DatePicker from "react-date-picker";
import axios from "../../customAxios";
import { environment } from "../env";
import BlockStaffModal from "../../shared/components/BlockStaffModal";
import { useForm } from "react-hook-form";
import swal from "@sweetalert/with-react";
import { GenerateSlotStaffModal } from "../../shared/components/GenerateSlotStaffModal";

const StaffCalender = () => {
  const [staffTimeSlotsList, setStaffTimeSlotsList] = useState([]);
  const [staffBookingList, setStaffBookingList] = useState([]);
  const [staffDetails, setStaffDetails] = useState([]);
  const [start, onChangeStart] = useState(new Date());
  const [times, setTimes] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
    "12:45 AM",
    "01:30 AM",
    "02:15 AM",
  ]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const GetStaffBookingDetails = (startdate) => {
    setStaffBookingList([]);
    const calenderDataPayload = {
      date: startdate,
    };
    const GetBookingDetailsUrl = `${environment.baseUrl}staffmanagement/staff-calender/`;
    axios
      .post(GetBookingDetailsUrl, calenderDataPayload)
      .then((bookingResponse) => {
        setStaffTimeSlotsList(bookingResponse.data.totalUniqueStaffNameHeader);
        setStaffBookingList(bookingResponse.data.bookingDetails);
      });
  };

  const GetStaffDetails = () => {
    const GetStaffDetailsURL = `${environment.baseUrl}staffmanagement/staff/`;
    axios.get(GetStaffDetailsURL).then((res) => {
      setStaffDetails(res?.data);
    });
  };

  const submitDate = () => {
    let startdate = moment(start).format("YYYY-MM-DD");
    GetStaffBookingDetails(startdate);
  };

  const onSubmit = (data) => {
    let blockDate = moment(start).format("YYYY-MM-DD");

    const UnBlockStaffPayload = {
      blockingDate: blockDate,
      blockFrom: data?.starttime,
      blockTo: data?.endtime,
      staffId: parseInt(data?.staffid),
    };

    const UnBlockStaffURL = `${environment.baseUrl}staffmanagement/unblock-staff/`;
    axios
      .post(UnBlockStaffURL, UnBlockStaffPayload)
      .then((UnBlockCoachResponse) => {
        if (UnBlockCoachResponse.data.Message === "Staff is UnBlocked") {
          swal({
            icon: "success",
            content: (
              <div>
                <h1 style={{ color: "#28a745" }}>Staff is UnBlocked</h1>
              </div>
            ),
          }).then(() => {
            submitDate();
          });
        } else {
          swal({
            icon: "error",
            content: (
              <div>
                <h1 style={{ color: "darkred" }}>Error</h1>
                <p>Error, While UnBlocking Staff</p>
              </div>
            ),
          });
        }
      });
  };

  useEffect(() => {
    let startdate = moment().format("YYYY-MM-DD");
    GetStaffBookingDetails(startdate);
    GetStaffDetails();
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={3} lg={3}>
          <h3 className="page-title">Staff Booking Calender</h3>
        </Col>
        <Col md={9} lg={9}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="flex-nowrap">
              {/* <div className="inner-container">
                <DatePicker
                  dateFormat="YYYY-MM-DD"
                  onChange={onChangeStart}
                  value={start}
                  className="custom-css-dp"
                />
              </div> */}
              <Col sm="3" md="3" lg="3" className="px-2">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <select
                      name="padelcourt"
                      className="textareaFeild"
                      {...register("starttime", { required: true })}
                    >
                      <option value="">Select Start Time</option>
                      {times &&
                        times.map((time) => (
                          <option value={time}>{time}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.starttime && (
                  <span className="invalid-feedback d-flex">
                    Start time is required
                  </span>
                )}
              </Col>
              <Col sm="3" md="3" lg="3" className="px-0">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <select
                      name="padelcourt"
                      className="textareaFeild"
                      {...register("endtime", { required: true })}
                    >
                      <option value="">Select End Time</option>
                      {times &&
                        times.map((time) => (
                          <option value={time}>{time}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.endtime && (
                  <span className="invalid-feedback d-flex">
                    End time is required
                  </span>
                )}
              </Col>
              <Col sm="2" md="2" lg="2" className="px-1">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <select
                      name="padelcourt"
                      className="textareaFeild"
                      {...register("staffid", { required: true })}
                    >
                      <option value="">Select CoachId</option>
                      {staffDetails &&
                        staffDetails.map((Staff) => (
                          <option value={Staff.id}>{Staff.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.staffid && (
                  <span className="invalid-feedback d-flex">
                    Please Select Coach
                  </span>
                )}
              </Col>

              <Col sm="2" md="2" lg="2">
                <div className="form__form-group">
                  <div className="inner-container">
                    <button
                      color="warning"
                      size="sm"
                      style={{
                        height: 35,
                        backgroundColor: "#FDAE00",
                        paddingRight: 20,
                        paddingLeft: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        borderRadius: 4,
                        fontSize: 15,
                        fontFamily: "sans-serif",
                        border: 0,
                      }}
                    >
                      <p style={{ color: "#fff" }}>UnBlock</p>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <span
                style={{
                  marginTop: 4,
                  fontSize: 16,
                  color: "#6d6d6d",
                  fontFamily: "sans-serif",
                  marginRight: 15,
                }}
              >
                Select Date
              </span>
              <DatePicker
                dateFormat="YYYY-MM-DD"
                onChange={onChangeStart}
                value={start}
                className="custom-css-dp"
              />
            </div>

            <div className="cal-top-submit-btn">
              <button
                color="warning"
                size="sm"
                onClick={submitDate}
                style={{
                  height: 35,
                  backgroundColor: "#FDAE00",
                  paddingRight: 20,
                  paddingLeft: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  borderRadius: 4,
                  fontSize: 15,
                  fontFamily: "sans-serif",
                  border: 0,
                }}
              >
                <p style={{ color: "#fff" }}>Submit</p>
              </button>
            </div>
          </div>
          <Table responsive className="calendar mt-4">
            <thead>
              <tr className="d-flex">
                {staffTimeSlotsList.map((data, index) => {
                  return (
                    <th
                      style={{ height: "100%", width: 110 }}
                      className="calender__courts"
                      key={index}
                    >
                      Staff - {data}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {staffBookingList.map((booking, index) => {
                return (
                  <>
                    <tr style={{ display: "flex", marginLeft: -6 }} key={index}>
                      <td className="cal-headerTD calender__timeslots">
                        {booking.timeslots}
                      </td>

                      {booking.bookingData.map((datas) => {
                        return (
                          <>
                            {datas.bookingdetails === "Available" ? (
                              <BlockStaffModal
                                bnt="Available"
                                color="primary"
                                header
                                title="Block Staff Time"
                                staffId={datas.staffId}
                                submitDate={submitDate}
                                selectedDate={moment(start).format(
                                  "YYYY-MM-DD"
                                )}
                              />
                            ) : datas.bookingdetails === "Blocked" ? (
                              <td className="calender__booked bg-danger">
                                Blocked
                              </td>
                            ) : datas.bookingdetails === "Generate Slots" ? (
                              <GenerateSlotStaffModal
                                bnt="Generate Slots"
                                color="primary"
                                header
                                title="Generate Slots for Staff"
                                staffId={datas.staffId}
                                submitDate={submitDate}
                                selectedDate={moment(start).format(
                                  "YYYY-MM-DD"
                                )}
                              />
                            ) : (
                              <td
                                className="calender__booked"
                                style={{
                                  backgroundColor: "red",
                                }}
                              >
                                {datas.bookingdetails.bookedByFirstName}
                                {"-"}
                                {datas.bookingdetails.bookedByLastName}
                                {"-"}
                                {datas.bookingdetails.bookingId}
                              </td>
                            )}
                          </>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};

export default StaffCalender;
