import React from 'react'
import SidebarCategory from '../SidebarCategory';
import SidebarLink from '../SidebarLink';

const UserSidebar = () => {
    return (
        <SidebarCategory title="User" icon="user">
            <SidebarLink title="Registered Users" route="/user" />
            <SidebarLink title="Admin Users" route="/admin-users" />
            <SidebarLink title="Loyalty Points" route="/loyalty-points-management" />
            <SidebarLink title="Generate Slot" route="/generate-slot" />
        </SidebarCategory>
    )
};

export default UserSidebar