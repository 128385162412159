/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Card, CardBody, Col, Button, ButtonToolbar, Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from "reactstrap";
import { useForm } from "react-hook-form";
import {
  GetMainCourt,
  GetPadelCourt,
  BulkBookingApi,
  GetRegisteredUser,
  getPublicCoupon,
  GetMainCourtById,
} from "../Reuse/ApiCalls";
import "./BulkBooking.css";
import { environment } from "../env";
import axios from "../../customAxios";
import axiosSearch from "axios";
import moment from "moment";
import swal from "sweetalert";
import { AiOutlineSearch, AiOutlineCloseCircle } from "react-icons/ai";
import AddUserModal from "../../shared/components/AddUserModal";
import EditUserModal from "../../shared/components/EditUserModal";
import debounce from "lodash.debounce";
import _ from 'lodash';
import momenttimezone from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux'
import { getAllPadelCourtData } from "../../redux/thunk/ConfigThunk";

const initialState = {
  availableCoacheList: undefined,
  availableStaffList: undefined,
  finalizePlayer: undefined,
  isPlayerSelected: false,
  playerIs: undefined,
  courtTiming: undefined,
  courtStartingTime: "12:00 AM",
  courtFinishingTime: "11:45 PM",
  courtStartTimeSlots: [],
  courtEndTimeSlots: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_AVAILABLE_COACHES':
      return {
        ...state,
        availableCoacheList: action.data,
      };
    case 'COACHE_SELECTED':
      var finalizePlayer;
      var isPlayerSelected;
      var playerIs;
      const data = state.availableCoacheList.map((coache, index) => {
        if (coache.id == action.coacheId) {
          if (!coache.isSelected) {
            finalizePlayer = { ...coache, isSelected: !coache.isSelected };
            isPlayerSelected = true;
            playerIs = "coache";
          } else {
            finalizePlayer = undefined;
            isPlayerSelected = false;
            playerIs = undefined;
          }
          return { ...coache, isSelected: !coache.isSelected };
        } else {
          return { ...coache, isSelected: false };
        }
      });

      return {
        ...state,
        availableCoacheList: data,
        finalizePlayer: finalizePlayer,
        isPlayerSelected: isPlayerSelected,
        playerIs: playerIs,
      };

    case 'SELECTING_COACHE':
      const unSelectStaffList = state?.availableStaffList?.map(
        (staff, index) => {
          return { ...staff, isSelected: false };
        }
      );
      return {
        ...state,
        availableStaffList: unSelectStaffList,
      };

    case 'FETCH_AVAILABLE_STAFF':
      return {
        ...state,
        availableStaffList: action.availableStaff,
      };

    case 'STAFF_SELECTED':
      var finalizePlayer;
      var isPlayerSelected;
      var playerIs;
      const staffData = state.availableStaffList.map((staff, index) => {
        if (staff.id == action.staffId) {
          if (!staff.isSelected) {
            finalizePlayer = { ...staff, isSelected: !staff.isSelected };
            isPlayerSelected = true;
            playerIs = "staff";
          } else {
            finalizePlayer = undefined;
            isPlayerSelected = false;
            playerIs = undefined;
          }
          return { ...staff, isSelected: !staff.isSelected };
        }
        return { ...staff, isSelected: false };
        // return { ...staff };
      });

      return {
        ...state,
        availableStaffList: staffData,
        finalizePlayer: finalizePlayer,
        isPlayerSelected: isPlayerSelected,
        playerIs: playerIs,
      };

    case 'SELECTING_STAFF':
      const unSelectCoacheList = state?.availableCoacheList?.map(
        (coache, index) => {
          return { ...coache, isSelected: false };
        }
      );
      return {
        ...state,
        availableCoacheList: unSelectCoacheList,
      };

    case 'DESELECT_PLAYER':
      return {
        ...state,
        isPlayerSelected: false,
        playerIs: undefined
      };
    case 'courtTiming':
      return {
        ...state,
        courtTiming: action.payload
      }
    case 'courtStartingTime':
      return {
        ...state,
        courtStartingTime: action.payload
      }
    case 'courtFinishingTime':
      return {
        ...state,
        courtFinishingTime: action.payload
      }
    case 'courtStartTimeSlots':
      return {
        ...state,
        courtStartTimeSlots: action.payload
      }
    case 'courtEndTimeSlots':
      return {
        ...state,
        courtEndTimeSlots: action.payload
      }
    default:
      throw new Error();
  }
};


const renderField = ({
  input,
  placeholder,
  type,
  values,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} value={values} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

const BulkBooking = ({ prePopulatedDated, closeModal, close }) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [times, setTimes] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
    "12:45 AM",
    "01:30 AM",
    "02:15 AM",
  ]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [mainCourtList, setMainCourtList] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [resetData, setResetData] = useState();
  const [registeredUser, setRegisteredUser] = useState([]);
  const [customerEmail, setCustomerEmail] = useState();
  const [customerName, setCustomerName] = useState();
  const [customerPhone, setCustomerPhone] = useState();
  const [search, setSearch] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [publicCouponList, setPublicCouponList] = useState([]);
  const [clearSearch, setClearSearch] = useState(false);
  const [checkbox, setCheckBox] = useState(true);
  const [availableCoachList, setAvailableCoachList] = useState([]);
  const [availableCustomerSupportPeople, setAvailableCustomerSupportPeople] = useState([]);
  const [selectedCoache, setSelectedCoache] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const starttime = watch("starttime");
  const endTime = watch("endtime");
  const startdate = watch("startdate");
  const maincourt = watch("maincourt");

  const handleCheckBox = () => {
    setCheckBox(!checkbox)
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownStaffOpen, setDropdownStaffOpen] = useState(false);
  const [filteredMainCourt, setFilteredMainCourt] = useState([]);
  const mainDispatch = useDispatch();

  const { mainCourtListAdmin, padelCourtList, pageLimits } = useSelector((state) => state.config);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleStaff = () => setDropdownStaffOpen((prevState) => !prevState);



  // const filteredMainCourt = mainCourtList.filter((data) => (data.id !== 12))


  const maincourtRegister = register("maincourt", { required: true });
  const CustomerEmail = watch("customeremail");

  const GetPublicCouponMethod = () => {
    getPublicCoupon().then((getPublicCouponResponse) => {
      setPublicCouponList(getPublicCouponResponse);
    });
  };

  useEffect(() => {
    setResetData({
      starttime: prePopulatedDated?.startTime,
      endtime: prePopulatedDated?.endTime,
      maincourt: parseInt(prePopulatedDated?.main_court_id),
      padelcourt: parseInt(prePopulatedDated?.padelcourtId),
      startdate: prePopulatedDated?.startDate,
      enddate: prePopulatedDated?.endDate,
    });
    handleMainCourt(parseInt(prePopulatedDated?.main_court_id));
    const timeslot = [];
    times.map((data) => {
      timeslot.push({ value: data, label: data });
    });
    setTimeSlots(timeslot);
    if (prePopulatedDated?.main_court_id) {
      GetMainCourtById(prePopulatedDated?.main_court_id).then((response) => {
        setMainCourtList([response]);
      });
    };

    GetPublicCouponMethod();
  }, []);

  register("maincourt", {
    onChange: (e) => console.log(e),
  });

  useEffect(() => {
    reset({
      starttime: prePopulatedDated?.startTime,
      endtime: prePopulatedDated?.endTime,
      maincourt: parseInt(prePopulatedDated?.main_court_id),
      padelcourt: parseInt(prePopulatedDated?.padelcourtId),
      startdate: prePopulatedDated?.startDate,
      enddate: prePopulatedDated?.endDate,
    });
  }, [prePopulatedDated]);

  const GetAvailableCoachList = (payload) => {
    const GetAvailableCoachListUrl = `${environment.baseUrl}staffmanagement/admin-coache-availability/`;
    const result = axios
      .post(GetAvailableCoachListUrl, payload)
      .then((response) => {
        const data = response.data.map((data) => {
          return { ...data, isSelected: false };
        });
        setAvailableCoachList(data);
        dispatch({ type: 'FETCH_AVAILABLE_COACHES', data })

      })
      .catch((err) => {
        console.log("not available====>>", err);
      });
  };

  const GetAvailablePeoplesList = (payload) => {
    const GetAvailablePeopleListUrl = `${environment.baseUrl}staffmanagement/admin-staff-availability/`;
    const result = axios
      .post(GetAvailablePeopleListUrl, payload)
      .then((response) => {
        const data = response.data.map((data) => {
          return { ...data, isSelected: false };
        });
        dispatch({ type: 'FETCH_AVAILABLE_STAFF', availableStaff: data })
        setAvailableCustomerSupportPeople(response.data);
      })
      .catch((err) => {
        console.log("not available====>>", err);
      });
  };

  const handleMainCourt = async (value) => {
    // await GetPadelCourt().then((response) => {
    //   const padelCourt = [];
    //   let padelCourts = response;
    //   let padelCourtFilter = padelCourts.filter(function (padelCourts) {
    //     return padelCourts.maincourt.id === parseInt(value);
    //   });
    //   padelCourtFilter.map((data) => {
    //     padelCourt.push({ id: data.id, courtname: data.courtname });
    //   });
    //   // setPadelCourtList(padelCourt);
    // });
  };



  const CoachBooking = async (bookingID, bookFrom, bookTo, bookingDate, bookingCategory) => {
    const CoachCSBookingPayload = {
      bookFrom: bookFrom,
      bookTo: bookTo,
      date: bookingDate,
      coacheId: state.finalizePlayer?.id,
      bookingId: bookingID,
      price: bookingCategory == "Coaching-Just Padel" ? 0 : parseInt(state.finalizePlayer?.Pricing)
    };
    const BookCoacheURL = `${environment.baseUrl}staffmanagement/admin-coache-booking/`;
    await axios.post(BookCoacheURL, CoachCSBookingPayload).then((CoacheBookingResponse) => {
      dispatch({ type: 'DESELECT_PLAYER' });
    })
  };

  const CSBooking = async (bookingID, bookFrom, bookTo, bookingDate) => {
    const CSBookingPayload = {
      bookFrom: bookFrom,
      bookTo: bookTo,
      date: bookingDate,
      staffId: state.finalizePlayer?.id,
      bookingId: bookingID,
      price: parseInt(state.finalizePlayer?.Pricing)
    };
    const BookCSURL = `${environment.baseUrl}staffmanagement/admin-staff-booking/`;
    await axios.post(BookCSURL, CSBookingPayload).then((CSBookingResponse) => {
      dispatch({ type: 'DESELECT_PLAYER' });
    })
  };


  const onSubmit = async (values) => {
    setSpinner(true);
    const adminId = localStorage.getItem("UserId");

    const bulkbookingPayload = {
      start_date: values.startdate,
      end_date: values.enddate,
      start_time: values.starttime,
      end_time: values.endtime,
      maincourtid: parseInt(values.maincourt),
      padecourtid: parseInt(values.padelcourt),
      customer_name: customerName
        ? customerName
        : localStorage.getItem("UserName"),
      customer_email: customerEmail.split(" ")[0],
      customer_phone_no: customerPhone
        ? customerPhone
        : localStorage.getItem("UserPhoneNo"),
      amount_paid_by: values.amountpaidby,
      booking_category: values.booking_category,
      comments: JSON.stringify([
        {
          comment: values.comments,
          date: moment(new Date()).format("DD-MM-YYYY"),
          time: moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),
          user: localStorage.getItem("AdminUserName"),
        },
      ]),
      admin_id: parseInt(adminId),
      amount: parseInt(values.amountpaid),
      add_ons: "",
      sendmail: values.sendmail,
      coupan_applied: values.apply_coupon,
    };

    BulkBookingApi(bulkbookingPayload).then(async (response) => {
      if (response.Message === "Success") {
        handleCustomerDetails(response?.BookingId, bulkbookingPayload);
        if (state.isPlayerSelected) {
          if (state.playerIs === "coache") {
            await CoachBooking(response?.BookingId, values.starttime, values.endtime, values.startdate, values.booking_category);
          } else if (state.playerIs === "staff") {
            await CSBooking(response?.BookingId, values.starttime, values.endtime, values.startdate);
          } else {
            console.log("not booking staff and coache");
          }
        }
      } else if (response.Message === "Court Already Booked!!!") {
        setSpinner(false);
        swal({
          title: "Error",
          text: "Please. Try booking again. Court is already Booked",
          icon: "error",
          button: "Close",
        });
      } else {
        setSpinner(false);
        swal({
          title: "Error",
          text: "Error while Booking",
          icon: "error",
          button: "Close",
        });
      }
    });
  };

  const handleCustomerDetails = (booking_id, data) => {

    const userId = localStorage.getItem("UserId");
    const customerDetails = {
      customer_name: data?.customer_name,
      customer_email: data?.customer_email,
      customer_phone: data?.customer_phone_no,
      payment_type: data?.amount_paid_by,
      user: parseInt(userId),
      booking: booking_id,
      additionalcomments: data?.comments,
      sendmail: data.sendmail,
    };

    const admonBookingUrl = `${environment.baseUrl}admin_booking`;
    const result = axios
      .post(admonBookingUrl, customerDetails)
      .then((response) => {
        setSpinner(false);
        swal({
          title: "Court Booked",
          text: "Court is Booked, Booking id is " + booking_id,
          icon: "success",
          button: "Close",
        }).then(() => {
          closeModal();
        });
      })
      .catch((err) => {
        setSpinner(false);
        swal({
          title: "Error",
          text: "Error while booking" + err,
          icon: "error",
          button: "Close",
        });
      });
    localStorage.removeItem("UserName");
    localStorage.removeItem("UserPhoneNo");
  };

  let newValue;
  let cancelToken;

  const getData = () => {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel();
    }
    //Save the cancel token for the current request
    if (axiosSearch.CancelToken) {
      cancelToken = axiosSearch.CancelToken.source();
    }

    const FilterUserURL = `${environment.baseUrl}filter-user/${newValue}`;
    const userToken = localStorage.getItem("Token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    };
    const result = axiosSearch
      .get(FilterUserURL, { headers: headers, cancelToken: cancelToken.token })
      .then((filterUserResponse) => {
        setSearch(true);
        setRegisteredUser(filterUserResponse.data);
      });
  };

  // const debounce = function (fn, d) {
  //   let timer;

  //   return function () {
  //     if (timer) {
  //       clearTimeout(timer);
  //     }

  //     timer = setTimeout(fn, d);
  //   };
  // };

  // const intermediate =  debounce(() => { getData() }, 3000)
  const debouncedThing = _.debounce(getData, 3000);

  // execute debounce, it will wait one second before executing thing
  debouncedThing();

  // will cancel the execution of thing if executed before 1 second
  debouncedThing.cancel();

  const searchOnEmail = (value) => {
    if (value.length > 0) {
      newValue = value;
      debouncedThing();
    }
    setRegisteredUser();
    setSearch(false);
  };

  const hideSearchBar = () => {
    setSearch(false);
  };

  const ShowDetails = (email, name, phone_no) => {
    setSearch(false);
    setShowEdit(true);
    var uname = name === "null" ? "No Data" : name;
    setCustomerName(uname);
    setCustomerEmail(email);
    localStorage.setItem("UserName", uname);
    var pnumber = phone_no === "null" ? "No Data" : phone_no;
    setCustomerPhone(pnumber);
    localStorage.setItem("UserPhoneNo", pnumber);
    setSearch(false);
    setClearSearch(true);
  };

  const handleSelectedCoache = (coache) => {
    setSelectedCoache(coache);
  };

  useEffect(() => {
    setCustomerName(customerName);
    setCustomerPhone(customerPhone);
  }, [customerName, customerPhone]);


  useEffect(() => {
    reset(resetData);
    setTimeout(() => {
      reset(resetData);
    }, 1000);
  }, [resetData])


  const handleClearSearch = () => {
    setCustomerName("");
    setCustomerEmail("");
    setCustomerPhone("");
    setSearch(false);
    setClearSearch(false);
    setShowEdit(false);
  };

  useEffect(() => {
    const FilterPlayerPayload = {
      starttime: starttime,
      endtime: endTime,
      date: startdate,
      location: parseInt(maincourt),
    };

    if (endTime && starttime && startdate && maincourt) {
      GetAvailableCoachList(FilterPlayerPayload);
      GetAvailablePeoplesList(FilterPlayerPayload);
    }

  }, [endTime, starttime, startdate, maincourt]);

  const selectedBookingCategory = watch("booking_category");

  useMemo(() => {
    if (selectedBookingCategory) {
      if (selectedBookingCategory.includes("Coaching") > 0) {
        if (state.availableCoacheList?.length > 0) {
          if (!state.isPlayerSelected && (!state.playerIs)) {
            dispatch({ type: "COACHE_SELECTED", coacheId: state.availableCoacheList[0]?.id })
          } else if (state.isPlayerSelected && state.playerIs == "staff") {
            dispatch({ type: "COACHE_SELECTED", coacheId: state.availableCoacheList[0]?.id })
          }
        }
      } else {
        dispatch({ type: 'DESELECT_PLAYER' });
        dispatch({ type: 'SELECTING_STAFF' });
      }
    }
  }, [selectedBookingCategory]);

  // ----------------- Code to generate start time for booking start here  -------------

  const CreateStartAndEndTiming = () => {
    let day;
    let splitedDay;
    let startCourtTiming;
    let endCourtTiming;

    // setIsLoading(true);

    if (new Date(startdate).getDay() == 0 || new Date(startdate).getDay() == 6) {

      if (state?.courtTiming?.split(",")[1]) {
        day = state?.courtTiming?.split(",")[1];
        splitedDay = day?.split(": ")[1];

        if (splitedDay?.indexOf("to") > 0) {
          startCourtTiming = splitedDay?.split("to")[0];
          endCourtTiming = splitedDay?.split("to")[1];
        } else {
          startCourtTiming = splitedDay?.split("-")[0];
          endCourtTiming = splitedDay?.split("-")[1];
        }
        // setIsLoading(false);
      } else {
        day = state?.courtTiming?.split(",")[0];
        splitedDay = day?.split(": ")[1];

        if (splitedDay?.indexOf("to") > 0) {
          startCourtTiming = splitedDay?.split("to")[0];
          endCourtTiming = splitedDay?.split("to")[1];
        } else {
          startCourtTiming = splitedDay?.split("-")[0];
          endCourtTiming = splitedDay?.split("-")[1];
        }
        // setIsLoading(false);
      }

    } else {
      day = state?.courtTiming?.split(",")[0];
      splitedDay = day?.split(": ")[1];

      if (splitedDay?.indexOf("to") > 0) {
        startCourtTiming = splitedDay?.split("to")[0];
        endCourtTiming = splitedDay?.split("to")[1];
      } else {
        startCourtTiming = splitedDay?.split("-")[0];
        endCourtTiming = splitedDay?.split("-")[1];
      }
    }

    dispatch({ type: 'courtStartingTime', payload: moment(startCourtTiming, ["h:mm a"]).format("hh:mm A") });
    dispatch({ type: 'courtFinishingTime', payload: moment(endCourtTiming, ["h:mm a"]).format("hh:mm A") });
  };


  const GenerateCourtStatingTime = () => {
    var selectedBookingDateIs = moment(startdate).format("YYYY-MM-DD");
    var bookingDate = moment(selectedBookingDateIs, ["YYYY-MM-DD"]).format("YYYY-MM-DD");

    var dubaiCurrentTime = momenttimezone.tz('Asia/Dubai').format('HH:mm:ss');

    var bookingStartTime = moment(state.courtStartingTime, ["h:mm A"]).format("HH:mm:ss");

    var start = new Date(`${bookingDate}T${bookingStartTime}.826Z`);

    var bookingEndTime = moment(state.courtFinishingTime, ["h:mm A"]).format("HH:mm:ss");
    var endDate;
    var dubaiCurrentTimeZone;

    if (new Date(startdate).getDate() == new Date().getDate()) {
      dubaiCurrentTimeZone = new Date(`${bookingDate}T${dubaiCurrentTime}.826Z`);
    } else {
      dubaiCurrentTimeZone = new Date(`${bookingDate}T${bookingStartTime}.826Z`);
    }

    var courtStartingTiming = [];

    if (state.courtFinishingTime == "12:00 AM" || state.courtFinishingTime == "02:15 AM" || state.courtFinishingTime == "01:30 AM") {
      endDate = moment(bookingDate).add(1, 'days').format("YYYY-MM-DD");
    } else {
      endDate = moment(selectedBookingDateIs, ["YYYY-MM-DD"]).format("YYYY-MM-DD");
    }

    var end = new Date(`${endDate}T${bookingEndTime}.826Z`);
    while (start < end) {
      var timeSplited = start.toISOString().split('T')[1];
      var thisSelectedTime = moment(timeSplited.split('.')[0], 'HH:m:s').format('hh:mm A');
      courtStartingTiming.push({ label: thisSelectedTime, value: thisSelectedTime, timestamp: moment(new Date(startdate).toISOString().split("T") + " " + thisSelectedTime, "YYYY-MM-DD, hh:mm A").format("X"), });
      start.setMinutes(start.getMinutes() + 45);
      start = new Date(start);
    }

    courtStartingTiming = courtStartingTiming.sort((a, b) => b.timestamp - a.timestamp).reverse();
    courtStartingTiming = courtStartingTiming.filter((item) => item.label !== "02:15 AM");
    dispatch({ type: 'courtStartTimeSlots', payload: courtStartingTiming });
  };

  const GenerateCourtFinishingTime = () => {
    var selectedBookingDateIs = moment(startdate).format("YYYY-MM-DD");
    var bookingDate = moment(selectedBookingDateIs, ["YYYY-MM-DD"]).format("YYYY-MM-DD");
    var endDate;
    var bookingStartTime = moment(starttime, ["h:mm A"]).format("HH:mm:ss");
    var start = new Date(`${bookingDate}T${bookingStartTime}.826Z`);

    if (state.courtFinishingTime == "12:00 AM" || state.courtFinishingTime == "02:15 AM" || state.courtFinishingTime == "01:30 AM") {
      if (starttime == "12:00 AM" || starttime == "12:45 AM" || starttime == "01:30 AM" || starttime == "02:15 AM") {
        endDate = moment(selectedBookingDateIs, ["YYYY-MM-DD"]).format("YYYY-MM-DD");
      } else {
        endDate = moment(bookingDate).add(1, 'days').format("YYYY-MM-DD");
      }
    } else {
      endDate = moment(selectedBookingDateIs, ["YYYY-MM-DD"]).format("YYYY-MM-DD");
    }

    start.setMinutes(start.getMinutes() + 45);
    var bookingEndTime;

    if (starttime == "12:00 AM" || starttime == "12:45 AM" || starttime == "01:30 AM" || starttime == "02:15 AM") {
      bookingEndTime = moment(state.courtFinishingTime, ["h:mm A"]).format("HH:mm:ss");
    } else if (state.courtFinishingTime == "12:00 AM" || state.courtFinishingTime == "02:15 AM" || state.courtFinishingTime == "01:30 AM") {
      bookingEndTime = moment("12:00 AM", ["h:mm A"]).format("HH:mm:ss");
    } else {
      bookingEndTime = moment(state.courtFinishingTime, ["h:mm A"]).format("HH:mm:ss");
    }

    var end = new Date(`${endDate}T${bookingEndTime}.826Z`);
    var courtFinishingTiming = [];

    while (start <= end) {
      var timeSplited = start.toISOString().split('T')[1];
      var thisSelectedTime = moment(timeSplited.split('.')[0], 'HH:m:s').format('hh:mm A');
      courtFinishingTiming.push({ label: thisSelectedTime, value: thisSelectedTime });
      start.setMinutes(start.getMinutes() + 45);
      start = new Date(start);
    }

    // setEndTime(courtFinishingTiming[0]?.label)
    dispatch({ type: "courtEndTimeSlots", payload: courtFinishingTiming });
    // setIsLoading(false);
  };


  // ----------------- Code to generate start time for booking end here -------------

  useMemo(() => {
    if (maincourt && mainCourtList.length > 0) {
      var selectedMainCourt = mainCourtList.filter((court) => court.id == parseInt(maincourt))
      dispatch({ type: 'courtTiming', payload: selectedMainCourt[0]?.timeslot });
    }
  }, [maincourt, mainCourtList, startdate]);

  useMemo(() => {
    if (state.courtTiming) {
      CreateStartAndEndTiming();
    }
  }, [state.courtTiming, startdate]);

  useMemo(() => {
    if (startdate && state.courtStartingTime) {
      GenerateCourtStatingTime();
    }
  }, [state.courtStartingTime, startdate]);

  useMemo(() => {
    if (starttime && state.courtStartingTime) {
      GenerateCourtFinishingTime();
    }
  }, [starttime, state.courtStartTimeSlots]);

  useEffect(() => {
    if (mainCourtList.length > 0) {
      const filteredMainCourts = mainCourtList.filter((data) => (data.id !== 12));
      setFilteredMainCourt(filteredMainCourts);
    }
  }, [mainCourtList]);

  useEffect(() => {
    if (prePopulatedDated) {
      reset({ "padelcourt": prePopulatedDated?.padelcourtId });
    }
  }, [prePopulatedDated]);

  useEffect(() => {
    if (!starttime) {
      reset({ "starttime": prePopulatedDated?.startTime });
    }
  }, [starttime])


  return (
    <Col md={12} lg={12} xl={12} onClick={hideSearchBar}>
      <Card>
        <CardBody className="card-body">
          <div className="center-spinner">
            {spinner ? <Spinner className="table-fetch-spinner" /> : ""}
          </div>
          <div className="bulk-header-container">
            <div className="bulk-header">
              <h3 className="bulk-h3 mr-3">
                {" "}
                <nobr>Client Details:</nobr>
              </h3>
              <div
                className="bulk-d"
                style={{ minWidth: showEdit ? "49%" : "62%" }}
              >
                <input
                  type="text"
                  className="bulk-input"
                  onChange={(e) => setCustomerEmail(e.target.value)}
                  onKeyUp={(e) => searchOnEmail(e.target.value)}
                  disabled={clearSearch}
                  value={
                    customerEmail && customerName && customerPhone
                      ? `${customerName} - ${customerEmail}  - ${customerPhone}`
                      : customerEmail
                  }
                />

                {clearSearch ? (
                  <AiOutlineCloseCircle
                    className="close"
                    onClick={() => handleClearSearch()}
                  />
                ) : (
                  <AiOutlineSearch
                    className="search-icon"
                    style={{ marginLeft: 0, fontSize: "24px" }}
                  />
                )}
                {search ? (
                  <div className="email-list" id="data">
                    {registeredUser?.map((item, key) => (
                      <div
                        key={key}
                        onClick={() =>
                          ShowDetails(
                            item.email,
                            item.first_name,
                            item.phone_no
                          )
                        }
                      >
                        <div className="single-emeil__data">
                          {item.first_name === "null"
                            ? "No Data"
                            : item.first_name}{" "}
                          {" - "} {item.email} {" - "}{" "}
                          {item.phone_no === "null" ? "No Data" : item.phone_no}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {showEdit && (
                <EditUserModal
                  btn="Edit User"
                  header
                  title="User Details"
                  color="primary"
                  ShowDetails={ShowDetails}
                  name={customerName}
                  email={customerEmail}
                  phone_no={customerPhone}
                />
              )}
              <AddUserModal
                btn="Add User"
                header
                title="User Details"
                color="primary"
                ShowDetails={ShowDetails}
              />
            </div>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Start Date</span>
                <div className="form__form-group-field">
                  <input
                    name="startdate"
                    type="date"
                    {...register("startdate", { required: true })}
                  />
                </div>
                {errors.startdate && (
                  <span style={{ color: "#bc4040" }}>
                    {" "}
                    Start Date Is require
                  </span>
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">End Date</span>
                <div className="form__form-group-field">
                  <input
                    name="enddate"
                    type="date"
                    {...register("enddate", { required: true })}
                  />
                </div>
                {errors.enddate && (
                  <span style={{ color: "#bc4040" }}> End Date Is require</span>
                )}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Start Time</span>
                <div className="form__form-group-field">
                  <select
                    onClick={hideSearchBar}
                    name="starttime"
                    className="textareaFeild"
                    {...register("starttime", { required: true })}
                  >
                    <option value="">Select Start Time </option>
                    {
                      state.courtStartTimeSlots.map((timing, index) => {
                        return (<option key={index} value={timing.value}>{timing.label}</option>)
                      })
                    }
                  </select>
                </div>
                {errors.starttime && (
                  <span style={{ color: "#bc4040" }}>
                    {" "}
                    Start Time Is require
                  </span>
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">End Time</span>
                <div className="form__form-group-field">
                  <select
                    onClick={hideSearchBar}
                    name="endtime"
                    className="textareaFeild"
                    {...register("endtime", { required: true })}
                  >
                    <option value="">Select End Time</option>
                    {
                      state.courtEndTimeSlots.map((timing, index) => {
                        return (<option key={index} value={timing.value}>{timing.label}</option>)
                      })
                    }
                  </select>
                </div>
                {errors.endtime && (
                  <span style={{ color: "#bc4040" }}> End Time Is require</span>
                )}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Location</span>
                <div className="form__form-group-field">
                  <select
                    onClick={hideSearchBar}
                    name="maincourt"
                    className="textareaFeild"
                    {...maincourtRegister}
                    onChange={(e) => {
                      maincourtRegister.onChange(e);
                      handleMainCourt(e.target.value);
                    }}
                  >
                    <option value="">Select Main Court</option>
                    {mainCourtListAdmin?.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.courtName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.maincourt && (
                  <span style={{ color: "#bc4040" }}>
                    {" "}
                    Main Court Is require
                  </span>
                )}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group" style={{ marginLeft: 30 }}>
                <span className="form__form-group-label">Padel Court</span>
                <div className="form__form-group-field">
                  <select
                    onClick={hideSearchBar}
                    name="padelcourt"
                    className="textareaFeild"
                    {...register("padelcourt", { required: true })}
                  >
                    <option value="">Select Padel Court</option>
                    {padelCourtList.filter((data) => data.maincourt.id === parseInt(maincourt))?.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          Court - {data.courtname}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.padelcourt && (
                  <span style={{ color: "#bc4040" }}>
                    {" "}
                    Padel Court Is require
                  </span>
                )}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group ">
                <span className="form__form-group-label">Amount Paid</span>
                <div className="form__form-group-field">
                  <select
                    name="amountpaidby"
                    className="textareaFeild"
                    {...register("amountpaidby", { required: true })}
                    onClick={hideSearchBar}
                  >
                    <option value="">Select method</option>
                    <option value="Pay Later">Pay Later</option>
                    <option value="Paid via Cash">Paid via Cash</option>
                    <option value="Paid via Card">Paid via Card</option>
                    <option value="Paid via 3rd Party App">
                      Paid via 3rd Party App
                    </option>
                    <option value="Paid via Simplybook">
                      Paid via Simplybook
                    </option>
                    <option value="Just Padel Booking">
                      Just Padel Booking
                    </option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                {errors.amountpaidby && (
                  <span style={{ color: "#bc4040" }}>
                    {" "}
                    Amount paid By Is require
                  </span>
                )}
              </div>
              <div className="form__form-group ">
                <span className="form__form-group-label">
                  Apply Coupon (If Applicable)
                </span>
                <div className="form__form-group-field">
                  <select
                    name="apply_coupon"
                    className="textareaFeild"
                    {...register("apply_coupon", { required: true })}
                    onClick={hideSearchBar}
                  >
                    <option value=" ">Select from the list</option>
                    {publicCouponList?.map((couponList, index) => {
                      return (
                        <option key={index} value={couponList?.name}>
                          {couponList?.name}
                          {" - "}
                          {couponList?.percentage_of_discount}%
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.apply_coupon && (
                  <span style={{ color: "#bc4040" }}> Please add coupon</span>
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Available Coach
                </span>
                <div className="form__form-group-field">
                  <Dropdown isOpen={dropdownOpen} toggle={toggle} className="w-100">
                    <DropdownToggle color="secondary" size={state.isPlayerSelected ? "sm" : "lg"} outline className="w-100 d-inline-flex">
                      {state.isPlayerSelected
                        ? state.playerIs == 'coache' ?
                          <div className="d-flex justify-content-start align-items-center">
                            <img src={state.finalizePlayer?.image} style={{ marginRight: 10, height: "40px", width: "40px" }} className="rounded-circle" />
                            <span style={{ padding: "8px 0 8px 0" }}>{state.finalizePlayer?.name}</span>
                          </div>
                          : "Select Coach"
                        : "Select Coach"
                      }
                    </DropdownToggle>
                    <DropdownMenu className="w-100" >
                      {state.availableCoacheList?.map((coachList, index) => {
                        return <DropdownItem onClick={() => [dispatch({ type: "COACHE_SELECTED", coacheId: coachList?.id })]} className="d-flex" style={{ alignItems: 'center' }}>
                          <img src={coachList?.image} className="flex" style={{ width: 40, height: 40, alignSelf: 'center' }} />
                          <div className="d-flex flex-column">
                            <span style={{ fontSize: 14, fontWeight: "600" }}>{coachList?.name}</span>
                            <span style={{ fontSize: 13, textTransform: 'capitalize' }}> {coachList?.nationality}{" | "}
                              {JSON.parse(coachList.languages).map((language, index) => {
                                return (
                                  <>
                                    {index > 0 ? ", " : ""}
                                    <span className="text-capitalize" style={{ fontSize: 12 }}>{language.value}</span>
                                  </>
                                )
                              })}
                            </span>
                          </div>
                        </DropdownItem>;
                      })}
                      {state.availableCoacheList?.length < 1 &&
                        <DropdownItem>No Coach is available for this time range</DropdownItem>
                      }
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>

              {/* <div className="form__form-group">
                <span className="form__form-group-label">
                  Available Coaches
                </span>
                <div className="form__form-group-field">
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle primary>
                    {state.isPlayerSelected
                      ? state.playerIs == 'coache' ?
                        <div><img src={state.finalizePlayer?.image}  height={25} width={25} />{state.finalizePlayer?.name}</div>  
                        : "Select Coache"
                      : "Select Coache" 
                    }
                  </DropdownToggle>
                  <DropdownMenu>
                    {state.availableCoacheList?.map((coachList, index) => {
                      return <DropdownItem onClick={()=>[dispatch({type:"COACHE_SELECTED", coacheId:coachList?.id})]}><img src={coachList?.image} height={30} width={30} />{coachList?.name}</DropdownItem>;
                    })}
                  </DropdownMenu>
                </Dropdown>                 
                </div>
              </div> */}

            </div>
            <div className="form__half">
              <div className="form__form-group left-space">
                <span className="form__form-group-label">Amount</span>
                <div className="form__form-group-field">
                  <input
                    name="amountpaid"
                    type="number"
                    step="0.01"
                    {...register("amountpaid", { required: true })}
                  />
                </div>
                {errors.amountpaid && (
                  <span style={{ color: "#bc4040" }}>
                    {" "}
                    Amount paid Is require
                  </span>
                )}
              </div>
              <div className="form__form-group" style={{ marginLeft: 30 }}>
                <span className="form__form-group-label">Category</span>

                <div className="form__form-group-field">
                  <select
                    name="booking_category"
                    className="textareaFeild"
                    {...register("booking_category", { required: true })}
                    onClick={hideSearchBar}
                  >
                    <option value="">Select method</option>
                    <option value="Bookings">Bookings</option>
                    <option value="Events">Events</option>
                    <option value="Tournaments">Tournaments</option>
                    <option value="Maintainence">Maintenance</option>
                    {/* <option value="Coaching">Coaching - Regular</option> */}
                    <option value="Coaching-Pro Tennis">
                      Coaching - Pro Tennis
                    </option>
                    <option value="Coaching-Just Padel">
                      Coaching - Just Padel
                    </option>
                    <option value="GTAP">GTAP</option>
                    <option value="Matchmaking">Matchmaking</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                {errors.booking_category && (
                  <span style={{ color: "#bc4040" }}> Please add category</span>
                )}
              </div>

              <div className="form__form-group" style={{ marginLeft: 30 }}>
                <span className="form__form-group-label">
                  Available Customer Support
                </span>
                <div className="form__form-group-field">
                  <Dropdown isOpen={dropdownStaffOpen} toggle={toggleStaff} className="w-100">
                    <DropdownToggle color="secondary" size={state.isPlayerSelected ? "sm" : "lg"} outline className="w-100 d-inline-flex">
                      {state.isPlayerSelected
                        ? state.playerIs == 'staff' ?
                          <div className="d-flex justify-content-start align-items-center">
                            <img src={state.finalizePlayer?.image} style={{ marginRight: 10, height: "40px", width: "40px" }} className="rounded-circle" />
                            <span style={{ padding: "8px 0 8px 0" }}>{state.finalizePlayer?.name}</span>
                          </div>
                          : "Select Customer Support"
                        : "Select Customer Support"
                      }
                    </DropdownToggle>
                    <DropdownMenu className="w-100" >
                      {state.availableStaffList?.map((staffList, index) => {
                        return <DropdownItem onClick={() => [dispatch({ type: "STAFF_SELECTED", staffId: staffList?.id })]} className="d-flex" style={{ alignItems: 'center' }}>
                          <img src={staffList?.image} className="flex" style={{ width: 40, height: 40, alignSelf: 'center' }} />
                          <div className="d-flex flex-column">
                            <span style={{ fontSize: 14, fontWeight: "600" }}>{staffList?.name}</span>
                            <span style={{ fontSize: 13, textTransform: 'capitalize' }}> {staffList?.nationality}{" | "}
                              {JSON.parse(staffList.languages).map((language, index) => {
                                return (
                                  <>
                                    {index > 0 ? ", " : ""}
                                    <span className="text-capitalize" style={{ fontSize: 12 }}>{language.value}</span>
                                  </>
                                )
                              })}
                            </span>
                          </div>
                        </DropdownItem>;
                      })}
                      {state.availableStaffList?.length < 1 &&
                        <DropdownItem>No Customer Support is available for this time range</DropdownItem>
                      }
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group ">
                <div className="form__form-group-field">
                  <input
                    className="toggle-btn__input send-mail"
                    type="checkbox"
                    style={{ marginLeft: 12 }}
                    name="sendmail"
                    id="sendmail"
                    {...register("sendmail")}
                    onClick={handleCheckBox}
                    checked={checkbox && "checked"}
                  />
                  <span
                    className="form__form-group-label"
                    style={{ width: "120px" }}
                  >
                    <nobr>
                      Enable to send Email Notification to the Customer
                    </nobr>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="form__form-group left-space"
              style={{ marginLeft: 0 }}
            >
              <span className="form__form-group-label">Comments</span>
              <div className="form__form-group-field">
                <textarea
                  name="comments"
                  type="textarea"
                  className="textareaFeild"
                  style={{ border: "1px solid #ccc", borderRadius: 10 }}
                  {...register("comments", { required: true })}
                />
              </div>
              {errors.comments && (
                <span style={{ color: "#bc4040" }}> Comments Is require</span>
              )}
            </div>
            <div className="btn-cust-css">
              <ButtonToolbar
                className="form__button-toolbar "
                style={{ marginBottom: "40ppx" }}
              >
                <Button type="button" size="sm" onClick={() => close()}>
                  Cancel
                </Button>
                <Button color="warning" size="sm" type="submit">
                  Submit
                </Button>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

BulkBooking.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  formdata: PropTypes.array,
};

export default BulkBooking;
// export default reduxForm({
//     form: 'horizontal_form_validation', // a unique identifier for this form
//     validate,
// })(BulkBooking);
