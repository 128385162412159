import React, { useState, useEffect } from 'react'
import {
    Col,
    CardBody,
    Spinner,
    Row,
} from "reactstrap";
import DatePicker from "react-date-picker";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import { environment } from '../env';
import axios from '../../customAxios';
import moment from 'moment';
import swal from 'sweetalert';

const GenerateSlotCard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        setIsLoading(true);
        const slotGeneratePayload = {
            "date": moment(data.date).format('YYYY-MM-DD'),
            "maincourtId": data.maincourt.id
        }
        const GenerateSlots = `${environment.baseUrl}availableslots`
        const result = axios.post(GenerateSlots, slotGeneratePayload).then((response) => {
            console.log("response.data-->", response.data);
            
            if (response.data.length > 0) {
                setIsLoading(false);
                console.log(" done");
                swal({
                    title: 'Success',
                    text: 'Slot Generated Succesfully!',
                    icon: "success",
                    button: 'Ok'
                })
            }
            else {
                swal({
                    title: "Warning",
                    text: "Error while giving loyality poinst. ",
                    icon: "error",
                    button: "Ok",
                });
            }
        })
        console.log("GenerateSlots===============>", GenerateSlots);
    }
    const loadOptions = async () => {
        const courtNameURL = `${environment.baseUrl}maincourts-admin/`;
        const Slotdata = await axios.get(courtNameURL)
        const CtNmapped = Slotdata.data.map(element => ({ title: `${element.courtName}`, value: element.id, ...element }))
        return CtNmapped;
        // return []
    }
    return (
        <CardBody>
            {isLoading && (
                <div class="loader-div">
                    <span class="loader">
                        <div className="center-spinner">
                            <Spinner className="table-fetch-spinner" />
                        </div>
                    </span>
                </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={4} lg={4}>
                        <Controller
                            control={control}
                            name="maincourt"
                            rules={{ required: true }}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    value={value}
                                    loadOptions={loadOptions}
                                    getOptionLabel={e => e.title}
                                    onChange={onChange}
                                />
                            )}
                        />
                        {errors.maincourt && (<span className="invalid-feedback d-flex" >Select maincourt!</span>)}
                    </Col>
                    <Col md={4} lg={4}>
                        <Controller
                            control={control}
                            name="date"
                            rules={{ required: true }}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <DatePicker
                                    dateFormat="YYYY-MM-DD"
                                    onChange={onChange}
                                    value={value}
                                    className="custom-css-dp"
                                />
                            )}
                        />
                        {errors.date && (<span className="invalid-feedback d-flex" >Select date!</span>)}
                    </Col>
                    <Col md={4} lg={4}>
                        <div className="cal-top-submit-btn">
                            <button
                                color="warning"
                                size="sm"
                                style={{
                                    height: 35,
                                    backgroundColor: "#FDAE00",
                                    paddingRight: 20,
                                    paddingLeft: 20,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#fff",
                                    borderRadius: 4,
                                    fontSize: 15,
                                    fontFamily: "sans-serif",
                                    border: 0,
                                }}
                            >
                                <p style={{ color: "#fff" }}>Submit</p>
                            </button>
                        </div>
                    </Col>
                </Row>
            </form>
        </CardBody>
    )
}

export default GenerateSlotCard