/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Col, Card, CardBody,
} from 'reactstrap';
import axios from '../../../customAxios';

import { useNavigate, Link } from "react-router-dom";
import { environment } from '../../env';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';

const RatingView = () => {
  const [ratings, setRatings] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const navigate = useNavigate();
  const ratingUrl = `${environment.baseUrl}rating`;

  const getRating = async () => {
    const result = await axios(ratingUrl);
    console.log(result.data);
    setRatings(result.data);
  };

  useEffect(() => {
    if (localStorage.getItem('Token')) {
      console.log('True');
    } else {
      alert('You are not logged In. Please LogIn');
      navigate('/');
    }

    getRating();
  }, []);

  var jsonObj = [];
  
  console.log("NEw JSON ",jsonObj);
  for(let i=0; i<ratings.length; i++){
    var item = {}
    item["id"] = i+1;
    item["maincourt"] = <div className="right-space">{ratings[i].maincourt}</div>;
    item["rating"] = <div className="right-space">{ratings[i].rating}</div>;
    jsonObj.push(item);
}

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Main Court",
        accessor: "maincourt",
        disableGlobalFilter: true,
        width: 150,
      },
      {
        Header: "Ratings",
        accessor: "rating",
        disableGlobalFilter: true,
        width: 270,
      },
    ],
    rows: jsonObj,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };
  
  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
             <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
             />
          </div>
         
          <div className="hover-effect table-id-width">
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
          />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default RatingView;
